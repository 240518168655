import { Link } from 'components/link';

import { clickedManageStaff } from '../events';
import { useNetworkManager } from '../hooks';
import styles from './shared.module.css';

export const NotApproved = () => {
  const networkManagerText = useNetworkManager();

  return (
    <>
      <p>
        <strong>
          We can&apos;t currently access data about classes in your school&apos;s MIS.
        </strong>
      </p>
      <br />
      <p>
        <strong>What&apos;s next?</strong>
      </p>
      <div className={styles.Box}>
        <p>
          You&apos;ll need to approve the Sparx Maths MIS connection and enable the required
          permissions to gain access to all Sparx features such as parent emails.
        </p>

        <p className={styles.Subtle}>
          We have contacted your {networkManagerText} about this. Please speak with them as soon as
          possible to get this approved.
        </p>

        <p className={styles.Subtle}>
          If this isn&apos;t correct, please update the network manager&apos;s details on{' '}
          {
            <Link
              to="/teacher/new-year-setup/manage-staff"
              analyticsEvent={clickedManageStaff()}
              className={styles.Link}
            >
              <span>the Manage Staff & Key Contacts tab</span>
            </Link>
          }
          .
        </p>
      </div>
    </>
  );
};
