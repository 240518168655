import { AnalyticEventFields } from 'components/client-events/client-event-provider';

import { WidgetConfig } from './types';

const CATEGORY = 'praise-stats';

export const downloadedAsImageEvent = (config: WidgetConfig): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'downloaded as image',
  labels: {
    widget: config.id,
    count: config.count,
  },
});

export const clickedSelectClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked select classes',
});
