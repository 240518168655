import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { useClientEvent } from 'components/client-events/use-client-event';
import { useAtom } from 'jotai';
import { useUpdateLibraryBook } from 'queries/library-books';

import { selectedRatingAtom } from '../../atoms';
import { Stars } from '../stars/stars';
import styles from './book-rating.module.css';

type Props = {
  studentBook: StudentBook;
  task: Task;
};

export const BookRating = ({ studentBook, task }: Props) => {
  const { sendEvent } = useClientEvent();
  const updateLibraryBook = useUpdateLibraryBook();
  const [, setSelectedRating] = useAtom(selectedRatingAtom);

  const selectRating = (rating: number) => {
    sendEvent({
      action: 'book rating',
      category: 'rating',
      labels: { value: rating, taskId: task.taskId },
    });
    updateLibraryBook.mutate({
      studentBook: {
        ...studentBook,
        rating,
      },
      taskId: task.taskId,
    });
    setSelectedRating(rating);
  };

  return (
    <>
      <p className={styles.RatingText}>Give this book a final enjoyment rating:</p>

      <div className={styles.Stars}>
        <Stars onSelectRating={selectRating} />
      </div>
    </>
  );
};
