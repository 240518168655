import { faChevronLeft, faChevronRight, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Task, TaskState } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import {
  ExperienceReward,
  ExperienceType,
} from '@sparx/api/apis/sparx/reading/users/v1/experience';
import classNames from 'classnames';
import { BankLink } from 'components/alert/banking-alert';
import { useClearAlert } from 'components/alert/hooks';
import bank from 'components/alert/images/bank.svg';
import goldPreview from 'components/alert/images/gold_reader_preview.png';
import { Button } from 'components/buttons/button';
import { useClientEvent } from 'components/client-events/use-client-event';
import { BookRating } from 'components/rating/book-rating';
import goldIcon from 'components/sections/gold_icon_white.png';
import styles from 'components/tasks/panel.module.css';
import moment, { Moment } from 'moment';
import { useGoldReaderState } from 'queries/gold';
import { useLibraryBookByPackage, useTryAnotherBook } from 'queries/library-books';
import { useTaskRewards } from 'queries/rewards';
import { useIsUserOnboarded, UserRole, useUser, useUserHasRole } from 'queries/session';
import {
  useActiveHomeworks,
  useGetBookTask,
  useGetNextPackageTask,
  useTaskActionsLoading,
} from 'queries/tasks';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FCWithChildren } from 'utils/props';
import { getTaskProgress } from 'utils/tasks';
import { timestampToMoment } from 'utils/time';
import { View } from 'views';
import { BookCompleteView } from 'views/student/book-complete-view/book-complete-view';
import {
  GoldReaderProgress,
  HomeworkPie,
  HomeworkSRPCount,
} from 'views/student/library-view/components/homework-components';
import { pathForView } from 'views/views';

import {
  PanelIconBook,
  PanelIconCross,
  PanelIconTear,
  PanelIconTick,
  PanelIconTrophy,
} from './panel-icons';
import { RewardPage } from './task-complete-pages/reward-page';
import { FailText, SuccessText } from './types';

export const TaskCheckPage: FCWithChildren<{ passed: boolean }> = ({ passed, children }) => (
  <div className={styles.Page}>
    {passed ? <PanelIconTick /> : <PanelIconCross />}
    <h2 className={`${styles.ModalTitle} enter-down-1`}>{passed ? SuccessText : FailText}</h2>
    {children && <div className={`${styles.PanelResult} enter-down-2`}>{children}</div>}
  </div>
);

export const LargeSRPReward = ({
  experience,
  className,
  onEnd,
  onStart,
}: {
  experience: number;
  className?: string;
  onEnd?: () => void;
  onStart?: () => void;
}) => {
  const [state, setState] = useState<'start' | 'finish'>('start');
  return (
    <div
      className={classNames(
        styles.SRPCounter,
        state === 'finish' && styles.SRPCounterFinish,
        className,
      )}
    >
      <strong>
        +
        <CountUp
          start={0}
          end={experience}
          delay={0.25}
          duration={Math.max(1, experience / 1000)}
          separator=","
          onStart={() => {
            onStart && onStart();
            setState('start');
          }}
          onEnd={() => {
            onEnd && onEnd();
            setState('finish');
          }}
        />
      </strong>
      SRP
    </div>
  );
};

interface IHomeworkProgressPageProps {
  homework?: Homework[];
  reward?: ExperienceReward;
  goldReaderAchieved?: boolean;
  // Whether the progress page is showing after completing a gold reader task.
  isGRTask?: boolean;
}

export const HomeworkProgressPage: React.FC<IHomeworkProgressPageProps> = ({
  homework,
  reward,
  goldReaderAchieved,
  isGRTask,
}) => {
  const multipleActive = homework && homework.length > 1;
  const homeworkWithTarget = homework?.filter(hwk => hwk.targetExperience) || [];
  const allComplete = !homework?.some(hwk => hwk.experience < hwk.targetExperience);
  const haveActiveHomework = homework && homework.length > 0;
  const onboarded = useIsUserOnboarded();
  const user = useUser();

  const experienceByType = new Map<ExperienceType, number>();
  reward?.homeworkExperience.map(hwkExp => {
    experienceByType.set(
      hwkExp.experienceType,
      experienceByType.get(hwkExp.experienceType) || 0 + hwkExp.experienceAdded,
    );
  });

  // Sort visible homework in order that they end
  homeworkWithTarget.sort((a, b) =>
    timestampToMoment(a.endDate).diff(timestampToMoment(b.endDate)),
  );

  const wheels = homeworkWithTarget.map(hwk => {
    return (
      <HomeworkProgressWheel
        homework={hwk}
        reward={reward}
        key={hwk.homeworkId}
        multiple={homeworkWithTarget.length > 1}
      />
    );
  });

  return (
    <div className={styles.Page}>
      {homeworkWithTarget.length > 0 && (
        <h2 className={`${styles.ModalTitle} enter-down-1`} style={{ margin: '0 0 25px 0' }}>
          Task{allComplete && multipleActive && 's'} {allComplete ? 'Complete' : 'Progress'}
        </h2>
      )}
      {haveActiveHomework &&
        // If there is no targetExperience on the homework then it is optional
        (homeworkWithTarget.length > 0 ? (
          <div className={styles.HomeworkWheels}>{wheels}</div>
        ) : (
          <span className="info-text enter-down-1">
            Your teacher has made your homework optional.
          </span>
        ))}
      {experienceByType.get(ExperienceType.OVERDUE) && (
        <div>
          <span className="info-text enter-down-3">
            <span className="info-text--success">
              +{experienceByType.get(ExperienceType.OVERDUE)}
            </span>{' '}
            SRP added to overdue tasks
          </span>
        </div>
      )}
      {experienceByType.get(ExperienceType.EXTRA) && !haveActiveHomework && (
        <div>
          <span className="info-text enter-down-3">
            <span className="info-text--success">
              +{experienceByType.get(ExperienceType.EXTRA)}
            </span>{' '}
            SRP added to your most recent task
          </span>
        </div>
      )}
      {reward?.bankedExperience ? (
        <div className={`enter-down-1 ${styles.GoldReaderContainer}`}>
          <span className="info-text--success">
            +
            <CountUp start={0} end={reward?.bankedExperience || 0} delay={0.25} duration={1} /> SRP
          </span>{' '}
          added to your <BankLink />
        </div>
      ) : onboarded && !user?.statistics?.goldReaderDisabled && !goldReaderAchieved && !isGRTask ? (
        <GoldReaderProgress
          animateFromPrevious={true}
          titleClassName={styles.GoldReaderTitle}
          wrapper={child => (
            <div className={`enter-down-1 ${styles.GoldReaderContainer}`}>{child}</div>
          )}
        />
      ) : null}
    </div>
  );
};

interface IHomeworkProgressWheelProps {
  homework: Homework;
  reward?: ExperienceReward;
  multiple?: boolean;
}

export const HomeworkProgressWheel = ({
  homework,
  reward,
  multiple,
}: IHomeworkProgressWheelProps) => {
  const { sendEvent } = useClientEvent();
  const rewardedXP = reward?.homeworkRewards[homework.homeworkId] || 0;
  useEffect(() => {
    // Don't let startXP be negative
    if (!homework.experience && rewardedXP > 0) {
      sendEvent({
        category: 'finish task',
        action: 'negative SRP shown',
        labels: {
          rewardedXP,
          homeworkId: homework.homeworkId,
          homeworkExperience: homework.experience,
          homeworkTargetExperience: homework.targetExperience,
        },
      });
    }
  }, [homework, rewardedXP, sendEvent]);
  const [startXP] = useState<number>(Math.max(0, homework.experience - rewardedXP));
  const [homeworkXP, setHomeworkXP] = useState<number>(startXP);
  const progress = getTaskProgress(homeworkXP, homework.targetExperience);
  const start = getTaskProgress(startXP, homework.targetExperience);

  useEffect(() => {
    setTimeout(() => {
      // If homework.experience is 0, show the rewardedXP instead.
      setHomeworkXP(homework.experience || rewardedXP);
    }, 1500);
  }, [homework, setHomeworkXP, rewardedXP]);

  return (
    <div className={styles.HomeworkProgressWheel}>
      {multiple && (
        <h4 className={styles.HomeworkProgressEnd}>
          {timeTilHomeworkEnd(timestampToMoment(homework.endDate))}
        </h4>
      )}
      <HomeworkPie
        className="enter-down-2"
        progress={progress}
        start={start}
        countUp={true}
        small={multiple}
      />
      <div style={{ height: 20 }} />
      <HomeworkSRPCount
        className="enter-down-3"
        target={homework.targetExperience}
        current={homeworkXP}
      />
      <div style={{ height: 15 }} />
    </div>
  );
};

const timeTilHomeworkEnd = (timestamp: Moment) => {
  if (timestamp.isSame(moment(), 'day')) {
    return `Today at ${timestamp.format('h:mm a')}`;
  }

  const days = timestamp.diff(moment(), 'days');
  if (days < 6) {
    return `Due on ${timestamp.format('dddd')}`;
  }
  return `Due on ${timestamp.format('MMM Do')}`;
};

interface IEndBookBankedPage {
  studentBook: StudentBook | undefined;
}

const EndBookBankedPage = ({ studentBook }: IEndBookBankedPage) => {
  return (
    <div className={styles.Page}>
      <img src={bank} className={'enter-down-1'} style={{ width: 60, marginBottom: 10 }} alt="" />
      <div className={`enter-down-1 ${styles.GoldReaderContainer}`}>
        <span className="info-text--success">
          +{(studentBook?.bankedExperience || 0).toLocaleString()} SRP
        </span>{' '}
        in your <BankLink /> for this book
      </div>
      <p className={`enter-down-3`}>Your banked points will be reviewed and awarded to you soon.</p>
    </div>
  );
};

const RatingPage: React.FC<{
  studentBook: StudentBook;
  askDifficulty?: boolean;
  taskId: string;
}> = ({ studentBook, askDifficulty, taskId }) => (
  <div className={styles.Page}>
    <BookRating studentBook={studentBook} difficultyOnly={askDifficulty} taskId={taskId} />
  </div>
);

const ContinueWithBookPage = () => (
  <div className={styles.Page}>
    <h3 className={`${styles.HavingTrouble1} enter-down-0`}>
      It looks like you&apos;re struggling...
    </h3>
    <h2 className={`${styles.HavingTrouble2} enter-down-2`}>
      Let&apos;s carry on with the next part of the book.
    </h2>
    <p className="enter-down-3">
      Try to <strong>read the text carefully</strong> before answering the questions. If you are
      finding these tasks hard you should <strong>try a different book</strong>.
    </p>
  </div>
);

interface ISwapPageProps {
  struggling: boolean;
}

const SwapPage: React.FC<ISwapPageProps> = ({ struggling }) => (
  <div className={styles.Page}>
    <h2 className={`${styles.ModalTitle} enter-down-1`} style={{ margin: '0 0 25px 0' }}>
      Would you like to swap this book for a different one?
    </h2>
    {struggling && (
      <p className="enter-down-2">
        It looks like you are finding this book hard. Why don&apos;t you try a different one.
      </p>
    )}
  </div>
);

interface ITaskCompleteModalProps {
  passed: boolean;
  retry: () => void;
  task: Task;
  hideTaskCheck?: boolean;
}

const showRatingAfterNTasks = 2;
const showRatingEveryNTasks = 3;

export const TaskCompleteModal: FCWithChildren<ITaskCompleteModalProps> = ({
  children,
  passed,
  retry,
  task,
  hideTaskCheck,
}) => {
  const loading = useTaskActionsLoading();
  const canAdminRetry = useUserHasRole(UserRole.EQMRead);
  const tryAnotherBook = useTryAnotherBook();

  const { data: libraryBook } = useLibraryBookByPackage(task.packageId);
  const studentBook = libraryBook?.studentBook;

  const nextTaskOpts = { popOnError: true, replace: true };
  const openBook = useGetBookTask(studentBook?.bookId || '', nextTaskOpts);
  const openPackageTask = useGetNextPackageTask(task.packageId, nextTaskOpts);
  const openNextTask = () => {
    if (studentBook) {
      // If we have a book then load the next book for this package
      openBook.mutate();
    } else {
      // Else just try to load the next task for this package
      openPackageTask.mutate();
    }
  };

  const onboarding = !useIsUserOnboarded();

  useGoldReaderState();

  const { data: homework } = useActiveHomeworks();
  const { data: taskRewards } = useTaskRewards();
  const rewards = (taskRewards || {})[task.taskId];
  const reward = reduceRewards(rewards);

  const [pageIndex, setPageIndex] = useState<number>(0);

  // Construct the pages to display
  const pages =
    hideTaskCheck && !(reward && reward.experience > 0)
      ? []
      : [
          <RewardPage
            key="reward-page"
            reward={reward}
            hideTaskCheck={!!hideTaskCheck}
            passed={passed}
          >
            {children}
          </RewardPage>,
        ];
  if (reward?.goldReaderAchieved) {
    pages.push(<GoldReader />);
  }
  if (
    reward?.bankedExperience ||
    reward?.currentHomeworkExperience ||
    reward?.previousHomeworkExperience
  ) {
    pages.push(
      <HomeworkProgressPage
        homework={homework}
        reward={reward}
        goldReaderAchieved={reward?.goldReaderAchieved}
        isGRTask={!studentBook?.isEbook}
      />,
    );
  }

  // Flag for if this task has finished the book or has finished the onboarding tasks
  const bookIsFinished = studentBook && studentBook.progress >= 1;

  if (bookIsFinished && studentBook?.bankedExperience) {
    pages.push(<EndBookBankedPage studentBook={studentBook} />);
  }

  // Only show at end of task for onboarding else show after two tasks
  const showRating =
    !onboarding &&
    (bookIsFinished ||
      // If they are on the 2nd task or later then every 3 tasks
      (task.taskIndex + 1 >= showRatingAfterNTasks &&
        (task.taskIndex + 1 - showRatingAfterNTasks) % showRatingEveryNTasks === 0));

  if (studentBook && showRating && bookIsFinished) {
    pages.push(
      <BookCompleteView
        bookMetadata={libraryBook.metadataAbridged as MetadataAbridged}
        studentBook={libraryBook.studentBook as StudentBook}
        task={task}
      />,
    );
  } else if (studentBook && showRating && passed) {
    pages.push(<RatingPage studentBook={studentBook} askDifficulty={false} taskId={task.taskId} />);
  }

  // If onboarding, the book cannot be skipped so don't offer the student to get
  // a different book.
  const bookIsSkippable = studentBook && !onboarding;

  // Swap page is shown if the student rates the book <= 2 stars or rates the difficulty
  // either much too hard or much to easy
  const swappable =
    !bookIsFinished &&
    ((studentBook?.rating !== 0 && (studentBook?.rating || 0) <= 4) ||
      (studentBook?.difficultyRating === 1 && !onboarding) ||
      studentBook?.difficultyRating === 5);

  // Book is deemed too hard if they have got too many tasks in a row incorrect,
  const struggling = (studentBook?.extra?.incorrectStreak || 0) >= 6;

  // Whether to prompt the user to switch book.
  const sentenceTask =
    task.state?.state.oneofKind === 'paperback' // special case
      ? task.state.state.paperback.useSelectSentence
      : undefined;
  const promptChangeBook = bookIsSkippable && (swappable || struggling) && !sentenceTask;

  // Determine if this task has been skipped
  const skipped = !onboarding && !passed && task.state?.results && task.state?.results.length >= 3;

  if (promptChangeBook) {
    pages.push(<SwapPage struggling={struggling} />);
  } else if (skipped) {
    pages.push(<ContinueWithBookPage />);
  }

  // If they are on the last page of the modal
  const onLastPage = pageIndex >= pages.length - 1;

  const getContinueButton = (outlined = false) => (
    <Button
      onClick={() => openNextTask()}
      variant={outlined ? 'secondary' : 'primary'}
      rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
      loading={loading || openBook.isLoading}
      analyticsEvent={undefined}
    >
      {bookIsFinished ? 'Finish' : 'Continue'}
    </Button>
  );

  let continueButton: JSX.Element;
  if (!onLastPage) {
    continueButton = (
      <Button
        onClick={() => setPageIndex(pageIndex + 1)}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        data-test-id="next-continue-button"
        analyticsEvent={undefined}
      >
        Next
      </Button>
    );
  } else {
    continueButton = getContinueButton(false);
  }

  let buttons = (
    <Button
      onClick={retry}
      rightIcon={<FontAwesomeIcon icon={faRedo} />}
      loading={loading}
      analyticsEvent={undefined}
    >
      {promptChangeBook ? 'Keep trying' : 'Retry'}
    </Button>
  );
  if (!onLastPage) {
    buttons = continueButton;
  } else if (task.state?.completed && !promptChangeBook) {
    buttons = (
      <>
        {canAdminRetry && (
          <Button
            onClick={retry}
            color="danger"
            variant="secondary"
            rightIcon={<FontAwesomeIcon icon={faRedo} />}
            loading={loading}
            analyticsEvent={undefined}
          >
            Retry (admin)
          </Button>
        )}
        {continueButton}
      </>
    );
  } else if (promptChangeBook && onLastPage) {
    buttons = (
      <>
        <Button
          onClick={() =>
            tryAnotherBook(
              studentBook,
              true,
              swappable
                ? 'STUDENT_SWAP_RATING'
                : struggling
                  ? 'STUDENT_SWAP_STRUGGLING'
                  : 'STUDENT_SWAP_UNKNOWN',
            )
          }
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          analyticsEvent={undefined}
        >
          Try another book
        </Button>
        {(skipped || passed) && getContinueButton(true)}
        {!skipped && !passed && buttons}
      </>
    );
  }

  return (
    <div className={classNames(styles.ModalWrapper, styles.ModalWrapperScrollable)}>
      <CSSTransition classNames="slideup-modal" timeout={500} appear={true} in={true}>
        <div className={`${styles.Panel} ${styles.Modal} ${styles.ModalTaskComplete}`}>
          {pages[pageIndex] && (
            <TransitionGroup className={styles.PageAnimationContainer} component="div">
              <CSSTransition key={pageIndex} timeout={500} classNames="PageSlide">
                <div className={styles.PageAnimationChild}>
                  {pages[pageIndex]}
                  <div className={`${styles.Buttons} enter-down-3`}>{buttons}</div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export const GoldReader = () => {
  const user = useUser();
  const navigate = useNavigate();

  return (
    <div className={styles.Page}>
      <div className={styles.GoldReaderOutline} />
      <div className={`enter-down-1 ${styles.GoldReaderContent}`}>
        <h2 className={`enter-down-2 ${styles.GoldReaderTitle}`}>
          <img src={goldIcon} alt="" />{' '}
          <span className="goldreader-text">You&apos;ve got a Gold Reader pass!</span>
        </h2>
        <p className="enter-down-3">
          <strong>Well done {user?.firstName}!</strong> You&apos;ve earned a 5-week Gold Reader
          pass. With a Gold Reader pass you can <strong>add your own books</strong> to Sparx Reader.
        </p>
        <img src={goldPreview} alt="" className="enter-down-3" />
        <div className={`enter-down-3 ${styles.GoldReadLink}`}>
          <span
            className={styles.BookButtonsLink}
            onClick={() => navigate(pathForView(View.GoldReader))}
          >
            Go to Gold Reader
          </span>
        </div>
      </div>
    </div>
  );
};

export const BookCompleteModal = ({ bookID }: { bookID: string }) => {
  const close = useClearAlert();
  const user = useUser();
  return (
    <div className={styles.PanelAlert}>
      <PanelIconTrophy />
      <h2 className={`${styles.ModalTitle} enter-down-1`}>Book Complete</h2>
      {user?.firstName && (
        <div className={`${styles.PanelResult} enter-down-2`}>
          Well done, <span>{user?.firstName}</span>!
        </div>
      )}
      <div className={`${styles.Buttons} enter-down-3`}>
        <Button
          onClick={close}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          analyticsEvent={{
            category: 'completed book modal',
            action: 'clicked continue',
            labels: { bookID, userID: user?.userId },
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const BookIncompleteModal = ({ bookID }: { bookID: string }) => {
  const close = useClearAlert();
  const user = useUser();
  return (
    <div className={styles.PanelAlert}>
      <PanelIconTear />
      <h2 className={`${styles.ModalTitle} enter-down-1`}>Book coming soon</h2>
      <div className={`${styles.PanelResult} enter-down-2`}>
        We are sorry, the rest of this book is unavailable.
        <br />
        Please check back soon!
      </div>
      <div className={`${styles.Buttons} enter-down-3`}>
        <Button
          onClick={close}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          analyticsEvent={{
            category: 'incomplete book modal',
            action: 'clicked continue',
            labels: { bookID, userID: user?.userId },
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const BookLockedModal = ({ onClose }: { onClose?: () => void }) => {
  const close = useClearAlert();
  return (
    <div className={styles.PanelAlert}>
      <PanelIconBook />
      <h2 className={`${styles.ModalTitle} enter-down-1`}>No copies available</h2>
      <div className={`${styles.PanelResult} enter-down-2`}>
        We are sorry, all the available copies of this book are in use.
        <br />
        Please choose another book!
      </div>
      <div className={`${styles.Buttons} enter-down-3`}>
        <Button
          onClick={onClose || close}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          analyticsEvent={undefined}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export const BookBlocklistedModal = ({ taskID }: { taskID: string }) => {
  const close = useClearAlert();
  const user = useUser();
  return (
    <div className={styles.PanelAlert}>
      <PanelIconBook />
      <h2 className={`${styles.ModalTitle} enter-down-1`}>Book not available</h2>
      <div className={`${styles.PanelResult} enter-down-2`}>
        We are sorry, this book isn&apos;t available to read with Sparx Reader. Please choose
        another book. <br />
        If you would like to read this book in future, please speak to your Teacher.
      </div>
      <div className={`${styles.Buttons} enter-down-3`}>
        <Button
          onClick={close}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          analyticsEvent={{
            category: 'blocklisted book modal',
            action: 'clicked ok',
            labels: { taskID, userID: user?.userId },
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export const PackageExpiredModal = ({ onClose }: { onClose?: () => void }) => {
  const close = useClearAlert();
  return (
    <div className={styles.PanelAlert}>
      <PanelIconBook />
      <h2 className={`${styles.ModalTitle} enter-down-1`}>Time up!</h2>
      <div className={`${styles.PanelResult} enter-down-2`}>
        This reading test has ended and you can no longer submit answers.
      </div>
      <div className={`${styles.Buttons} enter-down-3`}>
        <Button
          onClick={onClose || close}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          analyticsEvent={undefined}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

interface ITaskStateCompleteModalProps {
  state?: TaskState;
  retry: () => void;
  isOpen: boolean | undefined;
  task: Task;
  requirementsMetMessage?: boolean;
  hideScoreMessage?: boolean;
  hideTaskCheck?: boolean;
}

export const ScoredTaskCompleteModal: React.FC<ITaskStateCompleteModalProps> = ({
  state,
  retry,
  isOpen,
  task,
  requirementsMetMessage,
  hideScoreMessage,
  hideTaskCheck,
}) => {
  if (!isOpen) {
    return null;
  }
  if (!state) {
    return null;
  }
  const latestResult = state.results[state.results.length - 1];
  const passed = latestResult.passed;

  return (
    <TaskCompleteModal passed={passed} retry={retry} task={task} hideTaskCheck={hideTaskCheck}>
      {hideScoreMessage || latestResult.total === 0 ? (
        <></>
      ) : requirementsMetMessage ? (
        <span className="info-text">Thank you for logging your progress</span>
      ) : (
        <span className="info-text">
          Your score was{' '}
          <span className={passed ? 'info-text--success' : 'info-text--danger'}>
            {latestResult.score}/{latestResult.total}
          </span>
          .
        </span>
      )}
    </TaskCompleteModal>
  );
};

// This is gross as anything but sums over a list of rewards to a single reward
const reduceRewards = (rewards: ExperienceReward[] | undefined): ExperienceReward | undefined =>
  (rewards || []).reduce<ExperienceReward | undefined>(
    (p, v) => ({
      homeworkRewards: v.homeworkRewards || p?.homeworkRewards || {}, // TODO: check?
      detail: v.detail || p?.detail || [],
      experienceMultiplier: 0,
      ...p,
      currentHomeworkExperience: v.currentHomeworkExperience + (p?.currentHomeworkExperience || 0),
      bankedExperience: v.bankedExperience + (p?.bankedExperience || 0),
      experience: v.experience + (p?.experience || 0),
      previousHomeworkExperience:
        v.previousHomeworkExperience + (p?.previousHomeworkExperience || 0),
      goldReaderAchieved: Boolean(v.goldReaderAchieved || p?.goldReaderAchieved),
      homeworkExperience: v.homeworkExperience,
    }),
    undefined,
  );
