import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/buttons/button.module.css';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { useClientEvent } from 'components/client-events/use-client-event';
import { useState } from 'react';
import { FCWithChildren } from 'utils/props';

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onAnimationEnd?: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'srt' | 'success' | 'inverse';
  color?: 'default' | 'danger';
  size?: 'default' | 'small';
  className?: string;
  style?: React.CSSProperties;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  loading?: boolean;
  autoFocus?: boolean;
  type?: 'button' | 'submit';
  'data-test-id'?: string;
  // The analytics event to send on click. This is not an optional field so you
  // have to explicitly opt out of sending an event using undefined.
  analyticsEvent: AnalyticEventFields | undefined;
  // Minimum time between clicks of the button, button is disabled for this duration. Default 400ms.
  debounceMs?: number;
}

export const Button: FCWithChildren<ButtonProps> = ({
  children,
  onClick,
  onAnimationEnd,
  disabled,
  variant = 'primary',
  color,
  size,
  className,
  style,
  leftIcon,
  rightIcon,
  loading,
  autoFocus,
  type,
  analyticsEvent,
  'data-test-id': dataTestId,
  debounceMs = 400,
}) => {
  const { sendEvent } = useClientEvent();
  const [inDebounce, setInDebounce] = useState(false);

  return (
    <button
      type={type || 'button'}
      style={style}
      disabled={disabled || loading}
      onClick={evt => {
        if (!loading && !disabled && !inDebounce) {
          if (debounceMs > 0) {
            setInDebounce(true);
            setTimeout(() => setInDebounce(false), debounceMs);
          }
          if (onClick) {
            onClick(evt);
          }
          // Fire the analytics event either if we've just run the click handler or if the button
          // submits a form.
          if (onClick || type === 'submit') {
            if (analyticsEvent) {
              sendEvent(analyticsEvent);
            }
          }
        }
      }}
      onAnimationEnd={onAnimationEnd}
      autoFocus={autoFocus}
      className={classNames(
        styles.Button,
        {
          [styles.ButtonDisabled]: disabled,
          [styles.ButtonPrimary]: variant === 'primary',
          [styles.ButtonSecondary]: variant === 'secondary',
          [styles.ButtonSrt]: variant === 'srt',
          [styles.ButtonSuccess]: variant === 'success',
          [styles.ButtonInverse]: variant === 'inverse',
          [styles.ButtonSmall]: size === 'small',
          [styles.ButtonDanger]: color === 'danger',
          [styles.ButtonLoading]: loading,
        },
        className,
      )}
      data-test-id={dataTestId}
    >
      {leftIcon && <div className={styles.ButtonIconLeft}>{leftIcon}</div>}
      <div className={styles.ButtonContent}>{children}</div>
      {rightIcon && <div className={styles.ButtonIconRight}>{rightIcon}</div>}
      {loading && (
        <div className={styles.ButtonLoadingOverlay}>
          <FontAwesomeIcon icon={faSpinner} spin={true} fixedWidth={true} />
        </div>
      )}
    </button>
  );
};
