// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/management/v1/management.proto" (package "sparx.reading.management.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Management } from './management';
import type { CreateHomeworkViewResponse } from './management';
import type { CreateHomeworkViewRequest } from './management';
import type { ListHomeworkViewsResponse } from './management';
import type { ListHomeworkViewsRequest } from './management';
import type { GetTaskBreakpointQuestionsResponse } from './management';
import type { GetTaskBreakpointQuestionsRequest } from './management';
import type { GetStudentHomeworkResponse } from './management';
import type { GetStudentHomeworkRequest } from './management';
import type { GetStudentHomeworkTaskAttemptsResponse } from './management';
import type { GetStudentHomeworkTaskAttemptsRequest } from './management';
import type { SchoolCompletedBookCountResponse } from './management';
import type { SchoolCompletedBookCountRequest } from './management';
import type { ListAccountStatusesResponse } from './management';
import type { ListAccountStatusesRequest } from './management';
import type { School } from '../../../school/v2/schools';
import type { GetSchoolRequest } from './management';
import type { ListStudentPackageTasksResponse } from './management';
import type { ListStudentPackageTasksRequest } from './management';
import type { SwapStudentBookResponse } from './management';
import type { SwapStudentBookRequest } from './management';
import type { GetStudentBooksResponse } from './management';
import type { GetStudentBooksRequest } from './management';
import type { SearchStudentsResponse } from './management';
import type { SearchStudentsRequest } from './management';
import type { CancelAssessmentResponse } from './management';
import type { CancelAssessmentRequest } from './management';
import type { ExtendAssessmentsResponse } from './management';
import type { ExtendAssessmentsRequest } from './management';
import type { ListAssessmentPackagesResponse } from './management';
import type { ListAssessmentPackagesRequest } from './management';
import type { CreateAssessmentResponse } from './management';
import type { CreateAssessmentRequest } from './management';
import type { ListAvailableAssessmentsResponse } from './management';
import type { ListAvailableAssessmentsRequest } from './management';
import type { BatchPreviewHomeworkGroupDatesResponse } from './management';
import type { BatchPreviewHomeworkGroupDatesRequest } from './management';
import type { PreviewHomeworkGroupDatesResponse } from './management';
import type { PreviewHomeworkGroupDatesRequest } from './management';
import type { EndHomeworkGroupResponse } from './management';
import type { EndHomeworkGroupRequest } from './management';
import type { BatchUpdateHomeworkGroupsResponse } from './management';
import type { BatchUpdateHomeworkGroupsRequest } from './management';
import type { UpdateHomeworkGroupResponse } from './management';
import type { UpdateHomeworkGroupRequest } from './management';
import type { ListHomeworkGroupsResponse } from './management';
import type { ListHomeworkGroupsRequest } from './management';
import type { ListHomeworkStudentFlagsResponse } from './management';
import type { ListHomeworkStudentFlagsRequest } from './management';
import type { UpdateStudentGroupStarredResponse } from './management';
import type { UpdateStudentGroupStarredRequest } from './management';
import type { GrantStudentPasswordResponse } from './management';
import type { GrantStudentPasswordRequest } from './management';
import type { UpdateStudentResponse } from './management';
import type { UpdateStudentRequest } from './management';
import type { DeleteHomeworkResponse } from './management';
import type { DeleteHomeworkRequest } from './management';
import type { UpdateHomeworkResponse } from './management';
import type { UpdateHomeworkRequest } from './management';
import type { GetStudentGroupsResponse } from './management';
import type { GetStudentGroupsRequest } from './management';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetTeacherExperienceResponse } from './management';
import type { GetTeacherExperienceRequest } from './management';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.management.v1.Management
 */
export interface IManagementClient {
  /**
   * GetTeacherExperience queries for student experience for a teacher.
   *
   * @generated from protobuf rpc: GetTeacherExperience(sparx.reading.management.v1.GetTeacherExperienceRequest) returns (sparx.reading.management.v1.GetTeacherExperienceResponse);
   */
  getTeacherExperience(
    input: GetTeacherExperienceRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTeacherExperienceRequest, GetTeacherExperienceResponse>;
  /**
   * GetStudentGroups requests a list of student groups for the teachers school.
   *
   * @generated from protobuf rpc: GetStudentGroups(sparx.reading.management.v1.GetStudentGroupsRequest) returns (sparx.reading.management.v1.GetStudentGroupsResponse);
   */
  getStudentGroups(
    input: GetStudentGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentGroupsRequest, GetStudentGroupsResponse>;
  /**
   * UpdateHomeworkRequest requests a homework be updated or inserted.
   *
   * @generated from protobuf rpc: UpdateHomework(sparx.reading.management.v1.UpdateHomeworkRequest) returns (sparx.reading.management.v1.UpdateHomeworkResponse);
   */
  updateHomework(
    input: UpdateHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateHomeworkRequest, UpdateHomeworkResponse>;
  /**
   * DeleteHomework removes a homework. Only future or existing homeworks can be
   * removed.
   *
   * @generated from protobuf rpc: DeleteHomework(sparx.reading.management.v1.DeleteHomeworkRequest) returns (sparx.reading.management.v1.DeleteHomeworkResponse);
   */
  deleteHomework(
    input: DeleteHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteHomeworkRequest, DeleteHomeworkResponse>;
  /**
   * UpdateStudent requests that a student be updated with the values in the
   * request. The updated student is returned in the response.
   *
   * @generated from protobuf rpc: UpdateStudent(sparx.reading.management.v1.UpdateStudentRequest) returns (sparx.reading.management.v1.UpdateStudentResponse);
   */
  updateStudent(
    input: UpdateStudentRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentRequest, UpdateStudentResponse>;
  /**
   * GrantStudentPassword grants a password reset to a student.
   *
   * @generated from protobuf rpc: GrantStudentPassword(sparx.reading.management.v1.GrantStudentPasswordRequest) returns (sparx.reading.management.v1.GrantStudentPasswordResponse);
   */
  grantStudentPassword(
    input: GrantStudentPasswordRequest,
    options?: RpcOptions,
  ): UnaryCall<GrantStudentPasswordRequest, GrantStudentPasswordResponse>;
  /**
   * UpdateStudentGroupStarred updates the starred state of a student group for
   * the current authenticated user.
   *
   * @generated from protobuf rpc: UpdateStudentGroupStarred(sparx.reading.management.v1.UpdateStudentGroupStarredRequest) returns (sparx.reading.management.v1.UpdateStudentGroupStarredResponse);
   */
  updateStudentGroupStarred(
    input: UpdateStudentGroupStarredRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentGroupStarredRequest,
    UpdateStudentGroupStarredResponse
  >;
  /**
   * ListHomeworkStudentFlags returns a list of student flags that were raised
   * during a given homework.
   *
   * @generated from protobuf rpc: ListHomeworkStudentFlags(sparx.reading.management.v1.ListHomeworkStudentFlagsRequest) returns (sparx.reading.management.v1.ListHomeworkStudentFlagsResponse);
   */
  listHomeworkStudentFlags(
    input: ListHomeworkStudentFlagsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListHomeworkStudentFlagsRequest,
    ListHomeworkStudentFlagsResponse
  >;
  /**
   * ListHomeworkGroups returns a list of homework groups for the current
   * school.
   *
   * @generated from protobuf rpc: ListHomeworkGroups(sparx.reading.management.v1.ListHomeworkGroupsRequest) returns (sparx.reading.management.v1.ListHomeworkGroupsResponse);
   */
  listHomeworkGroups(
    input: ListHomeworkGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworkGroupsRequest, ListHomeworkGroupsResponse>;
  /**
   * UpdateHomeworkGroup updates a homework group and the associated homeworks.
   * If the homework group id is empty string, then one will be created.
   *
   * @generated from protobuf rpc: UpdateHomeworkGroup(sparx.reading.management.v1.UpdateHomeworkGroupRequest) returns (sparx.reading.management.v1.UpdateHomeworkGroupResponse);
   */
  updateHomeworkGroup(
    input: UpdateHomeworkGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateHomeworkGroupRequest, UpdateHomeworkGroupResponse>;
  /**
   * BatchUpdateHomeworkGroups updates a batch of homework groups and the associated homeworks.
   * If the homework group id is empty string on the group, then one will be created.
   *
   * @generated from protobuf rpc: BatchUpdateHomeworkGroups(sparx.reading.management.v1.BatchUpdateHomeworkGroupsRequest) returns (sparx.reading.management.v1.BatchUpdateHomeworkGroupsResponse);
   */
  batchUpdateHomeworkGroups(
    input: BatchUpdateHomeworkGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchUpdateHomeworkGroupsRequest,
    BatchUpdateHomeworkGroupsResponse
  >;
  /**
   * EndHomeworkGroup ends a homework group early. It can be used to remove a
   * homework group entirely or end it from a particular point.
   *
   * @generated from protobuf rpc: EndHomeworkGroup(sparx.reading.management.v1.EndHomeworkGroupRequest) returns (sparx.reading.management.v1.EndHomeworkGroupResponse);
   */
  endHomeworkGroup(
    input: EndHomeworkGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<EndHomeworkGroupRequest, EndHomeworkGroupResponse>;
  /**
   * PreviewHomeworkGroupDates returns the homework dates for a homework group.
   *
   * @generated from protobuf rpc: PreviewHomeworkGroupDates(sparx.reading.management.v1.PreviewHomeworkGroupDatesRequest) returns (sparx.reading.management.v1.PreviewHomeworkGroupDatesResponse);
   */
  previewHomeworkGroupDates(
    input: PreviewHomeworkGroupDatesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    PreviewHomeworkGroupDatesRequest,
    PreviewHomeworkGroupDatesResponse
  >;
  /**
   * BatchPreviewHomeworkGroupDates returns the homework dates for a batch of homework groups.
   *
   * @generated from protobuf rpc: BatchPreviewHomeworkGroupDates(sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesRequest) returns (sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesResponse);
   */
  batchPreviewHomeworkGroupDates(
    input: BatchPreviewHomeworkGroupDatesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchPreviewHomeworkGroupDatesRequest,
    BatchPreviewHomeworkGroupDatesResponse
  >;
  /**
   * ListAvailableAssessments returns a list of available assessments that
   * can be set.
   *
   * @generated from protobuf rpc: ListAvailableAssessments(sparx.reading.management.v1.ListAvailableAssessmentsRequest) returns (sparx.reading.management.v1.ListAvailableAssessmentsResponse);
   */
  listAvailableAssessments(
    input: ListAvailableAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAvailableAssessmentsRequest,
    ListAvailableAssessmentsResponse
  >;
  /**
   * CreateAssessment creates an assessment for some students.
   *
   * @generated from protobuf rpc: CreateAssessment(sparx.reading.management.v1.CreateAssessmentRequest) returns (sparx.reading.management.v1.CreateAssessmentResponse);
   */
  createAssessment(
    input: CreateAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAssessmentRequest, CreateAssessmentResponse>;
  /**
   * ListAssessmentPackages lists assessment packages.
   *
   * @generated from protobuf rpc: ListAssessmentPackages(sparx.reading.management.v1.ListAssessmentPackagesRequest) returns (sparx.reading.management.v1.ListAssessmentPackagesResponse);
   */
  listAssessmentPackages(
    input: ListAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentPackagesRequest, ListAssessmentPackagesResponse>;
  /**
   * Update the end time of assessment packages to a new time. The assessments
   * must either be active or able to be resumed (ended recently and incomplete).
   *
   * @generated from protobuf rpc: ExtendAssessments(sparx.reading.management.v1.ExtendAssessmentsRequest) returns (sparx.reading.management.v1.ExtendAssessmentsResponse);
   */
  extendAssessments(
    input: ExtendAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ExtendAssessmentsRequest, ExtendAssessmentsResponse>;
  /**
   * @generated from protobuf rpc: CancelAssessment(sparx.reading.management.v1.CancelAssessmentRequest) returns (sparx.reading.management.v1.CancelAssessmentResponse);
   */
  cancelAssessment(
    input: CancelAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CancelAssessmentRequest, CancelAssessmentResponse>;
  /**
   * @generated from protobuf rpc: SearchStudents(sparx.reading.management.v1.SearchStudentsRequest) returns (sparx.reading.management.v1.SearchStudentsResponse);
   */
  searchStudents(
    input: SearchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<SearchStudentsRequest, SearchStudentsResponse>;
  /**
   * @generated from protobuf rpc: GetStudentBooks(sparx.reading.management.v1.GetStudentBooksRequest) returns (sparx.reading.management.v1.GetStudentBooksResponse);
   */
  getStudentBooks(
    input: GetStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentBooksRequest, GetStudentBooksResponse>;
  /**
   * @generated from protobuf rpc: SwapStudentBook(sparx.reading.management.v1.SwapStudentBookRequest) returns (sparx.reading.management.v1.SwapStudentBookResponse);
   */
  swapStudentBook(
    input: SwapStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<SwapStudentBookRequest, SwapStudentBookResponse>;
  /**
   * @generated from protobuf rpc: ListStudentPackageTasks(sparx.reading.management.v1.ListStudentPackageTasksRequest) returns (sparx.reading.management.v1.ListStudentPackageTasksResponse);
   */
  listStudentPackageTasks(
    input: ListStudentPackageTasksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentPackageTasksRequest, ListStudentPackageTasksResponse>;
  /**
   * @generated from protobuf rpc: GetSchool(sparx.reading.management.v1.GetSchoolRequest) returns (sparx.school.v2.School);
   */
  getSchool(
    input: GetSchoolRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolRequest, School>;
  /**
   * @generated from protobuf rpc: ListAccountStatuses(sparx.reading.management.v1.ListAccountStatusesRequest) returns (sparx.reading.management.v1.ListAccountStatusesResponse);
   */
  listAccountStatuses(
    input: ListAccountStatusesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAccountStatusesRequest, ListAccountStatusesResponse>;
  /**
   * Deprecated: use the version in sparx/reading/reports/v2/reporting.proto.
   *
   * @generated from protobuf rpc: SchoolCompletedBookCount(sparx.reading.management.v1.SchoolCompletedBookCountRequest) returns (sparx.reading.management.v1.SchoolCompletedBookCountResponse);
   */
  schoolCompletedBookCount(
    input: SchoolCompletedBookCountRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SchoolCompletedBookCountRequest,
    SchoolCompletedBookCountResponse
  >;
  /**
   * @generated from protobuf rpc: GetStudentHomeworkTaskAttempts(sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsRequest) returns (sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsResponse);
   */
  getStudentHomeworkTaskAttempts(
    input: GetStudentHomeworkTaskAttemptsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentHomeworkTaskAttemptsRequest,
    GetStudentHomeworkTaskAttemptsResponse
  >;
  /**
   * @generated from protobuf rpc: GetStudentHomework(sparx.reading.management.v1.GetStudentHomeworkRequest) returns (sparx.reading.management.v1.GetStudentHomeworkResponse);
   */
  getStudentHomework(
    input: GetStudentHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentHomeworkRequest, GetStudentHomeworkResponse>;
  /**
   * For a given task, get all questions which belong to its breakpoint / end part id
   *
   * @generated from protobuf rpc: GetTaskBreakpointQuestions(sparx.reading.management.v1.GetTaskBreakpointQuestionsRequest) returns (sparx.reading.management.v1.GetTaskBreakpointQuestionsResponse);
   */
  getTaskBreakpointQuestions(
    input: GetTaskBreakpointQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetTaskBreakpointQuestionsRequest,
    GetTaskBreakpointQuestionsResponse
  >;
  /**
   * ListHomeworkViews returns the ids of teachers who viewed each homework.
   *
   * @generated from protobuf rpc: ListHomeworkViews(sparx.reading.management.v1.ListHomeworkViewsRequest) returns (sparx.reading.management.v1.ListHomeworkViewsResponse);
   */
  listHomeworkViews(
    input: ListHomeworkViewsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworkViewsRequest, ListHomeworkViewsResponse>;
  /**
   * CreateHomeworkView records that a teacher viewed a homework.
   *
   * @generated from protobuf rpc: CreateHomeworkView(sparx.reading.management.v1.CreateHomeworkViewRequest) returns (sparx.reading.management.v1.CreateHomeworkViewResponse);
   */
  createHomeworkView(
    input: CreateHomeworkViewRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateHomeworkViewRequest, CreateHomeworkViewResponse>;
}
/**
 * @generated from protobuf service sparx.reading.management.v1.Management
 */
export class ManagementClient implements IManagementClient, ServiceInfo {
  typeName = Management.typeName;
  methods = Management.methods;
  options = Management.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetTeacherExperience queries for student experience for a teacher.
   *
   * @generated from protobuf rpc: GetTeacherExperience(sparx.reading.management.v1.GetTeacherExperienceRequest) returns (sparx.reading.management.v1.GetTeacherExperienceResponse);
   */
  getTeacherExperience(
    input: GetTeacherExperienceRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTeacherExperienceRequest, GetTeacherExperienceResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetTeacherExperienceRequest,
      GetTeacherExperienceResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetStudentGroups requests a list of student groups for the teachers school.
   *
   * @generated from protobuf rpc: GetStudentGroups(sparx.reading.management.v1.GetStudentGroupsRequest) returns (sparx.reading.management.v1.GetStudentGroupsResponse);
   */
  getStudentGroups(
    input: GetStudentGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentGroupsRequest, GetStudentGroupsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentGroupsRequest, GetStudentGroupsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateHomeworkRequest requests a homework be updated or inserted.
   *
   * @generated from protobuf rpc: UpdateHomework(sparx.reading.management.v1.UpdateHomeworkRequest) returns (sparx.reading.management.v1.UpdateHomeworkResponse);
   */
  updateHomework(
    input: UpdateHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateHomeworkRequest, UpdateHomeworkResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateHomeworkRequest, UpdateHomeworkResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * DeleteHomework removes a homework. Only future or existing homeworks can be
   * removed.
   *
   * @generated from protobuf rpc: DeleteHomework(sparx.reading.management.v1.DeleteHomeworkRequest) returns (sparx.reading.management.v1.DeleteHomeworkResponse);
   */
  deleteHomework(
    input: DeleteHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteHomeworkRequest, DeleteHomeworkResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteHomeworkRequest, DeleteHomeworkResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateStudent requests that a student be updated with the values in the
   * request. The updated student is returned in the response.
   *
   * @generated from protobuf rpc: UpdateStudent(sparx.reading.management.v1.UpdateStudentRequest) returns (sparx.reading.management.v1.UpdateStudentResponse);
   */
  updateStudent(
    input: UpdateStudentRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentRequest, UpdateStudentResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateStudentRequest, UpdateStudentResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GrantStudentPassword grants a password reset to a student.
   *
   * @generated from protobuf rpc: GrantStudentPassword(sparx.reading.management.v1.GrantStudentPasswordRequest) returns (sparx.reading.management.v1.GrantStudentPasswordResponse);
   */
  grantStudentPassword(
    input: GrantStudentPasswordRequest,
    options?: RpcOptions,
  ): UnaryCall<GrantStudentPasswordRequest, GrantStudentPasswordResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GrantStudentPasswordRequest,
      GrantStudentPasswordResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * UpdateStudentGroupStarred updates the starred state of a student group for
   * the current authenticated user.
   *
   * @generated from protobuf rpc: UpdateStudentGroupStarred(sparx.reading.management.v1.UpdateStudentGroupStarredRequest) returns (sparx.reading.management.v1.UpdateStudentGroupStarredResponse);
   */
  updateStudentGroupStarred(
    input: UpdateStudentGroupStarredRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentGroupStarredRequest,
    UpdateStudentGroupStarredResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentGroupStarredRequest,
      UpdateStudentGroupStarredResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListHomeworkStudentFlags returns a list of student flags that were raised
   * during a given homework.
   *
   * @generated from protobuf rpc: ListHomeworkStudentFlags(sparx.reading.management.v1.ListHomeworkStudentFlagsRequest) returns (sparx.reading.management.v1.ListHomeworkStudentFlagsResponse);
   */
  listHomeworkStudentFlags(
    input: ListHomeworkStudentFlagsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListHomeworkStudentFlagsRequest,
    ListHomeworkStudentFlagsResponse
  > {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListHomeworkStudentFlagsRequest,
      ListHomeworkStudentFlagsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListHomeworkGroups returns a list of homework groups for the current
   * school.
   *
   * @generated from protobuf rpc: ListHomeworkGroups(sparx.reading.management.v1.ListHomeworkGroupsRequest) returns (sparx.reading.management.v1.ListHomeworkGroupsResponse);
   */
  listHomeworkGroups(
    input: ListHomeworkGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworkGroupsRequest, ListHomeworkGroupsResponse> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListHomeworkGroupsRequest,
      ListHomeworkGroupsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * UpdateHomeworkGroup updates a homework group and the associated homeworks.
   * If the homework group id is empty string, then one will be created.
   *
   * @generated from protobuf rpc: UpdateHomeworkGroup(sparx.reading.management.v1.UpdateHomeworkGroupRequest) returns (sparx.reading.management.v1.UpdateHomeworkGroupResponse);
   */
  updateHomeworkGroup(
    input: UpdateHomeworkGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateHomeworkGroupRequest, UpdateHomeworkGroupResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateHomeworkGroupRequest,
      UpdateHomeworkGroupResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * BatchUpdateHomeworkGroups updates a batch of homework groups and the associated homeworks.
   * If the homework group id is empty string on the group, then one will be created.
   *
   * @generated from protobuf rpc: BatchUpdateHomeworkGroups(sparx.reading.management.v1.BatchUpdateHomeworkGroupsRequest) returns (sparx.reading.management.v1.BatchUpdateHomeworkGroupsResponse);
   */
  batchUpdateHomeworkGroups(
    input: BatchUpdateHomeworkGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchUpdateHomeworkGroupsRequest,
    BatchUpdateHomeworkGroupsResponse
  > {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchUpdateHomeworkGroupsRequest,
      BatchUpdateHomeworkGroupsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * EndHomeworkGroup ends a homework group early. It can be used to remove a
   * homework group entirely or end it from a particular point.
   *
   * @generated from protobuf rpc: EndHomeworkGroup(sparx.reading.management.v1.EndHomeworkGroupRequest) returns (sparx.reading.management.v1.EndHomeworkGroupResponse);
   */
  endHomeworkGroup(
    input: EndHomeworkGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<EndHomeworkGroupRequest, EndHomeworkGroupResponse> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<EndHomeworkGroupRequest, EndHomeworkGroupResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * PreviewHomeworkGroupDates returns the homework dates for a homework group.
   *
   * @generated from protobuf rpc: PreviewHomeworkGroupDates(sparx.reading.management.v1.PreviewHomeworkGroupDatesRequest) returns (sparx.reading.management.v1.PreviewHomeworkGroupDatesResponse);
   */
  previewHomeworkGroupDates(
    input: PreviewHomeworkGroupDatesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    PreviewHomeworkGroupDatesRequest,
    PreviewHomeworkGroupDatesResponse
  > {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      PreviewHomeworkGroupDatesRequest,
      PreviewHomeworkGroupDatesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * BatchPreviewHomeworkGroupDates returns the homework dates for a batch of homework groups.
   *
   * @generated from protobuf rpc: BatchPreviewHomeworkGroupDates(sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesRequest) returns (sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesResponse);
   */
  batchPreviewHomeworkGroupDates(
    input: BatchPreviewHomeworkGroupDatesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchPreviewHomeworkGroupDatesRequest,
    BatchPreviewHomeworkGroupDatesResponse
  > {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchPreviewHomeworkGroupDatesRequest,
      BatchPreviewHomeworkGroupDatesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListAvailableAssessments returns a list of available assessments that
   * can be set.
   *
   * @generated from protobuf rpc: ListAvailableAssessments(sparx.reading.management.v1.ListAvailableAssessmentsRequest) returns (sparx.reading.management.v1.ListAvailableAssessmentsResponse);
   */
  listAvailableAssessments(
    input: ListAvailableAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAvailableAssessmentsRequest,
    ListAvailableAssessmentsResponse
  > {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAvailableAssessmentsRequest,
      ListAvailableAssessmentsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * CreateAssessment creates an assessment for some students.
   *
   * @generated from protobuf rpc: CreateAssessment(sparx.reading.management.v1.CreateAssessmentRequest) returns (sparx.reading.management.v1.CreateAssessmentResponse);
   */
  createAssessment(
    input: CreateAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAssessmentRequest, CreateAssessmentResponse> {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateAssessmentRequest, CreateAssessmentResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListAssessmentPackages lists assessment packages.
   *
   * @generated from protobuf rpc: ListAssessmentPackages(sparx.reading.management.v1.ListAssessmentPackagesRequest) returns (sparx.reading.management.v1.ListAssessmentPackagesResponse);
   */
  listAssessmentPackages(
    input: ListAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentPackagesRequest, ListAssessmentPackagesResponse> {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentPackagesRequest,
      ListAssessmentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Update the end time of assessment packages to a new time. The assessments
   * must either be active or able to be resumed (ended recently and incomplete).
   *
   * @generated from protobuf rpc: ExtendAssessments(sparx.reading.management.v1.ExtendAssessmentsRequest) returns (sparx.reading.management.v1.ExtendAssessmentsResponse);
   */
  extendAssessments(
    input: ExtendAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ExtendAssessmentsRequest, ExtendAssessmentsResponse> {
    const method = this.methods[17],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ExtendAssessmentsRequest, ExtendAssessmentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CancelAssessment(sparx.reading.management.v1.CancelAssessmentRequest) returns (sparx.reading.management.v1.CancelAssessmentResponse);
   */
  cancelAssessment(
    input: CancelAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CancelAssessmentRequest, CancelAssessmentResponse> {
    const method = this.methods[18],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CancelAssessmentRequest, CancelAssessmentResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SearchStudents(sparx.reading.management.v1.SearchStudentsRequest) returns (sparx.reading.management.v1.SearchStudentsResponse);
   */
  searchStudents(
    input: SearchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<SearchStudentsRequest, SearchStudentsResponse> {
    const method = this.methods[19],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SearchStudentsRequest, SearchStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetStudentBooks(sparx.reading.management.v1.GetStudentBooksRequest) returns (sparx.reading.management.v1.GetStudentBooksResponse);
   */
  getStudentBooks(
    input: GetStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentBooksRequest, GetStudentBooksResponse> {
    const method = this.methods[20],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentBooksRequest, GetStudentBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SwapStudentBook(sparx.reading.management.v1.SwapStudentBookRequest) returns (sparx.reading.management.v1.SwapStudentBookResponse);
   */
  swapStudentBook(
    input: SwapStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<SwapStudentBookRequest, SwapStudentBookResponse> {
    const method = this.methods[21],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SwapStudentBookRequest, SwapStudentBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListStudentPackageTasks(sparx.reading.management.v1.ListStudentPackageTasksRequest) returns (sparx.reading.management.v1.ListStudentPackageTasksResponse);
   */
  listStudentPackageTasks(
    input: ListStudentPackageTasksRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListStudentPackageTasksRequest,
    ListStudentPackageTasksResponse
  > {
    const method = this.methods[22],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListStudentPackageTasksRequest,
      ListStudentPackageTasksResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetSchool(sparx.reading.management.v1.GetSchoolRequest) returns (sparx.school.v2.School);
   */
  getSchool(
    input: GetSchoolRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolRequest, School> {
    const method = this.methods[23],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListAccountStatuses(sparx.reading.management.v1.ListAccountStatusesRequest) returns (sparx.reading.management.v1.ListAccountStatusesResponse);
   */
  listAccountStatuses(
    input: ListAccountStatusesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAccountStatusesRequest, ListAccountStatusesResponse> {
    const method = this.methods[24],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAccountStatusesRequest,
      ListAccountStatusesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Deprecated: use the version in sparx/reading/reports/v2/reporting.proto.
   *
   * @generated from protobuf rpc: SchoolCompletedBookCount(sparx.reading.management.v1.SchoolCompletedBookCountRequest) returns (sparx.reading.management.v1.SchoolCompletedBookCountResponse);
   */
  schoolCompletedBookCount(
    input: SchoolCompletedBookCountRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SchoolCompletedBookCountRequest,
    SchoolCompletedBookCountResponse
  > {
    const method = this.methods[25],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SchoolCompletedBookCountRequest,
      SchoolCompletedBookCountResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetStudentHomeworkTaskAttempts(sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsRequest) returns (sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsResponse);
   */
  getStudentHomeworkTaskAttempts(
    input: GetStudentHomeworkTaskAttemptsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentHomeworkTaskAttemptsRequest,
    GetStudentHomeworkTaskAttemptsResponse
  > {
    const method = this.methods[26],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentHomeworkTaskAttemptsRequest,
      GetStudentHomeworkTaskAttemptsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetStudentHomework(sparx.reading.management.v1.GetStudentHomeworkRequest) returns (sparx.reading.management.v1.GetStudentHomeworkResponse);
   */
  getStudentHomework(
    input: GetStudentHomeworkRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentHomeworkRequest, GetStudentHomeworkResponse> {
    const method = this.methods[27],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentHomeworkRequest,
      GetStudentHomeworkResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * For a given task, get all questions which belong to its breakpoint / end part id
   *
   * @generated from protobuf rpc: GetTaskBreakpointQuestions(sparx.reading.management.v1.GetTaskBreakpointQuestionsRequest) returns (sparx.reading.management.v1.GetTaskBreakpointQuestionsResponse);
   */
  getTaskBreakpointQuestions(
    input: GetTaskBreakpointQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetTaskBreakpointQuestionsRequest,
    GetTaskBreakpointQuestionsResponse
  > {
    const method = this.methods[28],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetTaskBreakpointQuestionsRequest,
      GetTaskBreakpointQuestionsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListHomeworkViews returns the ids of teachers who viewed each homework.
   *
   * @generated from protobuf rpc: ListHomeworkViews(sparx.reading.management.v1.ListHomeworkViewsRequest) returns (sparx.reading.management.v1.ListHomeworkViewsResponse);
   */
  listHomeworkViews(
    input: ListHomeworkViewsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworkViewsRequest, ListHomeworkViewsResponse> {
    const method = this.methods[29],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListHomeworkViewsRequest, ListHomeworkViewsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * CreateHomeworkView records that a teacher viewed a homework.
   *
   * @generated from protobuf rpc: CreateHomeworkView(sparx.reading.management.v1.CreateHomeworkViewRequest) returns (sparx.reading.management.v1.CreateHomeworkViewResponse);
   */
  createHomeworkView(
    input: CreateHomeworkViewRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateHomeworkViewRequest, CreateHomeworkViewResponse> {
    const method = this.methods[30],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateHomeworkViewRequest,
      CreateHomeworkViewResponse
    >('unary', this._transport, method, opt, input);
  }
}
