// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/types/user.proto" (package "sparx.types", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum sparx.types.UserType
 */
export enum UserType {
  /**
   * @generated from protobuf enum value: USER_TYPE_UNKNOWN = 0;
   */
  USER_TYPE_UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: SPARX_STAFF = 1;
   */
  SPARX_STAFF = 1,
  /**
   * @generated from protobuf enum value: STAFF_MEMBER = 2;
   */
  STAFF_MEMBER = 2,
  /**
   * @generated from protobuf enum value: STUDENT = 3;
   */
  STUDENT = 3,
  /**
   * @generated from protobuf enum value: PARENT = 4;
   */
  PARENT = 4,
}
