import {
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faKeyboard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitAnswerResponse } from '@sparx/api/apis/sparx/reading/vocab/v1/vocab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { vocabClient } from 'api';
import classNames from 'classnames';
import { Button } from 'components/buttons/button';
import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { TaskCheckPage } from 'components/tasks/task-complete-modal';
import { Title } from 'components/title/title';
import { useUpdateExperience } from 'queries/notifications';
import { useVocabQuestion } from 'queries/vocab';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import styles from 'views/student/vocab-view/vocab-view.module.css';
import { WordWheel } from 'views/student/vocab-view/word-wheel';
import { pathForView } from 'views/views';

enum InputType {
  Wheel = 1,
  Keyboard = 2,
}

export const VocabView = () => {
  const navigate = useNavigate();
  const [inputType, setInputType] = useState<InputType>(InputType.Wheel);

  const inputField = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const focusInput = () => {
    const current = inputField.current;
    if (current) {
      current.focus();
    }
  };

  const {
    data: question,
    isLoading,
    error,
    refetch: refetchQuestion,
  } = useVocabQuestion({
    onSuccess: () => {
      setInputValue('');
      setQuestionResult(undefined);
      if (inputType === InputType.Keyboard) {
        setTimeout(() => {
          focusInput();
        }, 500);
      }
    },
  });

  const updateExperience = useUpdateExperience();
  const [questionResult, setQuestionResult] = useState<SubmitAnswerResponse | undefined>();
  const queryClient = useQueryClient();
  const submit = useMutation(
    ['vocab', 'submit'],
    ({ answer, skip = false }: { answer: string; skip?: boolean }) =>
      vocabClient.submitAnswer({
        answer: answer,
        inputType: inputType === InputType.Wheel ? 'wheel' : 'keyboard',
        skip,
        position:
          question?.state?.algo.oneofKind === 'simpleAlgo'
            ? question.state.algo.simpleAlgo.currentWordPosition
            : question?.state?.algo.oneofKind === 'bucketAlgo'
              ? question.state.algo.bucketAlgo.currentWordPosition
              : 0,
      }).response,
    {
      onSuccess: data => {
        setQuestionResult(data);
        // Invalidate the vocab state so that is is reloaded
        queryClient.invalidateQueries(['vocab', 'state']);

        const xpUpdate = data.experienceUpdate;
        if (xpUpdate) {
          updateExperience(xpUpdate);
        }
      },
    },
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && questionResult?.continue) {
      nextQuestion();
    }
  };

  const submitAnswer = (answer: string) => {
    setQuestionResult(undefined);
    submit.mutate({ answer });
  };

  if (error) {
    switch ((error as Error).message) {
      case 'no more words to issue':
        return <ErrorMessage message="You have used all of the current words, more coming soon!" />;
      case 'max words today':
        return (
          <div className={styles.VocabView}>
            <div className={styles.CompletePageContainer}>
              <TaskCheckPage passed={true}>
                <span className="info-text">
                  You have completed today&apos;s words. Check back tomorrow for some more!
                </span>
                <br />
                <Button
                  analyticsEvent={undefined}
                  leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                  onClick={() => navigate(pathForView(View.Library), { state: { popped: true } })}
                  style={{ marginTop: 20 }}
                >
                  Back to library
                </Button>
              </TaskCheckPage>
            </div>
          </div>
        );
      default:
        return (
          <ErrorMessage message="There was an error while loading vocab questions. Please try refreshing." />
        );
    }
  }

  if (isLoading || !question) {
    return (
      <div className={styles.VocabView}>
        <Loading text="Loading words..." />
      </div>
    );
  }

  const nextQuestion = () => refetchQuestion();
  const skipQuestion = () => submit.mutate({ answer: '<SKIP>', skip: true });

  const completeWord = question.answer.length === inputValue.length;
  const fixed =
    (completeWord && (!questionResult || questionResult.correct)) || !!questionResult?.continue;
  const known = question.word.replace(/_/g, '');
  const maxLength = question.word.length - known.length;

  const getAnswerFromInput = (value: string): string => {
    let answer = value.substr(0, maxLength);
    if (answer.length === maxLength) {
      answer += known;
    }
    return answer;
  };

  const updateInputValue = (value: string, blockable: boolean) => {
    if (completeWord && value !== '' && blockable) {
      return;
    }
    if (fixed) {
      return;
    }
    const answer = getAnswerFromInput(value);
    if (answer === inputValue) {
      return; // don't care
    }
    setInputValue(answer);
    if (answer.length === question.answer.length) {
      submitAnswer(answer);
    }
  };
  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateInputValue(e.target.value.replace(/\s/g, '').toLowerCase(), false);
  };

  const updateInputWheel = (value: string) => {
    if (value === '') {
      const answer = getAnswerFromInput(inputValue);
      if (answer.length !== question.answer.length && answer.length > 0) {
        // Submit the unfinished answer
        submitAnswer(answer);
      }
    }
    updateInputValue(value, true);
  };

  return (
    <div className={styles.VocabView}>
      <Title>Vocab</Title>
      <div className={styles.VocabContainer} key={question.choices}>
        <div className={styles.Question}>
          <div className={`${styles.QuestionPanel} enter-down-0`}>
            <div className={styles.QuestionPanelTitle}>
              Complete the {question.word.length} letter word:
            </div>
            <div className={styles.QuestionPanelContent}>
              <div className={styles.Word}>
                {question.word.split('').map((a, i) => (
                  <span
                    className={classNames(styles.Letter, {
                      [styles.LetterUnknown]: a === '_',
                      [styles.LetterFirst]: !inputValue && i === 0,
                      [styles.LetterFirstWrong]:
                        i === 0 && inputValue[0] && inputValue[0] !== question?.answer[0],
                      [styles.LetterCorrect]:
                        completeWord && questionResult && questionResult.correct,
                      [styles.LetterWrong]:
                        completeWord && questionResult && !questionResult.correct,
                    })}
                    key={i}
                  >
                    {a !== '_' ? a : inputValue[i] || (i === 0 ? question?.answer[0] : '')}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className={`${styles.QuestionPanel} enter-down-1`}>
            <div className={styles.QuestionPanelTitle}>The word means:</div>
            <div className={`${styles.QuestionPanelContent} ${styles.Definition}`}>
              {question?.definition?.definition}
            </div>
          </div>
          <div className={`${styles.QuestionPanel} enter-down-2`}>
            <div className={styles.QuestionPanelTitle}>and completes the following gap:</div>
            <div className={`${styles.QuestionPanelContent} ${styles.Definition}`}>
              {question?.definition?.usage}
            </div>
          </div>
        </div>
        <div className={`${styles.AnswerEntry} enter-down-3`}>
          <div className={styles.QuestionPanelTitle}>&nbsp;</div>
          {/* spacing */}
          <div className={styles.EntryContainer}>
            {inputType === InputType.Wheel ? (
              <WordWheel
                options={question.choices}
                onChange={fixed ? () => undefined : updateInputWheel}
                active={!completeWord}
                fixed={fixed}
                result={completeWord ? questionResult : undefined}
                startingLetter={question.answer[0] || '??'}
              />
            ) : (
              <>
                <div className={styles.KeyboardHint}>Enter the word here:</div>
                <input
                  type="text"
                  value={inputValue.substr(0, 8)}
                  onChange={updateInput}
                  className={styles.InputBox}
                  ref={inputField}
                  onKeyDown={handleKeyDown}
                />
              </>
            )}
          </div>
          <Button
            analyticsEvent={undefined}
            size="small"
            className={styles.SwitchButton}
            onClick={() =>
              setInputType(inputType === InputType.Wheel ? InputType.Keyboard : InputType.Wheel)
            }
          >
            <FontAwesomeIcon icon={inputType === InputType.Wheel ? faKeyboard : faCircleNotch} />
            Use {inputType === InputType.Wheel ? 'Keyboard' : 'Wheel'} input
          </Button>
        </div>
      </div>
      <div className={styles.BottomButtons}>
        <Button
          analyticsEvent={undefined}
          disabled={questionResult?.continue}
          variant="secondary"
          onClick={skipQuestion}
        >
          I don&apos;t know
        </Button>
        {questionResult?.continue && (
          <Button analyticsEvent={undefined} onClick={nextQuestion} color="danger">
            Next Question
            <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 8 }} />
          </Button>
        )}
      </div>
    </div>
  );
};
