import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { IntercomProvider } from '@sparx/intercom';
import { getSchool } from '@sparx/query/schools-service';
import { useClientEvent } from 'components/client-events/use-client-event';
import { UserRole, useSession } from 'queries/session';
import { useCurrentStaffMember } from 'queries/staff';
import { PropsWithChildren } from 'react';

import { urlKnowledgeBase } from './knowledge-base-url';

// The Intercom app ID is shared across all Sparx products.
const intercomAppId = 'os8d5arr';

const urlKnowledgeBaseArticlePrefix = `${urlKnowledgeBase}articles/`;
const supportEmailAddress = 'schoolsupport@sparx.co.uk';

export const IntercomContext = ({ children }: PropsWithChildren) => {
  const bootProps = useIntercomBootProps();
  const { sendEvent } = useClientEvent();

  return (
    <IntercomProvider
      appId={intercomAppId}
      bootProps={bootProps}
      sendEvent={sendEvent}
      handleSupportLinks={urlKnowledgeBaseArticlePrefix}
      handleSupportEmail={supportEmailAddress}
    >
      {children}
    </IntercomProvider>
  );
};

const useIntercomBootProps = () => {
  const { data: session } = useSession();
  const user = session?.user;
  const isTeacher = user?.type === UserType.TEACHER;
  // We don't have a very good check for Sparx Staff on the client. Confusingly
  // they are the only users that won't have a sparxUserId (here that means a
  // user ID shared across Sparx products), and are currently the only users
  // with email addresses set. Adding the check for the role will hopefully
  // catch future bugs if we start returning email addresses for other users.
  const isSparxStaff =
    !user?.sparxUserId && !!user?.emailAddress && user?.roles.includes(UserRole.Admin);

  const { data: school } = getSchool.useQuery({ enabled: isTeacher });
  const { data: staffMember } = useCurrentStaffMember({ enabled: isTeacher && !isSparxStaff });

  // Don't return the properties until everything is loaded
  if (!user || !isTeacher || !school || (!staffMember && !isSparxStaff)) {
    return undefined;
  }

  const name = staffMember
    ? staffMember.displayName || `${staffMember.givenName} ${staffMember.familyName}`
    : [user.firstName, user.lastName].join(' ');
  const email = staffMember ? staffMember.emailAddress : user.emailAddress;
  const company = isSparxStaff
    ? {
        company_id: 'sparx',
        name: 'Sparx',
        created_at: 0,
        website: 'https://sparx-learning.com/',
      }
    : {
        company_id: school.name,
        name: school.displayName,
        created_at: school.createTime?.seconds,
        website: `https://app.sparxassessments.com?school=${school.name.split('/')[1]}`,
      };

  return {
    user_id: user.sparxUserId || user.userId,
    school_id: user.schoolId,
    name,
    email,
    user_hash: session.intercomHash,

    // company attributes
    company,

    action_color: '#3a1f85', // --midnight-indigo-100
    background_color: '#474ed1', // --royal-blue-100
  };
};
