import * as Sentry from '@sentry/react';
import { endOfDay, isWithinInterval, startOfDay, sub } from 'date-fns';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import { useSchoolID } from 'queries/schools';
import { useSubscriptionStatus } from 'queries/subscriptions';
import { Flags, useNumberFlag } from 'utils/featureflags';

export type SubscriptionBannerKind = 'TRIAL_ENDING' | 'SUBSCRIPTION_ENDING' | 'INTERIM';

type SchoolSubscriptionBanner =
  | {
      show: boolean;
      /** Specifying TRIAL uses 'confirm' wording, specifying INDIVIDUAL uses 'renew' wording.  */
      kind: SubscriptionBannerKind;
    }
  | { show: false; kind?: undefined };

export const useSchoolSubscriptionBanner = (enabled = true): SchoolSubscriptionBanner => {
  // Reader has no concept of an 'onboarding period' at present, but if it adopts one to match Maths,
  // we may want it to interact with the subscription banner in a similar manner.
  const isOnboarding = false;

  const schoolID = useSchoolID();
  const subscriptionStatus = useSubscriptionStatus({ enabled });
  const isInterim = useSchoolInInterimState(enabled);

  const { mode, isAgreed, currentEndDate } = subscriptionStatus ?? {
    mode: 'OTHER',
    isAgreed: false,
  };

  const warningPeriod = useNumberFlag(Flags.SubscriptionWarningPeriod);
  const warningPriorityPeriod = useNumberFlag(Flags.SubscriptionWarningPriorityPeriod);

  if (isInterim) {
    return { show: true, kind: 'INTERIM' };
  }

  if (Number.isNaN(warningPeriod) || Number.isNaN(warningPriorityPeriod)) {
    // Can only really interpret this as "don't show"
    return { show: false };
  }

  if (mode === 'TRIAL' || mode === 'INDIVIDUAL') {
    const kind = ({ TRIAL: 'TRIAL_ENDING', INDIVIDUAL: 'SUBSCRIPTION_ENDING' } as const)[mode];

    if (currentEndDate === undefined) {
      Sentry.captureMessage(
        `School ${schoolID} has no end date for current ${mode.toLowerCase()} subscription. This shouldn't happen.`,
      );
      return { show: false, kind };
    }

    const now = new Date();
    const isInSubsWarningPeriod = isWithinInterval(now, {
      start: startOfDay(sub(currentEndDate, { days: warningPeriod })),
      end: endOfDay(currentEndDate),
    });
    const isInSubsPriorityWarningPeriod = isWithinInterval(now, {
      start: startOfDay(sub(currentEndDate, { days: warningPriorityPeriod })),
      end: endOfDay(currentEndDate),
    });
    return {
      show: isInSubsWarningPeriod && !isAgreed && (!isOnboarding || isInSubsPriorityWarningPeriod),
      kind,
    };
  }

  return { show: false };
};
