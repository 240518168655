import { format } from 'date-fns';
import * as htmlToImage from 'html-to-image';
import { combineQueryStates } from 'queries/client';
import { useEnglishStudentGroups, useTeacherStudentsWithExperience } from 'queries/management';
import { useMemo } from 'react';

import { WidgetConfig } from './types';

export const fileName = (schoolName: string, type: string) =>
  `${schoolName} - ${type} (${format(new Date(), 'yyyy-MM-dd')})`;

export const downloadWidgetAsImage = async (
  schoolName: string,
  config: WidgetConfig,
  element: HTMLDivElement,
) => {
  // account for weird Safari bug
  // https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
  await htmlToImage.toPng(element);
  await htmlToImage.toPng(element);
  const dataUrl = await htmlToImage.toPng(element);
  const link = document.createElement('a');

  link.download = `${fileName(schoolName, config.id)}.png`;
  link.href = dataUrl;
  link.click();
};

/** SRP earned by students in non-expired English groups since the start of the
 * school year. If classIDs are omitted, returns hours of reading for all groups
 * in the school. */
export const useReadingHoursThisYear = (classIDs?: string[]) => {
  const { data, ...studentsQueryState } = useTeacherStudentsWithExperience();
  const { data: groups, ...groupsQueryState } = useEnglishStudentGroups();
  const readingHoursThisYear = useMemo(() => {
    const englishGroupIds = groups?.map(g => g.name.split('/')[3]) || [];
    const filterByClassIDs = classIDs?.length
      ? classIDs.filter(id => englishGroupIds.includes(id))
      : englishGroupIds;
    const srpThisYear = Object.values(data || {})
      .filter(s => filterByClassIDs.includes(s.studentGroupId))
      .reduce((acc, student) => acc + student.experienceThisYear, 0);
    return srpThisYear / 10 / 60;
  }, [data, groups, classIDs]);

  return {
    readingHoursThisYear,
    ...combineQueryStates(studentsQueryState, groupsQueryState),
  };
};

export const shouldShowPraiseStats = (
  forceEnable: boolean,
  completedBookCount: number,
  readingHoursThisYear: number,
) => {
  if (forceEnable) return true;

  return completedBookCount > 5 && readingHoursThisYear > 5;
};
