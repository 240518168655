import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { useSSOSetupStatus } from '@sparx/sso-settings/src/SSOSettings/hooks';
import { useSchoolID } from 'queries/schools';
import { Flags, useFlag } from 'utils/featureflags';

import { Tab } from '../../types';

export const useSSOTab = (): Tab & {
  isEnabled: boolean;
} => {
  const schoolID = useSchoolID();
  const isSSOTabEnabled = useFlag(Flags.SchoolSettingsSSOTab, false);
  const {
    data: ssoSetupStatus,
    isLoading,
    isError,
  } = useSSOSetupStatus(schoolID ? `schools/${schoolID}` : '');

  const hasSuccess = ssoSetupStatus?.enabledForStudents && ssoSetupStatus?.enabledForTeachers;
  const hasEmailAddressProblem =
    (ssoSetupStatus?.studentsWithMissingEmail || 0) > 0 ||
    (ssoSetupStatus?.studentsWithWrongEmailDomain || 0) > 0;

  let status = '<5 minutes';
  if (hasSuccess && hasEmailAddressProblem) {
    status = 'Check student email addresses';
  } else if (hasSuccess) {
    status = 'Enabled & set up';
  } else if (ssoSetupStatus?.enabledForStudents && !ssoSetupStatus?.enabledForTeachers) {
    status = 'Enable SSO for teachers';
  } else if (ssoSetupStatus?.enabledForTeachers && !ssoSetupStatus?.enabledForStudents) {
    status = 'Enable SSO for students';
  }

  return {
    id: 'sso',
    title: 'Set up Single Sign-On',
    subtitle: status,
    pageSubtitle: status,
    icon: faRightToBracket,
    responsibility: 'Sparx Leader & Head of Department',
    isLoading: false,
    hasSuccess,
    hasWarning: isLoading || isError || !hasSuccess || hasEmailAddressProblem,
    isEnabled: !!isSSOTabEnabled,
  };
};
