import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'new-year-setup-mis-tab';

export const clickedResetLink = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked reset link',
});

export const clickedGetInTouchLink = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked get in touch link',
});

export const clickedManageStaff = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked manage staff',
});
