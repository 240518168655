import { NotificationBankedReward } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { BankRewardAlert } from 'components/alert/banking-alert';
import { useAlert } from 'components/alert/hooks';
import bank from 'components/alert/images/bank.svg';
import { useListLibraryBooks } from 'queries/library-books';

import { INotificationWrapperProps, Notification } from './notification';
import styles from './notification.module.css';

interface Props extends INotificationWrapperProps {
  bankedReward: NotificationBankedReward;
}

export const BankedRewardNotification: React.FC<Props> = ({ notification, bankedReward }) => {
  const setAlert = useAlert();
  const { data: libraryBooks } = useListLibraryBooks();
  const book = libraryBooks?.libraryBooks?.find(
    lb => lb.metadataAbridged?.name === `books/${bankedReward.bookId}`,
  );

  if (!book) {
    return null;
  }

  return (
    <Notification
      title={
        <>
          <img src={bank} alt="" className={styles.BankedIcon} />
          You&apos;ve been awarded SRP for completing {book.metadataAbridged?.title}
        </>
      }
      notification={notification}
      onClick={() =>
        setAlert(
          <BankRewardAlert
            notification={notification}
            book={book.metadataAbridged}
            reward={bankedReward}
          />,
        )
      }
      clickMessage="Collect SRP"
    >
      Click here to collect the SRP
    </Notification>
  );
};
