import {
  ISchoolStaffServiceClient,
  SchoolStaffServiceClient,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const schoolStaffServiceId: interfaces.ServiceIdentifier<ISchoolStaffServiceClient> =
  Symbol.for('schoolStaffService');

/** Register a school staff service client with the sparx query container */
export const setSchoolStaffClient = (client: ISchoolStaffServiceClient) => {
  container.bind(schoolStaffServiceId).toConstantValue(client);
};

export const useSchoolStaffClient = (): ISchoolStaffServiceClient =>
  useOptionalInjection(schoolStaffServiceId, () => new SchoolStaffServiceClient(errorTransport));
