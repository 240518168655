import { EventLabels } from 'components/client-events/client-event-provider';
import { useClientEvent } from 'components/client-events/use-client-event';
import { IColumn, TableContainer } from 'components/table/table-container';
import { useState } from 'react';

import { usePaginator } from './paginator';
import styles from './table.module.css';

export interface ISortableTableProps<RowType, SortType extends string> {
  columns: IColumn<SortType>[];
  data?: RowType[];
  sorts: Record<SortType, (a: RowType, b: RowType) => number>;
  defaultSort?: SortType;
  defaultSortFlipped?: boolean;
  children: (row: RowType, ix: number) => React.ReactNode;
  noRows?: React.ReactNode;
  fixedRowHeight?: number;
  maxHeight?: number;
  analyticsCategory?: string;
  analyticsLabels?: EventLabels;
  loading?: boolean;
  paginated?: boolean;
  perPage?: number;
  className?: string;
}

export const SortableTable = <RowType, SortType extends string>({
  columns,
  data,
  sorts,
  defaultSort,
  defaultSortFlipped = false,
  children,
  noRows,
  fixedRowHeight,
  maxHeight,
  analyticsCategory,
  analyticsLabels,
  loading,
  paginated = false,
  perPage,
  className,
}: ISortableTableProps<RowType, SortType>) => {
  const { sendEvent } = useClientEvent();
  const [currentSort, setCurrentSort] = useState<SortType | undefined>(defaultSort);
  const [sortFlipped, setSortFlipped] = useState(defaultSortFlipped);
  const [page, setPage] = useState(0);

  const sortFunc = currentSort && sorts[currentSort];
  const sortedData = sortFunc
    ? data?.sort((a, b) => (sortFlipped ? sortFunc(b, a) : sortFunc(a, b))) || []
    : data || [];

  const { navigator, visibleItems, offset } = usePaginator(
    paginated,
    sortedData || [],
    page,
    setPage,
    perPage || 50,
  );

  return (
    <>
      <TableContainer
        fixedRowHeight={fixedRowHeight}
        maxHeight={maxHeight}
        sortProps={{
          flipped: sortFlipped,
          currentSort: currentSort,
          setSort: (sort: SortType, flip: boolean) => {
            if (analyticsCategory) {
              sendEvent({
                category: analyticsCategory,
                action: 'sort',
                labels: { sort, flip, ...analyticsLabels },
              });
            }
            setCurrentSort(sort);
            setSortFlipped(flip);
          },
        }}
        columns={columns}
        loading={loading}
        className={className}
      >
        {visibleItems.map((row, ix) => children(row, ix + offset))}

        {visibleItems.length === 0 && noRows}
      </TableContainer>
      {paginated && <div className={styles.PaginatorContainer}>{navigator}</div>}
    </>
  );
};
