import {
  faBook,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faSchool,
  faSpellCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Homework, WorkType } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { HomeworkAlert } from 'components/alert/homework-alert';
import { useAlert } from 'components/alert/hooks';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons/button';
import { HomeBox, HomeBoxWrapper } from 'components/home-box/home-box';
import { Info } from 'components/panel/info';
import { ProgressBar } from 'components/progress/progress-bar';
import { Title } from 'components/title/title';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useCurrentHomework } from 'hooks/use-current-homework';
import { useHomeworks } from 'hooks/use-homeworks';
import moment from 'moment';
import { useGoldReaderState, useIsGoldReader } from 'queries/gold';
import { useListCurrentLibraryBooks } from 'queries/library-books';
import { useIsUserOnboarded, useUser } from 'queries/session';
import { useExtraPackages, useLockedInPackage } from 'queries/tasks';
import { useVocabState } from 'queries/vocab';
import { useNavigate } from 'react-router-dom';
import { getTaskProgress } from 'utils/tasks';
import { formatRelativeTimestamp, timestampToMoment } from 'utils/time';
import { View } from 'views';
import { GoldReaderPass } from 'views/student/gold-reader-view/gold-reader-progress/gold-reader-progress';
import { GoldReaderProgressNotGr } from 'views/student/gold-reader-view/gold-reader-progress/gold-reader-progress-not-gr';
import { BonusQuestionModal } from 'views/student/library-view/components/bonus-question-modal';
import {
  HomeworkPie,
  HomeworkSRPCount,
} from 'views/student/library-view/components/homework-components';
import { ExtraPackage } from 'views/student/library-view/subviews/extra-package';
import styles from 'views/student/library-view/subviews/home-view.module.css';
import { pathForView } from 'views/views';

import { LibraryContent, LibraryTitle } from '../components/library-content';
import { LeagueStat } from './home-view-tiles/league-stat/league-stat';

export const HomeView = () => {
  const user = useUser();
  const onboarded = useIsUserOnboarded();
  const { data: currentBooks } = useListCurrentLibraryBooks();
  const [, openStartBookDialog] = useStartBookDialog();
  const currentHour = moment().hour();
  const hourText = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';

  const latestBook = currentBooks && currentBooks[0];

  // Either choose the first current classwork or the first current homework.
  const { currentTasks } = useHomeworks();
  const { data: extraPackages } = useExtraPackages();
  const { data: lockedInPackage } = useLockedInPackage();
  const isClasswork = currentTasks?.find(t => t.workType === WorkType.CLASSWORK);

  return (
    <LibraryContent>
      <BonusQuestionModal />
      <Title>Home</Title>
      <LibraryTitle>
        Good {hourText}, {user?.firstName}
      </LibraryTitle>
      <div className={styles.BoxContainer}>
        {lockedInPackage && (
          <HomeBoxWrapper lgw={100}>
            <Info>
              Your teacher has set a reading assessment. Once this is complete, you will be able to
              choose from the books in your Library.
            </Info>
          </HomeBoxWrapper>
        )}
        {extraPackages?.map(p => <ExtraPackage key={p.packageId} pkg={p} />)}
        {!lockedInPackage && (
          <>
            {isClasswork && currentTasks ? (
              <CurrentTask homeworks={currentTasks} classwork={true} />
            ) : (
              <>
                <CurrentTask homeworks={currentTasks || []} />
                <PreviousTaskCount />
              </>
            )}
          </>
        )}
        {onboarded && !lockedInPackage && (
          <>
            <BookPanel
              book={latestBook}
              onStart={() => openStartBookDialog(latestBook?.metadataAbridged)}
            />
            <Gold />
            <LeagueStat />
            <VocabStat />
          </>
        )}
      </div>
    </LibraryContent>
  );
};

interface ICurrentTaskProps {
  homeworks?: Homework[];
  classwork?: boolean;
}

export const CurrentTask = ({ homeworks, classwork }: ICurrentTaskProps) => {
  const setAlert = useAlert();
  const { isLoading, isError } = useHomeworks();

  const homework = useCurrentHomework(homeworks);

  const className = classwork ? styles.ClassworkBox : undefined;
  const optional = homework && homework?.targetExperience === 0;

  const header = (
    <h3>
      {classwork && <FontAwesomeIcon icon={faSchool} />}
      {classwork ? 'Classwork Task' : optional ? 'No task set' : 'Current Task'}
      <div className={styles.WhatIsThis} onClick={() => setAlert(<HomeworkAlert />)}>
        <FontAwesomeIcon icon={faInfoCircle} />
        What is this?
      </div>
    </h3>
  );

  if (!homework || isLoading || isError || optional) {
    return (
      <HomeBox
        variant={optional ? 'normal' : 'faded'}
        lgw={classwork ? 100 : 66}
        className={className}
      >
        <div className={styles.BoxSplit}>
          <div className={styles.BoxSplitInfo}>
            {header}
            <div className={styles.BoxSplitInfoContent}>
              {isLoading ? (
                <Loading />
              ) : isError ? (
                <ErrorLoading name="current task" />
              ) : optional ? (
                <span className={classNames(styles.PreviousTaskSRP)}>
                  Your teacher has made your homework optional. You can still earn SRP by reading.
                </span>
              ) : (
                <span className={classNames(styles.PreviousTaskSRP)}>
                  There is no current task set
                </span>
              )}
            </div>
          </div>
        </div>
      </HomeBox>
    );
  }

  const incomplete = homework.experience < homework.targetExperience;
  const dangered =
    !classwork &&
    incomplete &&
    timestampToMoment(homework.endDate)?.isBefore(moment().add(1, 'day'));

  const progress = getTaskProgress(homework.experience, homework.targetExperience);

  return (
    <HomeBox variant="dark" lgw={classwork ? 100 : 66} className={className}>
      <div className={styles.BoxSplit}>
        <div className={styles.BoxSplitInfo}>
          {header}
          {!classwork && (
            <div className={styles.BoxSplitInfoContent}>
              <div className={styles.CurrentTaskDatesLabel}>Set:</div>
              <div className={styles.CapitaliseFirstLetter}>
                {homework.startDate ? formatRelativeTimestamp(homework.startDate) : 'Unknown'}
              </div>
              <div className={styles.CurrentTaskDatesBreak} />
              <div className={styles.CurrentTaskDatesLabel}>Due:</div>
              <div
                className={classNames(
                  styles.CurrentTaskDate,
                  styles.CapitaliseFirstLetter,
                  dangered && styles.CurrentTaskDateDanger,
                )}
              >
                {homework.endDate ? formatRelativeTimestamp(homework.endDate) : 'Unknown'}
                {dangered && <FontAwesomeIcon icon={faExclamationTriangle} />}
                {!incomplete && <FontAwesomeIcon icon={faCheck} className={styles.Completed} />}
              </div>
            </div>
          )}
        </div>
        {!classwork && (
          <div className={styles.BoxSplitGraphic}>
            <HomeworkPie
              progress={progress}
              className={styles.CurrentTaskPie}
              background="var(--white-opacity-30)"
              gradientVariant={incomplete ? 'orange' : 'green'}
            />
            <HomeworkSRPCount
              target={homework.targetExperience}
              current={homework.experience}
              hideColour={true}
              className={classNames(
                styles.CurrentTaskCounts,
                incomplete ? styles.InProgress : styles.Completed,
              )}
            />
          </div>
        )}
      </div>
      {classwork && (
        <div className={styles.ClassworkProgress}>
          <ProgressBar
            percent={Math.min(1, homework.experience / homework.targetExperience) * 100}
            width="100%"
            className={styles.ClassBar}
            barClassName={incomplete ? styles.ClassBarOrange : styles.ClassBarGreen}
          />
          <HomeworkSRPCount
            target={homework.targetExperience}
            current={homework.experience}
            hideColour={true}
            className={classNames(
              styles.CurrentTaskCounts,
              incomplete ? styles.InProgress : styles.Completed,
            )}
          />
        </div>
      )}
    </HomeBox>
  );
};

interface IBookPanelProps {
  title?: string;
  // The book to display. If undefined, a link to the library will display in
  // place of the book start button.
  book: LibraryBook | undefined;
  onStart: () => void;
}

const BookPanel = ({ title, book }: IBookPanelProps) => {
  const navigate = useNavigate();
  const [, openStartBookDialog] = useStartBookDialog();

  return (
    <HomeBox title={title || 'Latest book'} lgw={66} breakWide>
      <div className={styles.Book}>
        {book ? (
          <>
            <div
              className={styles.BookImageButton}
              onClick={() => openStartBookDialog(book.metadataAbridged)}
            >
              {book.metadataAbridged && (
                <BookImage book={book.metadataAbridged} className={styles.BookImage} />
              )}
            </div>
            <div className={styles.BookMeta}>
              <div className={styles.BookTitle}>{book.metadataAbridged?.title}</div>
              <div className={styles.BookAuthors}>{book.metadataAbridged?.authors.join(', ')}</div>
              <ProgressBar
                percent={(book.studentBook?.progress || 0) * 100}
                className={styles.BookProgressBar}
                width="80%"
              />
              <div className={styles.BookButtons}>
                <Button
                  analyticsEvent={undefined}
                  onClick={() => openStartBookDialog(book.metadataAbridged)}
                >
                  Start Reading
                </Button>
                <span
                  onClick={() => navigate(pathForView(View.Explore))}
                  className={styles.BookButtonsLink}
                >
                  Choose a different book
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.NoBook}>
            <p>You have not started any books.</p>
            <Button
              analyticsEvent={undefined}
              className={styles.FindBookButton}
              onClick={() => navigate(pathForView(View.Explore))}
            >
              Find a new book to read in the{' '}
              <strong>
                <FontAwesomeIcon icon={faBook} /> Library
              </strong>
            </Button>
          </div>
        )}
      </div>
    </HomeBox>
  );
};

const PreviousTaskCount = () => {
  // Get homework information
  const { previousHwks: allPreviousWork, isLoading, isError } = useHomeworks();

  const homeworks = allPreviousWork.filter(hwk => hwk.workType === WorkType.HOMEWORK);
  const outstandingHwks = homeworks.filter(p => p.experience < p.targetExperience);
  const catchUp = outstandingHwks.reduce((p, v) => {
    const diff = v.targetExperience - v.experience;
    const outstanding = diff <= 0 ? 0 : diff;
    return p + outstanding;
  }, 0);

  const outstanding = outstandingHwks.length;
  const total = homeworks.length;

  if (total === 0 || isLoading || isError) {
    return (
      <HomeBox centered={true} lgw={33} variant="faded">
        <h3>Previous Tasks</h3>
        <div className={styles.BoxSplitInfoContent}>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <ErrorLoading name="curren task" />
          ) : (
            <span className={classNames(styles.PreviousTaskSRP)}>You have no previous tasks</span>
          )}
        </div>
      </HomeBox>
    );
  }

  const completed = total - outstanding;
  const graphic = (
    <div
      className={classNames(
        styles.PreviousTasks,
        total > 10 && styles.PreviousTasksSmall,
        completed < total && styles.InProgress,
        completed >= total && styles.Completed,
      )}
    >
      {completed}/{total}
    </div>
  );

  const text = (
    <>
      {catchUp > 0 ? (
        <span className={classNames(styles.InProgress, styles.PreviousTaskSRP)}>
          Reach {catchUp.toLocaleString()} SRP
          <br />
          to catch up
        </span>
      ) : (
        <span className={classNames(styles.Completed, styles.PreviousTaskSRP)}>
          Complete
          <FontAwesomeIcon icon={faCheck} />
        </span>
      )}
    </>
  );

  return (
    <HomeBox centered={true} lgw={33}>
      <div className={styles.SingleColumn}>
        <div className={styles.BoxSplit}>
          <div className={styles.BoxSplitInfo}>
            <h3>Previous Tasks</h3>
            <div className={styles.BoxSplitInfoContent}>{text}</div>
          </div>
          <div className={styles.BoxSplitGraphic}>{graphic}</div>
        </div>
      </div>
      <div className={styles.MultiColumn}>
        <h3>Previous Tasks</h3>
        {graphic}
        {text}
      </div>
    </HomeBox>
  );
};

const VocabStat = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useVocabState();
  const lastAttemptStr =
    data?.state?.algo.oneofKind === 'bucketAlgo'
      ? data.state.algo.bucketAlgo.lastAttempted
      : undefined;

  let doneToday = false;
  let lastAttemptFormatted = 'Never';
  if (lastAttemptStr) {
    const lastDay = moment(lastAttemptStr).startOf('day');
    const daysAgo = moment().startOf('day').diff(lastDay, 'days');
    if (daysAgo <= 0) {
      doneToday = true;
      lastAttemptFormatted = `Today`;
    } else {
      lastAttemptFormatted = `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
    }
  }

  return (
    <HomeBox>
      <h3>
        <FontAwesomeIcon icon={faSpellCheck} />
        Vocab practice
      </h3>
      <div className={styles.StatBox}>
        {data && (
          <>
            <div className={styles.StatName}>Last completed</div>
            <div className={styles.StatPosition}>
              {doneToday && <FontAwesomeIcon icon={faCheck} className={styles.Completed} />}
              {lastAttemptFormatted}
            </div>
            <span
              onClick={() => navigate(pathForView(View.Vocab))}
              className={styles.BookButtonsLink}
            >
              Practise vocab
            </span>
          </>
        )}
        {isLoading && <Loading />}
        {isError && <ErrorLoading name="vocab" />}
      </div>
    </HomeBox>
  );
};

const Gold = () => {
  const {
    data: goldreaderState,
    isLoading: goldReaderIsLoading,
    isFetched: goldReaderIsFetched,
  } = useGoldReaderState();
  const isGoldReader = useIsGoldReader();
  const user = useUser();
  if (goldReaderIsLoading) {
    return (
      <HomeBox variant={'normal'} lgw={33} breakWide>
        <Loading />
      </HomeBox>
    );
  }
  if (user?.statistics?.goldReaderDisabled) {
    return (
      <GoldReaderProgressNotGr
        disabledOverride={true}
        isFetched={goldReaderIsFetched}
        progress={0}
        stars={0}
        breakWide
      />
    );
  }
  return (
    <>
      {isGoldReader ? (
        <HomeBox variant={'normal'} lgw={33} breakWide>
          <div className={styles.Main}>
            <div className={styles.PassWrapper}>
              <GoldReaderPass passExpiry={goldreaderState?.passExpiry} showGoldReaderLink />
            </div>
          </div>
        </HomeBox>
      ) : (
        <GoldReaderProgressNotGr
          disabledOverride={false}
          isFetched={goldReaderIsFetched}
          progress={goldreaderState?.progress}
          stars={goldreaderState?.stars}
          showWhatIsLink
          breakWide
        />
      )}
    </>
  );
};

export const Loading = () => (
  <FontAwesomeIcon icon={faSpinner} spin={true} className={styles.LoadingIcon} />
);

export const ErrorLoading = ({ name }: { name: string }) => (
  <span className={styles.Error}>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    Error loading {name}
  </span>
);
