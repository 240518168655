import { isBefore, parseISO } from 'date-fns';
import { useAcademicYearStartDate } from 'queries/schools';
import { useUser } from 'queries/session';
import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const makeKey = (key: string, userId?: string) => `sparx-reader-${key}-${userId || ''}`;

// If the local storage value is before the year start, set it to false
const useResetWhenBeforeYearStart = (k: string) => {
  const { data: yearStart } = useAcademicYearStartDate();
  const [dismissedDate, setDimissedDate] = useLocalStorageState<false | string>(k);
  useEffect(() => {
    if (dismissedDate && yearStart) {
      const noClassesWidgetDismissedDate = parseISO(dismissedDate);
      if (isBefore(noClassesWidgetDismissedDate, yearStart)) {
        setDimissedDate(false);
      }
    }
  }, [yearStart]);
};

export const useNoClassesLocalStorageWidget = () => {
  const user = useUser();
  const k = makeKey('no-classes-widget', user?.userId);
  useResetWhenBeforeYearStart(k);
  return useLocalStorageState<false | string>(k, { defaultValue: false });
};

export const useNoClassesLocalStorageReminder = () => {
  const user = useUser();
  const k = makeKey('select-class-reminder', user?.userId);
  useResetWhenBeforeYearStart(k);
  return useLocalStorageState<false | string>(k, { defaultValue: false });
};
