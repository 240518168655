// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/reports/v2/reporting.proto" (package "sparx.reading.reports.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Book } from '../../books/v1/book';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Date } from '../../../../../google/type/date';
import { Package } from '../../tasks/v1/tasks';
import { Homework } from '../../tasks/v1/homework';
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListStudentsRequest
 */
export interface ListStudentsRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListStudentsResponse.next_page_token] from a previous `ListStudents` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListStudentsResponse
 */
export interface ListStudentsResponse {
  /**
   * The students in the school.
   *
   * @generated from protobuf field: repeated sparx.reading.reports.v2.ReportingStudent students = 1;
   */
  students: ReportingStudent[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListStudentsRequest.page_token` in a
   * subsequent call to `ListStudents` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListHomeworksRequest
 */
export interface ListHomeworksRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListHomeworksResponse.next_page_token] from a previous `ListHomeworks` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListHomeworksResponse
 */
export interface ListHomeworksResponse {
  /**
   * The homeworks set in the school since the start of the academic year,
   * including student progress on each.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListHomeworksRequest.page_token` in a
   * subsequent call to `ListHomeworks` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListAssessmentResultsRequest
 */
export interface ListAssessmentResultsRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListAssessmentResultsResponse.next_page_token] from a previous `ListAssessmentResults` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListAssessmentResultsResponse
 */
export interface ListAssessmentResultsResponse {
  /**
   * All of the assessments set for students in the school with students' progress on each.
   *
   * @generated from protobuf field: repeated sparx.reading.reports.v2.AssessmentResult assessment_results = 1;
   */
  assessmentResults: AssessmentResult[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListAssessmentResultsRequest.page_token` in a
   * subsequent call to `ListAssessmentResults` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.AssessmentResult
 */
export interface AssessmentResult {
  /**
   * Package includes all of the metadata about the assessment including which
   * student was set it and which SRT was sat (the package title).
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.Package package = 1;
   */
  package?: Package;
  /**
   * The reading age calculated after the student has finished the assessment.
   *
   * @generated from protobuf field: float reading_age_years = 2;
   */
  readingAgeYears: number;
  /**
   * Time spent on the assessment in seconds.
   *
   * @generated from protobuf field: float time_spent_seconds = 3;
   */
  timeSpentSeconds: number;
  /**
   * Whether the assessment has been completed.
   *
   * @generated from protobuf field: bool is_complete = 4;
   */
  isComplete: boolean;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListAssessmentPackagesRequest
 */
export interface ListAssessmentPackagesRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Requested page size. Server may return fewer results (maximum is 500).
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListAssessmentPackagesResponse.next_page_token] from a previous
   * `ListAssessmentPackages` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListAssessmentPackagesResponse
 */
export interface ListAssessmentPackagesResponse {
  /**
   * All SRT packages set in the school, including scheduled packages.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Package packages = 1;
   */
  packages: Package[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListAssessmentPackagesRequest.page_token` in a
   * subsequent call to `ListAssessmentPackages` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * The information needed to generate an activity report for a student.
 *
 * @generated from protobuf message sparx.reading.reports.v2.ReportingStudent
 */
export interface ReportingStudent {
  /**
   * The student's uuid
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * The student's first name
   *
   * @generated from protobuf field: string first_name = 2;
   */
  firstName: string;
  /**
   * The student's last name
   *
   * @generated from protobuf field: string last_name = 3;
   */
  lastName: string;
  /**
   * The student's CLASS_ENGLISH student group id
   *
   * @generated from protobuf field: string student_group_id = 4;
   */
  studentGroupId: string;
  /**
   * The student's reading level. If level is nil the student is in onboarding.
   *
   * @generated from protobuf field: sparx.reading.reports.v2.ReportingStudent.Level level = 5;
   */
  level?: ReportingStudent_Level;
  /**
   * SRP earned all-time
   *
   * @generated from protobuf field: int32 srp_total = 6;
   */
  srpTotal: number;
  /**
   * SRP earned this academic year
   *
   * @generated from protobuf field: int32 srp_this_year = 7;
   */
  srpThisYear: number;
  /**
   * Whether the student has Gold Reader
   *
   * @generated from protobuf field: bool has_gold_reader = 8;
   */
  hasGoldReader: boolean;
  /**
   * Students reading speed in wpm
   *
   * @generated from protobuf field: int32 reading_speed_wpm = 9;
   */
  readingSpeedWpm: number;
  /**
   * Students reading accuracy as a decimal
   *
   * @generated from protobuf field: float reading_accuracy = 11;
   */
  readingAccuracy: number;
  /**
   * Number of words read all-time
   *
   * @generated from protobuf field: int32 words_read_total = 12;
   */
  wordsReadTotal: number;
  /**
   * Whether the student has completed onboarding
   *
   * @generated from protobuf field: bool is_onboarded = 13;
   */
  isOnboarded: boolean;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ReportingStudent.Level
 */
export interface ReportingStudent_Level {
  /**
   * @generated from protobuf field: float value = 1;
   */
  value: number;
  /**
   * @generated from protobuf field: sparx.reading.reports.v2.ReportingStudent.Level.Source source = 2;
   */
  source: ReportingStudent_Level_Source;
}
/**
 * @generated from protobuf enum sparx.reading.reports.v2.ReportingStudent.Level.Source
 */
export enum ReportingStudent_Level_Source {
  /**
   * Unspecified source, usually the fallback 'level' field in users_analysis
   *
   * @generated from protobuf enum value: SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * The level is from a reading assessment
   *
   * @generated from protobuf enum value: SOURCE_ASSESSMENT = 1;
   */
  ASSESSMENT = 1,
  /**
   * The level is from the model
   *
   * @generated from protobuf enum value: SOURCE_DETAILED = 2;
   */
  DETAILED = 2,
  /**
   * The level is from a teacher override
   *
   * @generated from protobuf enum value: SOURCE_OVERRIDE = 3;
   */
  OVERRIDE = 3,
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.LatestStudentEbook
 */
export interface LatestStudentEbook {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The ID of the last ebook that the student read.
   *
   * @generated from protobuf field: string latest_ebook_id = 2;
   */
  latestEbookId: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListLatestStudentEbooksRequest
 */
export interface ListLatestStudentEbooksRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListLatestStudentEbooksResponse.next_page_token] from a previous
   * `ListLatestStudentEbooks` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.ListLatestStudentEbooksResponse
 */
export interface ListLatestStudentEbooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.reports.v2.LatestStudentEbook latest_student_ebooks = 1;
   */
  latestStudentEbooks: LatestStudentEbook[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListLatestStudentEbooksRequest.page_token` in a
   * subsequent call to `ListLatestStudentEbooks` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetSchoolCompletedBookCountRequest
 */
export interface GetSchoolCompletedBookCountRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The start of the date range to count completed books.
   * optional, if not set, the count will be from the start of the academic year.
   *
   * @generated from protobuf field: google.type.Date start_date = 2;
   */
  startDate?: Date;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetSchoolCompletedBookCountResponse
 */
export interface GetSchoolCompletedBookCountResponse {
  /**
   * @generated from protobuf field: int32 completed_book_count = 1;
   */
  completedBookCount: number;
  /**
   * Book completion counts by class ID.
   *
   * @generated from protobuf field: map<string, int32> counts_by_class = 2;
   */
  countsByClass: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetStudentCompletionSummaryRequest
 */
export interface GetStudentCompletionSummaryRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Optional: list of specific students within the school to compute the stats for.
   *
   * @generated from protobuf field: repeated string student_ids = 2;
   */
  studentIds: string[];
  /**
   * The timestamp to compute stats from (i.e homeworks due at or after this time).
   * Caller is responsible for adjusting for the school's timezone.
   *
   * @generated from protobuf field: google.protobuf.Timestamp from_time = 3;
   */
  fromTime?: Timestamp;
  /**
   * The timestamp to compute stats to (i.e homeworks due before this time).
   * Caller is responsible for adjusting for the school's timezone.
   *
   * @generated from protobuf field: google.protobuf.Timestamp to_time = 4;
   */
  toTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetStudentCompletionSummaryResponse
 */
export interface GetStudentCompletionSummaryResponse {
  /**
   * The total number of students assigned at least one compulsory homework.
   *
   * @generated from protobuf field: int32 total_count = 1;
   */
  totalCount: number;
  /**
   * The number of students that completed all of their compulsory homeworks before the due date.
   *
   * @generated from protobuf field: int32 on_time_completed_count = 2;
   */
  onTimeCompletedCount: number;
  // TODO: We don't have access to this data yet, but we plan to add it in the future.
  // The number of students that started at least one of their compulsory homeworks before the due date.
  // int32 on_time_started_count = 3;
  // The number of students that had not started any of their compulsory homeworks before the due date.
  // int32 on_time_unstarted_count = 4;

  /**
   * The number of students that have completed all of their compulsory homeworks (at time of request).
   *
   * @generated from protobuf field: int32 completed_count = 5;
   */
  completedCount: number;
  /**
   * The number of students that have started at least one of their compulsory homeworks (at time of request).
   *
   * @generated from protobuf field: int32 started_count = 6;
   */
  startedCount: number;
  /**
   * The number of students that have not started any of their compulsory homeworks (at time of request).
   *
   * @generated from protobuf field: int32 unstarted_count = 7;
   */
  unstartedCount: number;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetBookCompletionSummaryRequest
 */
export interface GetBookCompletionSummaryRequest {
  /**
   * Resource name of the school.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * ID of the student.
   *
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * ID of the book that was completed.
   *
   * @generated from protobuf field: string book_id = 3;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.GetBookCompletionSummaryResponse
 */
export interface GetBookCompletionSummaryResponse {
  /**
   * The book that was completed.
   *
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 1;
   */
  book?: Book;
  /**
   * The total number of words read.
   *
   * @generated from protobuf field: int32 words_read = 2;
   */
  wordsRead: number;
  /**
   * The SRP that the student earned by completing the book.
   *
   * @generated from protobuf field: int32 srp_earned = 3;
   */
  srpEarned: number;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.HasHomeworksRequest
 */
export interface HasHomeworksRequest {
  /**
   * School resource name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reading.reports.v2.HasHomeworksResponse
 */
export interface HasHomeworksResponse {
  /**
   * @generated from protobuf field: bool has_homeworks = 1;
   */
  hasHomeworks: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsRequest$Type extends MessageType<ListStudentsRequest> {
  constructor() {
    super('sparx.reading.reports.v2.ListStudentsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListStudentsRequest
 */
export const ListStudentsRequest = new ListStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsResponse$Type extends MessageType<ListStudentsResponse> {
  constructor() {
    super('sparx.reading.reports.v2.ListStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ReportingStudent,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListStudentsResponse
 */
export const ListStudentsResponse = new ListStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworksRequest$Type extends MessageType<ListHomeworksRequest> {
  constructor() {
    super('sparx.reading.reports.v2.ListHomeworksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListHomeworksRequest
 */
export const ListHomeworksRequest = new ListHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworksResponse$Type extends MessageType<ListHomeworksResponse> {
  constructor() {
    super('sparx.reading.reports.v2.ListHomeworksResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListHomeworksResponse
 */
export const ListHomeworksResponse = new ListHomeworksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentResultsRequest$Type extends MessageType<ListAssessmentResultsRequest> {
  constructor() {
    super('sparx.reading.reports.v2.ListAssessmentResultsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListAssessmentResultsRequest
 */
export const ListAssessmentResultsRequest =
  new ListAssessmentResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentResultsResponse$Type extends MessageType<ListAssessmentResultsResponse> {
  constructor() {
    super('sparx.reading.reports.v2.ListAssessmentResultsResponse', [
      {
        no: 1,
        name: 'assessment_results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentResult,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListAssessmentResultsResponse
 */
export const ListAssessmentResultsResponse =
  new ListAssessmentResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentResult$Type extends MessageType<AssessmentResult> {
  constructor() {
    super('sparx.reading.reports.v2.AssessmentResult', [
      { no: 1, name: 'package', kind: 'message', T: () => Package },
      {
        no: 2,
        name: 'reading_age_years',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 3,
        name: 'time_spent_seconds',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 4, name: 'is_complete', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.AssessmentResult
 */
export const AssessmentResult = new AssessmentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackagesRequest$Type extends MessageType<ListAssessmentPackagesRequest> {
  constructor() {
    super('sparx.reading.reports.v2.ListAssessmentPackagesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListAssessmentPackagesRequest
 */
export const ListAssessmentPackagesRequest =
  new ListAssessmentPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackagesResponse$Type extends MessageType<ListAssessmentPackagesResponse> {
  constructor() {
    super('sparx.reading.reports.v2.ListAssessmentPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListAssessmentPackagesResponse
 */
export const ListAssessmentPackagesResponse =
  new ListAssessmentPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportingStudent$Type extends MessageType<ReportingStudent> {
  constructor() {
    super('sparx.reading.reports.v2.ReportingStudent', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'level',
        kind: 'message',
        T: () => ReportingStudent_Level,
      },
      { no: 6, name: 'srp_total', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'srp_this_year',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'has_gold_reader',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'reading_speed_wpm',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'reading_accuracy',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 12,
        name: 'words_read_total',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 13,
        name: 'is_onboarded',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ReportingStudent
 */
export const ReportingStudent = new ReportingStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportingStudent_Level$Type extends MessageType<ReportingStudent_Level> {
  constructor() {
    super('sparx.reading.reports.v2.ReportingStudent.Level', [
      { no: 1, name: 'value', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 2,
        name: 'source',
        kind: 'enum',
        T: () => [
          'sparx.reading.reports.v2.ReportingStudent.Level.Source',
          ReportingStudent_Level_Source,
          'SOURCE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ReportingStudent.Level
 */
export const ReportingStudent_Level = new ReportingStudent_Level$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LatestStudentEbook$Type extends MessageType<LatestStudentEbook> {
  constructor() {
    super('sparx.reading.reports.v2.LatestStudentEbook', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'latest_ebook_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.LatestStudentEbook
 */
export const LatestStudentEbook = new LatestStudentEbook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestStudentEbooksRequest$Type extends MessageType<ListLatestStudentEbooksRequest> {
  constructor() {
    super('sparx.reading.reports.v2.ListLatestStudentEbooksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListLatestStudentEbooksRequest
 */
export const ListLatestStudentEbooksRequest =
  new ListLatestStudentEbooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestStudentEbooksResponse$Type extends MessageType<ListLatestStudentEbooksResponse> {
  constructor() {
    super('sparx.reading.reports.v2.ListLatestStudentEbooksResponse', [
      {
        no: 1,
        name: 'latest_student_ebooks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LatestStudentEbook,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.ListLatestStudentEbooksResponse
 */
export const ListLatestStudentEbooksResponse =
  new ListLatestStudentEbooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCompletedBookCountRequest$Type extends MessageType<GetSchoolCompletedBookCountRequest> {
  constructor() {
    super('sparx.reading.reports.v2.GetSchoolCompletedBookCountRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'start_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetSchoolCompletedBookCountRequest
 */
export const GetSchoolCompletedBookCountRequest =
  new GetSchoolCompletedBookCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCompletedBookCountResponse$Type extends MessageType<GetSchoolCompletedBookCountResponse> {
  constructor() {
    super('sparx.reading.reports.v2.GetSchoolCompletedBookCountResponse', [
      {
        no: 1,
        name: 'completed_book_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'counts_by_class',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetSchoolCompletedBookCountResponse
 */
export const GetSchoolCompletedBookCountResponse =
  new GetSchoolCompletedBookCountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentCompletionSummaryRequest$Type extends MessageType<GetStudentCompletionSummaryRequest> {
  constructor() {
    super('sparx.reading.reports.v2.GetStudentCompletionSummaryRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'from_time', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'to_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetStudentCompletionSummaryRequest
 */
export const GetStudentCompletionSummaryRequest =
  new GetStudentCompletionSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentCompletionSummaryResponse$Type extends MessageType<GetStudentCompletionSummaryResponse> {
  constructor() {
    super('sparx.reading.reports.v2.GetStudentCompletionSummaryResponse', [
      { no: 1, name: 'total_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'on_time_completed_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'completed_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'started_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'unstarted_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetStudentCompletionSummaryResponse
 */
export const GetStudentCompletionSummaryResponse =
  new GetStudentCompletionSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookCompletionSummaryRequest$Type extends MessageType<GetBookCompletionSummaryRequest> {
  constructor() {
    super('sparx.reading.reports.v2.GetBookCompletionSummaryRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetBookCompletionSummaryRequest
 */
export const GetBookCompletionSummaryRequest =
  new GetBookCompletionSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookCompletionSummaryResponse$Type extends MessageType<GetBookCompletionSummaryResponse> {
  constructor() {
    super('sparx.reading.reports.v2.GetBookCompletionSummaryResponse', [
      { no: 1, name: 'book', kind: 'message', T: () => Book },
      { no: 2, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'srp_earned', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.GetBookCompletionSummaryResponse
 */
export const GetBookCompletionSummaryResponse =
  new GetBookCompletionSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HasHomeworksRequest$Type extends MessageType<HasHomeworksRequest> {
  constructor() {
    super('sparx.reading.reports.v2.HasHomeworksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.HasHomeworksRequest
 */
export const HasHomeworksRequest = new HasHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HasHomeworksResponse$Type extends MessageType<HasHomeworksResponse> {
  constructor() {
    super('sparx.reading.reports.v2.HasHomeworksResponse', [
      {
        no: 1,
        name: 'has_homeworks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.reports.v2.HasHomeworksResponse
 */
export const HasHomeworksResponse = new HasHomeworksResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.reports.v2.Reporting
 */
export const Reporting = new ServiceType('sparx.reading.reports.v2.Reporting', [
  {
    name: 'ListStudents',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListStudentsRequest,
    O: ListStudentsResponse,
  },
  {
    name: 'ListHomeworks',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListHomeworksRequest,
    O: ListHomeworksResponse,
  },
  {
    name: 'ListAssessmentResults',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListAssessmentResultsRequest,
    O: ListAssessmentResultsResponse,
  },
  {
    name: 'ListAssessmentPackages',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListAssessmentPackagesRequest,
    O: ListAssessmentPackagesResponse,
  },
  {
    name: 'ListLatestStudentEbooks',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListLatestStudentEbooksRequest,
    O: ListLatestStudentEbooksResponse,
  },
  {
    name: 'GetSchoolCompletedBookCount',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: GetSchoolCompletedBookCountRequest,
    O: GetSchoolCompletedBookCountResponse,
  },
  {
    name: 'GetStudentCompletionSummary',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: GetStudentCompletionSummaryRequest,
    O: GetStudentCompletionSummaryResponse,
  },
  {
    name: 'GetBookCompletionSummary',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: GetBookCompletionSummaryRequest,
    O: GetBookCompletionSummaryResponse,
  },
  {
    name: 'HasHomeworks',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: HasHomeworksRequest,
    O: HasHomeworksResponse,
  },
]);
