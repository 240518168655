import { faCalendarCheck, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faCog,
  faComment,
  faCookie,
  faCopy,
  faGift,
  faShuffle,
  faSignOutAlt,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { getSchool } from '@sparx/query/schools-service';
import { Switch } from '@sparx/sparx-design/components/switch/Switch';
import { showDevToolsAtom } from 'app/dev-tools/atoms';
import { selectSchoolURL } from 'app/handle-err';
import { ConfirmAlert } from 'components/alert/confirm-alert';
import { useAlert } from 'components/alert/hooks';
import { SettingsAlert } from 'components/alert/settings-alert';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/use-client-event';
import { Version } from 'components/copyright/copyright';
import { TeacherTrainingListItem } from 'components/header/teacher-training/teacher-training-list-item';
import { Link } from 'components/link';
import { ProductSwitch } from 'components/sections/product-switch';
import { useIsSchoolPostReset } from 'hooks/school-calendar';
import { useAtom } from 'jotai';
import { useLogout, userHasRole, UserRole, useUser } from 'queries/session';
import { useCurrentStaffMember } from 'queries/staff';
import { useSubscriptionLockout } from 'queries/subscriptions';
import React, { useEffect, useState } from 'react';
import { openAnonymousMode } from 'utils/anonymous';
import { Flags, useBooleanFlag } from 'utils/featureflags';
import { useClickAwayListener } from 'utils/hooks';
import { useIsStudentDemoMode } from 'utils/student-demo';
import { View } from 'views';
import { useSparxStaffFeatures } from 'views/teacher/components/sparx-staff-features-context';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import { pathForView, useIsInTeacherMode } from 'views/views';

import styles from './header-menu.module.css';

interface DropdownMenuProps {
  children: (onClick: (e: React.MouseEvent<HTMLDivElement> | undefined) => void) => React.ReactNode;
  additional?: React.ReactNode;
}

export const DropdownMenu = ({ children, additional }: DropdownMenuProps) => {
  const user = useUser();
  const isAdmin = userHasRole(user, UserRole.Admin);
  const isTeacher = user?.type === UserType.TEACHER;
  const isStudentDemoMode = useIsStudentDemoMode();
  const subscriptionLockout = useSubscriptionLockout({ enabled: isTeacher });
  const cannyEnabled = useBooleanFlag(Flags.CannyEnabled);
  const { data: staffMember } = useCurrentStaffMember({
    enabled: isTeacher && !user?.roles?.includes(UserRole.Admin),
  });

  const isSchoolPostReset = useIsSchoolPostReset({ enabled: isTeacher });

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  useClickAwayListener(setMenuOpen, menuOpen);

  // Track if the menu has been opened at least once
  const [menuHasOpened, setMenuHasOpened] = useState<boolean>(false);
  useEffect(() => {
    if (menuOpen) {
      setMenuHasOpened(true);
    }
  }, [menuOpen]);

  const openMenu = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
    if (e) {
      e.stopPropagation();
    }
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={styles.Menu} data-test-id="header-menu">
      {children(openMenu)}
      <div className={`${styles.MenuList} ${menuOpen && styles.MenuListOpen}`}>
        <ul className={styles.MenuListButtons}>
          {additional}
          {isTeacher && <CurrentSchool />}
          {isAdmin && <SparxMenuFeatures />}
          {cannyEnabled && isTeacher ? <CannyButton /> : <FeedbackButton open={menuHasOpened} />}
          <SettingsButton />
          {isTeacher && !isStudentDemoMode && !subscriptionLockout && <WhatsNewButton />}
          {isTeacher && !subscriptionLockout && <TrainingButton />}
          {isTeacher && (isSchoolPostReset ? <NewYearSetupButton /> : <GettingStartedButton />)}
          {!isTeacher && menuHasOpened && <ProductSwitch />}
          {!subscriptionLockout && <MenuDivider />}
          {isTeacher && (isAdmin || staffMember?.multipleSchools) && <SwitchSchoolMenuItem />}
          <CookieSettingsButton />
          <SignOutButton />
        </ul>
      </div>
    </div>
  );
};

const getFeedbackButton = () =>
  document.querySelector<
    Element & {
      click?: () => void;
    }
  >('._hj_feedback_container button');

const FeedbackButton = ({ open }: { open: boolean }) => {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const showFeedback = () => {
    const feedbackButton = getFeedbackButton();
    if (feedbackButton && typeof feedbackButton.click === 'function') {
      feedbackButton.click();
    }
  };

  // Reevaluate visibility when the menu is opened
  useEffect(() => setFeedbackVisible(!!getFeedbackButton()), [setFeedbackVisible, open]);

  if (!feedbackVisible) {
    return null;
  }

  return (
    <li>
      <Button
        onClick={showFeedback}
        className={styles.MenuButton}
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faComment} />}
        analyticsEvent={undefined}
      >
        Feedback
      </Button>
    </li>
  );
};

const CannyButton = () => (
  <li>
    <Link
      to={pathForView(View.Feedback)}
      analyticsEvent={{ category: 'profile dropdown', action: 'clicked canny button' }}
    >
      <Button
        className={styles.MenuButton}
        data-test-id="header-menu-feedback"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faComment} />}
        analyticsEvent={undefined}
      >
        Feedback &amp; Suggestions
      </Button>
    </Link>
  </li>
);

const TrainingButton = () => (
  <TrainingContext>
    <TeacherTrainingListItem />
  </TrainingContext>
);

const GettingStartedButton = () => (
  <li>
    <Link
      to={pathForView(View.GettingStarted)}
      analyticsEvent={{ category: 'profile dropdown', action: 'clicked getting started button' }}
    >
      <Button
        className={styles.MenuButton}
        data-test-id="header-menu-getting-started"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faCircleCheck} />}
        analyticsEvent={undefined}
      >
        Getting started
      </Button>
    </Link>
  </li>
);

const NewYearSetupButton = () => (
  <li>
    <Link
      to={pathForView(View.NewYearSetup)}
      analyticsEvent={{ category: 'profile dropdown', action: 'clicked new year setup button' }}
    >
      <Button
        className={styles.MenuButton}
        data-test-id="header-menu-new-year-setup"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faCalendarCheck} />}
        analyticsEvent={undefined}
      >
        New year setup
      </Button>
    </Link>
  </li>
);

const WhatsNewButton = () => (
  <li>
    <Link
      to={pathForView(View.WhatsNew)}
      analyticsEvent={{ category: 'profile dropdown', action: "clicked what's new" }}
    >
      <Button
        className={styles.MenuButton}
        data-test-id="header-menu-whats-new"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faGift} />}
        analyticsEvent={undefined}
      >
        What&apos;s new?
      </Button>
    </Link>
  </li>
);

const CookieSettingsButton = () =>
  window.CookieScript?.instance !== undefined && (
    <li>
      <Button
        analyticsEvent={{
          category: 'profile dropdown',
          action: 'open cookie settings',
        }}
        className={styles.MenuButton}
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faCookie} />}
        onClick={window.CookieScript.instance.show}
      >
        Cookie Settings
      </Button>
    </li>
  );

const SettingsButton = () => {
  const alert = useAlert();
  const config = () => alert(<SettingsAlert />);
  const settingsVisible = !useIsInTeacherMode();

  if (!settingsVisible) {
    return null;
  }

  return (
    <li>
      <Button
        onClick={config}
        className={styles.MenuButton}
        data-test-id="header-menu-settings"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faCog} />}
        analyticsEvent={undefined}
      >
        Settings
      </Button>
    </li>
  );
};

const SignOutButton = () => {
  const alert = useAlert();
  const doLogout = useLogout();
  const user = useUser();

  const logout = () =>
    alert(
      <ConfirmAlert
        title="Are you sure you want to sign out?"
        confirmText={'Sign out'}
        rejectText={'Back'}
        onConfirm={() => doLogout.mutate()}
        confirmAnalytics={{
          category: 'logout',
          action: 'confirm logout',
          labels: { userID: user?.userId },
        }}
        rejectAnalytics={undefined}
      />,
    );

  return (
    <li>
      <Button
        onClick={logout}
        className={styles.MenuButton}
        data-test-id="header-menu-logout"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faSignOutAlt} />}
        analyticsEvent={undefined}
      >
        Sign out
      </Button>
    </li>
  );
};

const CurrentSchool = () => {
  const { data: school } = getSchool.useQuery();
  return (
    <>
      <li className={styles.CurrentSchoolBox}>
        <span>Signed into:</span>
        <h2>{school?.displayName}</h2>
      </li>
      <MenuDivider />
    </>
  );
};

const SwitchSchoolMenuItem = () => {
  const { sendEvent: sendClientEvent } = useClientEvent();
  return (
    <li>
      <Button
        onClick={() => {
          sendClientEvent(
            { action: 'click_switch_school', category: 'header-menu' },
            { immediate: true },
          );
          window.location.href = `${selectSchoolURL}/?app=sparx_reader&noredirect=1`;
        }}
        className={styles.MenuButton}
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faShuffle} />}
        analyticsEvent={undefined}
      >
        Switch school
      </Button>
    </li>
  );
};

const SparxMenuFeatures = () => {
  const { data: school } = getSchool.useQuery();
  const [showDevTools, setShowDevTools] = useAtom(showDevToolsAtom);
  const { sparxStaffFeaturesEnabled, toggleSparxStaffFeaturesEnabled } = useSparxStaffFeatures();

  const searchParams = window?.location.search;
  const schoolParam = (searchParams ? '&' : '?') + 'school=' + school?.name.split('schools/')[1];

  const [copied, setCopied] = useState(false);
  const handleCopy: React.MouseEventHandler<HTMLButtonElement> = evt => {
    // Prevent menu from closing when clicking copy.
    evt.stopPropagation();
    // eslint-disable-next-line compat/compat -- admin only feature
    navigator.clipboard.writeText(window.location.href + schoolParam);
    // Change the button text to indicate success
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <>
      <li className={styles.MenuVersion}>
        <Version />
      </li>
      <li className={styles.MenuGroupHeader}>
        <span>Sparx Staff Features</span>
        <Switch
          id="sparxStaffFeaturesToggle"
          labelLeft="Off"
          labelRight="On"
          checked={sparxStaffFeaturesEnabled}
          onCheckedChange={toggleSparxStaffFeaturesEnabled}
        />
      </li>
      <li className={styles.MenuGroupHeader}>
        <span>Dev tools</span>
        <Switch
          id="devToolsToggle"
          labelLeft="Off"
          labelRight="On"
          checked={showDevTools}
          onCheckedChange={() => setShowDevTools(!showDevTools)}
        />
      </li>
      <li>
        <Button
          onClick={handleCopy}
          className={styles.MenuButton}
          leftIcon={<FontAwesomeIcon fixedWidth={true} icon={copied ? faCheck : faCopy} />}
          analyticsEvent={undefined}
        >
          Copy URL
        </Button>
      </li>
      <li>
        <Button
          onClick={openAnonymousMode}
          className={styles.MenuButton}
          leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faUserSecret} />}
          analyticsEvent={undefined}
        >
          Anonymous mode
        </Button>
      </li>
      <MenuDivider />
    </>
  );
};

export const MenuDivider = () => <li className={styles.MenuDivider} />;
