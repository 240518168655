import { useMostRecentRolloverWasManual } from 'hooks/school-calendar';
import { combineQueryStates } from 'queries/client';
import { useEnglishStudentGroups } from 'queries/management';
import { useRecentlyStartedUsingReader } from 'queries/schools';
import { useAllHomeworks } from 'queries/tasks';

import { useGettingStartedOverviewTab } from './tabs/getting-started-overview-tab/hooks';
import { useImportTab } from './tabs/import-tab/hooks';
import { useGettingStartedMISTab, useMISTab } from './tabs/mis-tab/hooks';
import { useOverviewTab } from './tabs/overview-tab/hooks';
import { useResetTab } from './tabs/reset-tab/hooks';
import { useSettingsTab } from './tabs/settings-tab/hooks';
import { useSSOTab } from './tabs/sso-tab/hooks';
import { useStaffTab } from './tabs/staff-tab/hooks';
import { useTrainingTab } from './tabs/training-tab/hooks';
import { Tab } from './types';

export const useNewYearSetupTabs = (): Tab[] => {
  const overviewTab = useOverviewTab();
  const misTab = useMISTab();
  const contactsTab = useStaffTab();
  const trainingTab = useTrainingTab();
  const resetTab = useResetTab();
  const settingsTab = useSettingsTab();
  const importTab = useImportTab();
  const ssoTab = useSSOTab();

  return [
    overviewTab,
    misTab,
    contactsTab,
    trainingTab,
    resetTab,
    settingsTab,
    importTab,
    ...(ssoTab.isEnabled ? [ssoTab] : []),
  ];
};

export const useGettingStartedTabs = (): Tab[] => {
  const gettingStartedOverviewTab = useGettingStartedOverviewTab();
  const misTab = useGettingStartedMISTab();
  const contactsTab = useStaffTab();
  const trainingTab = useTrainingTab();
  const settingsTab = useSettingsTab();
  const importTab = useImportTab();
  const ssoTab = useSSOTab();

  return [
    gettingStartedOverviewTab,
    misTab,
    contactsTab,
    trainingTab,
    settingsTab,
    importTab,
    ...(ssoTab.isEnabled ? [ssoTab] : []),
  ];
};

/**
 * Returns whether the getting started information is loading, the total number
 * of tabs and whether each tab is completed
 */
export const useGettingStartedProgress = (): {
  isLoading: boolean;
  total: number;
  completed: number;
} => {
  // We're not using the `useImportTab` hook because that's very heavyweight
  // and loads far more data than we need to determine whether the check is
  // complete (e.g. SRT results for all students). Instead we're using
  // `useAllClassesHaveHomework`, which replicated the completeness check of
  // the import tab. The other tabs are less heavyweight so sharing their logic
  // makes sense. We also don't need the overview tab because that doesn't load
  // anything.
  const misTab = useGettingStartedMISTab();
  const contactsTab = useStaffTab();
  const trainingTab = useTrainingTab();
  const settingsTab = useSettingsTab();
  const allClassesHaveHomework = useAllClassesHaveHomework();
  const ssoTab = useSSOTab();

  const tabs = [
    misTab,
    contactsTab,
    trainingTab,
    settingsTab,
    allClassesHaveHomework,
    ...(ssoTab.isEnabled ? [ssoTab] : []),
  ];

  let completed = 0;
  let total = 0;
  const isLoading = tabs.some(tab => tab.isLoading);
  if (isLoading) {
    return { isLoading, total: 0, completed: 0 };
  }
  for (const tab of tabs) {
    total++;
    if (tab.hasSuccess) {
      completed++;
    }
  }
  return { isLoading, total, completed };
};

/**
 * Determine whether to show the "getting started" header
 * progress link. Note, it is always possible to get to the page even if this
 * returns false.
 *
 * Return whether loading date or if there was an error loading the data
 *
 * Don't show getting started if the school is in the interim state
 * Don't show if the last rollover was a manual one
 * Don't show if homework has been set and either all steps are complete or
 *  the school has been using Reader for more than 45 days.
 */
const cutOffDays = 45;
export const useGettingStartedHeaderLink = (): {
  isGettingStarted?: boolean;
  isLoading?: boolean;
  isError?: boolean;
} => {
  const inInterimStateOrLastRolloverManual = useMostRecentRolloverWasManual();

  const { isLoading: gettingStartedLoading, total, completed } = useGettingStartedProgress();
  const {
    data: allHomeworks,
    isLoading: allHomeworksLoading,
    isError: allHomeworksError,
  } = useAllHomeworks();
  const {
    data: nearStartOfSchoolYear,
    isLoading: isRecentLoading,
    isError: isRecentError,
  } = useRecentlyStartedUsingReader(cutOffDays);

  if (allHomeworksError || isRecentError) {
    return { isError: true };
  }

  if (gettingStartedLoading || allHomeworksLoading || isRecentLoading) {
    return { isLoading: true };
  }

  const allGettingStartedStepsComplete = !gettingStartedLoading && total === completed;
  const homeworkSet = allHomeworks?.length > 0;

  return {
    isGettingStarted: !(
      inInterimStateOrLastRolloverManual ||
      (homeworkSet && (allGettingStartedStepsComplete || !nearStartOfSchoolYear))
    ),
  };
};

/**
 * Determine whether to show the "getting started" home page widget.
 * Note, it is always possible to get to the page even if this returns false.
 *
 * Return whether loading date or if there was an error loading the data
 *
 * Don't show getting started if the school is in the interim state
 * Don't show if the last rollover was a manual one
 * Don't show if homework has been set and either all steps are complete or
 *  the school has been using Reader for more than 45 days.
 */
export const useGettingStartedHomepageBanner = (): {
  isGettingStarted?: boolean;
  isLoading?: boolean;
  isError?: boolean;
} => {
  const inInterimStateOrLastRolloverManual = useMostRecentRolloverWasManual();
  const {
    data: nearStartOfSchoolYear,
    isLoading: isRecentLoading,
    isError: isRecentError,
  } = useRecentlyStartedUsingReader(cutOffDays);
  const {
    data: allHomeworks,
    isLoading: allHomeworksLoading,
    isError: allHomeworksError,
  } = useAllHomeworks();

  if (allHomeworksError || isRecentError) {
    return { isError: true };
  }

  if (allHomeworksLoading || isRecentLoading) {
    return { isLoading: true };
  }

  const homeworkSet = allHomeworks?.length > 0;

  return {
    isGettingStarted: !(
      inInterimStateOrLastRolloverManual ||
      (homeworkSet && !nearStartOfSchoolYear)
    ),
  };
};

/**
 * Returns whether all English classes in the school have at least one homework
 * set. This is an abbreviated check to match the one done on the import tab
 * without loading all SRTs for all students, as that information isn't needed
 * to determine whether the import step is complete.
 */
const useAllClassesHaveHomework = () => {
  const { data: englishGroups, ...groupsQueryState } = useEnglishStudentGroups();
  const { data: homeworks, ...homeworksQueryState } = useAllHomeworks();

  const { isLoading } = combineQueryStates(groupsQueryState, homeworksQueryState);

  const classesWithHomework = new Set<string>();
  homeworks?.forEach(hw => classesWithHomework.add(hw.studentGroupId));

  const allClassesHaveHomework =
    englishGroups &&
    englishGroups.length > 0 &&
    englishGroups.every(g => classesWithHomework.has(g.name.split('/')[3]));

  return { isLoading, hasSuccess: !!allClassesHaveHomework };
};
