import { OnboardingStep, OnboardingTask } from '@sparx/api/apis/sparx/reading/users/v1/onboarding';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useClientEvent } from 'components/client-events/use-client-event';
import { USER_ONBOARDING_PROGRESS_QUERY_KEY } from 'queries/onboarding';
import { useIsUserOnboarded } from 'queries/session';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tasks } from './tasks';
import { findCurrentTask, taskToIcon } from './utils';
import styles from './wizard.module.css';

export const Wizard = ({ tasks }: { tasks: OnboardingTask[] }) => {
  const [currentTask, setCurrentTask] = useState<OnboardingTask | undefined>(
    findCurrentTask(tasks),
  );
  const { sendEvent } = useClientEvent();
  const queryClient = useQueryClient();
  const isOnboarded = useIsUserOnboarded();
  const navigate = useNavigate();

  useEffect(() => setCurrentTask(findCurrentTask(tasks)), [tasks]);

  useEffect(() => {
    if (isOnboarded && currentTask?.step !== OnboardingStep.BOOK_CHOICE) {
      navigate('/library/explore', { replace: true });
    }
  }, [isOnboarded, navigate, currentTask]);

  if (!currentTask) return <></>;

  const TaskComponent = Tasks[currentTask.step].component;

  return (
    <div className={styles.PanelContainer}>
      <ul className={styles.TabsContainer}>
        {tasks.map((task, i) => (
          <li
            key={task.step}
            className={classNames({
              [styles.CurrentTab]: task.step === currentTask.step,
            })}
            onClick={() =>
              sendEvent({
                category: 'onboarding',
                action: 'clicked tab',
                labels: {
                  clickedStep: OnboardingStep[task.step],
                  currentStep: OnboardingStep[currentTask.step],
                  isLocked: task.step !== currentTask.step,
                },
              })
            }
          >
            <img src={taskToIcon(task, currentTask)} className={styles.TabIcon} />
            <div className={styles.TabTitle}>
              {Tasks[task.step].title}
              <div className={styles.TabStepCount}>
                {i + 1}/{tasks.length}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.StepContainer}>
        <TaskComponent
          onNextStep={() => queryClient.invalidateQueries([USER_ONBOARDING_PROGRESS_QUERY_KEY])}
          task={currentTask}
        />
      </div>
    </div>
  );
};
