import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { useClientEvent } from 'components/client-events/use-client-event';
import { HappinessRating } from 'components/rating/happinessrating';
import { useAtom } from 'jotai';
import { useUpdateLibraryBook } from 'queries/library-books';

import { selectedDifficultyAtom } from '../../atoms';
import styles from './book-difficulty.module.css';

type Props = {
  studentBook: StudentBook;
  task: Task;
};

export const BookDifficulty = ({ studentBook, task }: Props) => {
  const { sendEvent } = useClientEvent();
  const updateLibraryBook = useUpdateLibraryBook();
  const [, setSelectedDifficulty] = useAtom(selectedDifficultyAtom);

  const onSelect = (difficultyRating: number) => {
    sendEvent({
      action: 'book rating',
      category: 'difficulty rating',
      labels: { value: difficultyRating, taskId: task.taskId },
    });
    updateLibraryBook.mutate({
      studentBook: {
        ...studentBook,
        difficultyRating,
      },
      taskId: task.taskId,
    });
    setSelectedDifficulty(difficultyRating);
  };

  return (
    <>
      <p className={styles.RatingText}>How easy or difficult was this book?</p>
      <HappinessRating rating={0} onChange={onSelect} className={styles.Buttons} />
    </>
  );
};
