import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import {
  NotificationBook_Type,
  NotificationWithContext,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import bank from 'components/alert/images/bank.svg';
import { FCWithChildren } from 'utils/props';
import styles from 'views/student/library-view/components/library-book.module.css';

type Props = {
  notifications?: NotificationWithContext[];
  studentBook?: StudentBook;
};

export const BookCorner = ({ notifications, studentBook }: Props) => {
  for (const notification of notifications || []) {
    const rewardNotification = notification.notification?.notification.oneofKind === 'bankedReward';
    if (rewardNotification) {
      // Show the reward notification with priority over the other two
      return <BookCornerBanked />;
    }
  }

  if (studentBook && studentBook?.progress >= 1) {
    return (
      <BookCornerSmall>
        <FontAwesomeIcon icon={faTrophy} />
      </BookCornerSmall>
    );
  }

  for (const notification of notifications || []) {
    const bookNotificationType =
      notification.notification?.notification.oneofKind === 'book' &&
      notification.notification?.notification.book.type;
    if (bookNotificationType === NotificationBook_Type.NEW_BOOK) {
      return (
        <BookCornerNormal>
          New
          <br />
          book
        </BookCornerNormal>
      );
    } else if (bookNotificationType === NotificationBook_Type.NEW_CHAPTERS) {
      return (
        <BookCornerNormal>
          New
          <br />
          chapters
        </BookCornerNormal>
      );
    }
  }
  return <></>;
};

const BookCornerNormal: FCWithChildren = ({ children }) => (
  <strong className={styles.BookCorner}>
    <span className={styles.BookCornerText}>{children}</span>
  </strong>
);

const BookCornerSmall: FCWithChildren = ({ children }) => (
  <strong className={`${styles.BookCorner} ${styles.BookCornerSmall}`}>
    <span className={styles.BookCornerText}>{children}</span>
  </strong>
);

const BookCornerBanked = () => (
  <strong className={`${styles.BookCorner} ${styles.BookCornerBanked}`}>
    <span className={styles.BookCornerText}>
      <img src={bank} alt="" />
    </span>
  </strong>
);
