import {
  ISchoolActionsServiceClient,
  SchoolActionsServiceClient,
} from '@sparx/api/apis/sparx/school/v2/schoolactions.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const schoolActionsServiceId: interfaces.ServiceIdentifier<ISchoolActionsServiceClient> =
  Symbol.for('schoolActionsService');

/** Register a schools service client with the sparx query container */
export const setSchoolActionsClient = (client: ISchoolActionsServiceClient) => {
  container.bind(schoolActionsServiceId).toConstantValue(client);
};

export const useSchoolActionsClient = (): ISchoolActionsServiceClient =>
  useOptionalInjection(
    schoolActionsServiceId,
    () => new SchoolActionsServiceClient(errorTransport),
  );
