import Quagga, { QuaggaJSConfigObject } from '@ericblade/quagga2';
import { faBarcode, faSpinner, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClearAlert } from 'components/alert/hooks';
import { Button } from 'components/buttons/button';
import { useClientEvent } from 'components/client-events/use-client-event';
import panelStyles from 'components/tasks/panel.module.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isISBN13 } from 'utils/books';

import styles from './custom-book-page/custom-book-page.module.css';

const config = (target: Element | string): QuaggaJSConfigObject => ({
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    target,
  },
  numOfWorkers: 4,
  decoder: {
    readers: ['ean_reader'],
  },
});

const stopScanner = () => {
  Quagga.offProcessed();
  Quagga.offDetected();
  Quagga.stop();
};

export const Scanner = ({ setISBN }: { setISBN: (code: string) => void }) => {
  const [error, setError] = useState();
  const video = useRef<HTMLDivElement>(null);
  const { sendEvent } = useClientEvent();
  const clearAlert = useClearAlert();
  const onClose = useCallback(() => {
    clearAlert();
    sendEvent({ category: 'book scanner', action: 'close' });
  }, [sendEvent, clearAlert]);

  useEffect(() => {
    startScanner();
    return () => stopScanner();
  }, []);

  const startScanner = () => {
    Quagga.init(config(video.current || ''), err => {
      if (err) {
        setError(err);
        return;
      }
      console.log('Init finished. Ready to start');
      Quagga.start();
    });
  };

  Quagga.onDetected(result => {
    const code = result.codeResult.code;
    if (code && isISBN13(code)) {
      setISBN(code);
      onClose();
    }
  });

  return (
    <div>
      <div className={panelStyles.ExitButton} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={styles.ScannerModal}>
        <h2 className={panelStyles.ModalTitle}>
          <FontAwesomeIcon icon={faBarcode} style={{ marginRight: 10 }} />
          Scan book barcode
        </h2>
        <p>
          Use this tool to scan the barcode on the back of the book. This window will close if it
          finds a valid barcode.
        </p>
        <div className={styles.ScannerModalPreview} ref={video}>
          <div className={styles.ScannerModalPreviewVideo}>
            <FontAwesomeIcon icon={faVideo} style={{ marginRight: 20 }} /> Loading...
          </div>
          {error && (
            <div className={styles.ScannerModalPreviewVideo}>Error in starting camera.</div>
          )}
          <div className={styles.ScannerModalPreviewSearching}>
            <FontAwesomeIcon icon={faSpinner} spin={true} style={{ marginRight: 10 }} />
            Searching...
          </div>
        </div>
        <p>
          Sparx will never send or store any captured images, all data is processed on your device.
        </p>
        <div className={panelStyles.Buttons}>
          <Button analyticsEvent={undefined} onClick={onClose} variant="secondary">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};
