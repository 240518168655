// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/management/v1/management.proto" (package "sparx.reading.management.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { School } from '../../../school/v2/schools';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Question } from '../../bookkeeper/v1/bookquestion';
import { StudentHomework } from '../../tasks/v1/homework';
import { MetadataAbridged } from '../../users/librarybooks/v1/librarybooks';
import { StudentHomeworkTaskAttempt } from '../../tasks/v1/homework';
import { TaskAttempt } from '../../tasks/v1/tasks';
import { AccountStatus as AccountStatus$ } from '../../users/v1/sessions';
import { Task } from '../../tasks/v1/tasks';
import { Book } from '../../books/v1/book';
import { StudentBook } from '../../content/v1/service';
import { Package } from '../../tasks/v1/tasks';
import { StudentFlag } from '../../users/flags/v1/flags';
import { GoldStatus } from '../../users/v1/statistics';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { GoldReaderState } from '../../users/v1/statistics';
import { HomeworkLength } from '../../users/v1/sessions';
import { Homework } from '../../tasks/v1/homework';
import { Experience } from '../../users/v1/experience';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { User } from '../../users/v1/sessions';
/**
 * @generated from protobuf message sparx.reading.management.v1.StudentGroup
 */
export interface StudentGroup {
  /**
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @deprecated
   * @generated from protobuf field: string invite_code = 3 [deprecated = true];
   */
  inviteCode: string;
  /**
   * @generated from protobuf field: repeated string starred_user_ids = 4;
   */
  starredUserIds: string[];
  /**
   * @generated from protobuf field: sparx.reading.management.v1.StudentGroupType type = 5;
   */
  type: StudentGroupType;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.HomeworkGroup
 */
export interface HomeworkGroup {
  /**
   * ID for this homework group which can group together multiple
   * homeworks.
   *
   * @generated from protobuf field: string homework_group_id = 1;
   */
  homeworkGroupId: string;
  /**
   * Student group that the homework should be set for
   *
   * @generated from protobuf field: string student_group_id = 2;
   */
  studentGroupId: string;
  /**
   * The day that homework should go out on. Sunday is 0.
   *
   * @generated from protobuf field: int32 set_day = 3;
   */
  setDay: number;
  /**
   * The day that homework is due in on. Sunday is 0.
   *
   * @generated from protobuf field: int32 due_day = 4;
   */
  dueDay: number;
  /**
   * Time that the homework should be set.
   *
   * @generated from protobuf field: string set_time = 5;
   */
  setTime: string; // format HH:MM
  /**
   * Time that the homework should be due.
   *
   * @generated from protobuf field: string due_time = 6;
   */
  dueTime: string; // format HH:MM
  /**
   * Date to start the homework group. The first homework will be set
   * the first set_day after this start_date (inclusive of the date).
   *
   * @generated from protobuf field: string start_date = 7;
   */
  startDate: string; // format YYYY-MM-DD
  /**
   * How many times the homework should be repeated weekly.
   *
   * @generated from protobuf field: int32 repeats = 8;
   */
  repeats: number;
  /**
   * Default amount of SRP to be set for each of the homework in the group.
   *
   * @generated from protobuf field: int32 default_srp = 9;
   */
  defaultSrp: number;
  /**
   * 0-indexed numbers of homeworks to skip relative to the start date.
   * Must be < `repeats`.
   *
   * @generated from protobuf field: repeated int32 skipped_homeworks = 10;
   */
  skippedHomeworks: number[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetSchoolRequest
 */
export interface GetSchoolRequest {
  /**
   * The name of the school
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.SearchStudentsRequest
 */
export interface SearchStudentsRequest {
  /**
   * The query to search for.
   *
   * @generated from protobuf field: string query = 1;
   */
  query: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.SearchStudentsResponse
 */
export interface SearchStudentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.User students = 2;
   */
  students: User[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetTeacherExperienceRequest
 */
export interface GetTeacherExperienceRequest {
  /**
   * Inclusive time since the experience was rewarded (can be null to select
   * all)
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp_since = 1;
   */
  timestampSince?: Timestamp;
  /**
   * Inclusive time up to the experience was rewarded (can be null to select
   * all)
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp_to = 2;
   */
  timestampTo?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetTeacherExperienceResponse
 */
export interface GetTeacherExperienceResponse {
  /**
   * Map of user_id to experience levels
   *
   * @generated from protobuf field: map<string, sparx.reading.users.v1.Experience> student_experience = 1;
   */
  studentExperience: {
    [key: string]: Experience;
  };
  /**
   * @generated from protobuf field: map<string, sparx.reading.users.v1.Experience> student_experience_this_year = 4;
   */
  studentExperienceThisYear: {
    [key: string]: Experience;
  };
  /**
   * List of the users that exist in the student_experience map.
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.User users = 2;
   */
  users: User[];
  /**
   * @generated from protobuf field: map<string, sparx.reading.management.v1.TeacherUserDetail> user_detail = 3;
   */
  userDetail: {
    [key: string]: TeacherUserDetail;
  };
}
/**
 * @generated from protobuf message sparx.reading.management.v1.TeacherUserDetail
 */
export interface TeacherUserDetail {
  /**
   * The students date of birth. The time component of the timestamp can
   * be ignored.
   *
   * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 1;
   */
  dateOfBirth?: Timestamp;
  /**
   * Timestamp they last logged into the Sparx system
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login_at = 2;
   */
  lastLoginAt?: Timestamp;
  /**
   * Timestamp that their password was last set
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_set_at = 3;
   */
  passwordSetAt?: Timestamp;
  /**
   * Timestamp that their password was last reset at
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_reset_at = 4;
   */
  passwordResetAt?: Timestamp;
  /**
   * Timestamp that they last requested a password reset. If they have not
   * requested a reset then this should be nil.
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_reset_requested_at = 5;
   */
  passwordResetRequestedAt?: Timestamp;
  /**
   * Timestamp that any password reset request was granted at. If this is
   * nil and the password_reset_requested_at is not then there is an
   * outstanding request.
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_reset_granted_at = 6;
   */
  passwordResetGrantedAt?: Timestamp;
  /**
   * The username they use to login to Sparx Maths
   *
   * @generated from protobuf field: string sparx_username = 7;
   */
  sparxUsername: string;
  /**
   * The student's email address if they use SSO.
   *
   * @generated from protobuf field: string email_address = 8;
   */
  emailAddress: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentGroupsRequest
 */
export interface GetStudentGroupsRequest {}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentGroupsResponse
 */
export interface GetStudentGroupsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.StudentGroup groups = 1;
   */
  groups: StudentGroup[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateHomeworkRequest
 */
export interface UpdateHomeworkRequest {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.Homework homework = 1;
   */
  homework?: Homework;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateHomeworkResponse
 */
export interface UpdateHomeworkResponse {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.Homework homework = 1;
   */
  homework?: Homework;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.DeleteHomeworkRequest
 */
export interface DeleteHomeworkRequest {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.DeleteHomeworkResponse
 */
export interface DeleteHomeworkResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateStudentRequest
 */
export interface UpdateStudentRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: float level_override = 2;
   */
  levelOverride: number;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.HomeworkLength homework_length = 4;
   */
  homeworkLength: HomeworkLength;
  /**
   * @generated from protobuf field: bool gold_reader_disabled = 5;
   */
  goldReaderDisabled: boolean;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.GoldReaderState gold_reader_state = 6;
   */
  goldReaderState?: GoldReaderState;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 20;
   */
  updateMask?: FieldMask;
  /**
   * @deprecated
   * @generated from protobuf field: optional sparx.reading.users.v1.GoldStatus gold_override = 3 [deprecated = true];
   */
  goldOverride?: GoldStatus;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateStudentResponse
 */
export interface UpdateStudentResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.User user = 1;
   */
  user?: User;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GrantStudentPasswordRequest
 */
export interface GrantStudentPasswordRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GrantStudentPasswordResponse
 */
export interface GrantStudentPasswordResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateStudentGroupStarredRequest
 */
export interface UpdateStudentGroupStarredRequest {
  /**
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * @generated from protobuf field: bool starred = 2;
   */
  starred: boolean;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateStudentGroupStarredResponse
 */
export interface UpdateStudentGroupStarredResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkStudentFlagsRequest
 */
export interface ListHomeworkStudentFlagsRequest {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListOfFlags
 */
export interface ListOfFlags {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.flags.v1.StudentFlag flags = 1;
   */
  flags: StudentFlag[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkStudentFlagsResponse
 */
export interface ListHomeworkStudentFlagsResponse {
  /**
   * A map of user ID to the flags for that user
   *
   * @generated from protobuf field: map<string, sparx.reading.management.v1.ListOfFlags> student_flags = 1;
   */
  studentFlags: {
    [key: string]: ListOfFlags;
  };
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkGroupsRequest
 */
export interface ListHomeworkGroupsRequest {}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkGroupsResponse
 */
export interface ListHomeworkGroupsResponse {
  /**
   * List of returned homework groups.
   *
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkGroup groups = 1;
   */
  groups: HomeworkGroup[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateHomeworkGroupRequest
 */
export interface UpdateHomeworkGroupRequest {
  /**
   * HomeworkGroup that the user is updating.
   *
   * @generated from protobuf field: sparx.reading.management.v1.HomeworkGroup homework_group = 1;
   */
  homeworkGroup?: HomeworkGroup;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.UpdateHomeworkGroupResponse
 */
export interface UpdateHomeworkGroupResponse {
  /**
   * The updated homework group. Will contain the new id if a new
   * one was inserted.
   *
   * @generated from protobuf field: sparx.reading.management.v1.HomeworkGroup homework_group = 1;
   */
  homeworkGroup?: HomeworkGroup;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.BatchUpdateHomeworkGroupsRequest
 */
export interface BatchUpdateHomeworkGroupsRequest {
  /**
   * HomeworkGroups that the user is updating.
   *
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkGroup homework_groups = 1;
   */
  homeworkGroups: HomeworkGroup[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.BatchUpdateHomeworkGroupsResponse
 */
export interface BatchUpdateHomeworkGroupsResponse {
  /**
   * The updated homework groups. Will contain the new id if a new
   * one was inserted.
   *
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkGroup homework_groups = 1;
   */
  homeworkGroups: HomeworkGroup[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.EndHomeworkGroupRequest
 */
export interface EndHomeworkGroupRequest {
  /**
   * The homework group to end.
   *
   * @generated from protobuf field: string homework_group_id = 1;
   */
  homeworkGroupId: string;
  /**
   * The homework within the group to end it from (this homework and all following homeworks will be deleted).
   *
   * @generated from protobuf field: string homework_id = 2;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.EndHomeworkGroupResponse
 */
export interface EndHomeworkGroupResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.PreviewHomeworkGroupDatesRequest
 */
export interface PreviewHomeworkGroupDatesRequest {
  /**
   * HomeworkGroup to preview the dates for
   *
   * @generated from protobuf field: sparx.reading.management.v1.HomeworkGroup homework_group = 1;
   */
  homeworkGroup?: HomeworkGroup;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.HomeworkPreview
 */
export interface HomeworkPreview {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 1;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 2;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: sparx.reading.management.v1.HomeworkPreviewStatus status = 3;
   */
  status: HomeworkPreviewStatus;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.PreviewHomeworkGroupDatesResponse
 */
export interface PreviewHomeworkGroupDatesResponse {
  /**
   * List of the homeworks that will result from the homework group.
   *
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkPreview homeworks = 1;
   */
  homeworks: HomeworkPreview[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesRequest
 */
export interface BatchPreviewHomeworkGroupDatesRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkGroup homework_groups = 1;
   */
  homeworkGroups: HomeworkGroup[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.HomeworkGroupPreview
 */
export interface HomeworkGroupPreview {
  /**
   * @generated from protobuf field: sparx.reading.management.v1.HomeworkGroup homework_group = 1;
   */
  homeworkGroup?: HomeworkGroup;
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkPreview homeworks = 2;
   */
  homeworks: HomeworkPreview[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesResponse
 */
export interface BatchPreviewHomeworkGroupDatesResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.HomeworkGroupPreview homework_group_previews = 1;
   */
  homeworkGroupPreviews: HomeworkGroupPreview[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAvailableAssessmentsRequest
 */
export interface ListAvailableAssessmentsRequest {}
/**
 * AssessmentForm is a type of assessment form.
 *
 * @generated from protobuf message sparx.reading.management.v1.AssessmentForm
 */
export interface AssessmentForm {
  /**
   * UUID identifier of the assessment.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * Type identifier of the assessment. For example 'srt-form1'.
   *
   * @generated from protobuf field: string type = 2;
   */
  type: string;
  /**
   * Title of this AssessmentForm.
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * Flag if this assessment can be set by the current user.
   *
   * @generated from protobuf field: bool can_set = 4;
   */
  canSet: boolean;
  /**
   * Order to display to the user when selecting a form.
   *
   * @generated from protobuf field: int32 order = 5;
   */
  order: number;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAvailableAssessmentsResponse
 */
export interface ListAvailableAssessmentsResponse {
  /**
   * List of known forms.
   *
   * @generated from protobuf field: repeated sparx.reading.management.v1.AssessmentForm forms = 1;
   */
  forms: AssessmentForm[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.CreateAssessmentRequest
 */
export interface CreateAssessmentRequest {
  /**
   * Identifier of the form that the students should be set.
   * The form is the variant of assessment that the student should
   * complete. A FailedPrecondition response will be returned if
   * the form does not exist on the server.
   *
   * @generated from protobuf field: string form_type = 1;
   */
  formType: string;
  /**
   * List of students who should receive the assessment.
   *
   * @generated from protobuf field: repeated string student_ids = 2;
   */
  studentIds: string[];
  /**
   * Duration of the assessment from now in minutes.
   *
   * The duration must be greater than 60 minutes and less than 9
   * hours. If this is not true a FailedPrecondition response will be
   * returned.
   *
   * @generated from protobuf field: float duration_minutes = 3;
   */
  durationMinutes: number;
  /**
   * The time the test should start. Defaults to the start of the current
   * minute if not set.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_time = 4;
   */
  startTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.CreateAssessmentResponse
 */
export interface CreateAssessmentResponse {
  /**
   * List of created package identifiers.
   *
   * @generated from protobuf field: repeated string package_ids = 1;
   */
  packageIds: string[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAssessmentPackagesRequest
 */
export interface ListAssessmentPackagesRequest {
  /**
   * List of students to get the assessment packages for.
   *
   * @generated from protobuf field: repeated string student_ids = 1;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAssessmentPackagesResponse
 */
export interface ListAssessmentPackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.AssessmentPackageState packages = 1;
   */
  packages: AssessmentPackageState[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ExtendAssessmentsRequest
 */
export interface ExtendAssessmentsRequest {
  /**
   * The packages to extend.
   *
   * @generated from protobuf field: repeated string package_ids = 1;
   */
  packageIds: string[];
  /**
   * The new end time of the assessment packages.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ExtendAssessmentsResponse
 */
export interface ExtendAssessmentsResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.CancelAssessmentRequest
 */
export interface CancelAssessmentRequest {
  /**
   * @generated from protobuf field: repeated string package_ids = 1;
   */
  packageIds: string[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.CancelAssessmentResponse
 */
export interface CancelAssessmentResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.AssessmentPackageState
 */
export interface AssessmentPackageState {
  /**
   * Assessment package
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.Package package = 1;
   */
  package?: Package;
  /**
   * Time spent on this package in seconds
   *
   * @generated from protobuf field: int32 time_spent_seconds = 2;
   */
  timeSpentSeconds: number;
  /**
   * Whether the package has been started or not
   *
   * @generated from protobuf field: bool started = 3;
   */
  started: boolean;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentBooksRequest
 */
export interface GetStudentBooksRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @deprecated
   * @generated from protobuf field: optional int32 lookback_days = 2 [deprecated = true];
   */
  lookbackDays?: number;
}
/**
 * The student_book describes the student's reading against a book
 * The book describes the book metadata, including whether it is an ebook or not
 *
 * @generated from protobuf message sparx.reading.management.v1.StudentBookAndMetadata
 */
export interface StudentBookAndMetadata {
  /**
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook student_book = 1;
   */
  studentBook?: StudentBook;
  /**
   * @generated from protobuf field: sparx.reading.books.v1.Book metadata = 2;
   */
  metadata?: Book;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentBooksResponse
 */
export interface GetStudentBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.StudentBookAndMetadata books = 1;
   */
  books: StudentBookAndMetadata[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.SwapStudentBookRequest
 */
export interface SwapStudentBookRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.StudentBookSwapReason reasons = 3;
   */
  reasons: StudentBookSwapReason[];
  /**
   * @generated from protobuf field: string comment = 4;
   */
  comment: string;
  /**
   * @generated from protobuf field: sparx.reading.management.v1.StudentBookSwapState swap_state = 5;
   */
  swapState: StudentBookSwapState;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.SwapStudentBookResponse
 */
export interface SwapStudentBookResponse {}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListStudentPackageTasksRequest
 */
export interface ListStudentPackageTasksRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string package_id = 2;
   */
  packageId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListStudentPackageTasksResponse
 */
export interface ListStudentPackageTasksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Task tasks = 1;
   */
  tasks: Task[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAccountStatusesRequest
 */
export interface ListAccountStatusesRequest {
  /**
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.AccountStatus
 */
export interface AccountStatus {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.AccountStatus status = 2;
   */
  status: AccountStatus$;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListAccountStatusesResponse
 */
export interface ListAccountStatusesResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.management.v1.AccountStatus statuses = 1;
   */
  statuses: AccountStatus[];
}
/**
 * Deprecated: use the version in sparx/reading/reports/v2/reporting.proto.
 *
 * @deprecated
 * @generated from protobuf message sparx.reading.management.v1.SchoolCompletedBookCountRequest
 */
export interface SchoolCompletedBookCountRequest {}
/**
 * Deprecated: use the version in sparx/reading/reports/v2/reporting.proto.
 *
 * @deprecated
 * @generated from protobuf message sparx.reading.management.v1.SchoolCompletedBookCountResponse
 */
export interface SchoolCompletedBookCountResponse {
  /**
   * @generated from protobuf field: int32 completed_book_count = 1;
   */
  completedBookCount: number;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsRequest
 */
export interface GetStudentHomeworkTaskAttemptsRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string homework_id = 2;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsResponse
 */
export interface GetStudentHomeworkTaskAttemptsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Task tasks = 1;
   */
  tasks: Task[];
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.TaskAttempt attempts = 2;
   */
  attempts: TaskAttempt[];
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.StudentHomeworkTaskAttempt student_homework_task_attempts = 3;
   */
  studentHomeworkTaskAttempts: StudentHomeworkTaskAttempt[];
  /**
   * @generated from protobuf field: repeated sparx.reading.users.librarybooks.v1.MetadataAbridged books = 4;
   */
  books: MetadataAbridged[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentHomeworkRequest
 */
export interface GetStudentHomeworkRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string homework_id = 2;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetStudentHomeworkResponse
 */
export interface GetStudentHomeworkResponse {
  /**
   * The student homework.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.StudentHomework student_homework = 1;
   */
  studentHomework?: StudentHomework;
  /**
   * The packages completed by the student for this homework.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Package packages = 2;
   */
  packages: Package[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetTaskBreakpointQuestionsRequest
 */
export interface GetTaskBreakpointQuestionsRequest {
  /**
   * @generated from protobuf field: string task_id = 1;
   */
  taskId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.GetTaskBreakpointQuestionsResponse
 */
export interface GetTaskBreakpointQuestionsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Question questions = 1;
   */
  questions: Question[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkViewsRequest
 */
export interface ListHomeworkViewsRequest {
  /**
   * @generated from protobuf field: repeated string homework_ids = 1;
   */
  homeworkIds: string[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.HomeworkViewers
 */
export interface HomeworkViewers {
  /**
   * @generated from protobuf field: repeated string teacher_ids = 1;
   */
  teacherIds: string[];
}
/**
 * @generated from protobuf message sparx.reading.management.v1.ListHomeworkViewsResponse
 */
export interface ListHomeworkViewsResponse {
  /**
   * Map of homework ID to user IDs that have viewed the homework.
   *
   * @generated from protobuf field: map<string, sparx.reading.management.v1.HomeworkViewers> homework_views = 1;
   */
  homeworkViews: {
    [key: string]: HomeworkViewers;
  };
}
/**
 * @generated from protobuf message sparx.reading.management.v1.CreateHomeworkViewRequest
 */
export interface CreateHomeworkViewRequest {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
}
/**
 * @generated from protobuf message sparx.reading.management.v1.CreateHomeworkViewResponse
 */
export interface CreateHomeworkViewResponse {}
/**
 * @generated from protobuf enum sparx.reading.management.v1.StudentGroupType
 */
export enum StudentGroupType {
  /**
   * @generated from protobuf enum value: STUDENT_GROUP_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * DEPRECATED: Student group is managed by reader (no longer used - all schools are managed by schoolman now)
   *
   * @generated from protobuf enum value: STUDENT_GROUP_TYPE_READER = 1;
   */
  READER = 1,
  /**
   * Student group is managed by MIS provider
   *
   * @generated from protobuf enum value: STUDENT_GROUP_TYPE_MIS = 2;
   */
  MIS = 2,
  /**
   * Student group is managed by MIS provider and is a tutor group
   *
   * @generated from protobuf enum value: STUDENT_GROUP_TYPE_MIS_TUTOR = 3;
   */
  MIS_TUTOR = 3,
}
/**
 * @generated from protobuf enum sparx.reading.management.v1.HomeworkPreviewStatus
 */
export enum HomeworkPreviewStatus {
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_SCHEDULE = 1;
   */
  SCHEDULE = 1,
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_OVERLAP = 2;
   */
  OVERLAP = 2,
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_SKIPPED = 3;
   */
  SKIPPED = 3,
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_START_DATE_PASSED = 4;
   */
  START_DATE_PASSED = 4,
  /**
   * @generated from protobuf enum value: HOMEWORK_PREVIEW_STATUS_OUTSIDE_SCHOOL_YEAR = 5;
   */
  OUTSIDE_SCHOOL_YEAR = 5,
}
/**
 * @generated from protobuf enum sparx.reading.management.v1.StudentBookSwapReason
 */
export enum StudentBookSwapReason {
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_REASON_TOO_EASY = 1;
   */
  TOO_EASY = 1,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_REASON_TOO_HARD = 2;
   */
  TOO_HARD = 2,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_REASON_THEME_INAPPROPRIATE = 3;
   */
  THEME_INAPPROPRIATE = 3,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_REASON_OTHER = 4;
   */
  OTHER = 4,
}
/**
 * @generated from protobuf enum sparx.reading.management.v1.StudentBookSwapState
 */
export enum StudentBookSwapState {
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_STATE_MANUAL_SWAP = 1;
   */
  MANUAL_SWAP = 1,
  /**
   * @generated from protobuf enum value: STUDENT_BOOK_SWAP_STATE_PERMANENT_REMOVAL = 2;
   */
  PERMANENT_REMOVAL = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentGroup$Type extends MessageType<StudentGroup> {
  constructor() {
    super('sparx.reading.management.v1.StudentGroup', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'invite_code',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'starred_user_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.management.v1.StudentGroupType',
          StudentGroupType,
          'STUDENT_GROUP_TYPE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.StudentGroup
 */
export const StudentGroup = new StudentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkGroup$Type extends MessageType<HomeworkGroup> {
  constructor() {
    super('sparx.reading.management.v1.HomeworkGroup', [
      {
        no: 1,
        name: 'homework_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'set_day', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'due_day', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'set_time', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'due_time', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'start_date', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'repeats', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 9, name: 'default_srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 10,
        name: 'skipped_homeworks',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.HomeworkGroup
 */
export const HomeworkGroup = new HomeworkGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolRequest$Type extends MessageType<GetSchoolRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetSchoolRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetSchoolRequest
 */
export const GetSchoolRequest = new GetSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsRequest$Type extends MessageType<SearchStudentsRequest> {
  constructor() {
    super('sparx.reading.management.v1.SearchStudentsRequest', [
      { no: 1, name: 'query', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.SearchStudentsRequest
 */
export const SearchStudentsRequest = new SearchStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsResponse$Type extends MessageType<SearchStudentsResponse> {
  constructor() {
    super('sparx.reading.management.v1.SearchStudentsResponse', [
      {
        no: 2,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => User,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.SearchStudentsResponse
 */
export const SearchStudentsResponse = new SearchStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTeacherExperienceRequest$Type extends MessageType<GetTeacherExperienceRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetTeacherExperienceRequest', [
      { no: 1, name: 'timestamp_since', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'timestamp_to', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetTeacherExperienceRequest
 */
export const GetTeacherExperienceRequest =
  new GetTeacherExperienceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTeacherExperienceResponse$Type extends MessageType<GetTeacherExperienceResponse> {
  constructor() {
    super('sparx.reading.management.v1.GetTeacherExperienceResponse', [
      {
        no: 1,
        name: 'student_experience',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => Experience },
      },
      {
        no: 4,
        name: 'student_experience_this_year',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => Experience },
      },
      {
        no: 2,
        name: 'users',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => User,
      },
      {
        no: 3,
        name: 'user_detail',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => TeacherUserDetail },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetTeacherExperienceResponse
 */
export const GetTeacherExperienceResponse =
  new GetTeacherExperienceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeacherUserDetail$Type extends MessageType<TeacherUserDetail> {
  constructor() {
    super('sparx.reading.management.v1.TeacherUserDetail', [
      { no: 1, name: 'date_of_birth', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'last_login_at', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'password_set_at', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'password_reset_at', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'password_reset_requested_at',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 6,
        name: 'password_reset_granted_at',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 7,
        name: 'sparx_username',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.TeacherUserDetail
 */
export const TeacherUserDetail = new TeacherUserDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupsRequest$Type extends MessageType<GetStudentGroupsRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentGroupsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentGroupsRequest
 */
export const GetStudentGroupsRequest = new GetStudentGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupsResponse$Type extends MessageType<GetStudentGroupsResponse> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentGroupsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentGroupsResponse
 */
export const GetStudentGroupsResponse = new GetStudentGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkRequest$Type extends MessageType<UpdateHomeworkRequest> {
  constructor() {
    super('sparx.reading.management.v1.UpdateHomeworkRequest', [
      { no: 1, name: 'homework', kind: 'message', T: () => Homework },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateHomeworkRequest
 */
export const UpdateHomeworkRequest = new UpdateHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkResponse$Type extends MessageType<UpdateHomeworkResponse> {
  constructor() {
    super('sparx.reading.management.v1.UpdateHomeworkResponse', [
      { no: 1, name: 'homework', kind: 'message', T: () => Homework },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateHomeworkResponse
 */
export const UpdateHomeworkResponse = new UpdateHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteHomeworkRequest$Type extends MessageType<DeleteHomeworkRequest> {
  constructor() {
    super('sparx.reading.management.v1.DeleteHomeworkRequest', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: { 'sparx.api.validation.v1.field': { required: true } },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.DeleteHomeworkRequest
 */
export const DeleteHomeworkRequest = new DeleteHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteHomeworkResponse$Type extends MessageType<DeleteHomeworkResponse> {
  constructor() {
    super('sparx.reading.management.v1.DeleteHomeworkResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.DeleteHomeworkResponse
 */
export const DeleteHomeworkResponse = new DeleteHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentRequest$Type extends MessageType<UpdateStudentRequest> {
  constructor() {
    super('sparx.reading.management.v1.UpdateStudentRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'level_override',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 4,
        name: 'homework_length',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.HomeworkLength',
          HomeworkLength,
          'HOMEWORK_LENGTH_',
        ],
      },
      {
        no: 5,
        name: 'gold_reader_disabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'gold_reader_state',
        kind: 'message',
        T: () => GoldReaderState,
      },
      { no: 20, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'gold_override',
        kind: 'enum',
        opt: true,
        T: () => [
          'sparx.reading.users.v1.GoldStatus',
          GoldStatus,
          'GOLD_STATUS_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateStudentRequest
 */
export const UpdateStudentRequest = new UpdateStudentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentResponse$Type extends MessageType<UpdateStudentResponse> {
  constructor() {
    super('sparx.reading.management.v1.UpdateStudentResponse', [
      { no: 1, name: 'user', kind: 'message', T: () => User },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateStudentResponse
 */
export const UpdateStudentResponse = new UpdateStudentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantStudentPasswordRequest$Type extends MessageType<GrantStudentPasswordRequest> {
  constructor() {
    super('sparx.reading.management.v1.GrantStudentPasswordRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GrantStudentPasswordRequest
 */
export const GrantStudentPasswordRequest =
  new GrantStudentPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantStudentPasswordResponse$Type extends MessageType<GrantStudentPasswordResponse> {
  constructor() {
    super('sparx.reading.management.v1.GrantStudentPasswordResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GrantStudentPasswordResponse
 */
export const GrantStudentPasswordResponse =
  new GrantStudentPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentGroupStarredRequest$Type extends MessageType<UpdateStudentGroupStarredRequest> {
  constructor() {
    super('sparx.reading.management.v1.UpdateStudentGroupStarredRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'starred', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateStudentGroupStarredRequest
 */
export const UpdateStudentGroupStarredRequest =
  new UpdateStudentGroupStarredRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentGroupStarredResponse$Type extends MessageType<UpdateStudentGroupStarredResponse> {
  constructor() {
    super('sparx.reading.management.v1.UpdateStudentGroupStarredResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateStudentGroupStarredResponse
 */
export const UpdateStudentGroupStarredResponse =
  new UpdateStudentGroupStarredResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkStudentFlagsRequest$Type extends MessageType<ListHomeworkStudentFlagsRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkStudentFlagsRequest', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkStudentFlagsRequest
 */
export const ListHomeworkStudentFlagsRequest =
  new ListHomeworkStudentFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOfFlags$Type extends MessageType<ListOfFlags> {
  constructor() {
    super('sparx.reading.management.v1.ListOfFlags', [
      {
        no: 1,
        name: 'flags',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentFlag,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListOfFlags
 */
export const ListOfFlags = new ListOfFlags$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkStudentFlagsResponse$Type extends MessageType<ListHomeworkStudentFlagsResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkStudentFlagsResponse', [
      {
        no: 1,
        name: 'student_flags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => ListOfFlags },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkStudentFlagsResponse
 */
export const ListHomeworkStudentFlagsResponse =
  new ListHomeworkStudentFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkGroupsRequest$Type extends MessageType<ListHomeworkGroupsRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkGroupsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkGroupsRequest
 */
export const ListHomeworkGroupsRequest = new ListHomeworkGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkGroupsResponse$Type extends MessageType<ListHomeworkGroupsResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkGroupsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkGroupsResponse
 */
export const ListHomeworkGroupsResponse = new ListHomeworkGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkGroupRequest$Type extends MessageType<UpdateHomeworkGroupRequest> {
  constructor() {
    super('sparx.reading.management.v1.UpdateHomeworkGroupRequest', [
      {
        no: 1,
        name: 'homework_group',
        kind: 'message',
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateHomeworkGroupRequest
 */
export const UpdateHomeworkGroupRequest = new UpdateHomeworkGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkGroupResponse$Type extends MessageType<UpdateHomeworkGroupResponse> {
  constructor() {
    super('sparx.reading.management.v1.UpdateHomeworkGroupResponse', [
      {
        no: 1,
        name: 'homework_group',
        kind: 'message',
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.UpdateHomeworkGroupResponse
 */
export const UpdateHomeworkGroupResponse =
  new UpdateHomeworkGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateHomeworkGroupsRequest$Type extends MessageType<BatchUpdateHomeworkGroupsRequest> {
  constructor() {
    super('sparx.reading.management.v1.BatchUpdateHomeworkGroupsRequest', [
      {
        no: 1,
        name: 'homework_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.BatchUpdateHomeworkGroupsRequest
 */
export const BatchUpdateHomeworkGroupsRequest =
  new BatchUpdateHomeworkGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateHomeworkGroupsResponse$Type extends MessageType<BatchUpdateHomeworkGroupsResponse> {
  constructor() {
    super('sparx.reading.management.v1.BatchUpdateHomeworkGroupsResponse', [
      {
        no: 1,
        name: 'homework_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.BatchUpdateHomeworkGroupsResponse
 */
export const BatchUpdateHomeworkGroupsResponse =
  new BatchUpdateHomeworkGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndHomeworkGroupRequest$Type extends MessageType<EndHomeworkGroupRequest> {
  constructor() {
    super('sparx.reading.management.v1.EndHomeworkGroupRequest', [
      {
        no: 1,
        name: 'homework_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.EndHomeworkGroupRequest
 */
export const EndHomeworkGroupRequest = new EndHomeworkGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndHomeworkGroupResponse$Type extends MessageType<EndHomeworkGroupResponse> {
  constructor() {
    super('sparx.reading.management.v1.EndHomeworkGroupResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.EndHomeworkGroupResponse
 */
export const EndHomeworkGroupResponse = new EndHomeworkGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewHomeworkGroupDatesRequest$Type extends MessageType<PreviewHomeworkGroupDatesRequest> {
  constructor() {
    super('sparx.reading.management.v1.PreviewHomeworkGroupDatesRequest', [
      {
        no: 1,
        name: 'homework_group',
        kind: 'message',
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.PreviewHomeworkGroupDatesRequest
 */
export const PreviewHomeworkGroupDatesRequest =
  new PreviewHomeworkGroupDatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPreview$Type extends MessageType<HomeworkPreview> {
  constructor() {
    super('sparx.reading.management.v1.HomeworkPreview', [
      { no: 1, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end_date', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.management.v1.HomeworkPreviewStatus',
          HomeworkPreviewStatus,
          'HOMEWORK_PREVIEW_STATUS_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.HomeworkPreview
 */
export const HomeworkPreview = new HomeworkPreview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewHomeworkGroupDatesResponse$Type extends MessageType<PreviewHomeworkGroupDatesResponse> {
  constructor() {
    super('sparx.reading.management.v1.PreviewHomeworkGroupDatesResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPreview,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.PreviewHomeworkGroupDatesResponse
 */
export const PreviewHomeworkGroupDatesResponse =
  new PreviewHomeworkGroupDatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchPreviewHomeworkGroupDatesRequest$Type extends MessageType<BatchPreviewHomeworkGroupDatesRequest> {
  constructor() {
    super('sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesRequest', [
      {
        no: 1,
        name: 'homework_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesRequest
 */
export const BatchPreviewHomeworkGroupDatesRequest =
  new BatchPreviewHomeworkGroupDatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkGroupPreview$Type extends MessageType<HomeworkGroupPreview> {
  constructor() {
    super('sparx.reading.management.v1.HomeworkGroupPreview', [
      {
        no: 1,
        name: 'homework_group',
        kind: 'message',
        T: () => HomeworkGroup,
      },
      {
        no: 2,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPreview,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.HomeworkGroupPreview
 */
export const HomeworkGroupPreview = new HomeworkGroupPreview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchPreviewHomeworkGroupDatesResponse$Type extends MessageType<BatchPreviewHomeworkGroupDatesResponse> {
  constructor() {
    super(
      'sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesResponse',
      [
        {
          no: 1,
          name: 'homework_group_previews',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkGroupPreview,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.BatchPreviewHomeworkGroupDatesResponse
 */
export const BatchPreviewHomeworkGroupDatesResponse =
  new BatchPreviewHomeworkGroupDatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAvailableAssessmentsRequest$Type extends MessageType<ListAvailableAssessmentsRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListAvailableAssessmentsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAvailableAssessmentsRequest
 */
export const ListAvailableAssessmentsRequest =
  new ListAvailableAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentForm$Type extends MessageType<AssessmentForm> {
  constructor() {
    super('sparx.reading.management.v1.AssessmentForm', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'can_set', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'order', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.AssessmentForm
 */
export const AssessmentForm = new AssessmentForm$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAvailableAssessmentsResponse$Type extends MessageType<ListAvailableAssessmentsResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListAvailableAssessmentsResponse', [
      {
        no: 1,
        name: 'forms',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentForm,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAvailableAssessmentsResponse
 */
export const ListAvailableAssessmentsResponse =
  new ListAvailableAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAssessmentRequest$Type extends MessageType<CreateAssessmentRequest> {
  constructor() {
    super('sparx.reading.management.v1.CreateAssessmentRequest', [
      { no: 1, name: 'form_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'duration_minutes',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 4, name: 'start_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CreateAssessmentRequest
 */
export const CreateAssessmentRequest = new CreateAssessmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAssessmentResponse$Type extends MessageType<CreateAssessmentResponse> {
  constructor() {
    super('sparx.reading.management.v1.CreateAssessmentResponse', [
      {
        no: 1,
        name: 'package_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CreateAssessmentResponse
 */
export const CreateAssessmentResponse = new CreateAssessmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackagesRequest$Type extends MessageType<ListAssessmentPackagesRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListAssessmentPackagesRequest', [
      {
        no: 1,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAssessmentPackagesRequest
 */
export const ListAssessmentPackagesRequest =
  new ListAssessmentPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackagesResponse$Type extends MessageType<ListAssessmentPackagesResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListAssessmentPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentPackageState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAssessmentPackagesResponse
 */
export const ListAssessmentPackagesResponse =
  new ListAssessmentPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtendAssessmentsRequest$Type extends MessageType<ExtendAssessmentsRequest> {
  constructor() {
    super('sparx.reading.management.v1.ExtendAssessmentsRequest', [
      {
        no: 1,
        name: 'package_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'end_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ExtendAssessmentsRequest
 */
export const ExtendAssessmentsRequest = new ExtendAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtendAssessmentsResponse$Type extends MessageType<ExtendAssessmentsResponse> {
  constructor() {
    super('sparx.reading.management.v1.ExtendAssessmentsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ExtendAssessmentsResponse
 */
export const ExtendAssessmentsResponse = new ExtendAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAssessmentRequest$Type extends MessageType<CancelAssessmentRequest> {
  constructor() {
    super('sparx.reading.management.v1.CancelAssessmentRequest', [
      {
        no: 1,
        name: 'package_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CancelAssessmentRequest
 */
export const CancelAssessmentRequest = new CancelAssessmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAssessmentResponse$Type extends MessageType<CancelAssessmentResponse> {
  constructor() {
    super('sparx.reading.management.v1.CancelAssessmentResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CancelAssessmentResponse
 */
export const CancelAssessmentResponse = new CancelAssessmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentPackageState$Type extends MessageType<AssessmentPackageState> {
  constructor() {
    super('sparx.reading.management.v1.AssessmentPackageState', [
      { no: 1, name: 'package', kind: 'message', T: () => Package },
      {
        no: 2,
        name: 'time_spent_seconds',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'started', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.AssessmentPackageState
 */
export const AssessmentPackageState = new AssessmentPackageState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentBooksRequest$Type extends MessageType<GetStudentBooksRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentBooksRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'lookback_days',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentBooksRequest
 */
export const GetStudentBooksRequest = new GetStudentBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentBookAndMetadata$Type extends MessageType<StudentBookAndMetadata> {
  constructor() {
    super('sparx.reading.management.v1.StudentBookAndMetadata', [
      { no: 1, name: 'student_book', kind: 'message', T: () => StudentBook },
      { no: 2, name: 'metadata', kind: 'message', T: () => Book },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.StudentBookAndMetadata
 */
export const StudentBookAndMetadata = new StudentBookAndMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentBooksResponse$Type extends MessageType<GetStudentBooksResponse> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentBookAndMetadata,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentBooksResponse
 */
export const GetStudentBooksResponse = new GetStudentBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SwapStudentBookRequest$Type extends MessageType<SwapStudentBookRequest> {
  constructor() {
    super('sparx.reading.management.v1.SwapStudentBookRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'reasons',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reading.management.v1.StudentBookSwapReason',
          StudentBookSwapReason,
          'STUDENT_BOOK_SWAP_REASON_',
        ],
      },
      { no: 4, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'swap_state',
        kind: 'enum',
        T: () => [
          'sparx.reading.management.v1.StudentBookSwapState',
          StudentBookSwapState,
          'STUDENT_BOOK_SWAP_STATE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.SwapStudentBookRequest
 */
export const SwapStudentBookRequest = new SwapStudentBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SwapStudentBookResponse$Type extends MessageType<SwapStudentBookResponse> {
  constructor() {
    super('sparx.reading.management.v1.SwapStudentBookResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.SwapStudentBookResponse
 */
export const SwapStudentBookResponse = new SwapStudentBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentPackageTasksRequest$Type extends MessageType<ListStudentPackageTasksRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListStudentPackageTasksRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListStudentPackageTasksRequest
 */
export const ListStudentPackageTasksRequest =
  new ListStudentPackageTasksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentPackageTasksResponse$Type extends MessageType<ListStudentPackageTasksResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListStudentPackageTasksResponse', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListStudentPackageTasksResponse
 */
export const ListStudentPackageTasksResponse =
  new ListStudentPackageTasksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAccountStatusesRequest$Type extends MessageType<ListAccountStatusesRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListAccountStatusesRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAccountStatusesRequest
 */
export const ListAccountStatusesRequest = new ListAccountStatusesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountStatus$Type extends MessageType<AccountStatus> {
  constructor() {
    super('sparx.reading.management.v1.AccountStatus', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.AccountStatus',
          AccountStatus$,
          'ACCOUNT_STATUS_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.AccountStatus
 */
export const AccountStatus = new AccountStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAccountStatusesResponse$Type extends MessageType<ListAccountStatusesResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListAccountStatusesResponse', [
      {
        no: 1,
        name: 'statuses',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AccountStatus,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListAccountStatusesResponse
 */
export const ListAccountStatusesResponse =
  new ListAccountStatusesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolCompletedBookCountRequest$Type extends MessageType<SchoolCompletedBookCountRequest> {
  constructor() {
    super('sparx.reading.management.v1.SchoolCompletedBookCountRequest', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.management.v1.SchoolCompletedBookCountRequest
 */
export const SchoolCompletedBookCountRequest =
  new SchoolCompletedBookCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolCompletedBookCountResponse$Type extends MessageType<SchoolCompletedBookCountResponse> {
  constructor() {
    super('sparx.reading.management.v1.SchoolCompletedBookCountResponse', [
      {
        no: 1,
        name: 'completed_book_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.management.v1.SchoolCompletedBookCountResponse
 */
export const SchoolCompletedBookCountResponse =
  new SchoolCompletedBookCountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkTaskAttemptsRequest$Type extends MessageType<GetStudentHomeworkTaskAttemptsRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsRequest
 */
export const GetStudentHomeworkTaskAttemptsRequest =
  new GetStudentHomeworkTaskAttemptsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkTaskAttemptsResponse$Type extends MessageType<GetStudentHomeworkTaskAttemptsResponse> {
  constructor() {
    super(
      'sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsResponse',
      [
        {
          no: 1,
          name: 'tasks',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Task,
        },
        {
          no: 2,
          name: 'attempts',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TaskAttempt,
        },
        {
          no: 3,
          name: 'student_homework_task_attempts',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentHomeworkTaskAttempt,
        },
        {
          no: 4,
          name: 'books',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => MetadataAbridged,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentHomeworkTaskAttemptsResponse
 */
export const GetStudentHomeworkTaskAttemptsResponse =
  new GetStudentHomeworkTaskAttemptsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkRequest$Type extends MessageType<GetStudentHomeworkRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentHomeworkRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentHomeworkRequest
 */
export const GetStudentHomeworkRequest = new GetStudentHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkResponse$Type extends MessageType<GetStudentHomeworkResponse> {
  constructor() {
    super('sparx.reading.management.v1.GetStudentHomeworkResponse', [
      {
        no: 1,
        name: 'student_homework',
        kind: 'message',
        T: () => StudentHomework,
      },
      {
        no: 2,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetStudentHomeworkResponse
 */
export const GetStudentHomeworkResponse = new GetStudentHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaskBreakpointQuestionsRequest$Type extends MessageType<GetTaskBreakpointQuestionsRequest> {
  constructor() {
    super('sparx.reading.management.v1.GetTaskBreakpointQuestionsRequest', [
      { no: 1, name: 'task_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetTaskBreakpointQuestionsRequest
 */
export const GetTaskBreakpointQuestionsRequest =
  new GetTaskBreakpointQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaskBreakpointQuestionsResponse$Type extends MessageType<GetTaskBreakpointQuestionsResponse> {
  constructor() {
    super('sparx.reading.management.v1.GetTaskBreakpointQuestionsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.GetTaskBreakpointQuestionsResponse
 */
export const GetTaskBreakpointQuestionsResponse =
  new GetTaskBreakpointQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkViewsRequest$Type extends MessageType<ListHomeworkViewsRequest> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkViewsRequest', [
      {
        no: 1,
        name: 'homework_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkViewsRequest
 */
export const ListHomeworkViewsRequest = new ListHomeworkViewsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkViewers$Type extends MessageType<HomeworkViewers> {
  constructor() {
    super('sparx.reading.management.v1.HomeworkViewers', [
      {
        no: 1,
        name: 'teacher_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.HomeworkViewers
 */
export const HomeworkViewers = new HomeworkViewers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkViewsResponse$Type extends MessageType<ListHomeworkViewsResponse> {
  constructor() {
    super('sparx.reading.management.v1.ListHomeworkViewsResponse', [
      {
        no: 1,
        name: 'homework_views',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => HomeworkViewers },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.ListHomeworkViewsResponse
 */
export const ListHomeworkViewsResponse = new ListHomeworkViewsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkViewRequest$Type extends MessageType<CreateHomeworkViewRequest> {
  constructor() {
    super('sparx.reading.management.v1.CreateHomeworkViewRequest', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CreateHomeworkViewRequest
 */
export const CreateHomeworkViewRequest = new CreateHomeworkViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkViewResponse$Type extends MessageType<CreateHomeworkViewResponse> {
  constructor() {
    super('sparx.reading.management.v1.CreateHomeworkViewResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.management.v1.CreateHomeworkViewResponse
 */
export const CreateHomeworkViewResponse = new CreateHomeworkViewResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.management.v1.Management
 */
export const Management = new ServiceType(
  'sparx.reading.management.v1.Management',
  [
    {
      name: 'GetTeacherExperience',
      options: {},
      I: GetTeacherExperienceRequest,
      O: GetTeacherExperienceResponse,
    },
    {
      name: 'GetStudentGroups',
      options: {},
      I: GetStudentGroupsRequest,
      O: GetStudentGroupsResponse,
    },
    {
      name: 'UpdateHomework',
      options: {},
      I: UpdateHomeworkRequest,
      O: UpdateHomeworkResponse,
    },
    {
      name: 'DeleteHomework',
      options: {},
      I: DeleteHomeworkRequest,
      O: DeleteHomeworkResponse,
    },
    {
      name: 'UpdateStudent',
      options: {},
      I: UpdateStudentRequest,
      O: UpdateStudentResponse,
    },
    {
      name: 'GrantStudentPassword',
      options: {},
      I: GrantStudentPasswordRequest,
      O: GrantStudentPasswordResponse,
    },
    {
      name: 'UpdateStudentGroupStarred',
      options: {},
      I: UpdateStudentGroupStarredRequest,
      O: UpdateStudentGroupStarredResponse,
    },
    {
      name: 'ListHomeworkStudentFlags',
      options: {},
      I: ListHomeworkStudentFlagsRequest,
      O: ListHomeworkStudentFlagsResponse,
    },
    {
      name: 'ListHomeworkGroups',
      options: {},
      I: ListHomeworkGroupsRequest,
      O: ListHomeworkGroupsResponse,
    },
    {
      name: 'UpdateHomeworkGroup',
      options: {},
      I: UpdateHomeworkGroupRequest,
      O: UpdateHomeworkGroupResponse,
    },
    {
      name: 'BatchUpdateHomeworkGroups',
      options: {},
      I: BatchUpdateHomeworkGroupsRequest,
      O: BatchUpdateHomeworkGroupsResponse,
    },
    {
      name: 'EndHomeworkGroup',
      options: {},
      I: EndHomeworkGroupRequest,
      O: EndHomeworkGroupResponse,
    },
    {
      name: 'PreviewHomeworkGroupDates',
      options: {},
      I: PreviewHomeworkGroupDatesRequest,
      O: PreviewHomeworkGroupDatesResponse,
    },
    {
      name: 'BatchPreviewHomeworkGroupDates',
      options: {},
      I: BatchPreviewHomeworkGroupDatesRequest,
      O: BatchPreviewHomeworkGroupDatesResponse,
    },
    {
      name: 'ListAvailableAssessments',
      options: {},
      I: ListAvailableAssessmentsRequest,
      O: ListAvailableAssessmentsResponse,
    },
    {
      name: 'CreateAssessment',
      options: {},
      I: CreateAssessmentRequest,
      O: CreateAssessmentResponse,
    },
    {
      name: 'ListAssessmentPackages',
      options: {},
      I: ListAssessmentPackagesRequest,
      O: ListAssessmentPackagesResponse,
    },
    {
      name: 'ExtendAssessments',
      options: {},
      I: ExtendAssessmentsRequest,
      O: ExtendAssessmentsResponse,
    },
    {
      name: 'CancelAssessment',
      options: {},
      I: CancelAssessmentRequest,
      O: CancelAssessmentResponse,
    },
    {
      name: 'SearchStudents',
      options: {},
      I: SearchStudentsRequest,
      O: SearchStudentsResponse,
    },
    {
      name: 'GetStudentBooks',
      options: {},
      I: GetStudentBooksRequest,
      O: GetStudentBooksResponse,
    },
    {
      name: 'SwapStudentBook',
      options: {},
      I: SwapStudentBookRequest,
      O: SwapStudentBookResponse,
    },
    {
      name: 'ListStudentPackageTasks',
      options: {},
      I: ListStudentPackageTasksRequest,
      O: ListStudentPackageTasksResponse,
    },
    { name: 'GetSchool', options: {}, I: GetSchoolRequest, O: School },
    {
      name: 'ListAccountStatuses',
      options: {},
      I: ListAccountStatusesRequest,
      O: ListAccountStatusesResponse,
    },
    {
      name: 'SchoolCompletedBookCount',
      options: {},
      I: SchoolCompletedBookCountRequest,
      O: SchoolCompletedBookCountResponse,
    },
    {
      name: 'GetStudentHomeworkTaskAttempts',
      options: {},
      I: GetStudentHomeworkTaskAttemptsRequest,
      O: GetStudentHomeworkTaskAttemptsResponse,
    },
    {
      name: 'GetStudentHomework',
      options: {},
      I: GetStudentHomeworkRequest,
      O: GetStudentHomeworkResponse,
    },
    {
      name: 'GetTaskBreakpointQuestions',
      options: {},
      I: GetTaskBreakpointQuestionsRequest,
      O: GetTaskBreakpointQuestionsResponse,
    },
    {
      name: 'ListHomeworkViews',
      options: {},
      I: ListHomeworkViewsRequest,
      O: ListHomeworkViewsResponse,
    },
    {
      name: 'CreateHomeworkView',
      options: {},
      I: CreateHomeworkViewRequest,
      O: CreateHomeworkViewResponse,
    },
  ],
);
