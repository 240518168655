// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reports/reportgen/v1/reportgen.proto" (package "sparx.reports.reportgen.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ReportGenMultiProduct } from './reportgen';
import { ReportGenGC } from './reportgen';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { GarbageCollectReportStatusRequest } from './reportgen';
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { ReportGen } from './reportgen';
import type { TempStudentActivityReportRequest } from './reportgen';
import type { ReportStatus } from './reportgen';
import type { GetReportStatusRequest } from './reportgen';
import type { StudentActivityReportResponse } from './reportgen';
import type { StudentActivityReportRequest } from './reportgen';
import type { XPReportResponse } from './reportgen';
import type { XPReportRequest } from './reportgen';
import type { StudentSSOReportResponse } from './reportgen';
import type { StudentSSOReportRequest } from './reportgen';
import type { HandInSummaryReportResponse } from './reportgen';
import type { HandInSummaryReportRequest } from './reportgen';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { HomeworkCompletionReportResponse } from './reportgen';
import type { HomeworkCompletionReportRequest } from './reportgen';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * ReportGen is for generating reports
 *
 * Reports are file based and are served from an external source such as GCS
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGen
 */
export interface IReportGenClient {
  /**
   * HomeworkCompletionReport generates a report containing homework completion
   * data for the given report type
   *
   * @generated from protobuf rpc: HomeworkCompletionReport(sparx.reports.reportgen.v1.HomeworkCompletionReportRequest) returns (sparx.reports.reportgen.v1.HomeworkCompletionReportResponse);
   */
  homeworkCompletionReport(
    input: HomeworkCompletionReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    HomeworkCompletionReportRequest,
    HomeworkCompletionReportResponse
  >;
  /**
   * HandInSummaryReport generates a report containing on-time completion data
   * at a class level.
   *
   * @generated from protobuf rpc: HandInSummaryReport(sparx.reports.reportgen.v1.HandInSummaryReportRequest) returns (sparx.reports.reportgen.v1.HandInSummaryReportResponse);
   */
  handInSummaryReport(
    input: HandInSummaryReportRequest,
    options?: RpcOptions,
  ): UnaryCall<HandInSummaryReportRequest, HandInSummaryReportResponse>;
  /**
   * StudentSSOReport generates a report containing details of students that
   * cannot use SSO due to their email being unset or wrong
   *
   * @generated from protobuf rpc: StudentSSOReport(sparx.reports.reportgen.v1.StudentSSOReportRequest) returns (sparx.reports.reportgen.v1.StudentSSOReportResponse);
   */
  studentSSOReport(
    input: StudentSSOReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentSSOReportRequest, StudentSSOReportResponse>;
  /**
   * XPReport generates a report containing xp data for students
   *
   * @generated from protobuf rpc: XPReport(sparx.reports.reportgen.v1.XPReportRequest) returns (sparx.reports.reportgen.v1.XPReportResponse);
   */
  xPReport(
    input: XPReportRequest,
    options?: RpcOptions,
  ): UnaryCall<XPReportRequest, XPReportResponse>;
  /**
   * StudentActivityReport generates a report containing homework completion
   * and other activity data
   *
   * @generated from protobuf rpc: StudentActivityReport(sparx.reports.reportgen.v1.StudentActivityReportRequest) returns (sparx.reports.reportgen.v1.StudentActivityReportResponse);
   */
  studentActivityReport(
    input: StudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentActivityReportRequest, StudentActivityReportResponse>;
  /**
   * GetReportStatus allows requesting the status of a previously generated report
   * including the report url if generation completed successfully or details of
   * failure if not.
   *
   * @generated from protobuf rpc: GetReportStatus(sparx.reports.reportgen.v1.GetReportStatusRequest) returns (sparx.reports.reportgen.v1.ReportStatus);
   */
  getReportStatus(
    input: GetReportStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<GetReportStatusRequest, ReportStatus>;
  /**
   * @generated from protobuf rpc: TEMPSAR(sparx.reports.reportgen.v1.TempStudentActivityReportRequest) returns (sparx.reports.reportgen.v1.StudentActivityReportResponse);
   */
  tEMPSAR(
    input: TempStudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<TempStudentActivityReportRequest, StudentActivityReportResponse>;
}
/**
 * ReportGen is for generating reports
 *
 * Reports are file based and are served from an external source such as GCS
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGen
 */
export class ReportGenClient implements IReportGenClient, ServiceInfo {
  typeName = ReportGen.typeName;
  methods = ReportGen.methods;
  options = ReportGen.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * HomeworkCompletionReport generates a report containing homework completion
   * data for the given report type
   *
   * @generated from protobuf rpc: HomeworkCompletionReport(sparx.reports.reportgen.v1.HomeworkCompletionReportRequest) returns (sparx.reports.reportgen.v1.HomeworkCompletionReportResponse);
   */
  homeworkCompletionReport(
    input: HomeworkCompletionReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    HomeworkCompletionReportRequest,
    HomeworkCompletionReportResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      HomeworkCompletionReportRequest,
      HomeworkCompletionReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * HandInSummaryReport generates a report containing on-time completion data
   * at a class level.
   *
   * @generated from protobuf rpc: HandInSummaryReport(sparx.reports.reportgen.v1.HandInSummaryReportRequest) returns (sparx.reports.reportgen.v1.HandInSummaryReportResponse);
   */
  handInSummaryReport(
    input: HandInSummaryReportRequest,
    options?: RpcOptions,
  ): UnaryCall<HandInSummaryReportRequest, HandInSummaryReportResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      HandInSummaryReportRequest,
      HandInSummaryReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * StudentSSOReport generates a report containing details of students that
   * cannot use SSO due to their email being unset or wrong
   *
   * @generated from protobuf rpc: StudentSSOReport(sparx.reports.reportgen.v1.StudentSSOReportRequest) returns (sparx.reports.reportgen.v1.StudentSSOReportResponse);
   */
  studentSSOReport(
    input: StudentSSOReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentSSOReportRequest, StudentSSOReportResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<StudentSSOReportRequest, StudentSSOReportResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * XPReport generates a report containing xp data for students
   *
   * @generated from protobuf rpc: XPReport(sparx.reports.reportgen.v1.XPReportRequest) returns (sparx.reports.reportgen.v1.XPReportResponse);
   */
  xPReport(
    input: XPReportRequest,
    options?: RpcOptions,
  ): UnaryCall<XPReportRequest, XPReportResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<XPReportRequest, XPReportResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * StudentActivityReport generates a report containing homework completion
   * and other activity data
   *
   * @generated from protobuf rpc: StudentActivityReport(sparx.reports.reportgen.v1.StudentActivityReportRequest) returns (sparx.reports.reportgen.v1.StudentActivityReportResponse);
   */
  studentActivityReport(
    input: StudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentActivityReportRequest, StudentActivityReportResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      StudentActivityReportRequest,
      StudentActivityReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetReportStatus allows requesting the status of a previously generated report
   * including the report url if generation completed successfully or details of
   * failure if not.
   *
   * @generated from protobuf rpc: GetReportStatus(sparx.reports.reportgen.v1.GetReportStatusRequest) returns (sparx.reports.reportgen.v1.ReportStatus);
   */
  getReportStatus(
    input: GetReportStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<GetReportStatusRequest, ReportStatus> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetReportStatusRequest, ReportStatus>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: TEMPSAR(sparx.reports.reportgen.v1.TempStudentActivityReportRequest) returns (sparx.reports.reportgen.v1.StudentActivityReportResponse);
   */
  tEMPSAR(
    input: TempStudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    TempStudentActivityReportRequest,
    StudentActivityReportResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      TempStudentActivityReportRequest,
      StudentActivityReportResponse
    >('unary', this._transport, method, opt, input);
  }
}
/**
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGenGC
 */
export interface IReportGenGCClient {
  /**
   * @generated from protobuf rpc: GarbageCollectReportStatus(sparx.reports.reportgen.v1.GarbageCollectReportStatusRequest) returns (google.protobuf.Empty);
   */
  garbageCollectReportStatus(
    input: GarbageCollectReportStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<GarbageCollectReportStatusRequest, Empty>;
}
/**
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGenGC
 */
export class ReportGenGCClient implements IReportGenGCClient, ServiceInfo {
  typeName = ReportGenGC.typeName;
  methods = ReportGenGC.methods;
  options = ReportGenGC.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GarbageCollectReportStatus(sparx.reports.reportgen.v1.GarbageCollectReportStatusRequest) returns (google.protobuf.Empty);
   */
  garbageCollectReportStatus(
    input: GarbageCollectReportStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<GarbageCollectReportStatusRequest, Empty> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GarbageCollectReportStatusRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
/**
 * Reports that are applicable to all products. This service is callable by GRPC Web, and the methods have RBAC auth
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGenMultiProduct
 */
export interface IReportGenMultiProductClient {
  /**
   * StudentSSOReport generates a report containing details of students that
   * cannot use SSO due to their email being unset or wrong
   *
   * @generated from protobuf rpc: StudentSSOReport(sparx.reports.reportgen.v1.StudentSSOReportRequest) returns (sparx.reports.reportgen.v1.StudentSSOReportResponse);
   */
  studentSSOReport(
    input: StudentSSOReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentSSOReportRequest, StudentSSOReportResponse>;
}
/**
 * Reports that are applicable to all products. This service is callable by GRPC Web, and the methods have RBAC auth
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReportGenMultiProduct
 */
export class ReportGenMultiProductClient
  implements IReportGenMultiProductClient, ServiceInfo
{
  typeName = ReportGenMultiProduct.typeName;
  methods = ReportGenMultiProduct.methods;
  options = ReportGenMultiProduct.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * StudentSSOReport generates a report containing details of students that
   * cannot use SSO due to their email being unset or wrong
   *
   * @generated from protobuf rpc: StudentSSOReport(sparx.reports.reportgen.v1.StudentSSOReportRequest) returns (sparx.reports.reportgen.v1.StudentSSOReportResponse);
   */
  studentSSOReport(
    input: StudentSSOReportRequest,
    options?: RpcOptions,
  ): UnaryCall<StudentSSOReportRequest, StudentSSOReportResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<StudentSSOReportRequest, StudentSSOReportResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
