import styles from './LoadingSpinner.module.css';

type LoadingSpinnerSize = 'sm' | 'md' | 'lg';

interface LoadingSpinnerProps {
  size?: LoadingSpinnerSize;
}

const sizeDimensions: Record<LoadingSpinnerSize, string> = {
  sm: '0.7em',
  md: '1em',
  lg: '2em',
};

export const LoadingSpinner = ({ size = 'md' }: LoadingSpinnerProps) => (
  <svg
    className={styles.Spinner}
    viewBox="0 0 50 50"
    style={{ width: sizeDimensions[size], height: sizeDimensions[size] }}
    aria-label="Loading"
  >
    <circle
      className={styles.Path}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
      stroke="currentColor"
    ></circle>
  </svg>
);
