import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { useMemo } from 'react';
import { timestampToMoment } from 'utils/time';

// useCurrentHomework returns the active homework that ends the soonest. See useActiveHomework if
// you want all active homeworks.
export const useCurrentHomework = (homeworks: Homework[] | undefined): Homework | undefined =>
  useMemo(() => {
    // Find the homework that is ending soonest. If it is complete we
    // can select the previous one.
    homeworks?.sort((a, b) => timestampToMoment(a.endDate).diff(timestampToMoment(b.endDate)));
    for (const hwk of homeworks || []) {
      if (hwk.experience < hwk.targetExperience) {
        // This homework is incomplete
        return hwk;
      }
    }
    // All active homeworks are complete, return the one ending soonest (from above sort)
    return homeworks?.[0];
  }, [homeworks]);
