import {
  GetSchoolOnboardingStatusResponse,
  SchoolOnboardingSummary,
} from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus';

const blankSummary: SchoolOnboardingSummary = {
  classesCount: 0,
  classesWithStudentsCount: 0,
  holidaysCount: 0,
  schemesOfLearningCount: 0,
  teachersCount: 0,
  studentsCount: 0,
  isMis: false,
  wondeAuthStatus: '',
  isOnline: true,
  features: {
    parentEmailPermissions: [],
    ssoPermissions: [],
    reportsDemographicsPermissions: [],
  },
  ssoStatus: {
    studentSsoEnabled: false,
    teacherSsoEnabled: false,
    studentDomain: '',
    teacherDomain: '',
    studentsWithMissingEmail: 0,
    studentsWithWrongEmailDomain: 0,
  },
  parentEmailsEnabled: false,
  studentsWithParentalContactsCount: 0,
};

export const getSchoolOnboardingTasks = (data: GetSchoolOnboardingStatusResponse) => {
  const {
    isMis: hasWonde,
    wondeAuthStatus: authStatus,
    classesCount: groupCount,
    classesWithStudentsCount: groupsWithStudentsCount,
    studentsCount: studentCount,
    holidaysCount,
    schemesOfLearningCount: solCount,
    teachersCount: teacherCount,
    features,
    ssoStatus,
    parentEmailsEnabled: parentEmails,
    studentsWithParentalContactsCount,
  } = data.summary || blankSummary;
  /**
   * Consider parent emails authorised if every wonde parent permission is
   * approved OR automatically for CSV schools
   */
  const parentEmailsAuthorised = hasWonde
    ? features?.parentEmailPermissions.every(perm => perm.approved)
    : true;
  return {
    hasWonde,
    authStatus,
    groupCount,
    groupsWithStudentsCount,
    studentCount,
    holidaysCount,
    solCount,
    teacherCount,
    features,
    ssoStatus,
    notUsingParentEmails: parentEmailsAuthorised && !parentEmails,
    noStudentsWithParentEmails:
      parentEmailsAuthorised && parentEmails && studentsWithParentalContactsCount === 0,
  };
};

export const download = (url: string) => {
  if (!url) {
    return;
  }
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
