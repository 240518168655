import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Button } from 'components/buttons';

import styles from './confirmation-dialog.module.css';

type ConfirmationDialogProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string | React.ReactNode;
  confirmText: string;
  cancelText?: string;
  closeButton?: boolean;
  confirmButtonColor?: 'default' | 'danger';
};

export const ConfirmationDialog = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
  cancelText,
  closeButton,
  confirmButtonColor = 'danger',
}: ConfirmationDialogProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Content width="xl" className={styles.Container}>
      <Modal.Title>{title}</Modal.Title>
      {closeButton && <Modal.CloseButton />}
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer align="center">
        <Button variant="secondary" onClick={() => onClose()} analyticsEvent={undefined}>
          {cancelText || 'Cancel'}
        </Button>
        <Button
          color={confirmButtonColor}
          disabled={isLoading}
          loading={isLoading}
          onClick={() => onConfirm()}
          analyticsEvent={undefined}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);
