// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reports/reportgen/v1/reportgen.proto" (package "sparx.reports.reportgen.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Date } from '../../../../../google/type/date';
/**
 * Request message for ReportGen.HomeworkCompletionReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.HomeworkCompletionReportRequest
 */
export interface HomeworkCompletionReportRequest {
  /**
   * @generated from protobuf oneof: report_type
   */
  reportType:
    | {
        oneofKind: 'schoolCompletion';
        /**
         * School completion report type
         *
         * @generated from protobuf field: sparx.reports.reportgen.v1.SchoolCompletion school_completion = 1;
         */
        schoolCompletion: SchoolCompletion;
      }
    | {
        oneofKind: 'schoolTimesTables';
        /**
         * School times tables report type
         *
         * @generated from protobuf field: sparx.reports.reportgen.v1.SchoolTimesTables school_times_tables = 2;
         */
        schoolTimesTables: SchoolTimesTables;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * Response returned by ReportGen.HomeworkCompletionReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.HomeworkCompletionReportResponse
 */
export interface HomeworkCompletionReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * School completion report type for a Homework Completion Report
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.SchoolCompletion
 */
export interface SchoolCompletion {
  /**
   * ID of the school to generate a report for
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * Whether or not to include the student demographic data
   *
   * @generated from protobuf field: bool include_demographic_data = 2;
   */
  includeDemographicData: boolean;
  /**
   * Optional. Only include students in these groups if provided.
   * Non-class group id's (eg tutor groups) are ignored
   *
   * @generated from protobuf field: repeated string student_group_ids = 3;
   */
  studentGroupIds: string[];
  /**
   * Deprecated. Consumers should determine and provide start and end dates for
   * the report. If either of start_date or end_date is provided, this field
   * will be ignored.
   *
   * @deprecated
   * @generated from protobuf field: bool use_previous_academic_year = 4 [deprecated = true];
   */
  usePreviousAcademicYear: boolean;
  /**
   * The earliest due date that should be considered.
   *
   * @generated from protobuf field: google.type.Date start_date = 5;
   */
  startDate?: Date;
  /**
   * The earliest due date that should be considered.
   *
   * @generated from protobuf field: google.type.Date end_date = 6;
   */
  endDate?: Date;
}
/**
 * School times tables report type
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.SchoolTimesTables
 */
export interface SchoolTimesTables {
  /**
   * ID of the school to generate a report for
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * Request message for ReportGen.HandInSummaryReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.HandInSummaryReportRequest
 */
export interface HandInSummaryReportRequest {
  /**
   * ID of the school to generate a report for
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The earliest due date that should be considered.
   *
   * @generated from protobuf field: google.type.Date start_date = 2;
   */
  startDate?: Date;
  /**
   * The latest due date that should be considered.
   *
   * @generated from protobuf field: google.type.Date end_date = 3;
   */
  endDate?: Date;
  /**
   * Optional. Alternate start date for the school leaderboards sheet.
   *
   * @generated from protobuf field: google.type.Date school_leaderboard_start_date = 5;
   */
  schoolLeaderboardStartDate?: Date;
  /**
   * Optional. Alternate end date for the school leaderboards sheet.
   *
   * @generated from protobuf field: google.type.Date school_leaderboard_end_date = 6;
   */
  schoolLeaderboardEndDate?: Date;
  /**
   * The column relating to the featured insight
   *
   * @generated from protobuf field: sparx.reports.reportgen.v1.HandInSummaryColumn featured_column = 4;
   */
  featuredColumn: HandInSummaryColumn;
}
/**
 * Response returned by ReportGen.HandInSummaryReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.HandInSummaryReportResponse
 */
export interface HandInSummaryReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * Request message for ReportGen.StudentSSOReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentSSOReportRequest
 */
export interface StudentSSOReportRequest {
  /**
   * ID of the school to generate a report for
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * Response returned by ReportGen.StudentSSOReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentSSOReportResponse
 */
export interface StudentSSOReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentXPData
 */
export interface StudentXPData {
  /**
   * The id of the student
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: sparx.reports.reportgen.v1.StudentXPData.XPForDateRange xp_for_date_range = 2;
   */
  xpForDateRange?: StudentXPData_XPForDateRange;
  /**
   * @generated from protobuf field: int32 total_xp_this_year = 3;
   */
  totalXpThisYear: number;
  /**
   * @generated from protobuf field: sparx.reports.reportgen.v1.StudentXPData.XPAllTime xp_all_time = 4;
   */
  xpAllTime?: StudentXPData_XPAllTime;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentXPData.XPForDateRange
 */
export interface StudentXPData_XPForDateRange {
  /**
   * @generated from protobuf field: int32 compulsory_xp = 1;
   */
  compulsoryXp: number;
  /**
   * @generated from protobuf field: int32 boost_and_target_xp = 2;
   */
  boostAndTargetXp: number;
  /**
   * @generated from protobuf field: int32 independent_learning_xp = 3;
   */
  independentLearningXp: number;
  /**
   * @generated from protobuf field: int32 total_xp = 4;
   */
  totalXp: number;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentXPData.XPAllTime
 */
export interface StudentXPData_XPAllTime {
  /**
   * @generated from protobuf field: int32 total_xp = 1;
   */
  totalXp: number;
  /**
   * @generated from protobuf field: int32 xp_level = 2;
   */
  xpLevel: number;
  /**
   * @generated from protobuf field: int32 xp_to_next_level = 3;
   */
  xpToNextLevel: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_level_up_date = 4;
   */
  lastLevelUpDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.XPReportRequest
 */
export interface XPReportRequest {
  /**
   * ID of the school to generate a report for
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The group selection for the report
   *
   * @generated from protobuf field: sparx.reports.reportgen.v1.XPReportRequest.GroupSelection group_selection = 2;
   */
  groupSelection: XPReportRequest_GroupSelection;
  /**
   * The class name, year group name or blank, depending on the group selection
   *
   * @generated from protobuf field: string group_selection_data = 3;
   */
  groupSelectionData: string;
  /**
   * The start of the date range for the xp for date range data
   *
   * @generated from protobuf field: google.protobuf.Timestamp xp_date_range_start = 4;
   */
  xpDateRangeStart?: Timestamp;
  /**
   * The end of the date range for the xp for date range data
   *
   * @generated from protobuf field: google.protobuf.Timestamp xp_date_range_end = 5;
   */
  xpDateRangeEnd?: Timestamp;
  /**
   * The xp data for all students in this report
   *
   * @generated from protobuf field: repeated sparx.reports.reportgen.v1.StudentXPData student_xp_data = 6;
   */
  studentXpData: StudentXPData[];
}
/**
 * @generated from protobuf enum sparx.reports.reportgen.v1.XPReportRequest.GroupSelection
 */
export enum XPReportRequest_GroupSelection {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: ALL_MY_CLASSES = 1;
   */
  ALL_MY_CLASSES = 1,
  /**
   * @generated from protobuf enum value: ALL_CLASSES = 2;
   */
  ALL_CLASSES = 2,
  /**
   * @generated from protobuf enum value: SINGLE_CLASS = 3;
   */
  SINGLE_CLASS = 3,
  /**
   * @generated from protobuf enum value: YEAR_GROUP = 4;
   */
  YEAR_GROUP = 4,
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.XPReportResponse
 */
export interface XPReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * Request message for ReportGen.StudentActivityReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentActivityReportRequest
 */
export interface StudentActivityReportRequest {
  /**
   * School completion report type
   *
   * @generated from protobuf field: sparx.reports.reportgen.v1.SchoolCompletion school_completion = 1;
   */
  schoolCompletion?: SchoolCompletion;
  /**
   * If enabled, the report will generate in the background. You can poll the
   * GetReportStatus endpoint to check it's status & actually download the
   * report.
   *
   * @generated from protobuf field: bool async = 2;
   */
  async: boolean;
  /**
   * Include optional XP data in the report.
   *
   * @generated from protobuf field: bool include_xp_data = 3;
   */
  includeXpData: boolean;
  /**
   * Include optional teacher names in the report.
   *
   * @generated from protobuf field: bool include_teacher_names = 4;
   */
  includeTeacherNames: boolean;
}
/**
 * Request message for ReportGen.StudentActivityReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.TempStudentActivityReportRequest
 */
export interface TempStudentActivityReportRequest {
  /**
   * The school to generate the report for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The dates to generate the report for.
   *
   * @generated from protobuf field: sparx.reports.reportgen.v1.ReportRequestDates dates = 2;
   */
  dates?: ReportRequestDates;
  /**
   * Features to include in this report
   *
   * @generated from protobuf field: repeated sparx.reports.reportgen.v1.TempStudentActivityReportRequest.ReportFeature features = 3;
   */
  features: TempStudentActivityReportRequest_ReportFeature[];
  /**
   * Optional. A list of group IDs to include data for.
   *
   * @generated from protobuf field: repeated string group_ids = 4;
   */
  groupIds: string[];
  /**
   * The method to align homework data to weeks in the report
   *
   * @generated from protobuf field: sparx.reports.reportgen.v1.TempStudentActivityReportRequest.HomeworkAlignment alignment = 5;
   */
  alignment: TempStudentActivityReportRequest_HomeworkAlignment;
}
/**
 * @generated from protobuf enum sparx.reports.reportgen.v1.TempStudentActivityReportRequest.ReportFeature
 */
export enum TempStudentActivityReportRequest_ReportFeature {
  /**
   * @generated from protobuf enum value: REPORT_FEATURE_UNKNOWN = 0;
   */
  REPORT_FEATURE_UNKNOWN = 0,
  /**
   * Include homework completion data in this report
   *
   * @generated from protobuf enum value: HOMEWORK_COMPLETION_DATA = 1;
   */
  HOMEWORK_COMPLETION_DATA = 1,
  /**
   * Include rewards data in this report (XP and levels)
   *
   * @generated from protobuf enum value: REWARDS_DATA = 2;
   */
  REWARDS_DATA = 2,
  /**
   * Include sensitive student information such as demographic data in this
   * report. Note that student names are always included in the report.
   *
   * @generated from protobuf enum value: SENSITIVE_STUDENT_INFORMATION = 3;
   */
  SENSITIVE_STUDENT_INFORMATION = 3,
  /**
   * Include the names of teachers assigned to groups
   *
   * @generated from protobuf enum value: TEACHER_INFORMATION = 4;
   */
  TEACHER_INFORMATION = 4,
}
/**
 * @generated from protobuf enum sparx.reports.reportgen.v1.TempStudentActivityReportRequest.HomeworkAlignment
 */
export enum TempStudentActivityReportRequest_HomeworkAlignment {
  /**
   * Use the server default for alignment
   *
   * @generated from protobuf enum value: HOMEWORK_ALIGNMENT_DEFAULT = 0;
   */
  DEFAULT = 0,
  /**
   * Align homework by set date
   *
   * @generated from protobuf enum value: HOMEWORK_ALIGNMENT_SET_DATE = 1;
   */
  SET_DATE = 1,
  /**
   * Align homework by due date
   *
   * @generated from protobuf enum value: HOMEWORK_ALIGNMENT_DUE_DATE = 2;
   */
  DUE_DATE = 2,
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GenerateReportResponse
 */
export interface GenerateReportResponse {
  /**
   * Resource name of report "schools/<school id>/<report type>/<uuid>"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetReportStatusRequest
 */
export interface GetReportStatusRequest {
  /**
   * Resource name of report "schools/<school id>/<report type>/<uuid>"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.ReportStatus
 */
export interface ReportStatus {
  /**
   * Resource name of report "schools/<school id>/<report type>/<uuid>"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: sparx.reports.reportgen.v1.ReportStatus.Status status = 2;
   */
  status: ReportStatus_Status;
  /**
   * Download URL for the generated report, if status = COMPLETE
   *
   * @generated from protobuf field: string download_url = 3;
   */
  downloadUrl: string;
  /**
   * The time the status was requested. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;
  /**
   * The time the status was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;
}
/**
 * @generated from protobuf enum sparx.reports.reportgen.v1.ReportStatus.Status
 */
export enum ReportStatus_Status {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: IN_PROGRESS = 1;
   */
  IN_PROGRESS = 1,
  /**
   * @generated from protobuf enum value: COMPLETE = 2;
   */
  COMPLETE = 2,
  /**
   * @generated from protobuf enum value: FAILED = 3;
   */
  FAILED = 3,
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GarbageCollectReportStatusRequest
 */
export interface GarbageCollectReportStatusRequest {
  /**
   * Delete report statuses older than this date.
   *
   * @generated from protobuf field: google.protobuf.Timestamp threshold = 1;
   */
  threshold?: Timestamp;
}
/**
 * Response returned by ReportGen.StudentActivityReport
 *
 * @generated from protobuf message sparx.reports.reportgen.v1.StudentActivityReportResponse
 */
export interface StudentActivityReportResponse {
  /**
   * Signed URL of report (if not async)
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
  /**
   * Name of a ReportStatus (if async)
   *
   * @generated from protobuf field: string status = 2;
   */
  status: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.ReportRequestDates
 */
export interface ReportRequestDates {
  /**
   * @generated from protobuf oneof: dates
   */
  dates:
    | {
        oneofKind: 'dateRange';
        /**
         * Include data between the specified dates.
         *
         * @generated from protobuf field: sparx.reports.reportgen.v1.ReportRequestDates.DateRange date_range = 1;
         */
        dateRange: ReportRequestDates_DateRange;
      }
    | {
        oneofKind: 'academicYear';
        /**
         * Include data for a specified academic year.
         *
         * @generated from protobuf field: sparx.reports.reportgen.v1.ReportRequestDates.AcademicYear academic_year = 2;
         */
        academicYear: ReportRequestDates_AcademicYear;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.ReportRequestDates.DateRange
 */
export interface ReportRequestDates_DateRange {
  /**
   * The earliest date to include appropriate data for.
   *
   * @generated from protobuf field: google.type.Date start_date = 1;
   */
  startDate?: Date;
  /**
   * The latest date to include appropriate data for.
   *
   * @generated from protobuf field: google.type.Date end_date = 2;
   */
  endDate?: Date;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.ReportRequestDates.AcademicYear
 */
export interface ReportRequestDates_AcademicYear {
  /**
   * @generated from protobuf oneof: identifier
   */
  identifier:
    | {
        oneofKind: 'currentYear';
        /**
         * Use the school's year dates for the current academic year.
         *
         * @generated from protobuf field: bool current_year = 1;
         */
        currentYear: boolean;
      }
    | {
        oneofKind: 'previousYear';
        /**
         * Use the school's year dates for the previous academic year.
         * A NotFound error will be returned if the school has no previous
         * academic year.
         *
         * @generated from protobuf field: bool previous_year = 2;
         */
        previousYear: boolean;
      }
    | {
        oneofKind: 'yearName';
        /**
         * Use the dates for the specified academic year.
         * `schools/<id>/calendar/years/<id>`
         *
         * @generated from protobuf field: string year_name = 3;
         */
        yearName: string;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * Optional. Earliest date to return data for. Must be within the
   * appropriate year.
   *
   * @generated from protobuf field: google.type.Date start_date = 4;
   */
  startDate?: Date;
  /**
   * Optional. Latest date to return data for. Must be within the appropriate
   * year and not before start date.
   *
   * @generated from protobuf field: google.type.Date end_date = 5;
   */
  endDate?: Date;
}
/**
 * @generated from protobuf enum sparx.reports.reportgen.v1.HandInSummaryColumn
 */
export enum HandInSummaryColumn {
  /**
   * @generated from protobuf enum value: HIS_COLUMN_UNKNOWN = 0;
   */
  HIS_COLUMN_UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: HIS_COLUMN_NON_STARTERS = 1;
   */
  HIS_COLUMN_NON_STARTERS = 1,
  /**
   * @generated from protobuf enum value: HIS_COLUMN_LOW_COMPLETION = 2;
   */
  HIS_COLUMN_LOW_COMPLETION = 2,
  /**
   * @generated from protobuf enum value: HIS_COLUMN_HIGH_COMPLETION = 3;
   */
  HIS_COLUMN_HIGH_COMPLETION = 3,
  /**
   * @generated from protobuf enum value: HIS_COLUMN_FULL_COMPLETION = 4;
   */
  HIS_COLUMN_FULL_COMPLETION = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportRequest$Type extends MessageType<HomeworkCompletionReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.HomeworkCompletionReportRequest', [
      {
        no: 1,
        name: 'school_completion',
        kind: 'message',
        oneof: 'reportType',
        T: () => SchoolCompletion,
      },
      {
        no: 2,
        name: 'school_times_tables',
        kind: 'message',
        oneof: 'reportType',
        T: () => SchoolTimesTables,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.HomeworkCompletionReportRequest
 */
export const HomeworkCompletionReportRequest =
  new HomeworkCompletionReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportResponse$Type extends MessageType<HomeworkCompletionReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.HomeworkCompletionReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.HomeworkCompletionReportResponse
 */
export const HomeworkCompletionReportResponse =
  new HomeworkCompletionReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolCompletion$Type extends MessageType<SchoolCompletion> {
  constructor() {
    super('sparx.reports.reportgen.v1.SchoolCompletion', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'include_demographic_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'student_group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'use_previous_academic_year',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 5, name: 'start_date', kind: 'message', T: () => Date },
      { no: 6, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.SchoolCompletion
 */
export const SchoolCompletion = new SchoolCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolTimesTables$Type extends MessageType<SchoolTimesTables> {
  constructor() {
    super('sparx.reports.reportgen.v1.SchoolTimesTables', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.SchoolTimesTables
 */
export const SchoolTimesTables = new SchoolTimesTables$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandInSummaryReportRequest$Type extends MessageType<HandInSummaryReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.HandInSummaryReportRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'start_date', kind: 'message', T: () => Date },
      { no: 3, name: 'end_date', kind: 'message', T: () => Date },
      {
        no: 5,
        name: 'school_leaderboard_start_date',
        kind: 'message',
        T: () => Date,
      },
      {
        no: 6,
        name: 'school_leaderboard_end_date',
        kind: 'message',
        T: () => Date,
      },
      {
        no: 4,
        name: 'featured_column',
        kind: 'enum',
        T: () => [
          'sparx.reports.reportgen.v1.HandInSummaryColumn',
          HandInSummaryColumn,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.HandInSummaryReportRequest
 */
export const HandInSummaryReportRequest = new HandInSummaryReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandInSummaryReportResponse$Type extends MessageType<HandInSummaryReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.HandInSummaryReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.HandInSummaryReportResponse
 */
export const HandInSummaryReportResponse =
  new HandInSummaryReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentSSOReportRequest$Type extends MessageType<StudentSSOReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentSSOReportRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentSSOReportRequest
 */
export const StudentSSOReportRequest = new StudentSSOReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentSSOReportResponse$Type extends MessageType<StudentSSOReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentSSOReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentSSOReportResponse
 */
export const StudentSSOReportResponse = new StudentSSOReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPData$Type extends MessageType<StudentXPData> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentXPData', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'xp_for_date_range',
        kind: 'message',
        T: () => StudentXPData_XPForDateRange,
      },
      {
        no: 3,
        name: 'total_xp_this_year',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'xp_all_time',
        kind: 'message',
        T: () => StudentXPData_XPAllTime,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentXPData
 */
export const StudentXPData = new StudentXPData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPData_XPForDateRange$Type extends MessageType<StudentXPData_XPForDateRange> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentXPData.XPForDateRange', [
      {
        no: 1,
        name: 'compulsory_xp',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'boost_and_target_xp',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'independent_learning_xp',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 4, name: 'total_xp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentXPData.XPForDateRange
 */
export const StudentXPData_XPForDateRange =
  new StudentXPData_XPForDateRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPData_XPAllTime$Type extends MessageType<StudentXPData_XPAllTime> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentXPData.XPAllTime', [
      { no: 1, name: 'total_xp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'xp_level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'xp_to_next_level',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'last_level_up_date',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentXPData.XPAllTime
 */
export const StudentXPData_XPAllTime = new StudentXPData_XPAllTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XPReportRequest$Type extends MessageType<XPReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.XPReportRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'group_selection',
        kind: 'enum',
        T: () => [
          'sparx.reports.reportgen.v1.XPReportRequest.GroupSelection',
          XPReportRequest_GroupSelection,
        ],
      },
      {
        no: 3,
        name: 'group_selection_data',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'xp_date_range_start',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 5, name: 'xp_date_range_end', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'student_xp_data',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentXPData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.XPReportRequest
 */
export const XPReportRequest = new XPReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XPReportResponse$Type extends MessageType<XPReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.XPReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.XPReportResponse
 */
export const XPReportResponse = new XPReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentActivityReportRequest$Type extends MessageType<StudentActivityReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentActivityReportRequest', [
      {
        no: 1,
        name: 'school_completion',
        kind: 'message',
        T: () => SchoolCompletion,
      },
      { no: 2, name: 'async', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'include_xp_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'include_teacher_names',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentActivityReportRequest
 */
export const StudentActivityReportRequest =
  new StudentActivityReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TempStudentActivityReportRequest$Type extends MessageType<TempStudentActivityReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.TempStudentActivityReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'dates', kind: 'message', T: () => ReportRequestDates },
      {
        no: 3,
        name: 'features',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reports.reportgen.v1.TempStudentActivityReportRequest.ReportFeature',
          TempStudentActivityReportRequest_ReportFeature,
        ],
      },
      {
        no: 4,
        name: 'group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'alignment',
        kind: 'enum',
        T: () => [
          'sparx.reports.reportgen.v1.TempStudentActivityReportRequest.HomeworkAlignment',
          TempStudentActivityReportRequest_HomeworkAlignment,
          'HOMEWORK_ALIGNMENT_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.TempStudentActivityReportRequest
 */
export const TempStudentActivityReportRequest =
  new TempStudentActivityReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateReportResponse$Type extends MessageType<GenerateReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.GenerateReportResponse', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GenerateReportResponse
 */
export const GenerateReportResponse = new GenerateReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportStatusRequest$Type extends MessageType<GetReportStatusRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetReportStatusRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetReportStatusRequest
 */
export const GetReportStatusRequest = new GetReportStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportStatus$Type extends MessageType<ReportStatus> {
  constructor() {
    super('sparx.reports.reportgen.v1.ReportStatus', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reports.reportgen.v1.ReportStatus.Status',
          ReportStatus_Status,
        ],
      },
      {
        no: 3,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'update_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.ReportStatus
 */
export const ReportStatus = new ReportStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GarbageCollectReportStatusRequest$Type extends MessageType<GarbageCollectReportStatusRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.GarbageCollectReportStatusRequest', [
      { no: 1, name: 'threshold', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GarbageCollectReportStatusRequest
 */
export const GarbageCollectReportStatusRequest =
  new GarbageCollectReportStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentActivityReportResponse$Type extends MessageType<StudentActivityReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.StudentActivityReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'status', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.StudentActivityReportResponse
 */
export const StudentActivityReportResponse =
  new StudentActivityReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportRequestDates$Type extends MessageType<ReportRequestDates> {
  constructor() {
    super('sparx.reports.reportgen.v1.ReportRequestDates', [
      {
        no: 1,
        name: 'date_range',
        kind: 'message',
        oneof: 'dates',
        T: () => ReportRequestDates_DateRange,
      },
      {
        no: 2,
        name: 'academic_year',
        kind: 'message',
        oneof: 'dates',
        T: () => ReportRequestDates_AcademicYear,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.ReportRequestDates
 */
export const ReportRequestDates = new ReportRequestDates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportRequestDates_DateRange$Type extends MessageType<ReportRequestDates_DateRange> {
  constructor() {
    super('sparx.reports.reportgen.v1.ReportRequestDates.DateRange', [
      { no: 1, name: 'start_date', kind: 'message', T: () => Date },
      { no: 2, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.ReportRequestDates.DateRange
 */
export const ReportRequestDates_DateRange =
  new ReportRequestDates_DateRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportRequestDates_AcademicYear$Type extends MessageType<ReportRequestDates_AcademicYear> {
  constructor() {
    super('sparx.reports.reportgen.v1.ReportRequestDates.AcademicYear', [
      {
        no: 1,
        name: 'current_year',
        kind: 'scalar',
        oneof: 'identifier',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'previous_year',
        kind: 'scalar',
        oneof: 'identifier',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'year_name',
        kind: 'scalar',
        oneof: 'identifier',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'start_date', kind: 'message', T: () => Date },
      { no: 5, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.ReportRequestDates.AcademicYear
 */
export const ReportRequestDates_AcademicYear =
  new ReportRequestDates_AcademicYear$Type();
/**
 * @generated ServiceType for protobuf service sparx.reports.reportgen.v1.ReportGen
 */
export const ReportGen = new ServiceType(
  'sparx.reports.reportgen.v1.ReportGen',
  [
    {
      name: 'HomeworkCompletionReport',
      options: {},
      I: HomeworkCompletionReportRequest,
      O: HomeworkCompletionReportResponse,
    },
    {
      name: 'HandInSummaryReport',
      options: {},
      I: HandInSummaryReportRequest,
      O: HandInSummaryReportResponse,
    },
    {
      name: 'StudentSSOReport',
      options: {},
      I: StudentSSOReportRequest,
      O: StudentSSOReportResponse,
    },
    { name: 'XPReport', options: {}, I: XPReportRequest, O: XPReportResponse },
    {
      name: 'StudentActivityReport',
      options: {},
      I: StudentActivityReportRequest,
      O: StudentActivityReportResponse,
    },
    {
      name: 'GetReportStatus',
      options: {},
      I: GetReportStatusRequest,
      O: ReportStatus,
    },
    {
      name: 'TEMPSAR',
      options: {},
      I: TempStudentActivityReportRequest,
      O: StudentActivityReportResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.reports.reportgen.v1.ReportGenGC
 */
export const ReportGenGC = new ServiceType(
  'sparx.reports.reportgen.v1.ReportGenGC',
  [
    {
      name: 'GarbageCollectReportStatus',
      options: { 'google.api.http': { get: '/gc/reportstatus' } },
      I: GarbageCollectReportStatusRequest,
      O: Empty,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.reports.reportgen.v1.ReportGenMultiProduct
 */
export const ReportGenMultiProduct = new ServiceType(
  'sparx.reports.reportgen.v1.ReportGenMultiProduct',
  [
    {
      name: 'StudentSSOReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: StudentSSOReportRequest,
      O: StudentSSOReportResponse,
    },
  ],
);
