import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';

export const getStudentGroupId = (group: Group) => group.name.split('/studentGroups/')[1];

// Extracts the Wonde ID from an externalID.
// Returns undefined if the externalID is absent, malformed, or not for Wonde.
export const getStudentGroupWondeID = (group: Group) => {
  if (!group.externalId) {
    return undefined;
  }
  const parts = group.externalId.split('_');
  if (parts.length !== 4 || parts[0] !== 'Wonde') {
    return undefined;
  }
  if (!(parts[1] === 'TeachingGroup' || parts[1] === 'TutorGroup')) {
    return undefined;
  }
  return parts[3];
};
