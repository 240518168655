import {
  ISchoolSubscriptionDataActionsServiceClient,
  SchoolSubscriptionDataActionsServiceClient,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const subscriptionActionsServiceId: interfaces.ServiceIdentifier<ISchoolSubscriptionDataActionsServiceClient> =
  Symbol.for('subscriptionActionsService');

/** Register a subscription actions client with the sparx query container */
export const setSubscriptionActionsClient = (
  client: ISchoolSubscriptionDataActionsServiceClient,
) => {
  container.bind(subscriptionActionsServiceId).toConstantValue(client);
};

export const useSubscriptionActionsClient = (): ISchoolSubscriptionDataActionsServiceClient =>
  useOptionalInjection(
    subscriptionActionsServiceId,
    () => new SchoolSubscriptionDataActionsServiceClient(errorTransport),
  );
