import { getSchool } from '@sparx/query/schools-service';
import classNames from 'classnames';
import logo from 'components/sections/logo.svg';
import { forwardRef } from 'react';
import CountUp from 'react-countup';

import { WidgetConfig } from '../types';
import styles from './downloadable-widget.module.css';

type Props = {
  config: WidgetConfig;
  // Whether the widget is only showing classes assigned to the current teacher.
  classSpecific: boolean;
};

export const DownloadableWidget = forwardRef<HTMLDivElement, Props>(
  ({ config, classSpecific }: Props, ref) => {
    const { data: school } = getSchool.useQuery();

    if (!school) return;

    return (
      <div
        className={classNames(styles.Widget, {
          [styles.WidgetClassSpecific]: classSpecific,
        })}
        ref={ref}
      >
        <div className={styles.IconContainer}>
          <img src={config.icon} className={styles.Icon} />
        </div>

        <div className={styles.Contents}>
          <p className={classNames(styles.SchoolName, styles.Text)}>{school.displayName}</p>

          <div style={{ position: 'relative' }}>
            <p className={classNames(styles.Text)}>{config.topLine}</p>

            <p className={styles.Text}>
              <strong>
                <CountUp start={config.count} end={config.count} separator="," /> {config.units}
              </strong>
            </p>

            <p className={classNames(styles.Text)}>{config.bottomLine}</p>
          </div>

          <div className={styles.BottomContainer}>
            <img src={logo} className={styles.SparxReaderLogo} />
            <p>sparxreader.com</p>
          </div>
        </div>
      </div>
    );
  },
);

DownloadableWidget.displayName = 'DownloadableWidget';
