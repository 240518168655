import { GetSchoolCompletedBookCountResponse } from '@sparx/api/apis/sparx/reading/reports/v2/reporting';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { reportingClient } from 'api';

import { useSchoolID } from './schools';

export const useSchoolCompletedBookCount = <T = GetSchoolCompletedBookCountResponse>(
  options?: UseQueryOptions<GetSchoolCompletedBookCountResponse, Error, T, string[]>,
) => {
  const schoolID = useSchoolID();
  return useQuery(
    ['school-completed-book-count'],
    () =>
      reportingClient.getSchoolCompletedBookCount({ schoolName: 'schools/' + schoolID }).response,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};
