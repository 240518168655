import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserDisplayData } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import {
  GetSchoolLeagueTableResponse,
  GetStudentGroupLeagueTableResponse,
} from '@sparx/api/apis/sparx/reading/league/v1/league';
import { User } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userDisplayClient } from 'api';
import { useClearAlert } from 'components/alert/hooks';
import { Button } from 'components/buttons/button';
import modalStyles from 'components/tasks/panel.module.css';
import {
  LEAGUE_TABLE_DISPLAY_DATA_QUERY_KEY,
  LEAGUE_TABLE_QUERY_KEY,
  useGeneratePositiveNoun,
  useGetUserDisplayDataForCurrentUser,
  useGetUserOptedInToLeagueTable,
} from 'queries/leagueTables';
import { useUser } from 'queries/session';
import styles from 'views/student/league-view/league-view.module.css';

import { createDisplayName } from './utils';

type tableResponse = GetSchoolLeagueTableResponse | GetStudentGroupLeagueTableResponse;

export const NameChooser = () => {
  const close = useClearAlert();
  const user = useUser();
  const client = useQueryClient();
  const {
    data: displayData,
    isLoading: userDisplayDataIsLoading,
    isError: userDisplayDataIsError,
  } = useGetUserDisplayDataForCurrentUser();
  const { data: optedIn } = useGetUserOptedInToLeagueTable();
  const {
    data: positiveNoun,
    refetch,
    fetchStatus: positiveNounFetchStatus,
    isError: positiveNounIsError,
  } = useGeneratePositiveNoun();

  const updateMutation = useMutation(
    ({ displayData, noun }: { displayData: UserDisplayData; noun: string }) =>
      userDisplayClient.updateUserDisplayDataForCurrentUser({
        // Saving a name also opts you in
        userDisplayData: {
          name: displayData.name,
          positiveNoun: noun,
          optedOutProducts: displayData.optedOutProducts.filter(p => p !== Product.SPARX_READER),
        },
        updateMask: { paths: ['positive_noun', 'opted_out_products'] },
      }).response,
    {
      // Optimistically update the UI
      onMutate: async ({ noun }) => {
        close();
        // Cancel any outgoing refetches
        await client.cancelQueries([LEAGUE_TABLE_QUERY_KEY]);

        // Snapshot the previous value
        const previousUserDisplayData = client.getQueryData(LEAGUE_TABLE_DISPLAY_DATA_QUERY_KEY);
        const previousTableData = client.getQueriesData([LEAGUE_TABLE_QUERY_KEY, 'table']);

        // Do optimistic updates
        client.setQueryData(
          LEAGUE_TABLE_DISPLAY_DATA_QUERY_KEY,
          (oldData: UserDisplayData | undefined) =>
            !oldData ? oldData : { ...oldData, positiveNoun: noun },
        );

        client.setQueriesData(
          { queryKey: [LEAGUE_TABLE_QUERY_KEY, 'table'] },
          (oldData: tableResponse | undefined) =>
            !oldData
              ? oldData
              : {
                  ...oldData,
                  results: updateRowByUserId(oldData, user, displayData, noun),
                },
        );

        // Return a context object with the snapshotted value
        return { previousUserDisplayData, previousTableData };
      },
      // If the mutation fails, roll back to the previous value
      onError: (_err, _new, context) => {
        if (!context) return;
        client.setQueryData([LEAGUE_TABLE_QUERY_KEY], context.previousUserDisplayData);
        for (const query of context.previousTableData) {
          client.setQueryData([query[0]], query[1]);
        }
      },
      onSettled: () => {
        client.invalidateQueries([LEAGUE_TABLE_QUERY_KEY]);
      },
    },
  );

  if (updateMutation.isError || positiveNounIsError || userDisplayDataIsError) {
    return (
      <div className={modalStyles.OnboardingModal} style={{ maxWidth: 600 }}>
        <div className={modalStyles.ExitButton} onClick={close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <h2 className={modalStyles.ModalTitle}>Something went wrong, try refreshing the page.</h2>{' '}
      </div>
    );
  }

  return (
    <div className={modalStyles.OnboardingModal} style={{ maxWidth: 600 }}>
      <div className={modalStyles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={modalStyles.ModalTitle}>Choose your name</h2>
      <p>
        This will be your public name on Sparx leaderboards. You can click Regenerate to generate a
        new random name:
      </p>
      <NamePreview />
      <div className={styles.Buttons}>
        <Button variant={'primary'} onClick={() => refetch()} analyticsEvent={undefined}>
          Regenerate
        </Button>
        <Button
          onClick={() =>
            displayData &&
            updateMutation.mutate({
              displayData,
              noun: positiveNoun?.positiveNoun || displayData.positiveNoun,
            })
          }
          disabled={
            userDisplayDataIsLoading ||
            updateMutation.isLoading ||
            (!positiveNoun?.positiveNoun && optedIn)
          }
          loading={positiveNounFetchStatus === 'fetching' || updateMutation.isLoading}
          analyticsEvent={undefined}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const updateRowByUserId = (
  oldData: GetSchoolLeagueTableResponse | GetStudentGroupLeagueTableResponse,
  user: User | undefined,
  displayData: UserDisplayData | undefined,
  noun: string,
) => {
  const userId = displayData?.name.split('/')[1];
  return oldData.results.map(row => {
    if (user && row.userId === userId) {
      row.name = createDisplayName(user, noun) || '';
    }
    return row;
  });
};

const NamePreview = () => {
  const user = useUser();
  const { data: displayData, isLoading: displayDataIsLoading } =
    useGetUserDisplayDataForCurrentUser();
  const { data, isFetched, fetchStatus } = useGeneratePositiveNoun();

  if (!user || (!displayData && !displayDataIsLoading) || (isFetched && !data)) {
    return null;
  }

  if (fetchStatus === 'fetching' || displayDataIsLoading) {
    return (
      <div className={styles.BigName}>
        <LoadingSpinner />
      </div>
    );
  }

  // if there is no new generated name, show the current name
  if (!isFetched) {
    return (
      <div className={styles.BigName}>{createDisplayName(user, displayData?.positiveNoun)}</div>
    );
  }

  return <div className={styles.BigName}>{createDisplayName(user, data?.positiveNoun)}</div>;
};
