import { useSchoolOnboardingStatus } from './queries';
import { getSchoolOnboardingTasks } from './utils';

interface SSOSetupStatus {
  isLoading: boolean;
  isError: boolean;
  data?: {
    enabledForTeachers?: boolean;
    enabledForStudents?: boolean;
    studentsWithMissingEmail?: number;
    studentsWithWrongEmailDomain?: number;
  };
}

/**
 * Hook to get the SSO setup status for a school - includes whether the school has SSO enabled for teachers and
 * students, and the number of students with missing email addresses or email addresses that don't match the school's
 * SSO domain.
 * @param schoolName the school resource name
 */
export const useSSOSetupStatus = (schoolName: string): SSOSetupStatus => {
  const {
    data: schoolOnboardingStatus,
    isLoading,
    isError,
  } = useSchoolOnboardingStatus(schoolName);

  if (isLoading || isError) {
    return { isLoading, isError };
  }

  const tasks = getSchoolOnboardingTasks(schoolOnboardingStatus);

  const { studentsWithMissingEmail = 0, studentsWithWrongEmailDomain = 0 } = tasks?.ssoStatus ?? {};

  return {
    isLoading,
    isError,
    data: {
      enabledForTeachers: !!schoolOnboardingStatus?.summary?.ssoStatus?.teacherSsoEnabled,
      enabledForStudents: !!schoolOnboardingStatus?.summary?.ssoStatus?.studentSsoEnabled,
      studentsWithMissingEmail,
      studentsWithWrongEmailDomain,
    },
  };
};
