import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/buttons';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';

import styles from './alert-close-button.module.css';
import { useClearAlert } from './hooks';

interface IAlertCloseButton {
  analyticsEvent: AnalyticEventFields | undefined;
}

/**
 * AlertCloseButton is a component that you can pass through as a child to useAlert
 * and it will add a cross to the top right to dismiss the alert.
 * You can optionally supply an analyticsEvent to fire when the button is pressed.
 */
export const AlertCloseButton: React.FC<IAlertCloseButton> = ({ analyticsEvent }) => {
  const clearAlert = useClearAlert();

  return (
    <Button
      className={styles.AlertCloseButton}
      onClick={clearAlert}
      analyticsEvent={analyticsEvent}
    >
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
};
