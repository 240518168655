import moment from 'moment';
import { useActiveHomeworks, useMyHomeworks } from 'queries/tasks';
import { timestampToMoment } from 'utils/time';

export const useHomeworks = () => {
  const { data: currentTasks } = useActiveHomeworks();
  const { data: allHwks, isLoading, isError } = useMyHomeworks();

  const previousHwks = (allHwks || [])
    .filter(hwk => hwk.endDate && timestampToMoment(hwk.endDate).isBefore(moment()))
    .sort((a, b) => timestampToMoment(a.endDate)?.diff(timestampToMoment(b.endDate)) || 0);

  return { currentTasks, allHwks, previousHwks, isLoading, isError };
};
