import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@sparx/design/components';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { WidgetConfig } from '../types';
import styles from './widget.module.css';

type Props = {
  config: WidgetConfig;
  animate: boolean;
  isGenerating: boolean;
  onDownloadClick: () => void;
  onAnimateEnd: () => void;
  // Whether the widget is only showing classes assigned to the current teacher.
  classSpecific?: boolean;
};

export const Widget = ({
  config,
  animate,
  isGenerating,
  onDownloadClick,
  onAnimateEnd,
  classSpecific,
}: Props) => {
  return (
    <div
      className={classNames(styles.Widget, {
        [styles.WidgetClassSpecific]: classSpecific,
      })}
    >
      <Tooltip content="Download as an image">
        <button
          onClick={() => onDownloadClick()}
          className={styles.DownloadButton}
          disabled={isGenerating}
        >
          <FontAwesomeIcon icon={isGenerating ? faSpinner : faDownload} spin={isGenerating} />
        </button>
      </Tooltip>

      <div className={styles.Contents}>
        <p className={classNames(styles.Text, styles.TopLine)}>{config.topLine}</p>

        <p className={styles.CountLine}>
          <strong>
            <CountUp
              start={animate ? 0 : config.count}
              end={config.count}
              duration={1}
              delay={animate ? 0.5 : 0}
              separator=","
              onEnd={() => onAnimateEnd()}
            />{' '}
            {config.units}
          </strong>
        </p>

        <p className={classNames(styles.Text, styles.BottomLine)}>{config.bottomLine}</p>
      </div>

      <div className={styles.IconContainer}>
        <img src={config.icon} className={styles.Icon} />
      </div>
    </div>
  );
};
