import { Flags, useBooleanFlag } from 'utils/featureflags';

import { DepartmentWidgets } from './department-widgets/department-widgets';
import { GettingStartedWidget } from './getting-started-widget/getting-started-widget';
import styles from './home-view.module.css';
import { InterimWidget } from './interim-widget/interim-widget';
import { PostResetWidget } from './post-reset-widget/post-reset-widget';
import { PraiseStats } from './praise-stats/praise-stats';

export const HomeViewDepartment = ({
  isInterim,
  showPostReset,
  showGettingStarted,
}: {
  showPostReset: boolean;
  showGettingStarted: boolean;
  isInterim: boolean;
}) => {
  const teacherGroupMembershipsEnabled = useBooleanFlag(Flags.TeacherGroupMemberships);

  return (
    <>
      <div className={styles.Widgets}>
        {isInterim && <InterimWidget />}
        {showPostReset && <PostResetWidget />}
        {showGettingStarted && <GettingStartedWidget />}
      </div>

      <DepartmentWidgets />
      {teacherGroupMembershipsEnabled && <PraiseStats classSpecific={false} />}
    </>
  );
};
