import { AdminContainer } from 'app/containers/admin-container/admin-container';
import { UserRole } from 'queries/session';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { BookView } from 'views/admin/views/book-view/book-view';

import { RequiresRole } from './requires-role';

export const contentAdminRoutes: RouteObject = {
  path: '/admin',
  element: (
    <RequiresRole role={UserRole.EQMRead}>
      <AdminContainer>
        <Outlet />
      </AdminContainer>
    </RequiresRole>
  ),
  children: [
    {
      path: '',
      element: (
        <RequiresRole role={UserRole.EQMAdmin} fallback={<Navigate to={'/admin/assignments'} />}>
          <Navigate replace={true} to={'/admin/books'} />
        </RequiresRole>
      ),
    },
    {
      path: 'books',
      lazy: async () => {
        const component = await import('views/admin/views/book-manager-view/book-manager-view');

        return { element: <component.BookManagerView /> };
      },
    },
    {
      path: 'preview',
      lazy: () => import('views/admin/views/preview-book-view'),
    },
    {
      path: 'book-choice-visualiser',
      lazy: () =>
        import('views/admin/views/book-choice-visualiser-view/book-choice-visualiser-view'),
    },
    {
      path: 'book-choice',
      lazy: () => import('views/admin/views/book-choice-view/book-choice-view'),
    },
    {
      path: 'tapestry-view',
      lazy: () => import('views/admin/views/book-tapestry-view'),
    },
    {
      path: 'silver-check-view',
      lazy: () => import('views/admin/views/silver-check-view/silver-check-view'),
    },
    {
      path: 'assignments',
      lazy: () => import('views/admin/views/assignments/assignments-view'),
    },
    {
      path: 'assignments/new',
      lazy: () => import('views/admin/views/assignments/create-edit-assignment-view'),
    },
    {
      path: 'assignments/:assignmentId/edit',
      lazy: () => import('views/admin/views/assignments/create-edit-assignment-view'),
    },
    {
      path: 'books/:bookId',
      element: <BookView />,
      children: [
        {
          path: '',
          lazy: async () => {
            const component = await import('views/admin/views/book-overview-view');

            return { element: <component.BookOverviewView /> };
          },
        },
        {
          path: 'preview',
          lazy: () => import('views/admin/views/ebooks/proofread-view'),
        },
        {
          path: 'write',
          lazy: () => import('views/admin/views/ebooks/book-view'),
        },
        {
          path: 'proofread',
          lazy: () => import('views/admin/views/ebooks/proofread-view'),
        },
        {
          path: 'feedback',
          lazy: () => import('views/admin/views/ebooks/feedback-view'),
        },
        {
          path: 'edit',
          lazy: () => import('views/admin/views/book-editor-view'),
        },
        {
          path: 'compare',
          lazy: () => import('views/admin/views/release-view/release-view'),
        },
        {
          path: 'editor',
          lazy: () => import('views/admin/views/ebooks/edit-book'),
        },
        {
          path: 'export',
          lazy: () => import('views/admin/views/ebooks/export-book-view'),
        },
        {
          path: 'importQuestions',
          lazy: () => import('views/admin/views/import-questions'),
        },
      ],
    },
  ],
};
