import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { getSchool } from '@sparx/query/schools-service';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useStaffKeyContacts } from '@sparx/staff-manager/src/queries';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import { useSchoolMISStatus } from 'queries/school-status';

import { Tab } from '../../types';
import { MISStatus, MISStatusConfig, misStatusConfig } from './types';

export const useMISTab = (): Tab & { status: MISStatus; config: MISStatusConfig } => {
  const { data, isLoading, isError } = useSchoolMISStatus();
  const isInterim = useSchoolInInterimState();

  const status = (): MISStatus => {
    if (!isInterim) return MISStatus.RESET;
    if (isLoading) return MISStatus.LOADING;
    if (!data || isError) return MISStatus.NOT_CONNECTED;
    return data.hasRolledOver ? MISStatus.READY : MISStatus.NOT_READY;
  };

  const config = misStatusConfig[status()];

  return {
    id: 'manage-mis',
    title: 'Manage your MIS',
    subtitle: config.subtitle,
    pageSubtitle: config.pageSubtitle,
    icon: faUsersCog,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: isLoading ? false : config.hasWarning,
    hasSuccess: isLoading ? false : config.hasSuccess,
    isLoading,
    status: status(),
    config,
  };
};

/**
 * Alternative implementation of useMISTab for the Getting Started page, which
 * shows whether the MIS is connected or not, but does not show anything about
 * rollover
 */
export const useGettingStartedMISTab = (): Tab & { status: MISStatus; config: MISStatusConfig } => {
  const { data, isLoading, isError } = useSchoolMISStatus();
  const { authStatus, isSuccess } = useMISAuthStatus();

  const status = (): MISStatus => {
    if (isLoading || !isSuccess) return MISStatus.LOADING;
    if (authStatus === 'approved') return MISStatus.NOT_AUTHED;
    if (authStatus === 'pending') return MISStatus.NOT_APPROVED;
    if (!data || isError || authStatus !== 'audited') return MISStatus.NOT_CONNECTED;
    return MISStatus.READY_TO_GET_STARTED;
  };

  const config = misStatusConfig[status()];

  return {
    id: 'manage-mis',
    title: 'Manage your MIS',
    subtitle: config.subtitle,
    pageSubtitle: config.pageSubtitle,
    icon: faUsersCog,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: isLoading ? false : config.hasWarning,
    hasSuccess: isLoading ? false : config.hasSuccess,
    isLoading,
    status: status(),
    config,
  };
};

const useMISAuthStatus = () => {
  const { data: school, isSuccess } = getSchool.useQuery();
  const authStatus = school?.annotations[AnnotationKeys.WondeAuthStatus] || 'Unknown';
  return {
    authStatus,
    isSuccess,
  };
};

export const useNetworkManager = () => {
  const { data: kc } = useStaffKeyContacts({ suspense: true });

  const isOk = kc?.status.status === 'ok';
  const netman = isOk ? kc?.keyContacts[StaffRole.NETWORK_MANAGER] : undefined;

  return staffRoleWithEmail('Network Manager', netman);
};

/**
 * Qualify a role name with their contact details so that other staff can contact them.
 * Prefer showing contact name if either exist, otherwise fall back to email.
 * If email doesn't exist, just show the role.
 */
const staffRoleWithEmail = (roleName: string, contact?: SchoolStaffMember): string => {
  let output = roleName;
  if (contact) {
    if (contact.emailAddress) {
      const name = `${contact.givenName} ${contact.familyName}`.trim();
      if (name) {
        output += `, ${name} (`;
      } else {
        output += ' (';
      }
      output += `${contact.emailAddress})`;
      if (name) {
        output += ',';
      }
    }
  }
  return output;
};
