import { IntercomLink } from 'app/intercom/intercom-link';

import { clickedGetInTouchLink } from '../events';
import styles from './shared.module.css';

export const NotConnected = () => {
  return (
    <>
      <div className={styles.Box}>
        <p>
          We weren&apos;t able to connect to your MIS, please try again or{' '}
          <IntercomLink to="newmessage" analyticsEvent={clickedGetInTouchLink()}>
            get in touch
          </IntercomLink>
          .
        </p>
      </div>
    </>
  );
};
