import {
  faBookmark,
  faBookOpen,
  faDrum,
  faEdit,
  faImage,
  faTasks,
  faWarning,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { Title } from 'components/title/title';
import { useAdminUserActive } from 'queries/adminuseractive';
import { UserRole, useUserHasRole } from 'queries/session';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CollapsibleSidebar,
  SidebarPrimaryLink,
} from 'views/teacher/components/collapsible-sidebar';

import styles from './admin-container.module.css';

const USER_ACTIVE_INTERVAL = 1000 * 60 * 5; // 5 minutes

type Props = {
  children: React.ReactNode;
  showSuperAdminSidebarLinks?: boolean;
};

export const AdminContainer = ({ children, showSuperAdminSidebarLinks }: Props) => {
  const isAdmin = useUserHasRole(UserRole.Admin);
  const isContentWriter = useUserHasRole(UserRole.EQMWrite);
  const location = useLocation();
  const { refetch } = useAdminUserActive(location.pathname);

  // Listen for mouse movements only after a delay
  const [mouseListener, setMouseListener] = useState<true | undefined>();
  const resetListener = () => {
    setMouseListener(undefined);
    setTimeout(() => setMouseListener(true), USER_ACTIVE_INTERVAL);
  };

  // Fire the admin user active event on page load
  useEffect(() => {
    resetListener();
    refetch();
  }, [refetch]);

  const handleUserInteraction = () => {
    resetListener();
    refetch();
  };

  return (
    <>
      <Title>EQM</Title>

      <div
        className={styles.Container}
        onMouseMove={mouseListener && handleUserInteraction}
        onWheel={mouseListener && handleUserInteraction}
        onTouchStart={mouseListener && handleUserInteraction}
      >
        <div className={styles.Container}>
          <CollapsibleSidebar
            allowToggle={true}
            primary={
              <>
                <SidebarPrimaryLink to="/admin/books" name="Books" icon={faBookOpen} />
                <SidebarPrimaryLink
                  to="/admin/assignments"
                  name="Assignments"
                  icon={faTasks}
                  active={
                    location.pathname === '/admin/assignments' ||
                    location.pathname === '/admin/assignments/new'
                  }
                />
                {isAdmin && <SidebarPrimaryLink to="/admin/super" name="Admin" icon={faWarning} />}
                {isContentWriter && (
                  <SidebarPrimaryLink to="/admin/preview" name="Book Preview" icon={faWrench} />
                )}
                {isAdmin && (
                  <SidebarPrimaryLink
                    to="/admin/tapestry-view"
                    name="Tapestry view"
                    icon={faImage}
                  />
                )}
                {isAdmin && (
                  <SidebarPrimaryLink to="/admin/book-choice" name="Book Choice" icon={faDrum} />
                )}
                {isAdmin && (
                  <SidebarPrimaryLink
                    to="/admin/silver-check-view"
                    name="Primary check"
                    icon={faBookmark}
                  />
                )}
                {showSuperAdminSidebarLinks && (
                  <>
                    <h4>Gold Reader</h4>
                    <SidebarPrimaryLink
                      to={`/admin/super/goldreader`}
                      name="Gold Readers"
                      icon={faEdit}
                    />
                    <SidebarPrimaryLink
                      to={`/admin/super/goldreaderadmin`}
                      name="Gold Reader award"
                      icon={faEdit}
                    />
                    <h4>Other</h4>
                    <SidebarPrimaryLink to={`/admin/super/schools`} name="Schools" icon={faEdit} />
                    <SidebarPrimaryLink to={`/admin/super/student`} name="Student" icon={faEdit} />
                    <SidebarPrimaryLink
                      to={`/admin/super/allbooks`}
                      name="All books"
                      icon={faEdit}
                    />
                  </>
                )}
              </>
            }
            secondary={<div id="sidebar-portal" />}
          />
        </div>
        {children}
      </div>
    </>
  );
};
