import { useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { AlertContext, IAlertOptions } from './alert';

export const useAlert = () => {
  const setAlert = useContext(AlertContext);
  return (alert?: React.ReactNode, options?: IAlertOptions) =>
    setAlert({
      alert,
      options,
      alertID: uuid(),
    });
};

export const useClearAlert = () => {
  const setAlert = useContext(AlertContext);
  return () => setAlert(null);
};
