import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'hand-in-summary';

export const openedNotStartedStudentsModal = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'opened not started students modal',
});

export const closedNotStartedStudentsModal = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'closed not started students modal',
});

export const copiedNotStartedStudentsTable = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'copied not started students table',
});

export const clickedSelectClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked select classes',
});
