import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useSchoolCalendar } from 'queries/school-calendar';
import { useSchoolOnboardingStatus } from 'queries/school-status';
import { Flags, useFlag } from 'utils/featureflags';

import { Tab } from '../../types';
import { SingleSignOnConfig, SingleSignOnConfigs } from './types';

export const useSettingsTab = (): Tab & {
  ssoConfig: SingleSignOnConfig;
  isParentEmailEnabled: boolean;
  plannedHomeworkStartDate: string | undefined;
  // If the SSO tab is enabled, then the section on this Settings tab about SSO is not displayed
  ssoTabEnabled: boolean;
} => {
  const { data: schoolCalendar, isLoading: isLoadingSchoolCalendar } = useSchoolCalendar();
  const { data: schoolOnboardingStatus, isLoading: isLoadingSchoolOnboadingStatus } =
    useSchoolOnboardingStatus();

  // TODO: When this feature flag is removed we can remove all the code related to SSO from this tab
  const isSSOTabEnabled = useFlag(Flags.SchoolSettingsSSOTab, false);

  const isStudentSSOEnabled = !!schoolOnboardingStatus?.summary?.ssoStatus?.studentSsoEnabled;
  const isTeacherSSOEnabled = !!schoolOnboardingStatus?.summary?.ssoStatus?.teacherSsoEnabled;
  const isParentEmailEnabled = !!schoolOnboardingStatus?.summary?.parentEmailsEnabled;

  const plannedHomeworkStartDate =
    schoolCalendar?.currentYear?.rolloverState?.readerRolloverState?.annotations[
      AnnotationKeys.RolloverStepPlannedHomeworkStartDate
    ];

  const ssoConfig = (): SingleSignOnConfig => {
    if (isStudentSSOEnabled && isTeacherSSOEnabled) return SingleSignOnConfigs.AllEnabled;
    if (isStudentSSOEnabled) return SingleSignOnConfigs.StudentEnabled;
    if (isTeacherSSOEnabled) return SingleSignOnConfigs.TeacherEnabled;
    return SingleSignOnConfigs.AllDisabled;
  };

  return {
    id: 'extra-settings',
    title: 'Manage extra settings',
    subtitle: 'Optional',
    pageSubtitle: 'Optional',
    icon: faCog,
    responsibility: 'Sparx Leader & Head of Department',
    isLoading: isLoadingSchoolCalendar || isLoadingSchoolOnboadingStatus,
    hasSuccess: plannedHomeworkStartDate !== undefined,
    ssoConfig: ssoConfig(),
    isParentEmailEnabled,
    plannedHomeworkStartDate,
    ssoTabEnabled: !!isSSOTabEnabled,
  };
};
