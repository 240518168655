// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/books/v1/book.proto" (package "sparx.reading.books.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * Some of these fields are also used in sparx.reading.users.librarybooks.v1.MetadataAbridged.
 * If you add a field here consider also adding it to that message.
 *
 * @generated from protobuf message sparx.reading.books.v1.Book
 */
export interface Book {
  /**
   * Resource name of the book. It is in the format `books/*`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * List of identifiers that uniquely identify this book. Note that this
   * can contain multiple of the same type of identifier.
   *
   * This field can also contain identifiers for the same book but with
   * different editions.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.BookIdentifier identifiers = 2;
   */
  identifiers: BookIdentifier[];
  /**
   * When true the book has been blacklisted so should not be read in
   * Sparx Reader.
   *
   * @generated from protobuf field: bool blacklisted = 12;
   */
  blacklisted: boolean;
  /**
   * Title of the book
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * Authors of the book
   *
   * @generated from protobuf field: repeated string authors = 4;
   */
  authors: string[];
  /**
   * Description of the authors used in exports.
   * TODO: could be separate field and keyed on the author
   *
   * @generated from protobuf field: string authors_description = 13;
   */
  authorsDescription: string;
  /**
   * Description text which can also be a preview of the book.
   *
   * @generated from protobuf field: string description = 5;
   */
  description: string;
  /**
   * Cover image of the book.
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookImage cover_image = 6;
   */
  coverImage?: BookImage;
  /**
   * Array of levels relating to this book.
   * Deprecated. Use book_difficulty instead.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.BookLevel levels = 7;
   */
  levels: BookLevel[];
  /**
   * List of genres the book can be grouped into.
   *
   * @generated from protobuf field: repeated string genres = 8;
   */
  genres: string[];
  /**
   * Word count information about this book.
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookWordCount word_count = 9;
   */
  wordCount?: BookWordCount;
  /**
   * Page count information about this book.
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookPageCount page_count = 10;
   */
  pageCount?: BookPageCount;
  /**
   * Information about any ebook that is attached to this book. This
   * field is optional. If it is missing it implies there is no ebook
   * available.
   *
   * @generated from protobuf field: sparx.reading.books.v1.Ebook ebook = 11;
   */
  ebook?: Ebook;
  /**
   * Priority in the printable export. The higher the number the higher
   * in the list it will be.
   *
   * @generated from protobuf field: int32 export_priority = 14;
   */
  exportPriority: number;
  /**
   * Custom list of tags for the book
   *
   * @generated from protobuf field: repeated string tags = 15;
   */
  tags: string[];
  /**
   * Book difficulty level - used for determining which books to show to
   * students.
   *
   * @generated from protobuf field: float book_difficulty = 16;
   */
  bookDifficulty: number;
  /**
   * If present then the book can be delivered in silver reader mode. The
   * message contains the configuration required to manage this.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v1.SilverReader silver_reader = 17;
   */
  silverReader: SilverReader[];
  /**
   * Mimimum age the book should be available to
   *
   * @generated from protobuf field: int32 minimum_age = 18;
   */
  minimumAge: number;
  /**
   * The initial difficulty set for a book. book_difficulty will be the latest value.
   *
   * @generated from protobuf field: float initial_book_difficulty = 19;
   */
  initialBookDifficulty: number;
  /**
   * The status of the book
   *
   * @generated from protobuf field: sparx.reading.books.v1.BookStatus status = 20;
   */
  status: BookStatus;
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.MatureTheme mature_themes = 21;
   */
  matureThemes: MatureTheme[];
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookImage
 */
export interface BookImage {
  /**
   * Optional: can contain a list of hex strings representing the prominent
   * colours in the book image.
   *
   * @generated from protobuf field: repeated string prominent_colours = 1;
   */
  prominentColours: string[];
  /**
   * @generated from protobuf oneof: source
   */
  source:
    | {
        oneofKind: 'url';
        /**
         * URL of the image. Deprecated.
         *
         * @deprecated
         * @generated from protobuf field: string url = 2 [deprecated = true];
         */
        url: string;
      }
    | {
        oneofKind: 'name';
        /**
         * Name of the asset. In the format books/{book_id}/asset/{asset_id}.
         *
         * @generated from protobuf field: string name = 3;
         */
        name: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookIdentifier
 */
export interface BookIdentifier {
  /**
   * Type of the identifier. For example ISBN_10 or ISBN_13.
   *
   * @generated from protobuf field: sparx.reading.books.v1.IdentifierType type = 1;
   */
  type: IdentifierType;
  /**
   * Value of the identifier. The format of the value depends on the
   * type of this book identifier.
   *
   * @generated from protobuf field: string value = 2;
   */
  value: string;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookLevel
 */
export interface BookLevel {
  /**
   * @generated from protobuf field: sparx.reading.books.v1.LevelType type = 1;
   */
  type: LevelType;
  /**
   * @generated from protobuf oneof: level
   */
  level:
    | {
        oneofKind: 'levelNumber';
        /**
         * @generated from protobuf field: float level_number = 2;
         */
        levelNumber: number;
      }
    | {
        oneofKind: 'levelString';
        /**
         * @generated from protobuf field: string level_string = 3;
         */
        levelString: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookWordCount
 */
export interface BookWordCount {
  /**
   * How the word count was calculated
   *
   * @generated from protobuf field: string source = 1;
   */
  source: string;
  /**
   * Word count for the book, either calculated from the actual source
   * or someone who has counted it.
   *
   * @generated from protobuf field: int32 count = 2;
   */
  count: number;
  /**
   * Flag if the page count is an estimation of the number of words.
   *
   * @generated from protobuf field: bool estimate = 3;
   */
  estimate: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookPageCount
 */
export interface BookPageCount {
  /**
   * Can be populated with the expected number of pages that the paperback
   * book has.
   *
   * As a Book can have multiple different printed versions this may not
   * match the count that the student has when they add their own book.
   * For this reason the student_page_count should be preferred if it is
   * populated.
   *
   * @generated from protobuf field: int32 expected_page_count = 1;
   */
  expectedPageCount: number;
  /**
   * Will be set if a student has added their own copy of the book.
   * Will be 0 otherwise.
   * This field is not used.
   *
   * @deprecated
   * @generated from protobuf field: int32 student_page_count = 2 [deprecated = true];
   */
  studentPageCount: number;
  /**
   * Flag that the book does not have page numbers.
   *
   * @generated from protobuf field: bool no_page_numbers = 3;
   */
  noPageNumbers: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.Ebook
 */
export interface Ebook {
  /**
   * Whether there is an ebook available and if it is active. This should
   * only be set if we have content available in the system, as it determines
   * how tasks are created. If an ebook content is not available in the system
   * but we need to flag this book as a future ebook, then the status field
   * should be used instead.
   *
   * @generated from protobuf field: bool active = 1;
   */
  active: boolean;
  /**
   * The maximum number of concurrent users that are allowed on the book.
   * This will usually be set in the case where we have licenced an ebook
   * with a maximum number of concurrent users.
   * Zero will mean that there is no maximum concurrent users.
   *
   * @generated from protobuf field: int32 max_concurrent_users = 2;
   */
  maxConcurrentUsers: number;
  /**
   * The status of the ebook. If the ebook is in a UNSPECIFIED state then
   * it is not an ebook and there are no plans to make it one at this time.
   * The status is separate to the active flag - this status could be set to
   * COMING_SOON indefinitely until the ebook is ready and marked as active.
   *
   * @generated from protobuf field: sparx.reading.books.v1.EbookStatus status = 3;
   */
  status: EbookStatus;
  /**
   * The number of beta licences to issue for this book. If this value is
   * greater than 0 then the book will only be available to beta users.
   * If the two-week-active count is greater or equal to this number then
   * the book will not be suggested to new users.
   *
   * @generated from protobuf field: int32 beta_licences = 4;
   */
  betaLicences: number;
  /**
   * The last content id that the ebook content references. If it is empty
   * then the book does not have any content in the content database.
   *
   * This field is read-only and will always be populated when returned.
   *
   * @generated from protobuf field: string last_content_id = 5;
   */
  lastContentId: string;
  /**
   * The last breakpoint id that the content will break on. If it is empty
   * then the book does not have any breakpoints. If it equals the books
   * last_part_id then the content is complete. If it does not equal the
   * last_part_id then the content is incomplete
   *
   * This field is read-only and will always be populated when returned.
   *
   * @generated from protobuf field: string last_breakpoint_id = 6;
   */
  lastBreakpointId: string;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.BookStatistics
 */
export interface BookStatistics {
  /**
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * @generated from protobuf field: int32 student_count = 2;
   */
  studentCount: number;
  /**
   * @generated from protobuf field: int32 student_count_last_week = 3;
   */
  studentCountLastWeek: number;
  /**
   * @generated from protobuf field: int32 custom_book_count = 4;
   */
  customBookCount: number;
  /**
   * @generated from protobuf field: int32 custom_book_questions = 5;
   */
  customBookQuestions: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 6;
   */
  createdTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.SilverReader
 */
export interface SilverReader {
  /**
   * Page that the student starts reading the book from.
   *
   * @generated from protobuf field: int32 start_page = 1;
   */
  startPage: number;
  /**
   * Page that the student stops reading the book on.
   *
   * @generated from protobuf field: int32 end_page = 2;
   */
  endPage: number;
  /**
   * Number of words per page.
   *
   * @generated from protobuf field: int32 words_per_page = 3;
   */
  wordsPerPage: number;
  /**
   * Amount of SRP that should be awarded per page. If this is 0 then
   * the value will instead be based on the estimated number of words
   * per page.
   *
   * @generated from protobuf field: int32 srp_per_page = 5;
   */
  srpPerPage: number;
  /**
   * ISBN's that are associated with this silver reader config.
   *
   * @generated from protobuf field: repeated string allowed_isbns = 4;
   */
  allowedIsbns: string[];
}
/**
 * @generated from protobuf message sparx.reading.books.v1.QueryBooksRequest
 */
export interface QueryBooksRequest {
  /**
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * @generated from protobuf message sparx.reading.books.v1.QueryBooksResponse
 */
export interface QueryBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1;
   */
  books: Book[];
}
/**
 * @generated from protobuf message sparx.reading.books.v1.SearchBooksRequest
 */
export interface SearchBooksRequest {
  /**
   * Set a query {expression} for querying books. Your {expression} must be
   * in the format: field_name=literal_string. The field_name is the name
   * of the field you want to compare. Supported fields are: isbn.
   *
   * @generated from protobuf field: string query = 1;
   */
  query: string;
  /**
   * If true, allows an external search (e.g. google books api) in the case
   * where the book is not found in bk.
   *
   * @generated from protobuf field: bool load_custom = 2;
   */
  loadCustom: boolean;
  /**
   * Disable merging books into existing when creating new books.
   *
   * @generated from protobuf field: bool disable_merge = 3;
   */
  disableMerge: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.SearchBooksResponse
 */
export interface SearchBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1;
   */
  books: Book[];
  /**
   * @generated from protobuf field: bool books_created = 2;
   */
  booksCreated: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.ListBooksRequest
 */
export interface ListBooksRequest {
  /**
   * @generated from protobuf field: bool include_paperbacks = 1;
   */
  includePaperbacks: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v1.ListBooksResponse
 */
export interface ListBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1;
   */
  books: Book[];
}
/**
 * @generated from protobuf message sparx.reading.books.v1.StoreBookRequest
 */
export interface StoreBookRequest {
  /**
   * Book information to store.
   *
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 1;
   */
  book?: Book;
  /**
   * List of book names that were merged with this book. These books can be
   * safely deleted as the main book now contains their information.
   *
   * @generated from protobuf field: repeated string merged_books = 2;
   */
  mergedBooks: string[];
  /**
   * @generated from protobuf oneof: image
   */
  image:
    | {
        oneofKind: 'newImage';
        /**
         * Optional, if set can contain a base64 encoded image string for the new
         * image to be used for this book.
         *
         * @generated from protobuf field: string new_image = 3;
         */
        newImage: string;
      }
    | {
        oneofKind: 'imageBookName';
        /**
         * Optional, if set will use this book for the image. Can reference one
         * of the books that is about to be deleted.
         *
         * @deprecated
         * @generated from protobuf field: string image_book_name = 4 [deprecated = true];
         */
        imageBookName: string;
      }
    | {
        oneofKind: 'imageAssetName';
        /**
         * Optional, if set will use this asset resource name for the image.
         *
         * @generated from protobuf field: string image_asset_name = 5;
         */
        imageAssetName: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.books.v1.StoreBookResponse
 */
export interface StoreBookResponse {}
/**
 * @generated from protobuf enum sparx.reading.books.v1.MatureTheme
 */
export enum MatureTheme {
  /**
   * @generated from protobuf enum value: MATURE_THEME_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: MATURE_THEME_BEREAVEMENT_AND_LOSS = 1;
   */
  BEREAVEMENT_AND_LOSS = 1,
  /**
   * @generated from protobuf enum value: MATURE_THEME_DISCRIMINATION = 2;
   */
  DISCRIMINATION = 2,
  /**
   * @generated from protobuf enum value: MATURE_THEME_SUBSTANCE_ABUSE = 3;
   */
  SUBSTANCE_ABUSE = 3,
  /**
   * @generated from protobuf enum value: MATURE_THEME_STRONG_LANGUAGE = 4;
   */
  STRONG_LANGUAGE = 4,
  /**
   * @generated from protobuf enum value: MATURE_THEME_SEX = 5;
   */
  SEX = 5,
  /**
   * @generated from protobuf enum value: MATURE_THEME_SEXUAL_VIOLENCE_AND_SEXUAL_THREAT = 6;
   */
  SEXUAL_VIOLENCE_AND_SEXUAL_THREAT = 6,
  /**
   * @generated from protobuf enum value: MATURE_THEME_VIOLENCE_AND_THREAT = 7;
   */
  VIOLENCE_AND_THREAT = 7,
  /**
   * @generated from protobuf enum value: MATURE_THEME_HISTORIC_PERSPECTIVE = 8;
   */
  HISTORIC_PERSPECTIVE = 8,
  /**
   * @generated from protobuf enum value: MATURE_THEME_MENTAL_HEALTH = 9;
   */
  MENTAL_HEALTH = 9,
  /**
   * @generated from protobuf enum value: MATURE_THEME_SERIOUS_ILLNESS = 10;
   */
  SERIOUS_ILLNESS = 10,
}
/**
 * @generated from protobuf enum sparx.reading.books.v1.BookStatus
 */
export enum BookStatus {
  /**
   * @generated from protobuf enum value: BOOK_STATUS_ACTIVE = 0;
   */
  ACTIVE = 0,
  /**
   * @generated from protobuf enum value: BOOK_STATUS_INACTIVE = 1;
   */
  INACTIVE = 1,
  /**
   * @generated from protobuf enum value: BOOK_STATUS_WINDING_DOWN = 2;
   */
  WINDING_DOWN = 2,
}
/**
 * @generated from protobuf enum sparx.reading.books.v1.IdentifierType
 */
export enum IdentifierType {
  /**
   * @generated from protobuf enum value: IDENTIFIER_UNSPECIFIED = 0;
   */
  IDENTIFIER_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ISBN_10 = 1;
   */
  ISBN_10 = 1,
  /**
   * @generated from protobuf enum value: ISBN_13 = 2;
   */
  ISBN_13 = 2,
  /**
   * @generated from protobuf enum value: SPARX_READER_UUID = 3;
   */
  SPARX_READER_UUID = 3,
}
/**
 * @generated from protobuf enum sparx.reading.books.v1.LevelType
 */
export enum LevelType {
  /**
   * @generated from protobuf enum value: LEVEL_UNSPECIFIED = 0;
   */
  LEVEL_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: AR = 1;
   */
  AR = 1,
  /**
   * @generated from protobuf enum value: SPARX_READER_LO = 2;
   */
  SPARX_READER_LO = 2,
  /**
   * @generated from protobuf enum value: SPARX_READER_HI = 3;
   */
  SPARX_READER_HI = 3,
}
/**
 * @generated from protobuf enum sparx.reading.books.v1.EbookStatus
 */
export enum EbookStatus {
  /**
   * Book is not an ebook.
   *
   * @generated from protobuf enum value: EBOOK_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Ebook content is coming soon.
   *
   * @generated from protobuf enum value: EBOOK_STATUS_COMING_SOON = 1;
   */
  COMING_SOON = 1,
  /**
   * Ebook content is being prepared or questions written. The ebook can
   * be deliverable at this state, although the content creation may not
   * be completed.
   *
   * @generated from protobuf enum value: EBOOK_STATUS_IN_PROGRESS = 2;
   */
  IN_PROGRESS = 2,
  /**
   * The book is available in the system and the content is fully written.
   *
   * @generated from protobuf enum value: EBOOK_STATUS_AVAILABLE = 3;
   */
  AVAILABLE = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class Book$Type extends MessageType<Book> {
  constructor() {
    super('sparx.reading.books.v1.Book', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'identifiers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookIdentifier,
      },
      { no: 12, name: 'blacklisted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'authors',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'authors_description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'cover_image', kind: 'message', T: () => BookImage },
      {
        no: 7,
        name: 'levels',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookLevel,
      },
      {
        no: 8,
        name: 'genres',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 9, name: 'word_count', kind: 'message', T: () => BookWordCount },
      { no: 10, name: 'page_count', kind: 'message', T: () => BookPageCount },
      { no: 11, name: 'ebook', kind: 'message', T: () => Ebook },
      {
        no: 14,
        name: 'export_priority',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 15,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'book_difficulty',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 17,
        name: 'silver_reader',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SilverReader,
      },
      {
        no: 18,
        name: 'minimum_age',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 19,
        name: 'initial_book_difficulty',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 20,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.books.v1.BookStatus',
          BookStatus,
          'BOOK_STATUS_',
        ],
      },
      {
        no: 21,
        name: 'mature_themes',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reading.books.v1.MatureTheme',
          MatureTheme,
          'MATURE_THEME_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.Book
 */
export const Book = new Book$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookImage$Type extends MessageType<BookImage> {
  constructor() {
    super('sparx.reading.books.v1.BookImage', [
      {
        no: 1,
        name: 'prominent_colours',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'url',
        kind: 'scalar',
        oneof: 'source',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'name',
        kind: 'scalar',
        oneof: 'source',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookImage
 */
export const BookImage = new BookImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookIdentifier$Type extends MessageType<BookIdentifier> {
  constructor() {
    super('sparx.reading.books.v1.BookIdentifier', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.reading.books.v1.IdentifierType', IdentifierType],
      },
      { no: 2, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookIdentifier
 */
export const BookIdentifier = new BookIdentifier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLevel$Type extends MessageType<BookLevel> {
  constructor() {
    super('sparx.reading.books.v1.BookLevel', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.reading.books.v1.LevelType', LevelType],
      },
      {
        no: 2,
        name: 'level_number',
        kind: 'scalar',
        oneof: 'level',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 3,
        name: 'level_string',
        kind: 'scalar',
        oneof: 'level',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookLevel
 */
export const BookLevel = new BookLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookWordCount$Type extends MessageType<BookWordCount> {
  constructor() {
    super('sparx.reading.books.v1.BookWordCount', [
      { no: 1, name: 'source', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'estimate', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookWordCount
 */
export const BookWordCount = new BookWordCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookPageCount$Type extends MessageType<BookPageCount> {
  constructor() {
    super('sparx.reading.books.v1.BookPageCount', [
      {
        no: 1,
        name: 'expected_page_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'student_page_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'no_page_numbers',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookPageCount
 */
export const BookPageCount = new BookPageCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ebook$Type extends MessageType<Ebook> {
  constructor() {
    super('sparx.reading.books.v1.Ebook', [
      { no: 1, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 2,
        name: 'max_concurrent_users',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.books.v1.EbookStatus',
          EbookStatus,
          'EBOOK_STATUS_',
        ],
      },
      {
        no: 4,
        name: 'beta_licences',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'last_content_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'last_breakpoint_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.Ebook
 */
export const Ebook = new Ebook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookStatistics$Type extends MessageType<BookStatistics> {
  constructor() {
    super('sparx.reading.books.v1.BookStatistics', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'student_count_last_week',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'custom_book_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'custom_book_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 6, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.BookStatistics
 */
export const BookStatistics = new BookStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SilverReader$Type extends MessageType<SilverReader> {
  constructor() {
    super('sparx.reading.books.v1.SilverReader', [
      { no: 1, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'end_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'words_per_page',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'srp_per_page',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'allowed_isbns',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.SilverReader
 */
export const SilverReader = new SilverReader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryBooksRequest$Type extends MessageType<QueryBooksRequest> {
  constructor() {
    super('sparx.reading.books.v1.QueryBooksRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.QueryBooksRequest
 */
export const QueryBooksRequest = new QueryBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryBooksResponse$Type extends MessageType<QueryBooksResponse> {
  constructor() {
    super('sparx.reading.books.v1.QueryBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.QueryBooksResponse
 */
export const QueryBooksResponse = new QueryBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBooksRequest$Type extends MessageType<SearchBooksRequest> {
  constructor() {
    super('sparx.reading.books.v1.SearchBooksRequest', [
      { no: 1, name: 'query', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'load_custom', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'disable_merge',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.SearchBooksRequest
 */
export const SearchBooksRequest = new SearchBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBooksResponse$Type extends MessageType<SearchBooksResponse> {
  constructor() {
    super('sparx.reading.books.v1.SearchBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
      {
        no: 2,
        name: 'books_created',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.SearchBooksResponse
 */
export const SearchBooksResponse = new SearchBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBooksRequest$Type extends MessageType<ListBooksRequest> {
  constructor() {
    super('sparx.reading.books.v1.ListBooksRequest', [
      {
        no: 1,
        name: 'include_paperbacks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.ListBooksRequest
 */
export const ListBooksRequest = new ListBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBooksResponse$Type extends MessageType<ListBooksResponse> {
  constructor() {
    super('sparx.reading.books.v1.ListBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.ListBooksResponse
 */
export const ListBooksResponse = new ListBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreBookRequest$Type extends MessageType<StoreBookRequest> {
  constructor() {
    super('sparx.reading.books.v1.StoreBookRequest', [
      { no: 1, name: 'book', kind: 'message', T: () => Book },
      {
        no: 2,
        name: 'merged_books',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'new_image',
        kind: 'scalar',
        oneof: 'image',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'image_book_name',
        kind: 'scalar',
        oneof: 'image',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'image_asset_name',
        kind: 'scalar',
        oneof: 'image',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.StoreBookRequest
 */
export const StoreBookRequest = new StoreBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreBookResponse$Type extends MessageType<StoreBookResponse> {
  constructor() {
    super('sparx.reading.books.v1.StoreBookResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v1.StoreBookResponse
 */
export const StoreBookResponse = new StoreBookResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.books.v1.Books
 */
export const Books = new ServiceType('sparx.reading.books.v1.Books', [
  {
    name: 'QueryBooks',
    options: {},
    I: QueryBooksRequest,
    O: QueryBooksResponse,
  },
  {
    name: 'SearchBooks',
    options: {},
    I: SearchBooksRequest,
    O: SearchBooksResponse,
  },
  { name: 'ListBooks', options: {}, I: ListBooksRequest, O: ListBooksResponse },
  { name: 'StoreBook', options: {}, I: StoreBookRequest, O: StoreBookResponse },
]);
