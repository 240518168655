import { RatingColour } from 'components/buttons/rating-button';

interface IRating {
  message: string[];
  image: RatingColour;
}

export const DifficultyRatings: Record<number, IRating> = {
  1: {
    message: ['Much too', 'easy'],
    image: 'red',
  },
  2: {
    message: ['A little', 'easy'],
    image: 'amber',
  },
  3: {
    message: ['About', 'right'],
    image: 'green',
  },
  4: {
    message: ['A little', 'difficult'],
    image: 'amber',
  },
  5: {
    message: ['Much too', 'difficult'],
    image: 'red',
  },
};
