import { GetSchoolRequest, School, SchoolsService } from '@sparx/api/apis/sparx/school/v2/schools';
import { ISchoolsServiceClient as Client } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { useQuery as useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { CommonParamsContext } from '../context';
import { joinKey } from '../key';
import { CustomOpts, Procedure } from '../procedure';
import { QueryOpts } from '../query-opts';
import { useSchoolsClient } from './client';

type Params = { schoolName: string };

const keyPrefix = [SchoolsService.typeName, GetSchoolRequest.typeName] as const;

// Use the same key for all requests
const makeKey = ({ schoolName }: Params) => joinKey(keyPrefix, { schoolName });

type Key = ReturnType<typeof makeKey>;

const getOpts = <T = School>(
  client: Client,
  { schoolName }: Params,
): QueryOpts<School, T, Key> => ({
  queryKey: makeKey({ schoolName }),
  queryFn: async () => client.getSchool({ name: schoolName }).response,
});

const useOpts = () => {
  const client = useSchoolsClient();
  const { schoolName } = useContext(CommonParamsContext);
  return <T = School>() => getOpts<T>(client, { schoolName });
};

const makeUseQuery =
  (suspense: boolean) =>
  <T = School>(opts?: CustomOpts<School, T>) => {
    const { queryKey, queryFn } = useOpts()();
    return useQuery({ queryKey, queryFn, ...opts, suspense });
  };

export const getSchool = {
  keyPrefix,
  makeKey,
  getOpts,
  useOpts,
  useQuery: makeUseQuery(false),
  useSuspenseQuery: makeUseQuery(true),
} satisfies Procedure<School, Client, Params, Key>;
