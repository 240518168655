import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'no-classes';

export const clickedWidgetSelectClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked select classes button on widget',
});

export const clickedConfirmDontHaveClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked confirm dont have classes button on dialog',
});

export const clickWidgetNoClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked no classes button on widget',
});

export const clickedCancel = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked cancel on modal',
});

export const clickedSaveMyClasses = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked save on modal',
});
