import {
  Button as SpxButton,
  ButtonColourScheme,
  ButtonSize,
  ButtonVariant,
} from '@sparx/design/components/button/Button';
import { ComponentWithAs } from '@sparx/design/ComponentWithAs';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { useClientEvent } from 'components/client-events/use-client-event';
import { MouseEventHandler, useState } from 'react';

interface ButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  colourScheme?: ButtonColourScheme;
  variant?: ButtonVariant;
  size?: ButtonSize;
  style?: React.CSSProperties;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  loading?: boolean;
  'data-test-id'?: string;
  // The analytics event to send on click. This is not an optional field so you
  // have to explicitly opt out of sending an event using undefined.
  analyticsEvent: AnalyticEventFields | undefined;
  // Minimum time between clicks of the button, button is disabled for this duration. Default 400ms.
  debounceMs?: number;
  isDisabled?: boolean;
}

export const Button: ComponentWithAs<'button', ButtonProps> = ({
  onClick,
  analyticsEvent,
  variant = 'primary',
  colourScheme = 'default',
  type = 'button',
  debounceMs = 400,
  loading = false,
  children,
  ...props
}) => {
  const { sendEvent } = useClientEvent();
  const [inDebounce, setInDebounce] = useState(false);

  return (
    <SpxButton
      {...props}
      type={type}
      variant={variant}
      colourScheme={colourScheme}
      isDisabled={props.isDisabled || props.disabled || loading}
      onClick={evt => {
        if (!loading && !props.disabled && !inDebounce) {
          if (debounceMs > 0) {
            setInDebounce(true);
            setTimeout(() => setInDebounce(false), debounceMs);
          }
          if (onClick) {
            onClick(evt);
          }
          // Fire the analytics event either if we've just run the click handler or if the button
          // submits a form.
          if (onClick || type === 'submit') {
            if (analyticsEvent) {
              sendEvent(analyticsEvent);
            }
          }
        }
      }}
      isLoading={loading}
    >
      {children}
    </SpxButton>
  );
};
