import {
  ISchoolStatusServiceClient,
  SchoolStatusServiceClient,
} from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const schoolStatusServiceId: interfaces.ServiceIdentifier<ISchoolStatusServiceClient> =
  Symbol.for('schoolStatusService');

/** Register a schools service client with the sparx query container */
export const setSchoolStatusClient = (client: ISchoolStatusServiceClient) => {
  container.bind(schoolStatusServiceId).toConstantValue(client);
};

export const useSchoolStatusClient = (): ISchoolStatusServiceClient =>
  useOptionalInjection(schoolStatusServiceId, () => new SchoolStatusServiceClient(errorTransport));
