import classNames from 'classnames';
import styles from 'components/tasks/panel.module.css';
import { createContext, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FCWithOptionalChildren } from 'utils/props';

// Hack - can set an initial alert before the app has begun rendering. Used in the case of the session
// getter
let _onLoadAlert: React.ReactNode | undefined;
// eslint-disable-next-line react-refresh/only-export-components
export const setOnLoadAlert = (alert: React.ReactNode) => (_onLoadAlert = alert);

export interface IAlertOptions {
  noClickAway?: boolean;
  modalClass?: string;
}

export interface IAlertData {
  alert?: React.ReactNode;
  options?: IAlertOptions;
  alertID?: string;
}

export const AlertProvider: FCWithOptionalChildren = ({ children }) => {
  const [alert, setAlert] = useState<IAlertData | null>(
    _onLoadAlert
      ? {
          alert: _onLoadAlert,
          alertID: 'onload',
        }
      : null,
  );

  return (
    <AlertContext.Provider value={setAlert}>
      <TransitionGroup
        className={`${styles.ModalWrapper} ${alert ? styles.ModalWrapperCovered : ''}`}
        component="div"
      >
        {alert && (
          <CSSTransition key={alert.alertID || 'modal'} classNames="slideup-modal" timeout={300}>
            <div className={styles.ModalContainer}>
              {!alert.options?.noClickAway && (
                <div
                  className={styles.ModalClickAway}
                  onClick={() => !alert?.options?.noClickAway && setAlert(null)}
                />
              )}
              <div className={classNames(styles.Panel, styles.Modal, alert?.options?.modalClass)}>
                {alert.alert}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {children}
    </AlertContext.Provider>
  );
};

export const AlertContext = createContext<(alert: IAlertData | null) => void>(() =>
  console.error('Alert context not configured'),
);
