import {
  BooklogReference,
  SubmitBooklogRange,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { Select } from '@sparx/design/components';
import { useAlert, useClearAlert } from 'components/alert/hooks';
import { Button } from 'components/buttons';
import { getBooklogTaskSpec, getBooklogTaskState, useTaskActionsLoading } from 'queries/tasks';
import { useMemo, useState } from 'react';
import pbstyles from 'views/student/task-view/paperback-reading-task-view.module.css';

import { BooklogInput } from '../booklog-input';
import { BooklogRangeInput } from '../booklog-range-input';
import styles from '../booklog-task-view.module.css';
import { validRange } from '../utils';

interface IStageRecordProps {
  task: Task;
  onSubmit: (req: SubmitBooklogRange) => void;
  onBack?: () => void;
}

export const StageLoggingEnd = ({ task, onSubmit, onBack }: IStageRecordProps) => {
  const booklogState = getBooklogTaskState(task);
  const booklogSpec = getBooklogTaskSpec(task);
  const setAlert = useAlert();
  const submit = () => onSubmit(req);
  const loading = useTaskActionsLoading();
  const isSilverReader = Boolean(booklogSpec?.silverReaderConfig);
  const timerTime = useMemo(
    () => (booklogState !== undefined ? Math.ceil(booklogState?.accumulatedReadingTime) : 0),
    [booklogState?.accumulatedReadingTime],
  );

  const initBookLogRef: BooklogReference = {
    page: 0,
    chapter: '',
    firstWord: '',
    lastWord: '',
  };
  const [req, setRequest] = useState({
    markFinished: !!booklogState?.finished,
    timeSpentMinutes: booklogState?.timeSpentMinutes || timerTime,
    end: booklogState?.end || initBookLogRef,
  });

  const valid =
    validRange(req.end, isSilverReader) &&
    req.timeSpentMinutes > 0 &&
    (booklogState?.start?.page || 0) <= req.end.page;

  const [shownSilverFinishedAlert, setShownSilverFinishedAlert] = useState(false);
  const toggleFinished = (value: boolean, enteredEndPage?: boolean) => {
    if (value) {
      // Check if they mean it
      setAlert(
        <FinishBookAlert
          enteredEndPage={enteredEndPage}
          submit={() => setRequest({ ...req, markFinished: value })}
        />,
      );
    } else {
      setRequest({ ...req, markFinished: value });
    }
  };

  return (
    <>
      <h2 className={styles.StageTitle}>Record your reading session</h2>
      <p className={styles.StageSubtitle}>
        <strong>Remember: </strong> your teacher will see this, so make sure it is accurate.
      </p>
      <BooklogRangeInput
        side="finish"
        onChange={end => {
          // Catch the change event if they put a page count larger than the
          // expected into the input.
          if (isSilverReader && booklogSpec?.pageCount) {
            if (end.page >= booklogSpec.pageCount) {
              // Only show the alert once when we go over the expected page count
              // This is reset when they enter a number lower than the page count
              if (!shownSilverFinishedAlert) {
                setShownSilverFinishedAlert(true);
                toggleFinished(true, true);
              }
            } else {
              setShownSilverFinishedAlert(false);
            }
          }
          setRequest({ ...req, end });
        }}
        defaultValue={booklogState?.end}
        hideLastThreeWords={isSilverReader}
      >
        <div className={styles.FormInput}>
          <div className={styles.FormInputLabel}>Was this the final page of the story?</div>
          <Select
            label="final page"
            value={req.markFinished ? 'yes' : 'no'}
            triggerClassName={styles.FinalPageField}
            onChange={value => {
              toggleFinished(value === 'yes', req.end.page >= (booklogSpec?.pageCount || 0));
            }}
            groups={[
              {
                items: [
                  {
                    value: 'no',
                    text: 'No',
                  },
                  {
                    value: 'yes',
                    text: 'Yes',
                  },
                ],
              },
            ]}
          />
        </div>
      </BooklogRangeInput>
      <div className={styles.TimeEntry}>
        <BooklogInput
          label="How many minutes did you read for?"
          value={req.timeSpentMinutes ? req.timeSpentMinutes.toString() : ''}
          setValue={val => {
            const timeSpentMinutes = parseInt(val) || 0;
            if (timeSpentMinutes < 1000) {
              // Cap at 999 minutes
              setRequest({ ...req, timeSpentMinutes });
            }
          }}
          short
          postFix={`Timer = ${timerTime} min${timerTime !== 1 ? 's' : ''}`}
        />
      </div>

      <div className={styles.ActionButtons}>
        <Button analyticsEvent={undefined} variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button analyticsEvent={undefined} disabled={!valid} onClick={submit} loading={loading}>
          Next
        </Button>
      </div>
    </>
  );
};

interface IFinishBookAlertProps {
  submit: () => void;
  enteredEndPage?: boolean;
}

const FinishBookAlert = ({ submit, enteredEndPage }: IFinishBookAlertProps) => {
  const loading = useTaskActionsLoading();
  const clearAlert = useClearAlert();

  return (
    <div style={{ maxWidth: 400 }}>
      <div className={pbstyles.WarningText}>
        {enteredEndPage ? (
          <p>This page number should mean you have finished the book.</p>
        ) : (
          <p>By selecting this option you are telling us you have finished the book.</p>
        )}
      </div>
      <Button
        analyticsEvent={undefined}
        loading={loading}
        className={pbstyles.WarningButton}
        onClick={() => {
          submit();
          clearAlert();
        }}
        variant={enteredEndPage ? 'primary' : 'secondary'}
      >
        Yes, I have finished this book
      </Button>
      <div style={{ height: '1rem' }} />
      <Button
        analyticsEvent={undefined}
        loading={loading}
        className={pbstyles.WarningButton}
        onClick={() => clearAlert()}
        variant="secondary"
      >
        No, I still have more pages to read
      </Button>
    </div>
  );
};
