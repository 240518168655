import { Checkbox } from '@sparx/sparx-design/components';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import classNames from 'classnames';
import { Button } from 'components/buttonsv2';
import { ConfirmationDialog } from 'components/dialogs/confirmation-dialog';
import {
  GroupWithMembership,
  useBatchUpdateGroups,
  useMyEnglishGroupMemberships,
} from 'queries/management';
import { useUser } from 'queries/session';
import { useMemo, useState } from 'react';
import { useTeacherAlert } from 'views/teacher/components/teacher-alert/use-teacher-alert';

import {
  clickedCancel,
  clickedConfirmDontHaveClasses,
  clickedSaveMyClasses,
  clickedWidgetSelectClasses,
  clickWidgetNoClasses,
} from './events';
import styles from './no-classes.module.css';
import dashImage from './no-classes-image.png';

export const NoClassesWidget = ({
  dismiss,
  openModal,
}: {
  dismiss: (now: Date) => void;
  openModal: () => void;
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <div className={styles.Card}>
      <NoClassesConfirmation
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          clickedConfirmDontHaveClasses();
          dismiss(new Date());
        }}
      />
      <div className={styles.Text}>
        <div className={styles.Pill}>Action required</div>
        <h2>Class selection & personalisation</h2>
        <p>Your dashboard can now show you information for just your classes.</p>
        <p>
          Select your classes to view tailored stats and relevant insights at a glance. You can
          always see whole school information from the Department view above.
        </p>
        <div className={styles.Buttons}>
          <Button
            analyticsEvent={clickedWidgetSelectClasses()}
            variant="primary"
            onClick={openModal}
          >
            Select your classes
          </Button>
          <Button
            analyticsEvent={clickWidgetNoClasses()}
            variant="link"
            onClick={() => setShowConfirmation(true)}
          >
            I don&apos;t have any classes
          </Button>
        </div>
      </div>
      <div className={styles.Image}>
        <img src={dashImage} />
      </div>
    </div>
  );
};

export const ClassSelectModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const user = useUser();
  const schoolID = user?.schoolId || '';
  const { query, batchUpdate } = useBatchUpdateGroups();
  const { data: groups } = useMyEnglishGroupMemberships();
  const [memberships, setMemberships] = useState(groups);
  const [colA, colB] = useMemo(() => {
    if (!memberships) return [[], []];
    const half = Math.ceil(memberships.length / 2);
    return [memberships.slice(0, half), memberships.slice(half)];
  }, [memberships]);

  const toast = useTeacherAlert();

  const updates = useMemo(() => {
    return memberships?.filter(group => {
      const original = groups?.find(g => g.name === group.name);
      return original?.isMember !== group.isMember;
    });
  }, [memberships, groups]);

  const toggleMembership = (groupName: string) => {
    setMemberships(memberships =>
      memberships?.map(group =>
        group.name === groupName ? { ...group, isMember: !group.isMember } : group,
      ),
    );
  };

  const save = async () => {
    if (updates?.length && user) {
      batchUpdate(schoolID, user.sparxUserId || '', updates)
        .then(() => {
          setIsOpen(false);
          toast({
            type: 'success',
            message: 'Your classes have been updated!',
          });
        })
        .catch(() => {
          setIsOpen(false);
          toast({
            type: 'warning',
            message: 'Something went wrong. Please try again.',
          });
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content width="3xl">
        <Modal.Title align="center">Select your classes</Modal.Title>
        <Modal.CloseButton />
        <Modal.Body>
          <p className={styles.Body}>
            By selecting your classes you&apos;ll receive tailored reports on your dashboard and
            quicker access in the class selection menu.
          </p>
          <p className={styles.Body}>
            <strong>Simply click on your classes below, then click Save my classes.</strong>
          </p>
          <div className={styles.ClassSelectColumns}>
            <div className={styles.ClassSelectColumn}>
              {colA.map(group => (
                <ClassButton key={group.name} group={group} toggleMembership={toggleMembership} />
              ))}
            </div>
            <div className={styles.ClassSelectColumn}>
              {colB.map(group => (
                <ClassButton key={group.name} group={group} toggleMembership={toggleMembership} />
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer align="center">
          <Button
            analyticsEvent={clickedCancel()}
            variant="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            analyticsEvent={clickedSaveMyClasses()}
            variant={'primary'}
            onClick={save}
            loading={query.isLoading}
            isDisabled={!updates || updates.length === 0}
          >
            Save my classes
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const ClassButton = ({
  group,
  toggleMembership,
}: {
  group: GroupWithMembership;
  toggleMembership: (groupName: string) => void;
}) => {
  return (
    <div
      className={classNames(styles.ClassButton, { [styles.ClassButtonSelected]: group.isMember })}
      onClick={e => (e.preventDefault(), toggleMembership(group.name))}
    >
      <Checkbox id={group.name} label={group.displayName} checked={group.isMember} />
    </div>
  );
};

const NoClassesConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <ConfirmationDialog
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    title={'No classes selected'}
    description={
      <div className={styles.ConfirmationDescription}>
        <p>
          Selecting classes helps create a more personalised and streamlined experience in Sparx
          Reader.
        </p>
        <p>
          <strong>Are you sure you want to continue without selecting any classes?</strong>
        </p>
        <p>You can always select your classes later using Class Manager.</p>
      </div>
    }
    confirmText={"Yes, I'm sure"}
    cancelText="Go back"
    isLoading={false}
    closeButton
    confirmButtonColor="default"
  />
);
