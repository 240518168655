import { SuccessAlert, WarningAlert } from 'components/panel/info';
import { createContext, useState } from 'react';
import { FCWithChildren } from 'utils/props';
import { v4 as uuid } from 'uuid';

export interface ITeacherAlert {
  type: 'success' | 'warning';
  message: string;
  id?: string;
}

export const TeacherAlert = createContext<(alert: ITeacherAlert) => void>(() =>
  console.log('no provider'),
);

export const TeacherAlertProvider: FCWithChildren = ({ children }) => {
  const [alert, setAlert] = useState<ITeacherAlert | undefined>();
  return (
    <TeacherAlert.Provider value={alert => setAlert({ id: uuid(), ...alert })}>
      <>
        {children}
        {alert?.type === 'success' && <SuccessAlert key={alert.id}>{alert.message}</SuccessAlert>}
        {alert?.type === 'warning' && <WarningAlert key={alert.id}>{alert.message}</WarningAlert>}
      </>
    </TeacherAlert.Provider>
  );
};
