import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';
import classNames from 'classnames';

import { useTheme } from '../../context';
import styles from './Select.module.css';

type SelectItem = {
  value: string;
  text: string;
  className?: string;
};

type SelectGroup = {
  items: SelectItem[];
  label?: string;
};

type Props = {
  // The name/id of the select field used when submitting a form
  name?: string;
  // The value of the selected item.
  value?: string;
  // The function to call when the selected item changes.
  onChange: (value: string) => void;
  // The groups of items to display in the select.
  groups: SelectGroup[];
  // The Aria label for the select.
  label?: string;
  // The placeholder text to display when no item is selected.
  placeholder?: string;
  // The class name to apply to the trigger
  triggerClassName?: string;
  // The class name to apply to the portal (dropdown)
  portalClassName?: string;
};

/**
 * Select is a wrapper around the Radix Select component.
 * https://www.radix-ui.com/primitives/docs/components/select
 *
 * It provides an opinionated, non-composable API in place of the building
 * blocks and other helpers exported from this package.
 */
export const Select = ({
  value,
  onChange,
  label,
  placeholder,
  groups,
  name,
  triggerClassName,
  portalClassName,
}: Props) => {
  return (
    <Root value={value} onValueChange={onChange} name={name}>
      <DropdownTrigger aria-label={label} className={triggerClassName} placeholder={placeholder} />
      <ScrollPortal className={portalClassName}>
        {groups.map((group, i) => (
          <Group key={i}>
            {group.label && <Label>{group.label}</Label>}
            {group.items.map(({ value: itemValue, text, className }) => (
              <Item key={itemValue} value={itemValue} className={className}>
                <ItemText>{text}</ItemText>
              </Item>
            ))}
          </Group>
        ))}
      </ScrollPortal>
    </Root>
  );
};

// This magic string is used under-the-hood in our Select component to allow
// adding items which reset the select value to the empty string. This is not
// supported by default in Radix; an Item with an empty string value will throw
// an error (e.g. `<Item value="" />`).
// See this issue: https://github.com/radix-ui/primitives/issues/2706
const RADIX_SELECT_EMPTY_STRING = 'RADIX_SELECT_EMPTY_STRING';

const Root = ({ onValueChange, children, ...props }: RadixSelect.SelectProps) => {
  // Wrap the change handler to allow items with empty string values.
  const onChangeHandler = (value: string) => {
    if (!onValueChange) {
      return;
    }
    if (value === RADIX_SELECT_EMPTY_STRING) {
      return onValueChange('');
    }
    onValueChange(value);
  };

  return (
    <RadixSelect.Root key={props.value || props.name} onValueChange={onChangeHandler} {...props}>
      {children}
    </RadixSelect.Root>
  );
};

const Item = ({ value, children, className, ...props }: RadixSelect.SelectItemProps) => {
  const val = value === '' ? RADIX_SELECT_EMPTY_STRING : value;
  return (
    <RadixSelect.Item value={val} {...props} className={classNames(styles.Item, className)}>
      {children}
    </RadixSelect.Item>
  );
};

/** A helper component that composes Portal, Content and Viewport with ScrollUp
 * and ScrollDown buttons so it will scroll when the items exceed the container
 * height. Class name and other props are applied to the Content component. */
const ScrollPortal = ({ children, ...props }: RadixSelect.SelectContentProps) => (
  <Portal>
    <Content position="popper" {...props}>
      <ScrollUpButton>
        <FontAwesomeIcon icon={faChevronUp} />
      </ScrollUpButton>
      <Viewport>{children}</Viewport>
      <ScrollDownButton>
        <FontAwesomeIcon icon={faChevronDown} />
      </ScrollDownButton>
    </Content>
  </Portal>
);

/** A helper component for a Trigger displaying the selected value or optional
 * placeholder and a down-chevron icon on the right. */
const DropdownTrigger = ({
  placeholder,
  ...props
}: RadixSelect.SelectTriggerProps & { placeholder?: string }) => (
  <Trigger {...props}>
    <Value placeholder={placeholder} />
    <Icon>
      <FontAwesomeIcon icon={faChevronDown} />
    </Icon>
  </Trigger>
);

const Content = ({ children, className, ...props }: RadixSelect.SelectContentProps) => {
  const { themeStyles } = useTheme();
  return (
    <RadixSelect.Content
      position="popper"
      className={classNames(
        themeStyles['SelectShared'] || styles.SelectShared,
        styles.Content,
        className,
      )}
      {...props}
    >
      {children}
    </RadixSelect.Content>
  );
};

const Label = ({ children, className, ...props }: RadixSelect.SelectLabelProps) => (
  <RadixSelect.Label className={classNames(styles.GroupLabel, className)} {...props}>
    {children}
  </RadixSelect.Label>
);

const ScrollDownButton = ({
  children,
  className,
  ...props
}: RadixSelect.SelectScrollDownButtonProps) => (
  <RadixSelect.ScrollDownButton className={classNames(styles.ScrollButton, className)} {...props}>
    {children}
  </RadixSelect.ScrollDownButton>
);

const ScrollUpButton = ({
  children,
  className,
  ...props
}: RadixSelect.SelectScrollUpButtonProps) => (
  <RadixSelect.ScrollUpButton className={classNames(styles.ScrollButton, className)} {...props}>
    {children}
  </RadixSelect.ScrollUpButton>
);

const Trigger = ({ children, className, ...props }: RadixSelect.SelectTriggerProps) => {
  const { themeStyles } = useTheme();
  return (
    <RadixSelect.Trigger
      className={classNames(
        themeStyles['SelectShared'] || styles.SelectShared,
        styles.Trigger,
        className,
      )}
      {...props}
    >
      {children}
    </RadixSelect.Trigger>
  );
};

const Viewport = ({ children, className, ...props }: RadixSelect.SelectViewportProps) => (
  <RadixSelect.Viewport className={classNames(styles.Viewport, className)} {...props}>
    {children}
  </RadixSelect.Viewport>
);

// Unchanged from Radix versions: we don't apply any Sparx-specific styling or behaviour to these.
const Arrow = RadixSelect.Arrow;
const Group = RadixSelect.Group;
const Icon = RadixSelect.Icon;
const ItemIndicator = RadixSelect.ItemIndicator;
const ItemText = RadixSelect.ItemText;
const Portal = RadixSelect.Portal;
const Separator = RadixSelect.Separator;
const Value = RadixSelect.Value;

export {
  Arrow,
  Content,
  DropdownTrigger,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Label,
  Portal,
  Root,
  ScrollDownButton,
  ScrollPortal,
  ScrollUpButton,
  Separator,
  Trigger,
  Value,
  Viewport,
};
