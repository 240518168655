import { useClientEvent } from 'components/client-events/use-client-event';
import { fromUnixTime, getUnixTime, isBefore, sub } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

const LOCAL_STORAGE_KEY = 'last-reload-attempt';
const ERROR_MESSAGES = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed.',
];

export const useAttemptReload = () => {
  const error = useRouteError();
  const { sendEvent } = useClientEvent();

  const reload = useCallback(() => {
    const lastAttemptAt = getLastAttemptAt();
    if (!lastAttemptAt || isLastAttemptBeforeThreshold(lastAttemptAt)) {
      setLastAttemptNow();
      sendEvent({ category: 'attempt-reload', action: 'reloading' }, { immediate: true });
      window.location.reload();
    }
  }, [sendEvent]);

  useEffect(() => {
    if (
      error instanceof Error &&
      ERROR_MESSAGES.some(errorMessage => error.message.includes(errorMessage))
    ) {
      sendEvent({ category: 'attempt-reload', action: 'caught error' });
      reload();
    }
  }, [reload, error, sendEvent]);
};

const getLastAttemptAt = (): Date | null => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY);

  return value ? fromUnixTime(parseInt(value, 10)) : null;
};

const setLastAttemptNow = () =>
  localStorage.setItem(LOCAL_STORAGE_KEY, getUnixTime(new Date()).toString());

const isLastAttemptBeforeThreshold = (lastAttemptAt: Date) =>
  isBefore(lastAttemptAt, sub(new Date(), { seconds: 10 }));
