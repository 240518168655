import { School, UpdateSchoolRequest } from '@sparx/api/apis/sparx/school/v2/schools';
import {
  GetSchoolOnboardingStatusResponse,
  StudentDomainCheckRequest as IStudentDomainCheckRequest,
  StudentDomainCheckResponse as IStudentDomainCheckResponse,
} from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus';
import { useReportGenMultiProductClient } from '@sparx/query/reportgen-service';
import { useSchoolActionsClient } from '@sparx/query/school-actions-service';
import { useSchoolStatusClient } from '@sparx/query/school-status-service';
import { getSchool } from '@sparx/query/schools-service';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { download } from './utils';

export const SCHOOL_ONBOARDING_STATUS_QUERY_KEY = 'school-onboarding-status';

/**
 * Get the onboarding status of a school. Note: this endpoint contains some information that is Maths-specific.
 * However the data we use here covers all products - e.g. the count of students with missing email addresses covers
 * all students in the school, not just students in Maths classes.
 * @param schoolName
 * @param options
 */
export const useSchoolOnboardingStatus = <T = GetSchoolOnboardingStatusResponse>(
  schoolName: string | undefined,
  options?: UseQueryOptions<GetSchoolOnboardingStatusResponse, Error, T, string[]>,
) => {
  const schoolStatusClient = useSchoolStatusClient();
  return useQuery(
    [SCHOOL_ONBOARDING_STATUS_QUERY_KEY],
    () => schoolStatusClient.getSchoolOnboardingStatus({ schoolName: schoolName || '' }).response,
    {
      enabled: !!schoolName,
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};

export const STUDENT_DOMAIN_CHECK_QUERY_KEY = 'student-domain-check';

/**
 * Check how many students have the wrong domain in their email address compared with the student SSO domain. Note: this
 * covers all students in the school, whatever products they are using.
 * @param request
 * @param options
 */
export const useStudentDomainCheck = <T = IStudentDomainCheckResponse>(
  request: IStudentDomainCheckRequest,
  options?: UseQueryOptions<IStudentDomainCheckResponse, Error, T, string[]>,
) => {
  const schoolStatusClient = useSchoolStatusClient();
  return useQuery(
    [STUDENT_DOMAIN_CHECK_QUERY_KEY, request.schoolName, request.domain],
    () => schoolStatusClient.studentDomainCheck(request).response,
    options,
  );
};

/**
 * Get a mutation to update the SSO settings for a school.
 * @param schoolName the resource name of the school
 */
export const useMutateSSOSettings = (schoolName: string) => {
  const schoolActionsClient = useSchoolActionsClient();
  const queryClient = useQueryClient();
  return useMutation(async (school: Partial<School>) => {
    const request = UpdateSchoolRequest.create({
      school: { ...school, name: schoolName },
      updateMask: { paths: ['annotations'] },
      allowDeleted: false,
    });
    const data = await schoolActionsClient.updateSchoolSSOSettings(request).response;
    queryClient.setQueryData(getSchool.makeKey({ schoolName }), data);
    // Changing the sso domain can invalidate this
    await queryClient.invalidateQueries([SCHOOL_ONBOARDING_STATUS_QUERY_KEY]);
    return data;
  });
};

/**
 * Get a mutation to trigger downloading the student SSO report for a school.
 * @param schoolName the school resource name
 */
export const useDownloadSSOReport = (schoolName: string) => {
  // TODO: Not working yet:
  const reportGenClient = useReportGenMultiProductClient();
  const schoolId = schoolName.split('/')[1];
  return useMutation(
    () =>
      reportGenClient.studentSSOReport({
        schoolId,
      }).response,
    {
      onSuccess: data => download(data.downloadUrl),
    },
  );
};
