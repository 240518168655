import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  BooklogTask_Spec,
  BooklogTask_State,
  BooklogTask_State_Stage,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Homework, WorkType } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import {
  PaperbackReadingTask_Spec,
  PaperbackReadingTask_State,
} from '@sparx/api/apis/sparx/reading/tasks/v1/paperback';
import {
  GetBookTaskResponse,
  GetHomeworksRequest_HomeworkRequestType,
  GetHomeworksResponse,
  Task,
  TaskAction,
} from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { ModuleStepState } from '@sparx/teacher-training';
import {
  useCompleteTrainingStep,
  useCurrentTrainingState,
} from '@sparx/teacher-training/src/queries';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { tasksClient } from 'api';
import { handleErr } from 'app/handle-err';
import { useAlert } from 'components/alert/hooks';
import {
  BookBlocklistedModal,
  BookCompleteModal,
  BookIncompleteModal,
  BookLockedModal,
  PackageExpiredModal,
} from 'components/tasks/task-complete-modal';
import { endOfWeek, Interval, isAfter, isBefore, isWithinInterval, startOfWeek } from 'date-fns';
import moment from 'moment';
import { queryClient } from 'queries/client';
import { useUpdateExperience } from 'queries/notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { timestampToDate, timestampToMoment } from 'utils/time';
import { View } from 'views';
import {
  READ_TRAINING_BOOK_STEP_ID,
  TRAINING_BOOK_ID,
} from 'views/teacher/teacher-training-view/module-specs';
import { pathForView } from 'views/views';

import { setBooksStale } from './books';
import { LIBRARY_BOOKS_QUERY_KEY, updateLibraryStudentBook } from './library-books';
import { useUser } from './session';

export const getBooklogTaskState = (task: Task): BooklogTask_State | undefined =>
  task.state?.state.oneofKind === 'booklog' ? task.state.state.booklog : undefined;
export const getBooklogTaskSpec = (task: Task): BooklogTask_Spec | undefined =>
  task.spec?.spec.oneofKind === 'booklog' ? task.spec.spec.booklog : undefined;

export const getPaperbackTaskState = (task: Task): PaperbackReadingTask_State | undefined =>
  task.state?.state.oneofKind === 'paperback' ? task.state.state.paperback : undefined;
export const getPaperbackTaskSpec = (task: Task): PaperbackReadingTask_Spec | undefined =>
  task.spec?.spec.oneofKind === 'paperback' ? task.spec.spec.paperback : undefined;

export const useAllHomeworks = (
  options?: UseQueryOptions<GetHomeworksResponse, Error, Homework[]>,
) =>
  useQuery<GetHomeworksResponse, Error, Homework[]>(
    ['homeworks', 'all'],
    () =>
      tasksClient.getHomeworks({
        type: GetHomeworksRequest_HomeworkRequestType.ALL,
      }).response,
    {
      select: data => data.homeworks,
      staleTime: 15000,
      ...options,
    },
  );

export const useHomeworksForStudentGroup = (
  studentGroupId: string,
  homeworkType: WorkType,
  options?: UseQueryOptions<GetHomeworksResponse, Error, Homework[]>,
) =>
  useAllHomeworks({
    refetchInterval: 60000,
    refetchIntervalInBackground: false,
    enabled: !!studentGroupId,
    select: data => {
      // Filter based on current group, the type of work, then sort
      return (
        (data.homeworks || [])
          .filter(hwk => hwk.studentGroupId === studentGroupId)
          .filter(
            hwk =>
              hwk.workType === homeworkType ||
              // Fallback case if server is previous version
              (hwk.workType === WorkType.UNSPECIFIED && homeworkType === WorkType.HOMEWORK),
          )
          // Filter homework that started today if we are in the classroom
          .filter(
            hwk =>
              // Show all non-classwork homework
              homeworkType !== WorkType.CLASSWORK ||
              // Show classwork if it is on the same day
              timestampToMoment(hwk.startDate)?.isSame(moment(), 'day'),
          )
          .sort((a, b) => timestampToMoment(a.endDate)?.diff(timestampToMoment(b.endDate)) || 0)
      );
    },
    ...options,
  });

// Homeworks which have started and either are not complete or completed this calendar day
export const isHomeworkRecent = (hwk: Homework) =>
  hwk.startDate &&
  hwk.endDate &&
  timestampToMoment(hwk.startDate).isBefore(moment()) &&
  moment().endOf('day').subtract(1, 'day').isBefore(timestampToMoment(hwk.endDate));

export const sortByOldestDueDate = (a: Homework, b: Homework) => {
  if (a.endDate && b.endDate) {
    return timestampToMoment(a.endDate).diff(timestampToMoment(b.endDate));
  }
  return 0;
};

export const selectAllRecentHomeworks = (data: GetHomeworksResponse) =>
  data.homeworks.filter(isHomeworkRecent).sort(sortByOldestDueDate);

export const useAllRecentHomeworks = () => useAllHomeworks({ select: selectAllRecentHomeworks });

const filterHomeworkDueBetween = (interval: Interval) => (data: GetHomeworksResponse) =>
  data.homeworks
    .filter(hwk => hwk.endDate && isWithinInterval(timestampToDate(hwk.endDate), interval))
    .sort(sortByOldestDueDate);

export const useHomeworkDueBetween = (interval: Interval) =>
  useAllHomeworks({ select: filterHomeworkDueBetween(interval) });

const filterHomeworkSetThisWeek = (data: GetHomeworksResponse) =>
  data.homeworks
    .filter(
      hwk =>
        hwk.startDate &&
        isAfter(timestampToDate(hwk.startDate), startOfWeek(new Date(), { weekStartsOn: 1 })) &&
        isBefore(timestampToDate(hwk.startDate), endOfWeek(new Date(), { weekStartsOn: 1 })),
    )
    .sort(sortByOldestDueDate);

export const useHomeworkSetThisWeek = () => useAllHomeworks({ select: filterHomeworkSetThisWeek });

export const useHomeworksInGroup = (homeworkGroupId: string) =>
  useAllHomeworks({
    select: data => data.homeworks.filter(hwk => hwk.homeworkGroupId === homeworkGroupId),
  });

const MY_HOMEWORKS_QUERY_KEY = ['homeworks', 'mine'];

export const setHomeworksStale = () => {
  queryClient.invalidateQueries(MY_HOMEWORKS_QUERY_KEY);
};

export const setHomeworksReset = () => {
  queryClient.resetQueries(MY_HOMEWORKS_QUERY_KEY);
};

const useMyHomeworkQuery = <T,>(options?: UseQueryOptions<GetHomeworksResponse, Error, T>) =>
  useQuery<GetHomeworksResponse, Error, T>(
    MY_HOMEWORKS_QUERY_KEY,
    () =>
      tasksClient.getHomeworks({
        type: GetHomeworksRequest_HomeworkRequestType.MINE,
      }).response,
    { staleTime: 15000, ...options },
  );

export const selectExtraPackages = (data: GetHomeworksResponse) => {
  const sortedPackages = data.extraPackages.sort(
    (a, b) => (b.endDate?.seconds || 0) - (a.endDate?.seconds || 0),
  );
  const incompletePackages = sortedPackages.filter(p => p.tasksComplete < p.tasksTotal);
  return incompletePackages.length > 0 ? incompletePackages : sortedPackages.slice(0, 1);
};

export const useExtraPackages = () => useMyHomeworkQuery({ select: selectExtraPackages });

export const useLockedInPackage = () =>
  useMyHomeworkQuery({
    select: data => Boolean(data.extraPackages.find(p => p.tasksComplete < p.tasksTotal)),
  });

export const useMyHomeworks = () =>
  useMyHomeworkQuery({
    select: data => data.homeworks,
  });

// isHomeworkActive returns true if the current time is between the start and end date of the homework.
export const isHomeworkActive = (hwk: Homework) =>
  hwk.startDate &&
  hwk.endDate &&
  timestampToMoment(hwk.startDate).isSameOrBefore() &&
  timestampToMoment(hwk.endDate).isAfter();

// useActiveHomeworks returns all of the active homeworks, see isHomeworkActive. There may be more
// than one active homework. See useCurrentHomework if you want the first of these active homeworks.
export const useActiveHomeworks = () =>
  useMyHomeworkQuery({
    select: ({ homeworks }) => homeworks.filter(isHomeworkActive),
  });

interface IUseGetBookTaskOptions {
  replace?: boolean;
  popOnError?: boolean;
  requestReread?: boolean;
  refetchCache?: boolean;
}

export const useStoreGetBookTaskData = () => {
  return (data: GetBookTaskResponse) => {
    if (data.studentBook) {
      updateLibraryStudentBook(data.studentBook);
    }
  };
};

export const useGetBookTask = (bookId: string, options?: IUseGetBookTaskOptions) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const trainingSrc = searchParams.get('src') === 'training';
  const setAlert = useAlert();
  const storeData = useStoreGetBookTaskData();
  return useMutation<GetBookTaskResponse, Error>(
    ['tasks', 'book', bookId],
    () => tasksClient.getBookTask({ bookId, requestReread: !!options?.requestReread }).response,
    {
      onSuccess: data => {
        storeData(data);
        if (options?.refetchCache) {
          queryClient.invalidateQueries(['books', 'available']);
          queryClient.invalidateQueries([LIBRARY_BOOKS_QUERY_KEY]);
        }
        // If the training search parameter is set, persist it in the URL
        if (data.loadTaskId) {
          const params = new URLSearchParams({ id: data.loadTaskId });
          if (trainingSrc) {
            params.append('src', 'training');
          }
          navigate(
            {
              pathname: pathForView(View.Task),
              search: `?${params}`,
            },
            { replace: !!options?.replace },
          );
        }
      },
      onError: async err => {
        if (options?.popOnError) {
          navigate(
            {
              pathname: pathForView(View.Library),
              search: trainingSrc ? '?src=training' : undefined,
            },
            { state: { popped: true } },
          );
        }
        if (err.message === 'no more book') {
          setAlert(<BookCompleteModal bookID={bookId} />);
        } else if (err.message === 'incomplete book') {
          setAlert(<BookIncompleteModal bookID={bookId} />);
        } else if (err.message === 'book not available') {
          setAlert(<BookLockedModal />);
        } else {
          console.error('Failed to get book package', err);
          handleErr(err);
        }
      },
    },
  );
};

export const useGetNextPackageTask = (packageId: string, options?: IUseGetBookTaskOptions) => {
  const navigate = useNavigate();

  return useMutation(() => tasksClient.listTasks({ packageId }).response, {
    onSuccess: data => {
      const nextIncomplete = data.tasks.find(t => !t.state?.completed);
      if (nextIncomplete) {
        navigate(
          {
            pathname: pathForView(View.Task),
            search: `?id=${nextIncomplete.taskId}`,
          },
          { replace: !!options?.replace },
        );
      } else {
        throw new Error('no more tasks in package');
      }
    },
    onError: err => {
      if (options?.popOnError) {
        navigate(pathForView(View.Library), { state: { popped: true } });
      }
      console.error('Failed to get package tasks', err);
      handleErr(err);
    },
  });
};

export const useTaskLoadActions = (taskId: string) =>
  useMutation(
    ['tasks', 'load', taskId],
    (action: TaskAction) =>
      tasksClient.sendTaskAction({
        taskId,
        action,
      }).response,
  );

export const useTaskActions = (taskId: string) => {
  const updateExperience = useUpdateExperience(taskId);
  const setAlert = useAlert();
  const navigate = useNavigate();
  const user = useUser();
  const { mutate: completeStep } = useCompleteTrainingStep();
  // The enabled: false means this query will not run until refetch is called.
  const { refetch: refetchTrainingProgress } = useCurrentTrainingState({ enabled: false });

  return useMutation(
    ['tasks', 'action', taskId],
    (action: TaskAction) => {
      return tasksClient.sendTaskAction({
        taskId,
        action,
      }).response;
    },
    {
      onSuccess: async data => {
        // Handle all the side effects from the task action result
        if (data.justCompleted) {
          setBooksStale();
          setHomeworksReset();
        }
        if (data.task) {
          queryClient.setQueryData(['task', data.task.taskId], data.task);
        }
        if (
          data.justCompletedBook &&
          user?.type === UserType.TEACHER &&
          data.studentBook?.bookId === TRAINING_BOOK_ID
        ) {
          const progress = await refetchTrainingProgress();
          const { data: moduleStates } = progress;
          const trainingBookStep = moduleStates
            ?.reduce<ModuleStepState[]>((p, m) => p.concat(m.steps), [])
            .find(s => s.spec.id === READ_TRAINING_BOOK_STEP_ID);
          if (trainingBookStep) {
            completeStep(trainingBookStep);
          }
        }

        // Update with experience update
        const xpUpdate = data.experienceUpdate;
        if (xpUpdate) {
          updateExperience(xpUpdate);
        }
      },
      onError: (err: Error) => {
        console.log('errrrrrrr');
        if (err instanceof RpcError && err.code === 'FAILED_PRECONDITION') {
          // The client + server state are out of sync - we should resync them
          queryClient.invalidateQueries(['task', taskId]);
        } else if (err.message === 'book not available') {
          setAlert(<BookLockedModal />);
          navigate(pathForView(View.Library));
        } else if (err.message === 'assessment package has expired') {
          setAlert(<PackageExpiredModal />);
          navigate(pathForView(View.Library));
        } else if (err.message === 'book is blacklisted') {
          setBooksStale();
          setAlert(<BookBlocklistedModal taskID={taskId} />);
          navigate(pathForView(View.Library));
        } else if (err.message === 'cannot assign custom books') {
          // They have just lost gold reader while in a task
          queryClient.invalidateQueries(['user', 'gold']);
        } else {
          queryClient.invalidateQueries(['task', taskId]);
          console.error('Failed to send task action', err);
          handleErr(err);
        }
      },
    },
  );
};

export const useTaskState = (taskId: string) =>
  useQuery(['task', taskId], async () => (await tasksClient.getTask({ taskId }).response).task, {
    retry: (retries, err) => {
      if (err instanceof RpcError && err.name === 'FAILED_PRECONDITION') {
        return false;
      }
      return retries < 3;
    },
  });

export const usePackageTasks = (packageId: string) =>
  useQuery(['package', packageId, 'tasks'], () => tasksClient.listTasks({ packageId }).response, {
    enabled: Boolean(packageId),
  });

export const useBooklogWritingTasks = (packageId: string) =>
  useQuery(['package', packageId, 'tasks'], () => tasksClient.listTasks({ packageId }).response, {
    select: data =>
      data.tasks.filter(
        task =>
          task.state?.state.oneofKind === 'booklog' &&
          task.state.state.booklog.stage == BooklogTask_State_Stage.WRITING,
      ),
  });

export const useTaskActionsLoading = () => {
  const queryClient = useQueryClient();
  return (
    queryClient.isMutating({
      mutationKey: ['tasks', 'action'],
    }) > 0
  );
};
