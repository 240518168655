import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { redirectToLogin } from 'app/handle-err';
import { useIsUserOnboarded, useSession } from 'queries/session';
import { useGetSubscriptionDetails } from 'queries/subscriptions';
import { useLocation, useNavigate } from 'react-router-dom';
import { View, ViewOptions, Views } from 'views';

import { useSchoolSubscriptionBanner } from './teacher/components/school-subscription-banner/hook';

export interface ICurrentView {
  view: View;
  options: ViewOptions;
  popped: boolean;
}

export const useCurrentView = (): ICurrentView | undefined => {
  const location = useLocation();
  const path = location.pathname;
  const view = Object.entries(Views).find(([_, options]) => options.path === path);
  return view
    ? { view: view[0] as View, options: view[1], popped: location.state?.popped }
    : undefined;
};

export const pathForView = (view: View) => {
  return Views[view] ? Views[view].path : '';
};

export const subPathForView = (view: View, parent: View) => {
  const p = pathForView(view);
  let pp = pathForView(parent);
  if (!pp.endsWith('/')) {
    pp += '/';
  }
  if (p.startsWith(pp)) {
    return p.slice(pp.length);
  }
  // Don't allow
  return '';
};

export const useNavigateHomeForUser: () => (isManualNavigation: boolean) => void = () => {
  const navigate = useNavigate();
  const { data: session, isLoading: isSessionLoading } = useSession();
  const isOnboarded = useIsUserOnboarded();

  // Wait until the subscription details are loaded so we know whether the subcription banner is showing.
  const shouldLoadSubscriptionDetails = session?.user?.type === UserType.TEACHER;
  const { isLoading: isSubscriptionDetailsLoading } = useGetSubscriptionDetails({
    enabled: shouldLoadSubscriptionDetails,
  });
  const schoolSubscriptionBanner = useSchoolSubscriptionBanner(shouldLoadSubscriptionDetails);

  if (isSessionLoading || (isSubscriptionDetailsLoading && shouldLoadSubscriptionDetails)) {
    return () => undefined;
  }
  if (!session?.user) {
    return () => redirectToLogin();
  }
  if (session.user.type === UserType.TEACHER) {
    // Temporary change to force teachers to visit the subscriptions page when landing in the app. We redirect if the
    // subcription banner is showing.
    if (schoolSubscriptionBanner.show && schoolSubscriptionBanner.kind === 'TRIAL_ENDING') {
      return isManualNavigation => {
        // A manual navigation is when the user clicks a button to go home rather than being automatically redirected.
        if (isManualNavigation) {
          navigate(pathForView(View.Teacher), { replace: true });
        } else {
          navigate(pathForView(View.SchoolSubscription), { replace: true });
        }
      };
    }
    return isManualNavigation => {
      // If the function returned by the hook is called with true,
      // that means the user is manually navigating.
      // In that case we *don't* want to replace.
      // (Better would probably be to use a Link but this fixes the issue)
      navigate(pathForView(View.Teacher), { replace: !isManualNavigation });
    };
  }
  if (!isOnboarded) {
    return () => navigate(pathForView(View.Onboarding), { replace: true });
  }
  return () => navigate(pathForView(View.Library), { replace: true });
};

export const useIsInTeacherMode = () => {
  const location = useLocation();
  return location.pathname.startsWith('/teacher');
};
