import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/backbutton/backbutton.module.css';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { useClientEvent } from 'components/client-events/use-client-event';

interface IBackButtonProps {
  onClick?: () => void;
  // The analytics event to send on click. This is not optional so you have to
  // explicitly opt out of sending an event.
  analyticsEvent: AnalyticEventFields | undefined;
  colour?: 'dark' | 'light';
  size?: 'small' | 'normal';
}

export const BackButton = ({
  onClick,
  analyticsEvent,
  colour = 'dark',
  size = 'normal',
}: IBackButtonProps) => {
  const { sendEvent } = useClientEvent();
  return (
    <span
      className={classNames(styles.BackButton, {
        [styles.Small]: size === 'small',
        [styles.Normal]: size === 'normal',
        [styles.Dark]: colour === 'dark',
        [styles.Light]: colour === 'light',
      })}
      onClick={() => {
        onClick && onClick();
        analyticsEvent && sendEvent(analyticsEvent);
      }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </span>
  );
};
