import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import classNames from 'classnames';
import { useClientEvent } from 'components/client-events/use-client-event';
import { useState } from 'react';

import { clickedSentimentRating } from '../events';
import styles from '../popover.module.css';
import { ReactComponent as Smiley1 } from '../smileys/Smiley1.svg';
import { ReactComponent as Smiley2 } from '../smileys/Smiley2.svg';
import { ReactComponent as Smiley3 } from '../smileys/Smiley3.svg';
import { ReactComponent as Smiley4 } from '../smileys/Smiley4.svg';
import { ReactComponent as Smiley5 } from '../smileys/Smiley5.svg';

const smileys = [
  { icon: Smiley1, text: 'I hate it', styles: styles.Smiley1, value: SentimentRating.HATE },
  { icon: Smiley2, text: 'I dislike it', styles: styles.Smiley2, value: SentimentRating.DISLIKE },
  { icon: Smiley3, text: "It's ok", styles: styles.Smiley3, value: SentimentRating.NEUTRAL },
  { icon: Smiley4, text: 'I like it', styles: styles.Smiley4, value: SentimentRating.LIKE },
  { icon: Smiley5, text: 'I love it', styles: styles.Smiley5, value: SentimentRating.LOVE },
];

interface ISmileyProps {
  onSelect: (i: SentimentRating) => void;
}

export const Sentiment = ({ onSelect }: ISmileyProps) => {
  const { sendEvent } = useClientEvent();
  const [hovered, setHovered] = useState('');

  const onClick = (rating: SentimentRating) => {
    onSelect(rating);
    sendEvent(clickedSentimentRating(rating));
  };

  return (
    <div>
      <p className={styles.SectionText}>How are you feeling about using Sparx Reader?</p>
      <div className={styles.Icons}>
        {smileys.map(smiley => (
          <div
            key={smiley.value}
            onMouseOver={() => setHovered(smiley.text)}
            onMouseOut={() => setHovered('')}
            onClick={() => onClick(smiley.value)}
            className={classNames(styles.IconContainer)}
          >
            <smiley.icon className={classNames(styles.Icon, smiley.styles)} />
          </div>
        ))}
      </div>
      <p className={styles.SmileyText} data-has-text={hovered !== ''}>
        {hovered}
      </p>
    </div>
  );
};
