import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  NotificationWarning,
  NotificationWithContext,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { Button } from 'components/buttons/button';
import { useBookNotificationRead } from 'queries/notifications';

import { useClearAlert } from './hooks';
import styles from './warning-alert.module.css';

interface IWarningAlertProps {
  notification: NotificationWithContext;
  warning: NotificationWarning;
}

export const WarningAlert = ({ notification, warning }: IWarningAlertProps) => {
  const close = useClearAlert();

  const readNotification = useBookNotificationRead();
  const markNotificationAsSeen = () => readNotification(notification.notificationId);

  const { modal } = warning;
  if (modal === undefined) {
    return null;
  }

  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeaderImage}>
          <FontAwesomeIcon size="4x" icon={faTriangleExclamation} />
        </div>
        <div className={styles.HeaderTitles}>
          <h2 className={styles.HeaderTitle}>{modal.title}</h2>
          {modal.subtitle ? <h5 className={styles.HeaderSubtitle}>{modal.subtitle}</h5> : null}
        </div>
      </div>
      <div className={styles.Content}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: modal.content }} className={styles.HTML}></div>
      </div>
      <div className={styles.Buttons}>
        <Button
          onClick={() => {
            close();
            markNotificationAsSeen();
          }}
          color="danger"
          analyticsEvent={undefined}
        >
          {modal.cta}
        </Button>
      </div>
    </>
  );
};
