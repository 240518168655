import { faCheck, faChevronRight, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/use-client-event';
import { Link } from 'components/link';
import { Loading } from 'components/loading/loading';
import { ProgressBar } from 'components/progress/progress-bar';
import { ProgressSegments } from 'components/progress-segments/progress-segments';
import { ProgressSegmentType } from 'components/progress-segments/types';
import { useUser } from 'queries/session';
import { useListReaderStaff } from 'queries/staff';
import { useMemo, useState } from 'react';
import { View } from 'views';
import {
  useAllStaffTrainingCompletion,
  useCurrentTrainingProgress,
} from 'views/teacher/teacher-training-view/hooks/training-progress';
import { useMakePathForStep } from 'views/teacher/teacher-training-view/utils';
import { pathForView } from 'views/views';

import { clickedButton, clickedImage, dismissedWidget } from './events';
import iconTrainingShiny from './icon-training-shiny.png';
import thumbnailTrainingVideo from './thumbnail-training-video.png';
import styles from './training-dashboard-widget.module.css';

const makeLocalStorageKey = (userId?: string) => `training-dashboard-widget-dismissed-${userId}`;

// The maximum number of icons to show for department completions before
// swapping to a progress bar.
const maxDeptCompletionIcons = 6;

type TrainingDashboardWidgetProps = {
  width: 'narrow' | 'wide';
};
export const TrainingDashboardWidget = ({ width }: TrainingDashboardWidgetProps) => {
  const user = useUser();
  const localStorageKey = useMemo(() => makeLocalStorageKey(user?.userId), [user?.userId]);
  const dismissedDate = useMemo(() => localStorage.getItem(localStorageKey), [localStorageKey]);
  const [justDismissed, setJustDismissed] = useState(false);
  const { allComplete, essential, usingSparxReader, completeStepsCount } =
    useCurrentTrainingProgress();
  const { sendEvent } = useClientEvent();
  const makePathForStep = useMakePathForStep();

  const currentModule = essential.complete ? usingSparxReader : essential;

  let firstIncompleteStep = essential?.steps?.[essential?.firstIncompleteIx];
  if (essential.complete) {
    firstIncompleteStep = usingSparxReader?.steps?.[usingSparxReader?.firstIncompleteIx];
  }

  // If there is an incomplete step, link directly to it. Otherwise, just link to the training page.
  let linkPath = makePathForStep(firstIncompleteStep);
  if (firstIncompleteStep === undefined) {
    linkPath = pathForView(View.TeacherTraining);
  }

  if (user === null || dismissedDate !== null || allComplete) {
    return null;
  }

  const onDismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setJustDismissed(true);
    sendEvent(dismissedWidget());
  };

  if (justDismissed) {
    return (
      <div className={styles.DismissedBanner}>
        <FontAwesomeIcon
          icon={faCheck}
          style={{ marginRight: 10 }}
          size="lg"
          className={styles.DismissedTick}
        />
        Training progress will no longer show. You can access training using the menu in the top
        right.
      </div>
    );
  }

  return (
    <div className={styles.Root}>
      <h3 className={styles.Label}>Get the most out of Sparx Reader</h3>
      <div className={styles.Banner}>
        {essential.complete && (
          <button className={styles.DismissButton} onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {width === 'narrow' && (
          <div className={styles.IconTrainingNarrow}>
            <img src={iconTrainingShiny} />
          </div>
        )}
        <div className={styles.Content}>
          <div className={styles.TitleContainer}>
            <div className={classNames(styles.Title, { [styles.TitleWide]: width === 'wide' })}>
              <h4>Complete your Sparx Reader {essential.complete ? '' : 'essential '}training</h4>
              <span className={styles.Subtitle}>
                Level up your Sparx Reader knowledge
                {width === 'wide' && ' - watch 2 minute videos and try out the student experience.'}
              </span>
            </div>
            <div className={styles.ImageContainer}>
              {width !== 'narrow' && (
                <>
                  <Link to={linkPath} analyticsEvent={clickedImage()}>
                    <img src={thumbnailTrainingVideo} />
                  </Link>
                  <img className={styles.IconTrainingWide} src={iconTrainingShiny} />
                </>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.Container, { [styles.ContainerWide]: width === 'wide' })}
          >
            <div className={classNames(styles.Left, { [styles.LeftWide]: width === 'wide' })}>
              {width === 'wide' ? (
                <>
                  {essential.complete ? (
                    <>
                      <div className={styles.CompleteEssential}>
                        Level 1
                        <div className={styles.CompleteEssentialIcon}>
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                      </div>
                      <div className={styles.LevelTwo}>
                        Level 2
                        <div className={styles.UsingSparxReader}>
                          Using Sparx Reader
                          {currentModule.steps && (
                            <div className={styles.ProgressSegments}>
                              <ProgressSegments
                                segments={currentModule.steps.map(step =>
                                  step.complete
                                    ? ProgressSegmentType.Success
                                    : ProgressSegmentType.Incomplete,
                                )}
                                background="var(--white-opacity-20)"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.LevelTwo}>
                        Level 1
                        <div className={styles.UsingSparxReader}>
                          Essential Training
                          {currentModule.steps && (
                            <div className={styles.ProgressSegments}>
                              <ProgressSegments
                                segments={currentModule.steps.map(step =>
                                  step.complete
                                    ? ProgressSegmentType.Success
                                    : ProgressSegmentType.Incomplete,
                                )}
                                background="var(--white-opacity-20)"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <span className={styles.ModuleName}>
                    {essential.complete ? (
                      <>
                        Level 2 - <strong>Using Sparx Reader</strong>
                      </>
                    ) : (
                      <>
                        Level 1 - <strong>Essential Training</strong>
                      </>
                    )}
                  </span>
                  {currentModule.steps && (
                    <div className={styles.ProgressSegments}>
                      <ProgressSegments
                        segments={currentModule.steps.map(step =>
                          step.complete
                            ? ProgressSegmentType.Success
                            : ProgressSegmentType.Incomplete,
                        )}
                        background="var(--white-opacity-20)"
                      />
                    </div>
                  )}
                </>
              )}

              <DepartmentCompletion width={width} />
            </div>
            <div className={classNames(styles.Actions, { [styles.ActionsWide]: width === 'wide' })}>
              {completeStepsCount === 0 ? (
                <p className={styles.Next}>
                  <em>Up first: What is Sparx Reader?</em>
                </p>
              ) : (
                <p className={styles.Next}>
                  <em>Up next: {firstIncompleteStep?.spec?.title}</em>
                </p>
              )}

              <Link to={linkPath} analyticsEvent={clickedButton()}>
                <Button
                  variant="inverse"
                  analyticsEvent={undefined}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  {completeStepsCount > 0 ? 'Continue training' : 'Start essential training'}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DepartmentCompletion = ({ width }: { width: 'narrow' | 'wide' }) => {
  const { data: deptCompletion, isLoading, isError } = useAllStaffTrainingCompletion();
  const { data: staffMembers } = useListReaderStaff();

  if (isError) {
    return <div className={styles.DeptCompletion}>Could not load department completion</div>;
  }
  if ((staffMembers || []).length > 0 && isLoading) {
    return (
      <div
        className={classNames(styles.LoadingDeptCompletion, {
          [styles.DeptCompletionWide]: width === 'wide',
        })}
      >
        <Loading white className={styles.LoadingSpinner} />
      </div>
    );
  }
  if (!deptCompletion || !deptCompletion.length) {
    return null;
  }

  const completeCount = deptCompletion.filter(c => c).length;
  const totalCount = deptCompletion.length;

  return (
    <div
      className={classNames(styles.DeptCompletion, {
        [styles.DeptCompletionWide]: width === 'wide',
      })}
    >
      <p className={styles.DeptCompletionTitle}>Department completion</p>
      <div className={styles.CompletionContainer}>
        <>
          {deptCompletion.length > maxDeptCompletionIcons ? (
            <div className={styles.CompletionBar}>
              <ProgressBar
                percent={(100 * completeCount) / totalCount}
                className={styles.CompletionBarBackground}
                barClassName={styles.CompletionBarForeground}
                width="100%"
                hideTick
              />
            </div>
          ) : (
            <div className={styles.CompletionIcons} style={{ width: `calc(${totalCount} * 28px)` }}>
              {deptCompletion.map((complete, i) => (
                <span
                  key={i}
                  className={classNames(styles.UserIcon, {
                    [styles.UserComplete]: complete,
                  })}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
              ))}
            </div>
          )}
          <div className={styles.CompletionCount}>
            {completeCount}/{totalCount}
          </div>
        </>
      </div>
    </div>
  );
};
