// Import PointerEvents polyfill for safari
import 'pepjs';
// Import IntersectionObserver polyfill
import 'intersection-observer/intersection-observer';
// Run code for compatibility with Google Translate and other extensions...
import './nodeRemoveFix';
import './index.css';

import * as Sentry from '@sentry/react';
import App from 'app/app';
import { SentryRootFallback } from 'app/errors/errors';
import { createRoot } from 'react-dom/client';
// Import ResizeObserver polyfill (for old Safari)
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { setupHotjar } from 'utils/hotjar';
import { isProdEnv, isTestEnv } from 'utils/settings';

// eslint-disable-next-line compat/compat
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

// Declare window types
declare global {
  interface Window {
    READER_SCHOOL_ID?: string;
    // https://help.hotjar.com/hc/en-us/articles/360034216634-Hotjar-JavaScript-Functions-Reference
    hj?: (event: string, exampleAction?: string) => void;
    settings?: {
      readerEnv: string;
      gaPropertyID: string;
      apiURL: string;
      tokenURL: string;
      interactionGatewayURL?: string;
      selectSchoolURL?: string;
      teacherApiURL?: string;
      assessmentsApiURL?: string;
      tpStudentApiURL?: string;
      trainingProgressApiURL?: string;
      schoolStatusApiURL?: string;
      schoolsApiURL?: string;
      indexweaverApiURL?: string;
      studentApiURL?: string;
      cannyAppID?: string;
      cannyFeedbackToken?: string;
      sparxLearningApi?: string;
      reportGenApiURL?: string;
    };
    toggleDevtools?: () => void;
  }
}

declare global {
  interface Window {
    // This should always be defined as it's loaded in the head, but it's possible it may
    // fail so we tell typescript it may be undefined.
    mw?: never;
  }
}

if (isProdEnv() || isTestEnv()) {
  setupHotjar();
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root element found');
}

const root = createRoot(container);
root.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <SentryRootFallback error={error} componentStack={componentStack} />
    )}
  >
    <App />
  </Sentry.ErrorBoundary>,
);
