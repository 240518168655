import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'components/buttonsv2';
import { Link } from 'components/link';
import { HeaderButton, HeaderPortal } from 'components/sections/header';
import { formatISO } from 'date-fns';
import { AnimatePresence } from 'motion/react';
import * as motion from 'motion/react-client';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useClickAwayListener, useWindowSize } from 'utils/hooks';
import styles from 'views/teacher/components/teacher-sidebar.module.css';
import homeViewStyles from 'views/teacher/home-view/home-view.module.css';

import { ClassSelectModal } from '../home-view/no-classes/no-classes';
import {
  useNoClassesLocalStorageReminder,
  useNoClassesLocalStorageWidget,
} from '../home-view/utils';

interface ICollapsibleSidebarProps {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  secondaryHidden?: boolean;
  secondarySelect?: boolean;
  allowToggle?: boolean;
  // Whether to show a dismissable pop-over message about assigning yourself to classes.
  showClassAssignmentReminder?: boolean;
}

const sidebarCookie = 'spxrdr_sidebar_hidden';

export const CollapsibleSidebar = ({
  primary,
  secondary,
  secondaryHidden,
  secondarySelect,
  allowToggle,
  showClassAssignmentReminder = false,
}: ICollapsibleSidebarProps) => {
  const windowSize = useWindowSize();
  const [showSidebarToggle, setShowSidebarToggle] = useState(false);
  useEffect(
    () => setShowSidebarToggle(Boolean(windowSize.width && windowSize.width <= 1000)),
    [windowSize],
  );

  const [sidebarVisible, setSidebarVisible] = useState(false);
  useClickAwayListener(setSidebarVisible, sidebarVisible, (ev: MouseEvent) => {
    const clickInSidebar = ev.target instanceof Element && ev.target.closest(`[data-prevent-hide]`);
    return !clickInSidebar;
  });

  const [cookies, setCookie] = useCookies([sidebarCookie]);
  const sidebarHidden = Boolean(cookies[sidebarCookie]);
  const toggleSidebarHidden = () => {
    if (sidebarHidden) {
      setCookie(sidebarCookie, '');
    } else {
      setCookie(sidebarCookie, 'true');
    }
  };

  const [noClassesWidgetDismissed] = useNoClassesLocalStorageWidget();
  const [dismissedClassReminder, setDismissedClassReminder] = useNoClassesLocalStorageReminder();

  const showClassReminder =
    showClassAssignmentReminder && !noClassesWidgetDismissed && !dismissedClassReminder;

  const sidebarCollapsable = showSidebarToggle || sidebarHidden;
  return (
    <div
      className={classNames(styles.TeacherSidebar, {
        [styles.TeacherSidebarShow]: sidebarVisible,
        [styles.TeacherSidebarCollapsible]: sidebarCollapsable,
      })}
    >
      {sidebarCollapsable && (
        <HeaderPortal>
          <HeaderButton
            icon={faBars}
            onClick={e => {
              e.stopPropagation();
              setSidebarVisible(!sidebarVisible);
            }}
          />
        </HeaderPortal>
      )}

      <div className={styles.TeacherSidebarMain}>{primary}</div>

      <div
        className={classNames(styles.TeacherSidebarScrollable, {
          [styles.TeacherSidebarScrollableClassSelect]: secondarySelect,
          [styles.TeacherSidebarScrollableClassHidden]: secondaryHidden,
        })}
      >
        {secondary}
        <AnimatePresence>
          {showClassReminder && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ClassAssignmentReminder
                dismissReminder={(now: Date) => setDismissedClassReminder(formatISO(now))}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {allowToggle && !showSidebarToggle && (
        <div className={styles.TeacherSidebarAfter} data-prevent-hide={true}>
          <SidebarPrimaryButton
            onClick={toggleSidebarHidden}
            name={`${sidebarHidden ? 'Show' : 'Hide'} sidebar`}
            icon={sidebarHidden ? faChevronRight : faChevronLeft}
          />
        </div>
      )}
    </div>
  );
};

type CustomIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

interface ISidebarPrimaryLinkProps {
  to: string;
  name: string;
  icon?: IconDefinition;
  customIcon?: CustomIcon;
  active?: boolean;
  preventHide?: boolean;
  showNewChip?: boolean;
}

export const SidebarPrimaryLink = ({
  to,
  active,
  icon,
  customIcon: CustomIcon,
  name,
  preventHide,
  showNewChip = false,
}: ISidebarPrimaryLinkProps) => {
  const location = useLocation();
  const showActive = active || location.pathname === to;
  return (
    <Link
      to={to}
      className={classNames(styles.TeacherSidebarButton, styles.TeacherSidebarButtonLarge, {
        [styles.TeacherSidebarButtonActive]: showActive,
      })}
      analyticsEvent={{ category: 'navigation', action: 'teacher sidebar click', labels: { name } }}
      {...(preventHide ? { 'data-prevent-hide': 'true' } : {})}
      data-testid={`teacher-sidebar-link-${name}`}
    >
      {CustomIcon && <CustomIcon className={styles.TeacherSidebarButtonIcon} />}
      {icon && <FontAwesomeIcon icon={icon} fixedWidth={true} />}
      <div className={styles.Content}>
        {name}
        {showNewChip && (
          <div
            className={homeViewStyles.NewFeatureIcon}
            style={{
              padding: '1px 4px',
              position: 'absolute',
              right: '-15px',
              fontSize: '0.75rem',
            }}
          >
            NEW
          </div>
        )}
      </div>
    </Link>
  );
};

interface ISidebarPrimaryButtonProps {
  name: string;
  icon?: IconDefinition;
  customIcon?: CustomIcon;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const SidebarPrimaryButton = ({
  name,
  icon,
  customIcon: CustomIcon,
  onClick,
}: ISidebarPrimaryButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.TeacherSidebarButton, styles.TeacherSidebarButtonLarge)}
      role="button"
    >
      {CustomIcon && <CustomIcon className={styles.TeacherSidebarButtonIcon} />}
      {icon && <FontAwesomeIcon icon={icon} fixedWidth={true} />}
      <span>{name}</span>
    </div>
  );
};

type ClassAssignmentReminderProps = {
  dismissReminder: (now: Date) => void;
};

const ClassAssignmentReminder = ({ dismissReminder }: ClassAssignmentReminderProps) => {
  const [classSelectModalOpen, setClassSelectModalOpen] = useState(false);
  return (
    <>
      <div
        className={styles.ClassAssignmentReminder}
        // Prevent hiding the menu on narrow screens when dismissing this reminder.
        onClick={e => e.stopPropagation()}
      >
        <Button
          analyticsEvent={{ category: 'sidebar', action: 'dismiss select classes reminder' }}
          className={styles.ClassAssignmentDismiss}
          onClick={() => dismissReminder(new Date())}
          variant="link"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <span>Bring your classes to the top</span>
        <Button
          analyticsEvent={{ category: 'sidebar', action: 'clicked select classes reminder' }}
          className={styles.ClassAssignmentButton}
          onClick={() => setClassSelectModalOpen(true)}
          variant="link"
        >
          Select your classes
        </Button>
      </div>

      <ClassSelectModal isOpen={classSelectModalOpen} setIsOpen={setClassSelectModalOpen} />
    </>
  );
};
