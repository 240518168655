import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/use-client-event';
import { ErrorMessage } from 'components/errors/error-message';
import { combineQueryStates } from 'queries/client';
import { useCanStartNewBook, useListMyLibraryBooks, useListNewBooks } from 'queries/library-books';
import { useFinishOnboarding } from 'queries/session';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { startNewBookEvent } from 'utils/book-events';
import { useBookChoiceConfigFlag } from 'utils/featureflags';
import { SpecialSpinner } from 'views/student/choose-new-book-view/choose-new-book-view';
import { NewBookItem } from 'views/student/choose-new-book-view/new-book-item';

import { TaskComponentProps } from '../../tasks';
import styles from './book-choice.module.css';

const bookCountWord = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export const BookChoiceOnboardingStep = ({ task }: TaskComponentProps) => {
  const finishOnboarding = useFinishOnboarding();
  const { sendEvent } = useClientEvent();
  const { data: newBooks, ...newBooksQueryState } = useListNewBooks();
  const [searchParams] = useSearchParams();

  const bookChoiceConfig = useBookChoiceConfigFlag();
  const bookChoiceAlgoVersion = bookChoiceConfig?.version;

  const canStartNewBook = useCanStartNewBook();
  const { data: myLibraryBooks, ...myBooksQueryState } = useListMyLibraryBooks();
  const { isLoading, isError } = combineQueryStates(newBooksQueryState, myBooksQueryState);
  const [showSpecialSpinner, setShowSpecialSpinner] = useState(true);

  const numNewBooks = newBooks?.metadataAbridged?.length || 0;
  const numMyBooks = myLibraryBooks?.length || 0;
  const trainingSrc = searchParams.get('src') === 'training';

  useEffect(() => {
    setTimeout(() => {
      setShowSpecialSpinner(false);
    }, 2000);
  }, []);

  if (isError) {
    return (
      <ErrorMessage message="Sorry, there's been a problem. Please refresh or try again later." />
    );
  }

  const anyBooks = !!numNewBooks || !!numMyBooks || false;
  if (isLoading || (anyBooks && showSpecialSpinner)) {
    return <SpecialSpinner />;
  }

  // Mark onboarding as complete when a book is selected & navigate to the reading view
  const onSelectBook = (doOpenBook: () => void) => {
    finishOnboarding.mutate({ overrideNavigationFn: doOpenBook });
  };

  if (task.isComplete && numMyBooks) {
    return (
      <div className={styles.ChoiceContainer}>
        <h3 className={styles.ReadyText}>
          You&apos;re ready to start reading with Sparx Reader.{' '}
          {numMyBooks > 1 ? 'Here are your first books!' : 'Here is your first book!'}
        </h3>
        <div className={styles.Items}>
          {myLibraryBooks?.map(
            book =>
              book.metadataAbridged && (
                <NewBookItem
                  key={book.metadataAbridged?.name}
                  book={book.metadataAbridged}
                  otherBooks={[]}
                  onConfirmAlternateAction={onSelectBook}
                />
              ),
          )}
        </div>
      </div>
    );
  }

  if (!canStartNewBook) {
    return (
      <div className={styles.ChoiceContainer}>
        <h3 className={styles.ReadyText}>You&apos;re ready to start reading!</h3>
        <Button
          analyticsEvent={undefined}
          onClick={() => finishOnboarding.mutate({})}
          variant={trainingSrc ? 'secondary' : 'primary'}
        >
          Start Sparx Reader
        </Button>
      </div>
    );
  }

  let bookPick = `and it's time to choose your first book from these ${bookCountWord[numNewBooks] ?? ''} options!`;
  if (numNewBooks === 1) {
    bookPick = "and we've chosen this book for you!";
  }
  return (
    <div className={styles.ChoiceContainer}>
      <h3 className={styles.ReadyText}>
        You&apos;re ready to start reading with Sparx Reader, {bookPick}
      </h3>
      <div className={styles.Items}>
        {newBooks?.metadataAbridged?.map(book => {
          const otherBooks = newBooks.metadataAbridged.filter(
            newBook => newBook.name !== book.name,
          );
          return (
            <NewBookItem
              key={book.name}
              book={book}
              otherBooks={otherBooks}
              onConfirmAlternateAction={doOpenBook => {
                sendEvent(
                  startNewBookEvent(
                    book.name,
                    otherBooks.map(b => b.name),
                    bookChoiceAlgoVersion,
                    true,
                  ),
                );
                onSelectBook(doOpenBook);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
