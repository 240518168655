import { RatingButton, RatingImage } from 'components/buttons/rating-button';
import React from 'react';

import { DifficultyRatings } from './types';

interface IHappinessRatingProps {
  rating: number;
  onChange: (rating: number) => void;
  className?: string;
}

export const HappinessRating: React.FC<IHappinessRatingProps> = ({
  rating,
  onChange,
  className,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
      }}
      className={className}
    >
      {[1, 2, 3, 4, 5].map(value => {
        const r = DifficultyRatings[value];
        return (
          <RatingButton
            key={value}
            image={r.image}
            selected={rating === value}
            onClick={() => onChange(value)}
          >
            {r.message.map((x, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <br />}
                {x}
              </React.Fragment>
            ))}
          </RatingButton>
        );
      })}
    </div>
  );
};

export const HappinessRatingValue: React.FC<{ value: number; hideText?: boolean }> = ({
  value,
  hideText,
}) => {
  const rating = DifficultyRatings[value];
  if (!rating) return null;
  return (
    <>
      <RatingImage image={rating.image} />
      {!hideText && rating.message.join(' ')}
    </>
  );
};
