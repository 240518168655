import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@sparx/design/components';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './performance-mode.module.css';

// In LocalStorage, we store whether performance mode is enabled and also remember the number of splits the user has chosen.
const PerformanceModeEnabledLocalStorageKey = 'PerformanceModeEnabled';
const PerformanceModeSplitsLocalStorageKey = 'PerformanceModeSplits';

export type PerformanceModeState = {
  // Enabling/disabling performance mode.
  performanceModeEnabled: boolean;
  setPerformanceModeEnabled: (enabled: boolean) => void;

  // The number of "chunks" (effectively React Nodes) being displayed, this includes book text, breakpoints, word counts.
  chunkCount: number;
  setChunkCount: (count: number) => void;

  // The chunk to start displaying from and the number of chunks to display.
  startChunk: number;
  setStartChunk: (start: number) => void;
  chunksToDisplay: number;
  setChunksToDisplay: (size: number) => void;

  // A mapping from section ID to the index of the chunk - allows skipping to chapters.
  sectionIDToChunkIndex: Record<string, number | undefined>;
  setSectionIDToChunkIndex: (chunkIDToChunkIndex: Record<string, number | undefined>) => void;
  // A function to call to navigate to a specific section of the book, e.g. a chapter.
  navigateToSection: (sectionID: string) => void;

  // The number of parts to split the book into and the current split being
  // displayed. These settings are the high level controls that the user has
  // access to and we update the more low level "startChunk" and
  // "chunksToDisplay" based on these.
  bookSplits: number;
  setBookSplits: (bookSplits: number) => void;
  currentBookSplit: number;
  setCurrentBookSplit: (currentBookSplit: number) => void;
  // A convenience function that sets the two settings above at once.
  setSplitState: (bookSplits: number, currentBookSplit: number) => void;
};

const usePerformanceModeState = (): PerformanceModeState => {
  const [performanceModeEnabled, setPerformanceModeEnabled] = useState(
    localStorage.getItem(PerformanceModeEnabledLocalStorageKey) === 'True' || false,
  );
  const location = useLocation();
  const navigate = useNavigate();

  const [chunkCount, setChunkCount] = useState(0);

  const [startChunk, setStartChunk] = useState(0);
  const [chunksToDisplay, setChunksToDisplay] = useState(0);

  const [sectionIDToChunkIndex, setSectionIDToChunkIndex] = useState<
    Record<string, number | undefined>
  >({});

  const [bookSplits, setBookSplits] = useState<number>(
    parseInt(localStorage.getItem(PerformanceModeSplitsLocalStorageKey) || '0') || 10,
  );
  const [currentBookSplit, setCurrentBookSplit] = useState<number>(1);
  const setSplitState = useCallback(
    (newBookSplits: number, newCurrentBookSplit: number) => {
      setStartChunk(Math.ceil(chunkCount / newBookSplits) * (newCurrentBookSplit - 1));
      setChunksToDisplay(Math.ceil(chunkCount / newBookSplits));
      setBookSplits(newBookSplits);
      setCurrentBookSplit(newCurrentBookSplit);
      if (newBookSplits !== bookSplits) {
        const scrollingElement = document.getElementById('performance-mode-scroll-container');
        if (scrollingElement) {
          scrollingElement.scrollTo(0, 0);
        }
      }
    },
    [chunkCount, bookSplits, setStartChunk, setChunksToDisplay, setBookSplits, setCurrentBookSplit],
  );

  useEffect(() => {
    // When the chunk count changes (in particular when it first loads), re-split the book.
    setSplitState(bookSplits, currentBookSplit);
  }, [chunkCount, bookSplits, currentBookSplit, setSplitState]);

  // Update whether performance mode is enabled in LocalStorage.
  useEffect(() => {
    localStorage.setItem(
      PerformanceModeEnabledLocalStorageKey,
      performanceModeEnabled ? 'True' : 'False',
    );
  }, [performanceModeEnabled]);

  // Update the number of splits the user has chosen in LocalStorage.
  useEffect(() => {
    localStorage.setItem(PerformanceModeSplitsLocalStorageKey, bookSplits.toString());
  }, [bookSplits]);

  return {
    performanceModeEnabled,
    setPerformanceModeEnabled,

    chunkCount,
    setChunkCount,

    startChunk,
    setStartChunk,
    chunksToDisplay,
    setChunksToDisplay,

    sectionIDToChunkIndex,
    setSectionIDToChunkIndex,
    navigateToSection: (sectionID: string) => {
      const chunkIndex = sectionIDToChunkIndex[sectionID];
      if (chunkIndex !== undefined) {
        // Navigate to the current page, but without the #<sectionID> part. If
        // we kept the section ID in the URL in performance mode, then whenever
        // you navigated to a different part of the book, we'd take you back to
        // the section in the URL!
        navigate(location.pathname);
        const splitThatTheChunkIsIn = Math.floor(chunkIndex / chunksToDisplay);
        setSplitState(bookSplits, splitThatTheChunkIsIn + 1);
        setTimeout(() => {
          // Scroll to that section after the split that contains it has been loaded.
          const element = document.getElementById(sectionID);
          if (element) element.scrollIntoView();
        }, 0);
      }
    },

    bookSplits,
    setBookSplits,
    currentBookSplit,
    setCurrentBookSplit,
    setSplitState,
  };
};

export const PerformanceModeContext = React.createContext<PerformanceModeState | undefined>(
  undefined,
);

/**
 * PerformanceModeProvider provides access to the performance mode context to all child components.
 */
export const PerformanceModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const state = usePerformanceModeState();
  return (
    <PerformanceModeContext.Provider value={state}>{children}</PerformanceModeContext.Provider>
  );
};

/**
 * PerformanceModeSettings is a component that allows the user to enable/disable
 * performance mode and control which bit of the book they're looking at.
 */
export const PerformanceModeSettings = () => {
  const state = useContext(PerformanceModeContext);

  if (!state) {
    return null;
  }

  return (
    <>
      <div>
        <Button
          onClick={() => state.setPerformanceModeEnabled(!state.performanceModeEnabled)}
          size="small"
          analyticsEvent={undefined}
        >
          {state.performanceModeEnabled
            ? 'Disable "Performance Mode"'
            : 'Enable "Performance Mode"'}
        </Button>
      </div>
      {state.performanceModeEnabled && (
        <>
          <div className={styles.BookSplitField}>
            <label className={styles.BookSplitLabel} htmlFor="book_splits">
              Split book:
            </label>
            <Select
              label="split book"
              value={state.bookSplits.toString()}
              triggerClassName={styles.BookSplitDropdown}
              portalClassName={styles.BookSplitPortal}
              name={'book_splits'}
              onChange={value => state.setSplitState(parseInt(value), 1)}
              groups={[
                {
                  items: [
                    { value: '1', text: '1' },
                    { value: '2', text: '2' },
                    { value: '3', text: '3' },
                    { value: '4', text: '4' },
                    { value: '5', text: '5' },
                    { value: '10', text: '10' },
                    { value: '20', text: '20' },
                    { value: '40', text: '40' },
                    { value: '100', text: '100' },
                  ],
                },
              ]}
            />
          </div>
          <div>
            <label className={styles.BookSplitLabel} htmlFor="current_book_split">
              Navigate:
            </label>
            <PerformanceModePrevious />
            <input
              id="current_book_split"
              name="current_book_split"
              type="number"
              min="1"
              max={state.bookSplits}
              value={state.currentBookSplit}
              onChange={e => {
                const newBookSplit = parseInt(e.target.value);
                state.setSplitState(state.bookSplits, Math.min(newBookSplit, state.bookSplits));
              }}
              className={styles.BookSplitNumber}
            />
            <PerformanceModeNext />
          </div>
        </>
      )}
    </>
  );
};

/**
 * PerformanceModePrevious is a button that allows the user to navigate to the previous bit of the book.
 * It's a shared component as there are two ways to go back - in the settings and at the top of the book content.
 */
export const PerformanceModePrevious = (props: {
  children?: React.ReactNode;
  fullWidth?: boolean;
}) => {
  const state = useContext(PerformanceModeContext);

  if (!state) {
    return null;
  }

  return (
    <button
      className={classNames(
        styles.BookSplitNavigate,
        props.fullWidth && styles.BookSplitNavigateInPage,
      )}
      onClick={() => {
        const newBookSplit = Math.max(1, state.currentBookSplit - 1);
        if (newBookSplit !== state.currentBookSplit) {
          state.setSplitState(state.bookSplits, newBookSplit);
          // We scroll to the bottom of the page after the page has re-rendered in case the size of the page changes.
          setTimeout(() => {
            const scrollingElement = document.getElementById('performance-mode-scroll-container');
            if (scrollingElement) {
              scrollingElement.scrollTo(0, scrollingElement.scrollHeight);
            }
          }, 0);
        }
      }}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
      {props.children}
    </button>
  );
};

/**
 * PerformanceModeNext is a button that allows the user to navigate to the next bit of the book.
 * It's a shared component as there are two ways to go forward - in the settings and at the bottom of the book content.
 */
export const PerformanceModeNext = (props: { children?: React.ReactNode; fullWidth?: boolean }) => {
  const state = useContext(PerformanceModeContext);

  if (!state) {
    return null;
  }

  return (
    <button
      className={classNames(
        styles.BookSplitNavigate,
        props.fullWidth && styles.BookSplitNavigateInPage,
      )}
      onClick={() => {
        const newBookSplit = Math.min(state.bookSplits, state.currentBookSplit + 1);
        if (newBookSplit !== state.currentBookSplit) {
          state.setSplitState(state.bookSplits, newBookSplit);
          const scrollingElement = document.getElementById('performance-mode-scroll-container');
          if (scrollingElement) {
            scrollingElement.scrollTo(0, 0);
          }
        }
      }}
    >
      {props.children}
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );
};
