import styles from './shared.module.css';

export const NotAuthed = () => (
  <>
    <p>
      <strong>We can&apos;t currently access data about classes in your school&apos;s MIS.</strong>
    </p>
    <br />
    <p>
      <strong>What&apos;s next?</strong>
    </p>
    <div className={styles.Box}>
      <p className={styles.Subtle}>
        <em>
          Our MIS connection partner, Wonde, have received your approval for us to access your
          school&apos;s data but they need to complete some checks before we can connect to your
          MIS/SIS. This can take up to 72 hours.
        </em>
      </p>
    </div>
  </>
);
