import { faBell, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationWithContext } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import classNames from 'classnames';
import { useAlert } from 'components/alert/hooks';
import { WarningAlert } from 'components/alert/warning-alert';
import { useClientEvent } from 'components/client-events/use-client-event';
import { BankedRewardNotification } from 'components/notifications/banked-reward-notification';
import { BookNotification } from 'components/notifications/book-notification';
import { CustomNotification } from 'components/notifications/custom-notification';
import { GoldReaderDisabledNotification } from 'components/notifications/gold-reader-disabled-notification';
import { GoldStarNotification } from 'components/notifications/gold-star-notification';
import { INotificationWrapperProps } from 'components/notifications/notification';
import { WarningNotification } from 'components/notifications/warning-notification';
import { WhatsNewNotification } from 'components/notifications/whats-new-notification';
import { useFirstWarningNotificationUnread, useNotifications } from 'queries/notifications';
import { useState } from 'react';
import { useClickAwayListener } from 'utils/hooks';

import styles from './notifications.module.css';

const MAX_NOTIFICATION_COUNT = 9;

export const NotificationContainer = () => {
  const { sendEvent } = useClientEvent();
  const setAlert = useAlert();
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
  useClickAwayListener(setNotificationsOpen, notificationsOpen);

  // Check whether there is an unread warning notification and set the alert if
  // there is.
  useFirstWarningNotificationUnread((notification: NotificationWithContext) => {
    if (notification?.notification?.notification.oneofKind === 'warning') {
      setAlert(
        <WarningAlert
          notification={notification}
          warning={notification.notification?.notification.warning}
        />,
        {
          modalClass: styles.WarningModalOverride,
        },
      );
    }
  });

  const { data: notifications, isLoading, error } = useNotifications();
  notifications?.sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0) || 0);

  const notificationElements =
    notifications?.map(n => <NotificationWrapper notification={n} key={n.notificationId} />) || [];
  const showBadge =
    notifications?.find((a, i) => !a.dismissed && notificationElements[i] !== null) !== undefined;

  const toggleNotifications = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!notificationsOpen) {
      sendEvent({ category: 'notifications', action: 'open' });
    }
    setNotificationsOpen(!notificationsOpen);
  };

  const unreadNotifications = notifications?.filter(
    (notification, i) => !notification.dismissed && notificationElements[i] !== null,
  );

  const loaded = !isLoading && !error;

  return (
    <div className={styles.NotificationContainer}>
      <div className={styles.Notifications} onClick={toggleNotifications}>
        <FontAwesomeIcon
          icon={faBell}
          className={showBadge ? styles.NotificationBell : styles.NotificationBellNone}
        />
        {showBadge && (
          <div className={styles.NotificationBadge}>
            {(unreadNotifications?.length || 0) > MAX_NOTIFICATION_COUNT ? (
              <div className={styles.Dot} />
            ) : (
              unreadNotifications?.length
            )}
          </div>
        )}
        {isLoading && (
          <FontAwesomeIcon icon={faSpinner} spin={true} className={styles.NotificationLoading} />
        )}
      </div>
      <div className={classNames(styles.MenuList, notificationsOpen && styles.MenuListOpen)}>
        {isLoading && <div className={styles.NoNotifications}>Loading...</div>}
        {Boolean(error) && (
          <div className={styles.NoNotifications}>Error loading notifications</div>
        )}
        {loaded && (
          <>
            {notifications?.length === 0 && (
              <div className={styles.NoNotifications}>You have no notifications.</div>
            )}
            {notificationElements}
          </>
        )}
      </div>
    </div>
  );
};

const NotificationWrapper: React.FC<INotificationWrapperProps> = ({
  notification: notificationWithContext,
}) => {
  const notification = notificationWithContext.notification?.notification;
  switch (notification?.oneofKind) {
    case 'book':
      return <BookNotification book={notification.book} notification={notificationWithContext} />;
    case 'custom':
      return (
        <CustomNotification custom={notification.custom} notification={notificationWithContext} />
      );
    case 'star':
      return (
        <GoldStarNotification star={notification.star} notification={notificationWithContext} />
      );
    case 'disabled':
      return <GoldReaderDisabledNotification notification={notificationWithContext} />;
    case 'bankedReward':
      return (
        <BankedRewardNotification
          bankedReward={notification.bankedReward}
          notification={notificationWithContext}
        />
      );
    case 'warning':
      return (
        <WarningNotification
          warning={notification.warning}
          notification={notificationWithContext}
        />
      );
    case 'whatsNew':
      return (
        <WhatsNewNotification
          whatsNew={notification.whatsNew}
          notification={notificationWithContext}
        />
      );
  }
  return null;
};
