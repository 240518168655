import { getSchool } from '@sparx/query/schools-service';
import { Button } from 'components/buttonsv2';
import { useClientEvent } from 'components/client-events/use-client-event';
import { atom, useAtom } from 'jotai';
import { combineQueryStates } from 'queries/client';
import { useMyEnglishGroups } from 'queries/management';
import { useSchoolCompletedBookCount } from 'queries/school-metrics';
import { useRef, useState } from 'react';
import { sleepMs } from 'utils/sleep';
import { useSparxStaffFeatures } from 'views/teacher/components/sparx-staff-features-context';

import bookImg from './book.png';
import clockImg from './clock.png';
import { clickedSelectClasses, downloadedAsImageEvent } from './events';
import styles from './praise-stats.module.css';
import { WidgetConfig } from './types';
import { downloadWidgetAsImage, shouldShowPraiseStats, useReadingHoursThisYear } from './utils';
import bookIcon from './widget/books_read.svg';
import bookIconClassSpecific from './widget/books_read_ondark.svg';
import srpIcon from './widget/clock.svg';
import srpIconClassSpecific from './widget/clock_ondark.svg';
import { DownloadableWidget } from './widget/downloadable-widget';
import { Widget } from './widget/widget';

const animatedCountAtom = atom(0);

type PraiseStatsProps = {
  // Whether the widgets only show stats for classes assigned to the current teacher.
  classSpecific: boolean;
  // Whether to show the empty version of this prompting user to choose classes.
  showEmptyState?: boolean;
  openClassSelectModal?: () => void;
};

export const PraiseStats = ({
  classSpecific: filterToMyGroups,
  showEmptyState,
  openClassSelectModal,
}: PraiseStatsProps) => {
  const { data: school } = getSchool.useQuery();
  const { sendEvent } = useClientEvent();
  const { sparxStaffFeaturesEnabled } = useSparxStaffFeatures();

  const [animatedCount, setAnimatedCount] = useAtom(animatedCountAtom);
  const { data: completedBooks, ...completedBookCountQueryState } = useSchoolCompletedBookCount();
  const [downloadConfig, setDownloadConfig] = useState<WidgetConfig>();
  const downloadableWidgetRef = useRef<HTMLDivElement>(null);
  const { data: myGroups, ...myGroupsQueryState } = useMyEnglishGroups();
  const myGroupIDs = myGroups?.map(g => g.name.split('/')[3]) || [];
  const classSpecific = filterToMyGroups && myGroupIDs.length > 0;
  const {
    readingHoursThisYear: myClassesReadingHoursThisYear,
    ...myClassesReadingHoursThisYearQueryState
  } = useReadingHoursThisYear(myGroupIDs);
  const {
    readingHoursThisYear: allClassesReadingHoursThisYear,
    ...allClassesReadingHoursThisYearQueryState
  } = useReadingHoursThisYear(undefined);

  const { isLoading, isError } = combineQueryStates(
    completedBookCountQueryState,
    myClassesReadingHoursThisYearQueryState,
    allClassesReadingHoursThisYearQueryState,
    myGroupsQueryState,
  );

  let myClassesBookCount = 0;
  for (const id of myGroupIDs) {
    myClassesBookCount += completedBooks?.countsByClass[id] ?? 0;
  }
  const completeBooksCount = classSpecific
    ? myClassesBookCount
    : completedBooks?.completedBookCount || 0;
  const hoursReading = classSpecific
    ? myClassesReadingHoursThisYear
    : allClassesReadingHoursThisYear;

  if (
    isLoading ||
    isError ||
    !school ||
    // Whether the class specific praise stats show is based on counts for all classes
    !shouldShowPraiseStats(
      sparxStaffFeaturesEnabled,
      completedBooks?.completedBookCount || 0,
      allClassesReadingHoursThisYear,
    )
  ) {
    return null;
  }

  const onDownloadClick = async (config: WidgetConfig) => {
    setDownloadConfig(config);

    await sleepMs(200);
    if (downloadableWidgetRef.current) {
      await downloadWidgetAsImage(school.displayName, config, downloadableWidgetRef.current);
      sendEvent(downloadedAsImageEvent(config));
    }

    setDownloadConfig(undefined);
  };

  const widgetConfigs: WidgetConfig[] = [
    {
      id: 'Hours read this year',
      topLine: classSpecific
        ? 'Students in your classes have completed'
        : 'Students in your school have completed',
      bottomLine: 'of careful reading this year in Sparx Reader. Great effort!',
      count: hoursReading,
      units: Math.ceil(hoursReading) === 1 ? 'hour' : 'hours',
      icon: classSpecific ? srpIconClassSpecific : srpIcon,
    },
    {
      id: 'Books read this year',
      topLine: classSpecific
        ? 'Students in your classes have read'
        : 'Students in your school have read',
      bottomLine: 'this year in Sparx Reader. Well done!',
      count: completeBooksCount,
      units: completeBooksCount === 1 ? 'book' : 'books',
      icon: classSpecific ? bookIconClassSpecific : bookIcon,
    },
  ];

  if (showEmptyState) {
    return (
      <div className={styles.Container}>
        <h3 className={styles.Title}>Praise</h3>
        <div className={styles.Grid}>
          <div className={styles.EmptyWidget}>
            <img src={clockImg} alt="Clock" />
            <p>
              See how many hours of careful reading your students have done this year in Sparx
              Reader
            </p>
            <p>
              <Button
                variant="link"
                analyticsEvent={clickedSelectClasses()}
                onClick={() => openClassSelectModal && openClassSelectModal()}
                className={styles.ButtonLink}
              >
                Select your classes
              </Button>{' '}
              to view this section
            </p>
          </div>
          <div className={styles.EmptyWidget}>
            <img src={bookImg} alt="Book" />
            <p>See how many books your students have read this year in Sparx Reader</p>
            <p>
              <Button
                variant="link"
                analyticsEvent={clickedSelectClasses()}
                onClick={() => openClassSelectModal && openClassSelectModal()}
                className={styles.ButtonLink}
              >
                Select your classes
              </Button>{' '}
              to view this section
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <h3 className={styles.Title}>Praise</h3>

      <div className={styles.Grid}>
        {widgetConfigs.map(widget => (
          <Widget
            key={widget.id}
            config={widget}
            animate={animatedCount < widgetConfigs.length}
            isGenerating={downloadConfig?.id === widget.id}
            onDownloadClick={() => onDownloadClick(widget)}
            onAnimateEnd={() => setAnimatedCount(count => count + 1)}
            classSpecific={classSpecific}
          />
        ))}

        {downloadConfig && (
          <div className={styles.HiddenWidget}>
            <DownloadableWidget
              config={downloadConfig}
              ref={downloadableWidgetRef}
              classSpecific={classSpecific}
            />
          </div>
        )}
      </div>
    </div>
  );
};
