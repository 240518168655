// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/reports/v2/reporting.proto" (package "sparx.reading.reports.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Reporting } from './reporting';
import type { HasHomeworksResponse } from './reporting';
import type { HasHomeworksRequest } from './reporting';
import type { GetBookCompletionSummaryResponse } from './reporting';
import type { GetBookCompletionSummaryRequest } from './reporting';
import type { GetStudentCompletionSummaryResponse } from './reporting';
import type { GetStudentCompletionSummaryRequest } from './reporting';
import type { GetSchoolCompletedBookCountResponse } from './reporting';
import type { GetSchoolCompletedBookCountRequest } from './reporting';
import type { ListLatestStudentEbooksResponse } from './reporting';
import type { ListLatestStudentEbooksRequest } from './reporting';
import type { ListAssessmentPackagesResponse } from './reporting';
import type { ListAssessmentPackagesRequest } from './reporting';
import type { ListAssessmentResultsResponse } from './reporting';
import type { ListAssessmentResultsRequest } from './reporting';
import type { ListHomeworksResponse } from './reporting';
import type { ListHomeworksRequest } from './reporting';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListStudentsResponse } from './reporting';
import type { ListStudentsRequest } from './reporting';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * Reporting provides endpoints for aggregated reading data needed to generate
 * reports.
 *
 * @generated from protobuf service sparx.reading.reports.v2.Reporting
 */
export interface IReportingClient {
  /**
   * ListStudents returns all the students in the school.
   *
   * @generated from protobuf rpc: ListStudents(sparx.reading.reports.v2.ListStudentsRequest) returns (sparx.reading.reports.v2.ListStudentsResponse);
   */
  listStudents(
    input: ListStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentsRequest, ListStudentsResponse>;
  /**
   * ListsHomeworks returns all homeworks in a school since the start of their
   * academic year, along with student progress on each.
   *
   * @generated from protobuf rpc: ListHomeworks(sparx.reading.reports.v2.ListHomeworksRequest) returns (sparx.reading.reports.v2.ListHomeworksResponse);
   */
  listHomeworks(
    input: ListHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworksRequest, ListHomeworksResponse>;
  /**
   * ListAssessmentResults returns reading assessment results for students in
   * the requested school.
   *
   * @generated from protobuf rpc: ListAssessmentResults(sparx.reading.reports.v2.ListAssessmentResultsRequest) returns (sparx.reading.reports.v2.ListAssessmentResultsResponse);
   */
  listAssessmentResults(
    input: ListAssessmentResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentResultsRequest, ListAssessmentResultsResponse>;
  /**
   * ListAssessmentPackages returns all SRT packages for students in the
   * requested school.
   *
   * @generated from protobuf rpc: ListAssessmentPackages(sparx.reading.reports.v2.ListAssessmentPackagesRequest) returns (sparx.reading.reports.v2.ListAssessmentPackagesResponse);
   */
  listAssessmentPackages(
    input: ListAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentPackagesRequest, ListAssessmentPackagesResponse>;
  /**
   * ListLatestStudentEbooks returns book IDs for the most recent ebook that
   * each student has read for all students in the school. It ignores
   * onboarding books and doesn't return anything if a student has never read
   * an ebook.
   *
   * @generated from protobuf rpc: ListLatestStudentEbooks(sparx.reading.reports.v2.ListLatestStudentEbooksRequest) returns (sparx.reading.reports.v2.ListLatestStudentEbooksResponse);
   */
  listLatestStudentEbooks(
    input: ListLatestStudentEbooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListLatestStudentEbooksRequest, ListLatestStudentEbooksResponse>;
  /**
   * GetSchoolCompletedBookCount returns the number of books completed since the given date
   * by all students in the school.`
   *
   * @generated from protobuf rpc: GetSchoolCompletedBookCount(sparx.reading.reports.v2.GetSchoolCompletedBookCountRequest) returns (sparx.reading.reports.v2.GetSchoolCompletedBookCountResponse);
   */
  getSchoolCompletedBookCount(
    input: GetSchoolCompletedBookCountRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSchoolCompletedBookCountRequest,
    GetSchoolCompletedBookCountResponse
  >;
  /**
   * GetStudentCompletionSummary returns the number of students that have not started/started/completed their homework in a given time range.
   * If students have more than one homework due during the time period, then they are considered as:
   *  - Unstarted if _none_ of them are started
   *  - Started if _any_ of them are started
   *  - Completed if _all_ of them are completed
   * If a list of student IDs is supplied then the returned data is filtered to just those students.
   *
   * @generated from protobuf rpc: GetStudentCompletionSummary(sparx.reading.reports.v2.GetStudentCompletionSummaryRequest) returns (sparx.reading.reports.v2.GetStudentCompletionSummaryResponse);
   */
  getStudentCompletionSummary(
    input: GetStudentCompletionSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentCompletionSummaryRequest,
    GetStudentCompletionSummaryResponse
  >;
  /**
   * GetBookCompletionSummary returns the completion information about a student book. If the
   * student has not completed the book, an error will be returned.
   *
   * @generated from protobuf rpc: GetBookCompletionSummary(sparx.reading.reports.v2.GetBookCompletionSummaryRequest) returns (sparx.reading.reports.v2.GetBookCompletionSummaryResponse);
   */
  getBookCompletionSummary(
    input: GetBookCompletionSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetBookCompletionSummaryRequest,
    GetBookCompletionSummaryResponse
  >;
  /**
   * HasHomeworks returns whether any homework exist for a school.
   *
   * @generated from protobuf rpc: HasHomeworks(sparx.reading.reports.v2.HasHomeworksRequest) returns (sparx.reading.reports.v2.HasHomeworksResponse);
   */
  hasHomeworks(
    input: HasHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<HasHomeworksRequest, HasHomeworksResponse>;
}
/**
 * Reporting provides endpoints for aggregated reading data needed to generate
 * reports.
 *
 * @generated from protobuf service sparx.reading.reports.v2.Reporting
 */
export class ReportingClient implements IReportingClient, ServiceInfo {
  typeName = Reporting.typeName;
  methods = Reporting.methods;
  options = Reporting.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * ListStudents returns all the students in the school.
   *
   * @generated from protobuf rpc: ListStudents(sparx.reading.reports.v2.ListStudentsRequest) returns (sparx.reading.reports.v2.ListStudentsResponse);
   */
  listStudents(
    input: ListStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentsRequest, ListStudentsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListStudentsRequest, ListStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListsHomeworks returns all homeworks in a school since the start of their
   * academic year, along with student progress on each.
   *
   * @generated from protobuf rpc: ListHomeworks(sparx.reading.reports.v2.ListHomeworksRequest) returns (sparx.reading.reports.v2.ListHomeworksResponse);
   */
  listHomeworks(
    input: ListHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListHomeworksRequest, ListHomeworksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListHomeworksRequest, ListHomeworksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListAssessmentResults returns reading assessment results for students in
   * the requested school.
   *
   * @generated from protobuf rpc: ListAssessmentResults(sparx.reading.reports.v2.ListAssessmentResultsRequest) returns (sparx.reading.reports.v2.ListAssessmentResultsResponse);
   */
  listAssessmentResults(
    input: ListAssessmentResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentResultsRequest, ListAssessmentResultsResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentResultsRequest,
      ListAssessmentResultsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListAssessmentPackages returns all SRT packages for students in the
   * requested school.
   *
   * @generated from protobuf rpc: ListAssessmentPackages(sparx.reading.reports.v2.ListAssessmentPackagesRequest) returns (sparx.reading.reports.v2.ListAssessmentPackagesResponse);
   */
  listAssessmentPackages(
    input: ListAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentPackagesRequest, ListAssessmentPackagesResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentPackagesRequest,
      ListAssessmentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListLatestStudentEbooks returns book IDs for the most recent ebook that
   * each student has read for all students in the school. It ignores
   * onboarding books and doesn't return anything if a student has never read
   * an ebook.
   *
   * @generated from protobuf rpc: ListLatestStudentEbooks(sparx.reading.reports.v2.ListLatestStudentEbooksRequest) returns (sparx.reading.reports.v2.ListLatestStudentEbooksResponse);
   */
  listLatestStudentEbooks(
    input: ListLatestStudentEbooksRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListLatestStudentEbooksRequest,
    ListLatestStudentEbooksResponse
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListLatestStudentEbooksRequest,
      ListLatestStudentEbooksResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetSchoolCompletedBookCount returns the number of books completed since the given date
   * by all students in the school.`
   *
   * @generated from protobuf rpc: GetSchoolCompletedBookCount(sparx.reading.reports.v2.GetSchoolCompletedBookCountRequest) returns (sparx.reading.reports.v2.GetSchoolCompletedBookCountResponse);
   */
  getSchoolCompletedBookCount(
    input: GetSchoolCompletedBookCountRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSchoolCompletedBookCountRequest,
    GetSchoolCompletedBookCountResponse
  > {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSchoolCompletedBookCountRequest,
      GetSchoolCompletedBookCountResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetStudentCompletionSummary returns the number of students that have not started/started/completed their homework in a given time range.
   * If students have more than one homework due during the time period, then they are considered as:
   *  - Unstarted if _none_ of them are started
   *  - Started if _any_ of them are started
   *  - Completed if _all_ of them are completed
   * If a list of student IDs is supplied then the returned data is filtered to just those students.
   *
   * @generated from protobuf rpc: GetStudentCompletionSummary(sparx.reading.reports.v2.GetStudentCompletionSummaryRequest) returns (sparx.reading.reports.v2.GetStudentCompletionSummaryResponse);
   */
  getStudentCompletionSummary(
    input: GetStudentCompletionSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentCompletionSummaryRequest,
    GetStudentCompletionSummaryResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentCompletionSummaryRequest,
      GetStudentCompletionSummaryResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetBookCompletionSummary returns the completion information about a student book. If the
   * student has not completed the book, an error will be returned.
   *
   * @generated from protobuf rpc: GetBookCompletionSummary(sparx.reading.reports.v2.GetBookCompletionSummaryRequest) returns (sparx.reading.reports.v2.GetBookCompletionSummaryResponse);
   */
  getBookCompletionSummary(
    input: GetBookCompletionSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetBookCompletionSummaryRequest,
    GetBookCompletionSummaryResponse
  > {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetBookCompletionSummaryRequest,
      GetBookCompletionSummaryResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * HasHomeworks returns whether any homework exist for a school.
   *
   * @generated from protobuf rpc: HasHomeworks(sparx.reading.reports.v2.HasHomeworksRequest) returns (sparx.reading.reports.v2.HasHomeworksResponse);
   */
  hasHomeworks(
    input: HasHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<HasHomeworksRequest, HasHomeworksResponse> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<HasHomeworksRequest, HasHomeworksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
