import { ErrorBoundary } from '@sentry/react';
import { TrainingContextProvider } from '@sparx/teacher-training';
import { schoolStaffClient, trainingProgressClient } from 'api';
import { useClientEvent } from 'components/client-events/use-client-event';
import { queryClient } from 'queries/client';
import { useUser } from 'queries/session';
import { PropsWithChildren } from 'react';

import { useTrainingModules } from './hooks';

export const TrainingContext = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const trainingModules = useTrainingModules();
  const { sendEvent } = useClientEvent();

  return (
    <ErrorBoundary>
      <TrainingContextProvider
        value={{
          schoolID: user?.schoolId || '',
          videoAssetDirURL: 'https://static.sparxhomework.uk/assets/reader/training/',
          moduleSpecs: trainingModules,
          queryClient: queryClient,
          trainingProgressClient: trainingProgressClient,
          staffClient: schoolStaffClient,
          sendClientEvent: sendEvent,
        }}
      >
        {children}
      </TrainingContextProvider>
    </ErrorBoundary>
  );
};
