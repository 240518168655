import classNames from 'classnames';

import styles from './pie-timer.module.css';

export const PieTimer = ({
  proportionRemaining,
  visible,
}: {
  proportionRemaining: number;
  visible: boolean;
}) => {
  if (proportionRemaining <= 0) {
    return null;
  }
  const proportionGone = 1 - proportionRemaining;
  return (
    <div
      className={classNames(styles.PieTimer, {
        [styles.PieTimerShowing]: visible,
        [styles.PieTimerGreen]: proportionGone < 0.75,
        [styles.PieTimerAmber]: proportionGone >= 0.75 && proportionGone < 0.9,
        [styles.PieTimerRed]: proportionGone >= 0.9,
      })}
      style={{ '--a': `${proportionGone * 360}deg` } as React.CSSProperties}
    />
  );
};
