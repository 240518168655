import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { BankRewardAlert } from 'components/alert/banking-alert';
import { useAlert } from 'components/alert/hooks';
import { BookImage } from 'components/book/book-image';
import { useBookNotifications } from 'queries/notifications';
import { useUserCanReadBook } from 'queries/session';
import React, { useMemo } from 'react';
import styles from 'views/student/library-view/components/library-book.module.css';

import { BookCorner } from './book-corner';

interface ILibraryBookProps {
  book: LibraryBook;
  onClick: () => void;
  size?: 'small' | 'large';
}

export const LibraryBookItem: React.FC<ILibraryBookProps> = ({ book, onClick, size = 'small' }) => {
  const bookID = book.metadataAbridged?.name.replace('books/', '');
  const bookNotificationMap = useBookNotifications();
  const notifications = useMemo(() => {
    if (bookID) {
      return bookNotificationMap[bookID] || [];
    }
    return [];
  }, [bookNotificationMap, bookID]);

  const { allowedToRead } = useUserCanReadBook(book.metadataAbridged);

  const setAlert = useAlert();
  const onOpen = () => {
    for (const notification of notifications) {
      if (
        notification.notification?.notification.oneofKind === 'bankedReward' &&
        !notification.dismissed &&
        book.metadataAbridged
      ) {
        setAlert(
          <BankRewardAlert
            notification={notification}
            book={book.metadataAbridged}
            reward={notification.notification.notification.bankedReward}
          />,
        );
        return;
      }
    }
    onClick();
  };

  return (
    <div
      className={classNames(
        styles.Book,
        'enter-down-0',
        (book.studentBook?.atContentEnd || !allowedToRead || book.metadataAbridged?.blacklisted) &&
          styles.MissingContent,
      )}
      onClick={onOpen}
      data-test-id={`library-book-${book.metadataAbridged?.name.replace('books/', '')}`}
    >
      {book.metadataAbridged && (
        <BookImage
          book={book.metadataAbridged}
          className={classNames(styles.BookCover, {
            [styles.BookCoverSmall]: size === 'small',
            [styles.BookCoverLarge]: size === 'large',
          })}
        >
          <BookCorner notifications={notifications} studentBook={book.studentBook} />
        </BookImage>
      )}
    </div>
  );
};

export const LibraryBookProgress: React.FC<{
  progress?: number;
  fullWidth?: boolean;
  tall?: boolean;
  fixedWidthProgress?: boolean;
  className?: string;
}> = ({ progress, fullWidth, tall, fixedWidthProgress, className }) =>
  progress !== undefined ? (
    <div
      className={classNames(styles.BookProgressBarBox, className, {
        [styles.BookProgressBarBoxFW]: fullWidth,
        [styles.BookProgressBarTall]: tall,
      })}
    >
      <LibraryBookProgressBar progress={progress} />
      <span
        className={classNames(
          styles.BookProgressBarValue,
          fixedWidthProgress && styles.BookProgressBarValueFW,
        )}
      >
        {Math.floor((Math.min(progress, 1) || 0) * 100)}%
      </span>
    </div>
  ) : null;

export const LibraryBookProgressBar: React.FC<{ progress?: number }> = ({ progress }) => (
  <span className={styles.BookProgressBarContainer}>
    <div className={styles.BookProgressBar} style={{ width: `${(progress || 0) * 100}%` }} />
  </span>
);
