import {
  IReportGenMultiProductClient,
  ReportGenMultiProductClient,
} from '@sparx/api/apis/sparx/reports/reportgen/v1/reportgen.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const reportGenMultiProductServiceId: interfaces.ServiceIdentifier<IReportGenMultiProductClient> =
  Symbol.for('reportgenMultiProductService');

/** Register a report gen service client with the sparx query container */
export const setReportGenMultiProductClient = (client: IReportGenMultiProductClient) => {
  container.bind(reportGenMultiProductServiceId).toConstantValue(client);
};

export const useReportGenMultiProductClient = (): IReportGenMultiProductClient =>
  useOptionalInjection(
    reportGenMultiProductServiceId,
    () => new ReportGenMultiProductClient(errorTransport),
  );
