import {
  faAirFreshener,
  faBook,
  faBookOpenReader,
  faCheck,
  faCrown,
  faEye,
  faGear,
  faHome,
  faListCheck,
  faSyncAlt,
  faUser,
  faUserGraduate,
  faUsers,
  faUsersRectangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import classNames from 'classnames';
import { ConfirmAlert } from 'components/alert/confirm-alert';
import { useAlert, useClearAlert } from 'components/alert/hooks';
import { Link } from 'components/link';
import { useSelectedGroups } from 'hooks/selected-groups';
import { useWondeImportEnabled } from 'hooks/wonde-import-enabled';
import { useEnglishStudentGroups } from 'queries/management';
import { useUser } from 'queries/session';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  Location,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Flags, useBooleanFlag, useFlag } from 'utils/featureflags';
import { getStudentGroupId } from 'utils/groups';
import { useHoverClickMenu } from 'utils/hooks';
import { View } from 'views';
import {
  CollapsibleSidebar,
  SidebarPrimaryButton,
  SidebarPrimaryLink,
} from 'views/teacher/components/collapsible-sidebar';
import { ReactComponent as HandInIcon } from 'views/teacher/components/icons/nav_handin.svg';
import { ReactComponent as InsightsIcon } from 'views/teacher/components/icons/nav_insights.svg';
import { ReactComponent as MoreIcon } from 'views/teacher/components/icons/nav_more.svg';
import { ReactComponent as ReportsIcon } from 'views/teacher/components/icons/nav_reports.svg';
import { useSparxStaffFeatures } from 'views/teacher/components/sparx-staff-features-context';
import styles from 'views/teacher/components/teacher-sidebar.module.css';
import { pathForView } from 'views/views';

const groupPages = new Set([
  '/teacher/classes',
  '/teacher/classes/homework',
  '/teacher/classes/classwork',
  '/teacher/classes/students',
  '/teacher/classes/insights',
  '/teacher/classes/readingtest',
  '/teacher/classes/bookmarks',
  '/teacher/leaderboard',
  '/teacher/leaderboard/class',
]);

const isGroupContextPage = (location: Location) => groupPages.has(location.pathname || '');

export const TeacherSidebar = () => {
  const [selectedGroups] = useSelectedGroups();
  const { data: groups } = useEnglishStudentGroups();

  // We don't display MIS tutor groups here.
  const location = useLocation();
  const user = useUser();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [lastGroup, setLastGroup] = useState<string | null>(null);

  const teacherGroupMembershipsEnabled = useBooleanFlag(Flags.TeacherGroupMemberships);
  const starredGroups = new Set(
    teacherGroupMembershipsEnabled
      ? groups?.filter(g => g.staff.find(s => s.staffID === user?.sparxUserId)).map(g => g.name)
      : [],
  );

  // Track the last group selected in the URL query string, so we can return to it if switching to a non-group view
  // and then back to a group view
  useEffect(() => {
    const group = searchParams.get('group');

    // Don't remember things from the live page:
    if (location.pathname === '/teacher/live') {
      if (lastGroup !== null) {
        setLastGroup(null);
      }
      return;
    }

    if (group && lastGroup !== group) {
      setLastGroup(group as string);
      return;
    }

    if (!group && lastGroup && isGroupContextPage(location)) {
      setSearchParams({ group: lastGroup });
    }
  }, [location, lastGroup, navigate, searchParams, setSearchParams]);

  const isTeacherLibraryEnabled = useFlag(Flags.TeacherLibrary, false);
  const { sparxStaffFeaturesEnabled } = useSparxStaffFeatures();

  const selectingGroup = groupPages.has(location.pathname);
  const toggleGroups = location.pathname === '/teacher/live';
  const showGroups = selectingGroup || toggleGroups;

  const getGroupSearchParams = (group: Group): URLSearchParamsInit => {
    if (selectingGroup) {
      return { group: getStudentGroupId(group) };
    } else if (toggleGroups) {
      let newSelectedGroups = [...selectedGroups];
      if (selectedGroups.find(g => g.name === group.name)) {
        newSelectedGroups = selectedGroups.filter(g => g.name !== group.name);
      } else {
        newSelectedGroups.push(group);
      }
      const groupIds = newSelectedGroups.map(g => getStudentGroupId(g));
      return { group: groupIds };
    }
    return {};
  };

  const groupList = (g: Group[]) =>
    g.map(group => (
      <div className={styles.TeacherSidebarGroup} key={group.name}>
        <Link
          to={{
            pathname: location.pathname,
            search: `?${createSearchParams(getGroupSearchParams(group))}`,
          }}
          className={classNames(styles.TeacherSidebarButton, {
            [styles.TeacherSidebarButtonActive]: selectedGroups.find(g => g.name === group.name),
          })}
          analyticsEvent={{
            category: 'navigation',
            action: 'select group',
            labels: {
              groupId: getStudentGroupId(group),
              selected: toggleGroups ? selectedGroups.join(',') : undefined,
              toggling: toggleGroups,
            },
          }}
          {...(!selectingGroup ? { 'data-prevent-hide': 'true' } : {})}
        >
          <div className={styles.TeacherSidebarClassSelect}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <span className={styles.TeacherSidebarButtonName}>{group.displayName}</span>
        </Link>
      </div>
    ));

  const starredGroupList = groups?.filter(g => starredGroups.has(g.name)) || [];
  const otherGroupList = groups?.filter(g => !starredGroups.has(g.name)) || [];

  return (
    <CollapsibleSidebar
      primary={
        <>
          <SidebarPrimaryLink
            name="Home"
            icon={faHome}
            to="/teacher"
            active={
              location.pathname === '/teacher/dashboard' ||
              location.pathname === '/teacher/dashboard/department'
            }
          />
          <SidebarPrimaryLink
            name="Hand-in"
            customIcon={HandInIcon}
            to={getGroupContextLink(selectedGroups, '/teacher/classes/homework')}
            active={location.pathname === '/teacher/classes/homework'}
            preventHide={true}
          />
          <SidebarPrimaryLink
            name="Insights"
            customIcon={InsightsIcon}
            to={getGroupContextLink(selectedGroups, '/teacher/classes/insights')}
            active={location.pathname === '/teacher/classes/insights'}
            preventHide={true}
          />
          <SidebarPrimaryLink name="Reporting" customIcon={ReportsIcon} to="/teacher/reports" />
          <SidebarPrimaryLink
            name="Leaderboard"
            icon={faCrown}
            active={location.pathname.startsWith('/teacher/leaderboard')}
            to={getGroupContextLink(selectedGroups, '/teacher/leaderboard/class')}
          />
          <SidebarPrimaryLink
            name="Live Monitoring"
            icon={faEye}
            to="/teacher/live"
            preventHide={true}
          />
          {isTeacherLibraryEnabled && (
            <SidebarPrimaryLink
              name="Library"
              icon={faBook}
              to={pathForView(View.TeacherLibrary)}
              preventHide={true}
              active={location.pathname.startsWith(pathForView(View.TeacherLibrary))}
            />
          )}
          {sparxStaffFeaturesEnabled && (
            <SidebarPrimaryLink
              name="EQM"
              icon={faAirFreshener}
              to={pathForView(View.EQM)}
              preventHide={true}
            />
          )}
          {/* WARNING: If adding any extra links to the sidebar, you will need
          to update the CSS styles for low screen height so that the more
          menu is not cut-off on low-height screens */}
          <TeacherMoreMenu selectedGroups={selectedGroups} />
        </>
      }
      secondary={
        <>
          {starredGroupList.length > 0 && (
            <>
              <h4>My classes</h4>
              {groupList(starredGroupList)}
            </>
          )}
          <h4>All classes:</h4>
          {groupList(otherGroupList)}
        </>
      }
      secondarySelect={selectingGroup || !showGroups}
      secondaryHidden={!showGroups}
      showClassAssignmentReminder={teacherGroupMembershipsEnabled && starredGroupList.length === 0}
    />
  );
};

interface ITeacherMoreMenuProps {
  selectedGroups: Group[];
}

const TeacherMoreMenu = ({ selectedGroups }: ITeacherMoreMenuProps) => {
  const location = useLocation();

  const setAlert = useAlert();
  const clearAlert = useClearAlert();
  const navigate = useNavigate();

  const misSyncImport = useWondeImportEnabled();

  const { onMouseAway, onMouseEnter, openMenu, menuOpen } = useHoverClickMenu();

  const isClassManagerEnabled = useBooleanFlag(Flags.TeacherGroupMemberships);

  const openStudentDemo = () =>
    setAlert(
      <StudentDemoAlert
        submit={() => {
          navigate('/library');
          clearAlert();
        }}
      />,
    );

  return (
    <div
      className={classNames(styles.MoreMenuWrapper, menuOpen && styles.MoreMenuWrapperOpen)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseAway}
    >
      <SidebarPrimaryButton onClick={openMenu} name={`More`} customIcon={MoreIcon} />
      <div className={classNames(styles.MoreMenu, menuOpen && styles.MoreMenuOpen)}>
        <SidebarPrimaryLink
          name="Reading Test"
          icon={faListCheck}
          to={getGroupContextLink(selectedGroups, '/teacher/classes/readingtest')}
          active={location.pathname === '/teacher/classes/readingtest'}
          preventHide={true}
        />
        <SidebarPrimaryLink
          name="Student Manager"
          icon={faUsers}
          to={getGroupContextLink(selectedGroups, '/teacher/classes/students')}
          active={location.pathname === '/teacher/classes/students'}
          preventHide={true}
        />
        <SidebarPrimaryLink name="Staff Manager" icon={faUserGraduate} to="/teacher/staff" />
        {isClassManagerEnabled && (
          <SidebarPrimaryLink
            name="Class Manager"
            icon={faUsersRectangle}
            to="/teacher/class-manager"
          />
        )}
        <SidebarPrimaryButton name="Student Demo" icon={faUser} onClick={openStudentDemo} />
        {misSyncImport && (
          <SidebarPrimaryLink
            name="Import and Manage Classes"
            icon={faSyncAlt}
            to={'/teacher/mis-sync-import'}
            active={location.pathname === '/teacher/mis-sync-import'}
          />
        )}
        <SidebarPrimaryLink
          name="Reading Matters"
          icon={faBookOpenReader}
          to="/teacher/reading-matters"
          preventHide={true}
        />
        <SidebarPrimaryLink
          name="School Settings"
          icon={faGear}
          to="/teacher/school-settings"
          active={location.pathname.startsWith('/teacher/school-settings')}
        />
      </div>
    </div>
  );
};

const getGroupContextLink = (selectedGroups: Group[], path: string) => {
  if (selectedGroups.length !== 1) {
    return path;
  }
  return `${path}?group=${getStudentGroupId(selectedGroups[0])}`;
};

const StudentDemoAlert = ({ submit }: { submit: () => void }) => (
  <ConfirmAlert
    title="Enter student demo?"
    confirmText={'Enter student demo'}
    rejectText={'Cancel'}
    onConfirm={submit}
    confirmAnalytics={{ category: 'student demo', action: 'enter' }}
    rejectAnalytics={{ category: 'student demo', action: 'dismiss' }}
  >
    <p>
      By entering the student demo you will be able to experience <strong>Sparx Reader</strong> as
      if you were a student.
    </p>
  </ConfirmAlert>
);
