import { Button } from '@sparx/design/components';
import { useIntercom } from '@sparx/intercom';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { useClientEvent } from 'components/client-events/use-client-event';

type ArticleId = number;
type QuickLink = 'home' | 'messages' | 'help' | 'newmessage';

type IntercomLink = ArticleId | QuickLink;

type Props = {
  to: IntercomLink;
  children: React.ReactNode;
  analyticsEvent?: AnalyticEventFields;
};

export const IntercomLink = ({ to, children, analyticsEvent }: Props) => {
  const { sendEvent } = useClientEvent();
  const { showArticle, showSpace, showNewMessage } = useIntercom();

  const onClick = () => {
    analyticsEvent && sendEvent(analyticsEvent);

    if (typeof to === 'number') {
      showArticle(to);
    } else if (to === 'newmessage') {
      showNewMessage();
    } else {
      showSpace(to);
    }
  };

  return (
    <Button variant="link" onClick={onClick}>
      {children}
    </Button>
  );
};
