import { Flags, useBooleanFlag } from 'utils/featureflags';

import { GettingStartedWidget } from './getting-started-widget/getting-started-widget';
import styles from './home-view.module.css';
import { InterimWidget } from './interim-widget/interim-widget';
import { NoClassesWidget } from './no-classes/no-classes';
import { PostResetWidget } from './post-reset-widget/post-reset-widget';
import { PraiseStats } from './praise-stats/praise-stats';
import { TrainingDashboardWidget } from './training-dashboard-widget/training-dashboard-widget';

export const HomeViewTeacher = ({
  isInterim,
  showPostReset,
  showGettingStarted,
  trainingWidgetWidth,
  showNoClassesWidget,
  dismissNoClassesWidget,
  openClassSelectModal,
}: {
  showPostReset: boolean;
  showGettingStarted: boolean;
  trainingWidgetWidth: 'narrow' | 'wide';
  isInterim: boolean;
  showNoClassesWidget: boolean;
  dismissNoClassesWidget: (now: Date) => void;
  openClassSelectModal: () => void;
}) => {
  const teacherGroupMembershipsEnabled = useBooleanFlag(Flags.TeacherGroupMemberships);
  return (
    <>
      {showNoClassesWidget && (
        <NoClassesWidget dismiss={dismissNoClassesWidget} openModal={openClassSelectModal} />
      )}
      <div className={styles.Widgets}>
        {isInterim && <InterimWidget />}
        {showPostReset && <PostResetWidget />}
        {showGettingStarted && <GettingStartedWidget />}
        <TrainingDashboardWidget width={trainingWidgetWidth} />
      </div>

      <PraiseStats
        classSpecific={teacherGroupMembershipsEnabled}
        showEmptyState={showNoClassesWidget}
        openClassSelectModal={openClassSelectModal}
      />
    </>
  );
};
